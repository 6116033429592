import React from "react";
import NumberToWords3 from "../utility/component/amountinWords3";

const Footer = ({ columns, rows, partyDetails }) => {
  const disqamt = rows.reduce((acc, row) => acc + Number(row.DISQ_AMT), 0);
  const tax1amt = rows.reduce((acc, row) => acc + Number(row.TAX1_AMT), 0);
  const tax2amt = rows.reduce((acc, row) => acc + Number(row.TAX2_AMT), 0);
  const amount = rows.reduce((acc, row) => acc + Number(row.AMOUNT), 0);
  const grandTotal = rows.reduce((acc, row) => acc + Number(row.NET_AMT), 0);
  let grandTotalRoundedValue = Math.floor(grandTotal);
  let gTotalformattedValue = grandTotalRoundedValue.toFixed(2);

  const cess = rows.reduce((acc, row) => acc + Number(row.CESS_AMOUNT), 0);
  const tcs = rows.reduce((acc, row) => acc + Number(row.TCS_AMOUNT), 0);
  const vat = rows.reduce((acc, row) => acc + Number(row.VAT_AMOUNT), 0);
  const vat_rate = rows.reduce((acc, row) => acc + Number(row.VAT_RATE), 0);
  const scheme = rows.reduce((acc, row) => acc + Number(row.SCHEME_AMOUNT), 0);
  const roundOff = grandTotal % 1;

  const freightTotal =
    grandTotal + (+partyDetails.FREIGHT ? parseFloat(partyDetails.FREIGHT) : 0);
  let roundedValue = Math.floor(freightTotal);
  let formattedValue = roundedValue.toFixed(2);

  const hsnCodes = rows.map((row) => row.HSN_CODE);
  const uniqueHsnCodes = Array.from(new Set(hsnCodes));
  const hsnTotals = {};
  const hsnList = [];
  uniqueHsnCodes.forEach((hsnCode) => {
    const filteredRows = rows.filter((row) => row.HSN_CODE === hsnCode);
    const totalAmountForHsnCode = filteredRows.reduce(
      (total, row) => total + Number(row.AMOUNT),
      0
    );
    hsnList.push({
      hsn: hsnCode,
      rate:
        Number(filteredRows[0].TAX1_RATE) + Number(filteredRows[0].TAX2_RATE),
    });
    hsnTotals[hsnCode] = totalAmountForHsnCode;
  });
  const taxrates = rows.map((row) => row.TAX1_RATE);

  const uniqueHsnCodestax = Array.from(new Set(taxrates));
  const taxRate = {};
  uniqueHsnCodestax.forEach((taxRateValue) => {
    const totalTaxRateForTaxRateValue = rows
      .filter((row) => row.TAX1_RATE === taxRateValue)
      .reduce(
        (total, row) => total + Number(row.TAX1_RATE) + Number(row.TAX2_RATE),
        0
      );

    taxRate[taxRateValue] = totalTaxRateForTaxRateValue;
  });
  const surcharge = rows.reduce(
    (acc, row) => acc + Number(row.SURCHARGE_AMT),
    0
  );

  const discount = {};
  uniqueHsnCodes.forEach((hsnCode) => {
    const totalTaxRateForHsnCode = rows
      .filter((row) => row.HSN_CODE === hsnCode)
      .reduce((total, row) => total + Number(row.DISQ_AMT), 0);

    discount[hsnCode] = totalTaxRateForHsnCode;
  });
  const taxAmount = {};
  uniqueHsnCodes.forEach((hsnCode) => {
    const totalTaxRateForHsnCode = rows
      .filter((row) => row.HSN_CODE === hsnCode)
      .reduce(
        (total, row) => total + Number(row.TAX1_AMT) + Number(row.TAX2_AMT),
        0
      );

    taxAmount[hsnCode] = totalTaxRateForHsnCode;
  });

  const total = {};
  uniqueHsnCodes.forEach((hsnCode) => {
    const totalTaxRateForHsnCode = rows
      .filter((row) => row.HSN_CODE === hsnCode)
      .reduce((total, row) => total + Number(row.NET_AMT), 0);

    total[hsnCode] = totalTaxRateForHsnCode;
  });

  const disq = rows.reduce((acc, row) => acc + Number(row.DISQ_AMT), 0)
  return (
    <div
      id="main"
      style={{ width: "100%", height: "100%", backgroundColor: "white" }}
    >
      <div
        className="flex font-[700]"
        style={{
          width: "100%",
          borderLeft: "1px solid black",
          borderTop: "1px solid black",
        }}
      >
        <div
          style={{
            width: columns[0].width + columns[1].width + columns[2].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1 "
        >
          Total
        </div>
        <div
          style={{
            width: columns[3].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        >
          {rows.reduce((acc, row) => acc + Number(row.QNTY), 0)}
        </div>
        <div
          style={{
            width: columns[4].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        ></div>
        <div
          style={{
            width: columns[5].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        ></div>
        <div
          style={{
            width: columns[6].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        >
          {disqamt.toFixed(2)}
        </div>
        <div
          style={{
            width: columns[7].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        >
          {amount.toFixed(2)}
        </div>

        <div
          style={{
            width: columns[8].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        >
          {tax1amt.toFixed(2)}
        </div>

        <div
          style={{
            width: columns[9].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        >
          {tax2amt.toFixed(2)}
        </div>
        <div
          style={{
            width: columns[10].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        ></div>
        <div
          style={{
            width: columns[11].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        >
          {""}
        </div>
        <div
          style={{
            width: columns[12].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        >
          {grandTotal.toFixed(2)}
        </div>
      </div>

      <div
        className="flex"
        style={{
          width: "100%",
          border: "1px solid black",
          borderBottom: "0px solid black",
          borderRight: "0px solid black",
          
        }}
      >
        <div
          style={{
            width: columns[0].width + columns[1].width,
            borderRight: "1px solid black",
          }}
          className="flex p-1"
        >
          Add Chrages :
          {partyDetails.ADD_CHARGES ? partyDetails.ADD_CHARGES : "0"}
        </div>

        <div
          style={{
            width: columns[2].width + columns[3].width + columns[4].width,

            borderRight: "1px solid black",
          }}
          className="flex p-1"
        >
          Surcharge :{surcharge ? surcharge.toFixed(2) : "0"}
        </div>

        <div
          style={{
            width: columns[5].width + columns[6].width + columns[7].width,
            borderRight: "1px solid black",
          }}
          className="flex  p-1"
        >
          Cess : {cess.toFixed(2)}
        </div>

        <div
          style={{
            width:
              columns[8].width +
              columns[9].width +
              columns[10].width +
              columns[11].width,

            borderRight: "1px solid black",
          }}
          className="flex  p-1"
        >
          Vat % : {vat_rate.toFixed(2)}
        </div>
        <div
          style={{
            width:
            
              columns[12].width,

            borderRight: "1px solid black",
          }}
          className="flex  p-1"
        >
          
        </div>
      </div>

      <div
        className="flex"
        style={{
          width: "100%",
          borderLeft: "1px solid black",

        }}
      >
        <div
          style={{
            width: columns[0].width + columns[1].width,
            borderRight: "1px solid black",
          }}
          className="flex p-1"
        >
          Less Chrages :
          {partyDetails.LESS_CHARGES ? partyDetails.LESS_CHARGES : "0"}
        </div>

        <div
          style={{
            width: columns[2].width + columns[3].width + columns[4].width,

            borderRight: "1px solid black",
          }}
          className="flex p-1"
        ></div>

        <div
          style={{
            width: columns[5].width + columns[6].width + columns[7].width,
            borderRight: "1px solid black",
          }}
          className="flex  p-1"
        >
          TCS : {tcs.toFixed(2)}
        </div>

        <div
          style={{
            width: columns[8].width + columns[9].width + columns[10].width,
          }}
          className="flex  p-1"
        >
          Vat Amount : {vat.toFixed(2)}
        </div>

        <div
          style={{
            width: columns[11].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        >
          Scheme
        </div>

        <div
          style={{
            width: columns[12].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        >
          {scheme.toFixed(2)}
        </div>
      </div>

      <div
        className="flex"
        style={{
          width: "100%",
          border: "1px solid black",
          borderTop: "1px solid black",
          borderBottom: "1px solid black",
          borderRight: "0px  solid black",
        }}
      >
        <div
          style={{
            width:
              columns[0].width +
              columns[1].width +
              columns[2].width +
              columns[3].width +
              columns[4].width +
              columns[5].width +
              columns[6].width +
              columns[7].width +
              columns[8].width +
              columns[9].width +
              columns[11].width +
              columns[10].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        >
          Discount
        </div>

        <div
          style={{
            width: columns[12].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        >
          {disq.toFixed(2)}
        </div>
      </div>

      <div
        className="flex"
        style={{
          width: "100%",
          border: "1px solid black",
          borderBottom: "0px  solid black",
          borderRight: "0px  solid black",
          borderTop: "0px  solid black",
        }}
      >
        <div
          style={{
            width:
              columns[0].width +
              columns[1].width +
              columns[2].width +
              columns[3].width +
              columns[4].width +
              columns[5].width +
              columns[6].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        ></div>
        <div
          style={{
            width:
              columns[7].width +
              columns[8].width +
              columns[9].width +
              columns[11].width +
              columns[10].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        >
          Freight
        </div>

        <div
          style={{
            width: columns[12].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        >
          {partyDetails.FREIGHT ? partyDetails.FREIGHT : "0"}
        </div>
      </div>

      <div
        className="flex"
        style={{
          width: "100%",
          border: "1px solid black",
          borderTop: "0px  solid black",
          borderBottom: "0px  solid black",
          borderRight: "0px  solid black",
        }}
      >
        <div
          style={{
            width:
              columns[0].width +
              columns[1].width +
              columns[2].width +
              columns[3].width +
              columns[4].width +
              columns[5].width +
              columns[6].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        ></div>
        <div
          style={{
            width:
              columns[7].width +
              columns[8].width +
              columns[9].width +
              columns[11].width +
              columns[10].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        >
          Round Off
        </div>

        <div
          style={{
            width: columns[12].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1"
        >
          {roundOff.toFixed(2)}
        </div>
      </div>

      <div
        className="flex"
        style={{
          width: "100%",
          borderTop: "1px solid black",
          borderLeft: "1px solid black",
          borderBottom: "0px solid black",
        }}
      >
        <div
          style={{
            width:
              columns[0].width +
              columns[1].width +
              columns[2].width +
              columns[3].width +
              columns[4].width +
              columns[5].width +
              columns[6].width,
            borderRight: "1px solid black",
          }}
          className="flex p-1"
        >
          {partyDetails.FREIGHT
            ? NumberToWords3(formattedValue)
            : NumberToWords3(gTotalformattedValue)}
        </div>
        <div
          style={{
            width:
              columns[7].width +
              columns[8].width +
              columns[9].width +
              columns[11].width +
              columns[10].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1 font-[700]"
        >
          Net Amount Payable
        </div>

        <div
          style={{
            width: columns[12].width,
            borderRight: "1px solid black",
          }}
          className="flex justify-end p-1 font-[700]"
        >
          {partyDetails.FREIGHT ? formattedValue : gTotalformattedValue}
        </div>
      </div>

      <div
        className="flex items-center justify-center font-[700]"
        style={{
          fontSize: "12px",
          border: "1px solid black",
        }}
      >
        <div className="px-2">
          <div>HSN Code</div>
          <table>
            <tbody>
              {uniqueHsnCodes.map((hsnCode, index) => (
                <tr key={index}>
                  <td>{hsnCode}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="px-2">
          <div>Tax Rate</div>
          <table>
            <tbody>
              {hsnList.map((i, index) => {
                return (
                  <tr key={index}>
                    <td>{i.rate + "%"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="px-2">
          <div>Taxable</div>
          <table>
            <tbody>
              {uniqueHsnCodes.map((hsnCode, index) => (
                <tr key={index}>
                  <td>{hsnTotals[hsnCode].toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="px-2">
          <div>Discount</div>
          <table>
            <tbody>
              {uniqueHsnCodes.map((hsnCode, index) => (
                <tr key={index}>
                  <td>{discount[hsnCode].toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="px-2">
          <div>Tax Amount</div>
          <table>
            <tbody>
              {uniqueHsnCodes.map((hsnCode, index) => (
                <tr key={index}>
                  <td>{taxAmount[hsnCode].toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="px-2">
          <div>Total</div>
          <table>
            <tbody>
              {uniqueHsnCodes.map((hsnCode, index) => (
                <tr key={index}>
                  <td>{total[hsnCode].toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Footer;
