import axios from 'axios';
import React from 'react'
import { setSessionExpired, setSpinnerLoading } from '../utility/Redux/modal';

const checkLastPurchasePrice = (prefix,partyName,icode,price,token,dispatch) => {
  dispatch(setSpinnerLoading('Last Purchase Price'))
  const data = {
    "table_prefix": prefix,
    "NAME":partyName,
    "ICODE": icode
  }
    return new Promise((resolve, reject) => {
        axios
          .post(`/api/purchase/lastPurchasePrice`, data, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (!res.data.error) {
              const data = res.data.response;
              resolve(data);
            } else {
              reject(new Error('Error in response data'));
            }
          })
          .catch((e) => {
            if (e.response.data.name === 'TokenExpiredError') {
              dispatch(setSessionExpired(true));
            }
            console.log(e);
            reject(e);
          })
          .finally(() => {
            dispatch(setSpinnerLoading(false));
          });
      });
}

export default checkLastPurchasePrice