import moment from "moment";
import { FormatNumber2 } from "../../utility/functions/formatNumber";

export const PurchaseSummaryColumns = (width) => {
  const columns = [
    {
      field: "DATE",
      accessor: "DATE",
      type: "date",
      headerAlign: "center",

      width: width < 768 ? 100 : 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value ? moment(params.value).format("DD-MM-yyyy") : ""}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Date
        </div>
      ),
    },
    {
      field: "ICODE",
      accessor: "ICODE",
      type: "string",
      headerAlign: "center",
      width: width < 768 ? 150 : width < 1400 ? 80 : 80,
      headerClassName: "super-app-theme--header",

      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Code
        </div>
      ),
    },

    {
      field: "ITEMNAME",
      accessor: "ITEMNAME",
      type: "string",
      width: width < 768 ? 100 : width < 1400 ? 180 : 180,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Item Name
        </div>
      ),
    },
    {
      field: "ACCOUNT_NO",
      accessor: "ACCOUNT_NO",
      type: "string",
      headerAlign: "center",
      width: width < 768 ? 150 : width < 1400 ? 80 : 80,
      headerClassName: "super-app-theme--header",

      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Acc No.
        </div>
      ),
    },
    {
      field: "NAME",
      accessor: "NAME",
      type: "string",
      width: width < 768 ? 100 : width < 1400 ? 180 : 160,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Party Name
        </div>
      ),
    },
    {
      field: "PQNTY",
      accessor: "PQNTY",
      type: "string",
      headerAlign: "center",
      width: width < 768 ? 150 : width < 1400 ? 80 : 80,
      headerClassName: "super-app-theme--header",

      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Pur. Qnty
        </div>
      ),
    },
    {
      field: "AMOUNT",
      accessor: "AMOUNT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Amount
        </div>
      ),
    },
    {
      field: "SALE_QNTY",
      accessor: "SALE_QNTY",
      type: "string",
      headerAlign: "center",
      width: width < 768 ? 150 : width < 1400 ? 80 : 80,
      headerClassName: "super-app-theme--header",

      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Sale Qnty
        </div>
      ),
    },
    {
      field: "SALE_AMT",
      accessor: "SALE_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 120,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Sale Amount
        </div>
      ),
    },
  ];

  const tabColumns = [
    {
      field: "INVNO",
      accessor: "INVNO",
      type: "string",
      headerAlign: "center",
      width: 60,
      headerClassName: "super-app-theme--header",

      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Invoice
        </div>
      ),
    },

    {
      field: "PUR_BILL_DATE",
      accessor: "PUR_BILL_DATE",
      type: "date",
      headerAlign: "center",

      width: 80,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value ? moment(params.value).format("DD-MM-yyyy") : ""}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Pur. Bill Date
        </div>
      ),
    },
    {
      field: "PUR_BILL_NO",
      accessor: "PUR_BILL_NO",
      type: "string",
      headerAlign: "center",

      width: 110,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value ? params.value : ""}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Pur. Bill No.
        </div>
      ),
    },
    {
      field: "BILL_TYPE",
      accessor: "BILL_TYPE",
      type: "string",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Bill Type
        </div>
      ),
    },

    {
      field: "NAME",
      accessor: "NAME",
      type: "string",
      width: 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Party Name
        </div>
      ),
    },

    {
      field: "MOBILE",
      accessor: "MOBILE",
      type: "number",
      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      headerClassName: "super-app-theme--header",
      width: 90,
      headerAlign: "center",
      align: "left",

      renderHeader: (params) => (
        <div className="text-white  whitespace-normal" style={{ fontSize: 13 }}>
          {" "}
          Mobile
        </div>
      ),
    },

    {
      field: "DISQ_AMT",
      accessor: "DISQ_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 70,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Discount
        </div>
      ),
    },

    {
      field: "TOTAL",
      accessor: "TOTAL",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 70,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Total
        </div>
      ),
    },
    {
      field: "TAX1_RATE",
      accessor: "TAX1_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 50,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value !== "0" ? FormatNumber2(param.value) + " %" : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          CGST
        </div>
      ),
    },
    {
      field: "TAX1_AMT",
      accessor: "TAX1_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 70,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          CGST
        </div>
      ),
    },
    {
      field: "TAX2_RATE",
      accessor: "TAX2_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 50,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== "0" ? FormatNumber2(param.value) + " %" : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          SGST
        </div>
      ),
    },
    {
      field: "TAX2_AMT",
      accessor: "TAX2_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 70,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== 0 ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          SGST
        </div>
      ),
    },
    {
      field: "TAX3_RATE",
      accessor: "TAX3_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 50,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== "0" ? FormatNumber2(param.value) + " %" : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          IGST
        </div>
      ),
    },
    {
      field: "TAX3_AMT",
      accessor: "TAX3_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 70,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== 0 ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          IGST
        </div>
      ),
    },

    {
      field: "ROUND_OFF",
      accessor: "ROUND_OFF",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 70,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Round Off
        </div>
      ),
    },

    {
      field: "GTOTAL",
      accessor: "GTOTAL",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Grand Total
        </div>
      ),
    },
  ];
  return width < 1281 ? tabColumns : columns;
};
