import React, { useEffect, useRef, useState } from 'react';

const GeniusSelect = ({country, onClickOpen, width,onKeyDown, value, options, height, handleClick, onChange,placeholder ,type,id,fontStyle,error}) => {
    const [focus, setFocus] = useState(false);
    const [fullOption, setFullOption] = useState(options);
    const myDivRef = useRef(null);
    const [selectOpen, setSelectOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
  const [border, setBorder] = useState('#A09E9E');
    const handleOptionClick = (optionValue) => {
        setSelectOpen(false); // Close the dropdown after selecting an option
        setFocus(false); // Reset focus
        if (handleClick) {
            handleClick(optionValue);
        }
    };

    const handleKeyDown = (e) => {
        if(selectOpen){
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, fullOption.length - 1));
                scrollOptionIntoView();
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
                scrollOptionIntoView();
            } else if (e.key === 'Enter') {
                setSelectOpen(false); // Close the dropdown after selecting an option
                setFocus(false); // Reset focus
                if(fullOption[0]){
                    handleOptionClick(fullOption[selectedIndex ]?.value);
    
                }else{
                    onKeyDown(e)
                }
            }
        }else{

            onKeyDown(e)

        }
     
    };

    const scrollOptionIntoView = () => {
        const selectedOption = document.querySelector('.selected-option');
        if (selectedOption) {
            selectedOption.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (myDivRef.current && !myDivRef.current.contains(event.target)) {
                setSelectOpen(false); // Close the dropdown after selecting an option
                setFocus(false); // Reset focus
            }
        }

        // Attach event listener when component mounts
        document.addEventListener('click', handleClickOutside);

        // Detach event listener when component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(()=>{
    if(error && value){
    if(error ==='mobile'){
        const phoneRegex = /^[6-9]\d{9}$/;
        if(phoneRegex.test(value)){
        setBorder('#A09E9E')
        }else{
        setBorder('red')
        }
    }
    }else{
        if(!value){
            setBorder('#A09E9E')
        }
}
    },[value])
    return (
        <div ref={myDivRef} className="text-sm">
            <div
                style={{
                    width: width,
                    border: `${focus ? '1px solid #43bff7' : `1px solid ${border}`}`,
                    borderBottom: `${
                        focus && !selectOpen ? '1px solid #43bff7' : focus && selectOpen ? '0px solid #43bff7' : `1px solid ${border}`
                    }`,
                    borderTopRightRadius: selectOpen ? '7px' : '7px',
                    borderTopLeftRadius: selectOpen ? '7px' : '7px',
                    borderBottomLeftRadius: selectOpen ? '0px' : '7px',
                    borderBottomRightRadius: selectOpen ? '0px' : '7px',
                }}
                className="flex bg-white overflow-hidden"
            >
                <input
                    onKeyDown={handleKeyDown}
                    id={id?id:""}
                    style={fontStyle}
                    onClick={() => {
                        if (fullOption[0]) {
                            setSelectOpen((e) => !e);
                        } else {
                            setSelectOpen((e) => false);
                        }
                        setFocus((e) => true);
                    }}
                    onBlur={() => {
                        setFocus(false);
                    }}
                    type={type?type :'text'}
                    value={value}
                    placeholder={placeholder}
                    className="outline-none w-full p-1 placeholder:font-normal"
                    onChange={(e) => {
                        if (fullOption[0]) {
                            setSelectOpen(true);
                        }
                        setFullOption((o) => options.filter((f) => f.name.toLowerCase().includes(e.target.value)));
                        if (onChange) {
                            onChange(e);
                        }
                    }}
                />
                <i
                    onClick={() =>
                        setSelectOpen((e) => {
                            setFocus(true);
                            return !e;
                        })
                    }
                    className="bi p-2 bi-chevron-down text black"
                ></i>
            </div>
            {selectOpen && (
                <div
                    style={{
                        border: '1px solid #43bff7',
                        borderTop: '0px solid #43bff7',
                        width,
                        height,
                        borderBottomLeftRadius: '7px',
                        borderBottomRightRadius: '7px',
                    }}
                    className="absolute bg-white overflow-hidden"
                >
                    <div className="overflow-y-scroll h-full">
                        {fullOption.map((e, k) => {
                            return (
                                <div
                                    onClick={() => handleOptionClick(e.value)}
                                    key={k}
                                    className={`w-full p-1 text-start hover:bg-[#a7a4a4] hover:text-white ${
                                        selectedIndex === k ? 'bg-[#a7a4a4]  selected-option text-black' : ''
                                    }`}
                                >
                                    {country && <span class={`fi fi-${e.iso.toLowerCase()} mr-2`}></span> }
                                    {e.value}{' '}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default GeniusSelect;
