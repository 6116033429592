import { setBillSattalmentModalStatus } from '../../Redux/modal';

export const handleSignout = (
  dispatch,
  navigate,

) => {
 

            localStorage.removeItem('userType');
            localStorage.removeItem('stationName');
            localStorage.removeItem('stationId');
            localStorage.removeItem('billDetails');
            localStorage.removeItem('userName');
            localStorage.removeItem('count');
            localStorage.removeItem('tkn');
            localStorage.removeItem('company');
            localStorage.removeItem('histryId');
            localStorage.removeItem('prefix');
            localStorage.removeItem('expiresAt');
            localStorage.removeItem('orderdItemList');
            localStorage.removeItem('email');
            localStorage.removeItem('dev');
            localStorage.removeItem('devloper');
            localStorage.removeItem('userDefaultSetting');
            localStorage.removeItem('statusField');
            navigate('/auth/login');
            window.location.reload();
       
};
