import React from "react";
import CallAPI from "../../utility/functions/getData";
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import dataToLocal from "../../utility/component/dataToLocal";
import deleteWholeDatabase from "../../utility/functions/deleteWholeDatabase";
export const getStockOpeningValue = (data, token, dispatch) => {
  return new Promise((resolve, reject) => {
    CallAPI(
      "/api/purchaseReports/StockOpeningValue",
      data,
      token,
      (e) => {
        if (!e.error) {
          resolve(e.data.response);
        } else {
          resolve([]);
        }
      },
      dispatch
    );
  });
};

export const GetPurchaseSummary = (data, token, dispatch, setPurchaseList) => {
  dispatch(setSpinnerLoading("Getting purchase summary"));
  deleteWholeDatabase("Closing_Value_Report");
  CallAPI(
    "/api/purchaseReports/DailyStockMovement",
    data,
    token,
    (e) => {
      if (!e.data.error) {
        {
          const res = e.data.response;
          if (res[0]) {
            getStockOpeningValue(data, token, dispatch).then((promisRes) => {
              const prv_pur_amt = promisRes[0]
                ? promisRes[0].PRV_PUR_AMOUNT
                : 0;
              const prv_Sale_amt = promisRes[0]
                ? promisRes[0].PRV_SALE_AMOUNT
                : 0;
              console.log(prv_pur_amt);
              console.log(prv_Sale_amt);
              let list = [];
              let opening = 0;
              let closing = 0;
              for (let i = 0; i < res.length; i++) {
                if (i === 0) {
                  // first added opening
                  opening = prv_pur_amt - prv_Sale_amt;
                  closing = opening + res[i].PUR_AMOUNT - res[i].SALE_AMOUNT;
                }
                if (i !== 0) {
                  closing = opening + res[i].PUR_AMOUNT - res[i].SALE_AMOUNT;
                }
                list.push({
                  ...res[i],
                  closing: closing,
                  opening: opening,
                });

                opening = closing;
              }

              console.log(list);
              setPurchaseList(list);
              dataToLocal("Closing_Value_Report", list, [
                {
                  ...data,
                  ICODE: 1,
                },
              ]);
            });
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "No Records found",
              })
            );
          }
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something Went Wrong.",
          })
        );
      }
    },
    dispatch,
    () => {
      dispatch(setSpinnerLoading(false));
    }
  );
};
