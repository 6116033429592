import moment from "moment";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import { useRef, useState } from "react";

export const PurchaseColumns = (width) => {
  const columns = [
    {
      field: "ICODE",
      accessor: "ICODE",
      type: "string",
      headerAlign: "center",
      width: 180,
      align: "left",
      headerClassName: "super-app-theme--header",
      editable: true,
      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Code
        </div>
      ),
    },

    {
      field: "ITEMNAME",
      accessor: "ITEMNAME",
      type: "string",
      headerAlign: "center",
      align: "left",

      width: 180,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Item Name
        </div>
      ),
    },
    {
      field: "UNIT",
      accessor: "UNIT",
      type: "string",
      headerAlign: "center",
      align: "left",

      width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Unit
        </div>
      ),
    },
    {
      field: "PACKING",
      accessor: "PACKING",
      type: "string",
      headerAlign: "center",
      align: "left",

      width: 80,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Packing
        </div>
      ),
    },
    {
      field: "HSN_CODE",
      accessor: "HSN_CODE",
      type: "string",
      width: 100,
      align: "left",

      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          HSN Code
        </div>
      ),
    },
    {
      field: "QNTY",
      accessor: "QNTY",
      type: "text",
      align: "left",

      width: 90,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      editable: true,
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Quantity
        </div>
      ),
    },
    {
      field: "FREE_QNTY",
      accessor: "FREE_QNTY",
      type: "text",
      align: "left",

      width: 70,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      editable: true,
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Free Qnty
        </div>
      ),
    },
    {
      field: "PUR_PRICE",
      accessor: "PUR_PRICE",
      type: "number",
      width: 70,
      align: "right",
      editable: true,
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Rate
        </div>
      ),
    },

    {
      field: "AMOUNT",
      accessor: "AMOUNT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Amount
        </div>
      ),
    },
    {
      field: "DISQ_RT",
      accessor: "DISQ_RT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 70,
      editable: true,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Disq %
        </div>
      ),
    },
    {
      field: "DISQ_AMT",
      accessor: "DISQ_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 80,
      align: "right",
      editable: true,
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Disq Amt.
        </div>
      ),
    },
    {
      field: "TAX1_RATE",
      accessor: "TAX1_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 58,
      align: "right",
      editable: true,

      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          CGST %
        </div>
      ),
    },
    {
      field: "TAX1_AMT",
      accessor: "TAX1_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 60,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          CGST
        </div>
      ),
    },
    {
      field: "TAX2_RATE",
      accessor: "TAX2_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 58,
      align: "right",
      editable: true,

      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          SGST %
        </div>
      ),
    },
    {
      field: "TAX2_AMT",
      accessor: "TAX2_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 60,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          SGST
        </div>
      ),
    },
    {
      field: "TAX3_RATE",
      accessor: "TAX3_RATE",
      type: "number",
      editable: true,

      headerClassName: "super-app-theme--header",
      width: 58,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          IGST %
        </div>
      ),
    },
    {
      field: "TAX3_AMT",
      accessor: "TAX3_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 60,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          IGST
        </div>
      ),
    },
    {
      field: "VAT_RATE",
      accessor: "VAT_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 58,
      align: "right",
      editable: true,
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          VAT %
        </div>
      ),
    },
    {
      field: "VAT_AMOUNT",
      accessor: "VAT_AMOUNT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 80,
      align: "right",
      editable: false,

      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          VAT Amt.
        </div>
      ),
    },
    {
      field: "TCS_RATE",
      accessor: "TCS_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 58,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          TCS %
        </div>
      ),
    },
    {
      field: "TCS_AMT",
      accessor: "TCS_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          TCS Amt.
        </div>
      ),
    },
    {
      field: "SURCHARGE_RATE",
      accessor: "SURCHARGE_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 90,
      align: "right",
      editable: true,
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Surcharge %
        </div>
      ),
    },
    {
      field: "SURCHARGE_AMT",
      accessor: "SURCHARGE_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 120,
      align: "right",
      editable: false,
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Surcharge Amt.
        </div>
      ),
    },
    {
      field: "NET_AMT",
      accessor: "NET_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : ""}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Net Amount
        </div>
      ),
    },
    {
      field: "SALE_PRICE",
      accessor: "SALE_PRICE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : ""}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Sale Price
        </div>
      ),
    },
    {
      field: "BATCH",
      accessor: "BATCH",
      headerClassName: "super-app-theme--header",
      editable: true,
      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Batch
        </div>
      ),
      width: 100,
      headerAlign: "center",
      align: "left",
      type: "string",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center  justify-end `}>
            {param.value}
          </div>
        );
      },
    },
    {
      field: "EXP_DATE",
      accessor: "EXP_DATE",
      editable: true,
      type: "date",
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Expiry Date
        </div>
      ),
      width: 160,
      headerAlign: "center",
      align: "left",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full flex items-center justify-end`}>
            {param.value && /^\d{4}-\d{2}-\d{2}$/.test(param.value)
              ? moment(param.value).format("DD-MM-YYYY")
              : ""}
          </div>
        );
      },
    },
  ];

  return columns;
};
