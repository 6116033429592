import moment from "moment";
import React from "react";
import { useTable } from "react-table";
import "../styles/purchasePrint.css";
const PurchasePrint = ({
  columns,
  data,
  footer,
  customeHeader,
  marginTop,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });


  return (
    <div
      id="main"
      style={{
        width: columns.reduce((acc, row) => acc + Number(row.width), 0) + 50,
        border: "1px solid #888c89",
        height: "100%",
        backgroundColor: "white",
      }}
      className="flex flex-col items-center"
    >
      <div
        id="print-header"
        style={{
          width: columns.reduce((acc, row) => acc + Number(row.width), 0) ,
        }}
        className="  items-center  my-3"
      >
        <div
          style={{ width: "100%", }}
          className="flex justify-center items-center   "
        >
          {customeHeader && customeHeader}
        </div>
      </div>

      {/* <div id="print-component">
        <div
          style={
            window.innerWidth < 600
              ? {
                  width: "100%",
                  height: "auto",
                  overflow: "hidden",
                  marginTop: marginTop ? marginTop : "10%",
                  borderTop: "1px solid black",
                }
              : {
                  width: "100%",
                  height: "auto",
                  overflow: "hidden",
                  borderTop: "1px solid black",
                  marginTop: marginTop ? marginTop : "2.5",

                  borderBottom: "1px solid black",
                }
          }
          className={`ml-[1px] `}
        >
              <table
            {...getTableProps()}
            style={{
              width: columns.reduce((acc, row) => acc + Number(row.width), 0),
            }}
          >
            <thead className="">
          {headerGroups.map((headerGroup) => (
            <tr
              style={{ fontSize: "14px" }}
              className="bg-white] text-black  "
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, index) => {
                return (
                  <td
                    {...column.getHeaderProps()}
                    className="whitespace-normal  text-center py-2 "
                    style={
                      columns[index].width
                        ? {
                            width: columns[index].width,
                            fontWeight: 700,
                            borderRight:
                              index < columns.length
                                ? "1px solid #888c89"
                                : "1px solid #8888c89",
                                borderLeft:
                                index===0
                                  ? "1px solid #888c89"
                                  : "0px solid #8888c89",
                          }
                        : {
                            flex: columns[index].flex,
                            fontWeight: 500,
                            borderRight:
                              index < columns.length
                                ? "1px solid #888c89"
                                : "1px solid #8888c89",
                          }
                    }
                  >

                    {columns[index].renderHeader().props.children}
                  </td>
                );
              })}
            </tr>
          ))}
          </thead>
          </table>
        </div>
        <div
          style={
            window.innerWidth < 600
              ? {
                  width: "100%",
                  height: "auto",
                  overflow: "hidden",
                }
              : {
                  width: "100%",
                  height: "auto",
                  overflow: "hidden",
                }
          }
          className=""
        >
          <table
            {...getTableProps()}
            style={{
              width: columns.reduce((acc, row) => acc + Number(row.width), 0),
            }}
          >
            <thead className="">
              {headerGroups.map((headerGroup) => (
                <tr
                  style={{ fontSize: "15px" }}
                  className="text-white "
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column, index) => (
                    <td
                      {...column.getHeaderProps()}
                      className="whitespace-nowrap  text-center "
                      style={
                        columns[index].width
                          ? {
                              minWidth: columns[index].width,
                              fontWeight: 500,
                            }
                          : {
                              flex: columns[index].flex,
                              fontWeight: 500,
                              borderRight: "1px solid #888c89",
                            }
                      }
                    ></td>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody
              {...getTableBodyProps()}
              className=" w-full "
              style={{  }}
            >
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <tr className="hover:cursor-pointer " {...row.getRowProps()}>
                    {row.cells.map((cell, i) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            borderRight: "1px dashed black",
                            borderTop:
                              rowIndex === 0
                                ? "0 dashed black"
                                : "1px dashed black",
                            borderBottom:
                              rowIndex !== rows.length
                                ? "0px dashed black"
                                : "1px dashed black",
                                borderLeft:
                                i ===0
                                  ? "1px dashed black"
                                  : "0px dashed black",
                            maxWidth: cell.column.width,
                            textOverflow: 'ellipsis' ,

                            fontSize: "14px",
                            whiteSpace: "normal",
                          }}
                          className="h-[70px] p-2 overflow-hidden "
                        >
                          <div
                            style={{
                              textAlign: cell.render("Cell").props.cell.column
                                .align
                                ? cell.render("Cell").props.cell.column.align
                                : "left",
                                whiteSpace: 'nowrap', // Prevent text from wrapping
                            }}
                            className=" "
                          >
                            <div   >
                              {cell.column.field !== "ICODE" &&
                              cell.column.field !== "ITEMNAME" ? (
                                cell.render("Cell").props.cell.column.type ===
                                "date" ? (
                                  moment(
                                    cell.render("Cell").props.cell.value
                                  ).format("DD-MM-YYYY")
                                ) : cell.render("Cell").props.cell.value ===
                                    "0.00" ||
                                  cell.render("Cell").props.cell.value === 0 ||
                                  cell.render("Cell").props.cell.value ===
                                    "0" ? (
                                  " "
                                ) : cell.column.renderCell ? (
                                  cell.column.renderCell(
                                    cell.render("Cell").props.cell
                                  )
                                ) : (
                                  cell.render("Cell")
                                )
                              ) : cell.column.field === "ICODE" ? (
                                <div className="whitespace-normal">
                                  {" "}
                                  {rowIndex + 1}{" "}
                                </div>
                              ) : (
                                <div>
                                  {row.original.ICODE +
                                    "-" +
                                    row.original.ITEMNAME}
                                </div>
                              )}
                              {cell.column.field === "TAX1_AMT" && (
                                <div>{row.original.TAX1_RATE + "%"}</div>
                              )}

                              {cell.column.field === "TAX2_AMT" && (
                                <div>{row.original.TAX2_RATE + "%"}</div>
                              )}
                            </div>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {footer && footer}
        </div>

        <div className="w-full flex justify-between">
          <div
            style={{
              fontSize: "12px",
            }}
            className="flex  pt-2"
          >
            <span style={{ fontSize: "10px", color: "#3b3a39" }}>
              {" "}
              Powered by{" "}
            </span>{" "}
            <span
              style={{ fontSize: "11px", color: "#171716" }}
              className="pl-1"
            >
              geniusoffice{" "}
            </span>
          </div>
          <div className="flex pt-2 items-center ">
            <div
              style={{
                fontSize: "11px",
                color: "#3b3a39",
                fontWeight: "600px",
              }}
            >
              Date :{" "}
            </div>

            <div
              style={{
                fontSize: "10px",
                color: "#3b3a39",
                fontWeight: "500px",
              }}
              className="pl-1"
            >
              {moment().format("DD-MM-yyyy HH:mm ")}
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default PurchasePrint;
