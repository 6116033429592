import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectWidth } from '../../utility/Redux/profile';
import GeniustextInput from '../../utility/component/mastertext';
import { toProperCase } from '../../utility/component/format';
import { PartyColumns } from './columns';
import { setAlertWithTitle } from '../../utility/Redux/modal';



const BasicInformation = ({
  partyName,missingId,
  setPartyName,
  gstNo,accoutList,setMissingId,
  setGstNo,
  panNo,
  setPanNo,
  mobileRef,
  phoneNo,
  setPhoneNo,
  mobile,
  setMobile,
  email,
  setEmail,
  website,
  setWebsite,
  creditAllow,
  creditPeriod,
  setCreditAllow,
  setCreditPeriod,
  setTabScreen,setPartyMasterhelpStatus,partyRefs
}) => {
  const windowWidth = useSelector(selectWidth);

  const dispatch = useDispatch()

  useEffect(() => {
    if (partyRefs) {
      const inputFields = partyRefs.current.querySelectorAll('input, select');

      // Add a keydown event listener to each input and select field
      inputFields.forEach((field, index) => {
        field.addEventListener('keydown', (event) => {
          if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
            const nextIndex = (index + 1) % inputFields.length;
            if (index === 11) {
              setTabScreen(2);
            } else {
              inputFields[nextIndex].focus();
            }
          }
        });
      });

      // Cleanup event listeners on unmount
      return () => {
        inputFields.forEach((field) => {
          field.removeEventListener('keydown', () => {});
        });
      };
    }
  }, []);

  const columns = PartyColumns(windowWidth)

useEffect(()=>{
  if(missingId){
    const inputFields = partyRefs.current.querySelectorAll('input, select')
    inputFields.forEach(element => {
  
        if (element.id ===missingId) {
            element.focus();
        setMissingId('')
  
        }})
  }
 
},[missingId])
  return (
    <div ref={partyRefs}  style={{
          height: windowWidth <= 1280 ? '410px' : '450px',
    }}  className=' px-2 min-w-[320px] grid grid-cols-1 gap-2 pt-4 overflow-y-auto'>
        <GeniustextInput
          title={'Party Name'}
          grid={true}
          search={()=>{
            setPartyMasterhelpStatus(true)
          }}
          id={'partyName'}
          type={'text'}
          value={partyName}
          onChange={(e) => {
      setPartyName(toProperCase(e.target.value));
          }}
        />
       <div className=" grid grid-cols-1 l:grid-cols-2 gap-2 ">
        <GeniustextInput
          title={'GST Number'}
          type={'text'}
          grid={true}
          value={gstNo}
          id={'GST'}
          onChange={(e) => {
            setGstNo( e.target.value.toUpperCase());
            const panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
            const match = e.target.value.match(panRegex);
            if (match) {
              setPanNo(match[0]);
            } 
          }}
        /> 
          <GeniustextInput
            title={'PAN Number'}
          grid={true}

            type={'text'}
            value={panNo}
            // onChange={(e) => {
            //   setPanNo(e.target.value);
            // }}
          />
      </div>
      <div className=" grid grid-cols-1 l:grid-cols-2 gap-2 ">

        <GeniustextInput
          title={'Phone'}
          type={'text'}
          grid={true}

          value={phoneNo}
          onChange={(e) => {
            setPhoneNo(e.target.value);
          }}
        />
          <GeniustextInput
            title={'Mobile'}
            id={'mobile'}
            type={'number'}
            grid={true}
            error={'mobile'}
            value={mobile}
            onChange={(e) => {
              const isSameMobile = accoutList.filter(a => a.MOBILE ===e.target.value);
              if(!isSameMobile[0]){
                setMobile(e.target.value);

              }else{
           dispatch(setAlertWithTitle({
            title:'Alert',
            msg:'Already registered party with same mobile'
           }))

              }
            }}
            refrance={mobileRef}
          />
      </div>
      <div className=" grid grid-cols-1 l:grid-cols-2 gap-2 ">
        <GeniustextInput
          title={'Email'}
          grid={true}
          type={'text'}
          error={'email'}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
          <GeniustextInput
            title={'Website'}
            type={'text'}
          grid={true}

            value={website}
            onChange={(e) => {
              setWebsite(e.target.value);
            }}
          />
      </div>
      <div className=" grid grid-cols-1 l:grid-cols-2 gap-2 ">
        <GeniustextInput
          title={'Credit Limit Allow'}
          type={'number'}
          grid={true}
          value={creditAllow}
          onChange={(e) => {
            setCreditAllow(e.target.value);
          }}
        />
          <GeniustextInput
            title={'Credit Limit Period'}
            type={'number'}
          grid={true}

            value={creditPeriod}
            onChange={(e) => {
              setCreditPeriod(e.target.value);
            }}
          />
      </div>



    </div>
  );
};

export default BasicInformation;
