import { useSelector } from "react-redux";
import { selectWidth } from "./Redux/profile";
import axios from "axios";
import { setSpinnerLoading } from "./Redux/modal";
import { FormatNumber2 } from "./functions/formatNumber";

export const isPosMod = false;
export const isFirebaseUserLoginCheckEnabeld = true;
export const isStateCheckEnabeld = false;
export const licenceExpired_alert =
  "Your license has expired. Please contact support@geniusoffice.com, +91 85589 51962 for renewal. Visit https://www.geniusoffice.com.";
export const fastMovingMovieItem = "Fast Moving Items";
export const openItemTitle = "Open Item";

export const FontSizeWidth = (width) => {
  if (width < 500) {
    return 11;
  }
  if (width < 1281) {
    return 12;
  } else {
    return 14;
  }
};

export const HeaderWidth = () => {
  const width = useSelector(selectWidth);
  if (width > 450) {
    return 60;
  } else {
    return 0;
  }
};

//  const bgColor = '#0d0d0d'

// export const TabBarBgColor ='#e6e4df'
export const TabBarBgColor = "#0d0d0d";
export const getMessageWallet = (companyEmail, token, sms) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/api/userSettings/loadMessageSetup`,
        {
          company_email: companyEmail,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          if (res.data.response[0]) {
            if (sms) {
              resolve(
                Number(res.data.response[0].sms_balance),
                res.data.response[0]
              );
            } else {
              resolve(
                Number(res.data.response[0].whatsapp_balance),
                res.data.response[0]
              );
            }
          } else {
            resolve(0);
          }
        } else {
          resolve(0);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(0);
      });
  });
};

export function balanceType(value) {
  // Check if the value is less than 0
  if (value < 0) {
    return FormatNumber2(Math.abs(value)) + " Cr"; // Return the absolute value with "Cr"
  } else {
    return FormatNumber2(value) + " Dr"; // Return the value with "Dr"
  }
}

export const getItemByCode = (
  prefix,
  icode,
  token,
  dispatch,
  isIgnoreStopingLoading
) => {
  dispatch(setSpinnerLoading("Loading item"));
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/api/master/listItemMaster`,
        {
          table_prefix: prefix,
          SECTION_NAME: "",
          ICODE: icode,
          ALIAS_CODE: "",
          ITEMNAME: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          if (res.data.response[0]) {
            resolve(res.data.response);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      })
      .catch((err) => {
        console.log(err);
        reject([]);
      })
      .finally(() => {
        if (!isIgnoreStopingLoading) {
          dispatch(setSpinnerLoading(false));
        }
      });
  });
};

export const getItemByCodeFromPurchase = (
  prefix,
  icode,
  token,
  dispatch,
  isIgnoreStopingLoading
) => {
  return new Promise((resolve, reject) => {
    // dispatch(setSpinnerLoading("Loading"));
    axios
      .post(
        `/api/master/itemMasterHelp`,
        {
          table_prefix: prefix,
          SECTION_NAME: "",
          ICODE: icode,
          ALIAS_CODE: "",
          ITEMNAME: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          if (res.data.response[0]) {
            resolve(res.data.response);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      })
      .catch((err) => {
        console.log(err);
        reject([]);
      })
      .finally(() => {
        if (!isIgnoreStopingLoading) {
          dispatch(setSpinnerLoading(false));
        }
      });
  });
};

export const getItemByAlios = (
  prefix,
  alios,
  token,
  dispatch,
  isIgnoreStopingLoading
) => {
  dispatch(setSpinnerLoading("Loading item"));

  return new Promise((resolve, reject) => {
    axios
      .post(
        `/api/master/listItemMaster`,
        {
          table_prefix: prefix,
          SECTION_NAME: "",
          ICODE: "",
          ALIAS_CODE: alios,
          ITEMNAME: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          if (res.data.response[0]) {
            resolve(res.data.response);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      })
      .catch((err) => {
        console.log(err);
        reject([]);
      })
      .finally(() => {
        if (!isIgnoreStopingLoading) {
          dispatch(setSpinnerLoading(false));
        }
      });
  });
};

export const getItemByName = (prefix, name, token, dispatch) => {
  dispatch(setSpinnerLoading("Loading item"));

  return new Promise((resolve, reject) => {
    axios
      .post(
        `/api/master/listItemMaster`,
        {
          table_prefix: prefix,
          SECTION_NAME: "",
          ICODE: "",
          ALIAS_CODE: "",
          ITEMNAME: name,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          if (res.data.response[0]) {
            resolve(res.data.response);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      })
      .catch((err) => {
        console.log(err);
        reject([]);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  });
};
