const HandleIndexedDB = ({ databaseName, storeName, dataToSave, callback ,afterUpdate}) => {
//   const localName = `${databaseName}_IDb`;
//   let versionCode = localStorage.getItem(localName);
//   let version = versionCode ? Number(versionCode) : 1;
//   if (!versionCode) {
//       localStorage.setItem(localName, 2);
//   } else {
//       localStorage.setItem(localName, version + 1);
//   }
let version =1
const request = indexedDB.open(databaseName);

request.onsuccess = function(event) {
  const db = event.target.result;
//   const version = db.version;
//   console.log("IndexedDB version:", version);
  version = db.version;

  db.close();

  request.onerror = function(event) {
    console.error("Failed to open IndexedDB:", event.target.errorCode);
  };

    const openRequest = indexedDB.open(databaseName, version+1);
  
    openRequest.onupgradeneeded = function(event) {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(storeName)) {
            const objectStore = db.createObjectStore(storeName, { keyPath: 'id', autoIncrement: true });
            if(afterUpdate){
                afterUpdate()

            }

        }
    };
  
    openRequest.onsuccess = function(event) {
        const db = event.target.result;
  
        const transaction = db.transaction([storeName], 'readwrite');
        const objectStore = transaction.objectStore(storeName);
  
        if (dataToSave) {
            dataToSave.forEach(data => {
                objectStore.add(data);

            });

            db.close();

        }
  
        if (callback) {
            const request = objectStore.getAll();
  
            request.onsuccess = function(event) {
                const data = event.target.result;
                const retrievedColumns = data.length > 0 ? Object.keys(data[0]).map(key => ({
                    field: key,
                    headerName: key,
                })) : [];
  
                callback(data, retrievedColumns);
  
                // Close the database connection after operations are completed
                db.close();
            };
        }
    };
  
    openRequest.onerror = function(event) {
        console.error("IndexedDB error:", event.target.error);
    };
};



}

export default HandleIndexedDB;
