import React, { useEffect, useRef, useState } from "react";
import orderRecieved from "../../json/newOrder.json";
import Lottie from "lottie-react";
import { toProperCase } from "../format";
import sound from "../../sounds/message-alert-190042.mp3";
import { useSelector } from "react-redux";
import { selectPrefix } from "../../Redux/profile";
import { useNavigate } from "react-router-dom";
import "./alertOrder.css";
import {
  removeOrderpath,
  UpdateNewOrderInFirebase,
} from "../../functions/common";

const cardClasses =
  "bg-[#E0E3D5] rounded-[18px] text-[#2C3E50] overflow-hidden outline-none border-0 shadow-lg max-w-md mx-auto transition-transform duration-500 ease-in-out";

export default function OrderRecivedAlert({
  details,
  setDetails,
  orderDetails,
  topOffset,
}) {
  const [visible, setVisible] = useState(false);
  const formRef = useRef();
  const prefix = useSelector(selectPrefix);
  const audioRef = useRef(null);
  const navigate = useNavigate();

  const handleClose = () => {
    setVisible(false);
    setTimeout(() => setDetails(), 1000); // Move to the next order in parent
    removeOrderpath(prefix.replace(/_.+_/, "_"), orderDetails.INVNO);
  };

  useEffect(() => {
    if (details.status) {
      setVisible(true);
      setTimeout(handleClose, 3000); // Close after 3 seconds
    }
  }, [details]);

  useEffect(() => {
    if (audioRef.current && details.status) {
      setTimeout(() => {
        audioRef.current.play();
      }, 100);
    }
  }, [audioRef, details.status]);

  return (
    <div
      ref={formRef}
      className={`${cardClasses} ${visible ? "slide-in" : "slide-out"}`}
      style={{
        position: "absolute",
        top: `calc(5% + ${topOffset}px)`, // Adjust position based on topOffset
        right: 10,
        transform: visible ? "translateX(0)" : "translateX(100%)",
      }}
    >
      <div className="flex justify-between items-center border-b border-[#c4c7bb]">
        <audio ref={audioRef} src={sound} preload="auto" />
        <div className="flex flex-1 items-center py-1">
          <div className="bg-[#daddcf] px-3">
            <Lottie
              animationData={orderRecieved}
              loop
              style={{ height: 100 }}
            />
          </div>
          <div className="px-4 flex flex-col justify-center">
            <div className="text-[17px] font-semibold">
              {toProperCase(orderDetails.ORDER_TYPE)} Order
            </div>
            <div className="text-muted-foreground">
              <span className="font-semibold mr-1">
                {toProperCase(orderDetails.CUSTOMER_NAME)}
              </span>
              placed a new order with a total of
              <span className="font-semibold mx-1">
                {" "}
                ₹{orderDetails.GTOTAL}
              </span>{" "}
              for{" "}
              <span className="font-semibold">
                {toProperCase(orderDetails.ORDER_TYPE)}
              </span>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
