import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';


import CryptoJS from 'crypto-js';
import axios from 'axios';
import { Button } from '@mui/material';
import { selectCompanyAddress, selectEmail, selectUserType, selectWidth } from '../utility/Redux/profile';
import { setSessionExpired } from '../utility/Redux/modal';
import MasterHelp from '../utility/component/modals/masterHelp';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: '85%',
  backgroundColor: 'white',
  padding: '2px',
  borderRadius: '8px',
  // Change the shadow color
  overflow: 'hidden',
  // boxShadow: 'none',
};

export default function ModUserHelp({ comapany, onRowClick, onClose, status }) {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const width = useSelector(selectWidth);
  const columns = React.useMemo(
    () => [
      {
        field: 'USER_NAME',
        accessor: 'USER_NAME',
        type: 'string',
        width:
          width <= 768 ? 100 : width <= 1280 ? 180 : width <= 1440 ? 150 : 180,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <div>{'User Name'}</div>,
      },
      {
        field: 'USER_TYPE',
        accessor: 'USER_TYPE',
        type: 'string',
        width:
          width <= 768 ? 100 : width <= 1280 ? 100: width <= 1440 ? 100: 100,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <div>{'User Type'}</div>,
      },
      {
        field: 'SHOP_ID',
        accessor: 'SHOP_ID',
        type: 'string',
        width:
          width <= 768 ? 80 : width <= 1280 ? 80 : width <= 1440 ? 100 : 80,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <div>{'Shop Id'}</div>,
      },
      {
        field: 'SHOP_NAME',
        accessor: 'SHOP_NAME',
        type: 'number',
        width:
          width <= 768 ? 100 : width <= 1280 ? 100 : width <= 1440 ? 100 : 150,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => <div>{'Shop Name'}</div>,
      },
      {
        field: 'USER_EMAIL',
        accessor: 'USER_EMAIL',
        type: 'number',
        width:
          width <= 768 ? 150 : width <= 1280 ? 170 : width <= 1440 ? 100 : 250,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => <div>{'User Email'}</div>,
      },
      {
        field: 'USER_MOBILE',
        accessor: 'USER_MOBILE',
        type: 'number',
        width:
          width <= 768 ? 100 : width <= 1280 ? 90 : width <= 1440 ? 100 : 150,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => <div>{'User Mobile'}</div>,
      },
  

    ],
    []
  );

  const mobileColumns =  [
      {
        field: 'USER_NAME',
        accessor: 'USER_NAME',
        type: 'string',
        width:90,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <div>{'User Name'}</div>,
      },
  

      {
        field: 'USER_EMAIL',
        accessor: 'USER_EMAIL',
        type: 'number',
        width:130,

        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => <div>{'User Email'}</div>,
      },
      {
        field: 'USER_MOBILE',
        accessor: 'USER_MOBILE',
        type: 'number',
        width:100,

        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => <div>{'User Mobile'}</div>,
      },
  

    ]

  const companyDetail = useSelector(selectCompanyAddress);
  const [list, setList] = useState([]);
  const [fullList, setFullList] = useState([]);
  const userEmail = useSelector(selectEmail);
  const userType = useSelector(selectUserType);
  const getListStationMaster = () => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    const hmac = CryptoJS.HmacSHA256(
      comapany?.userEmail ? comapany.userEmail : userEmail,
      secretPass
    );

    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${hmacString}`,
    };
    const data = {
      company_email: comapany?.userEmail
        ? comapany?.companyEmail
        : companyDetail.email,
      user_email: comapany?.userEmail ? comapany?.userEmail : userEmail,
      user_mobile: '',
      user_type: comapany?.userEmail ? 'USER' : userType,
    };

    axios
      .post(`/api/users/userHelp`, data, {
        headers: headers,
      })
      .then((p) => {
        if (!p.data.error) {
          let list = [];
          const data = p.data.response

          for (let i = 0; i < data.length; i++) {
            list.push({
              ...data[i],
              id:i+1
            });
          }
          setFullList(list);
          setList(list);
        }
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(e);
      })
      .finally(() => {});
  };
  useEffect(() => {
    if (status) {
      getListStationMaster();
    }
  }, [status]);

  useEffect(() => {
    if (searchText) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchText.substring(0, 1))) {
          return item.CODE.toString().includes(searchText);
        } else {
          return item.USER_NAME.toLowerCase().includes(
            searchText.toLowerCase()
          );
        }
      });

      setList(searchItems);
    } else {
      setList(fullList);
    }
  }, [searchText]);

  return (
    <div className="bg-white">
      <MasterHelp
        status={status}
        onClose={() => {
          onClose();
          setList([]);

          setSearchText('');
        }}
        style={style}
        title={'User - Help'}
        columns={ width< 560 ? mobileColumns:columns}
        list={list}
        rowId={(p) => p.CODE}
        searchQuery={searchText}
        setSearchQuery={setSearchText}
        onRowClick={(p) => {
          onRowClick(p);
          setList([]);
          setSearchText('');
        }}
      />
    </div>
  );
}
