import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { selectCompanyAddress } from "../utility/Redux/profile";
import '../styles/purchasePrint.css';

function CustomHeader({ partyDetails, currentINVNO }) {
  const companyAddress = useSelector(selectCompanyAddress);

  return (
    <div
    id={'main'}
      style={{ width: "100%", height: "100%", backgroundColor: "white" }}
    >
      <div className="border-black border-t border-r border-l">
        <div
          style={{ borderBottom: "1px solid black" }}
          className="flex justify-center font-bold py-1"
        >
          {partyDetails.BILL_TYPE}
        </div>
        <div className="flex flex-col items-center">
          <div className="font-bold text-2xl">
            {companyAddress.companyName}
          </div>
          <div className="text-sm text-center">
            {companyAddress.address + ", " + companyAddress.city}
          </div>
          <div className="text-sm text-center">{companyAddress.mobile}</div>
          <div className="text-sm text-center">
            GST No.: {companyAddress.regdNo}
          </div>
        </div>

        <div style={{}} className="flex items-center w-full">
          <div
            className="text-sm flex p-1 items-center font-bold"
            style={{
              border: "1px solid black",
              width: "15%",
              borderBottom: "none",
              borderRight: "none",
              borderLeft: "none",
            }}
          >
            Invoice No.: {currentINVNO}
          </div>
          <div
            className="text-sm flex p-1 items-center"
            style={{
              border: "1px solid black",
              width: "25%",
              borderBottom: "none",
              borderRight: "none",
            }}
          >
            Purchase Bill No.: {partyDetails.PUR_BILL_NO}
          </div>
          <div
            className="text-sm flex p-1 items-center"
            style={{
              border: "1px solid black",
              width: "25%",
              borderBottom: "none",
              borderRight: "none",
            }}
          >
            Purchase Bill Date:{" "}
            {partyDetails.PUR_BILL_DATE
              ? moment(partyDetails.PUR_BILL_DATE).format("DD-MM-yyyy")
              : ""}
          </div>
          <div
            className="text-sm flex p-1 items-center justify-end"
            style={{
              border: "1px solid black",
              width: "35%",
              borderBottom: "none",
              borderRight: "none",
            }}
          >
            <label className="font-bold">Date:</label>
            <div className="ml-1 font-bold">
              {moment().format("DD/MM/yyyy hh:mm")}
            </div>
          </div>
        </div>

        <div className="flex w-full">
          <div
            className="p-2"
            style={{
              border: "1px solid black",
              width: "65%",
              borderBottom: "none",
              borderRight: "none",
              borderLeft: "none",
            }}
          >
            <div className="text-sm">{partyDetails.NAME || ""}</div>
            <div className="text-sm pt-2">{partyDetails.ADDRESS || ""}</div>
            <div className="text-sm pt-2 font-bold">
              GST No.: {partyDetails.TAX_NUMBER || ""}
            </div>
            <div className="text-sm pt-2">Place Of Supply: {""}</div>
          </div>
          <div
            className="p-2"
            style={{
              border: "1px solid black",
              width: "35%",
              borderBottom: "none",
              borderRight: "none",
            }}
          >
            <div className="text-sm">Transport: {partyDetails.TRANSPORT || ""}</div>
            <div className="flex w-full pt-2">
              <div className="text-sm w-1/2">
                G.R. No.: {partyDetails.GR_NO || ""}
              </div>
              <div className="text-sm w-1/2">
                P.O. No.: {partyDetails.PO_NO || ""}
              </div>
            </div>
            <div className="flex w-full pt-2">
              <div className="text-sm w-1/2">Weight: {""}</div>
              <div className="text-sm w-1/2">Vehicle No.: {""}</div>
            </div>
            <div className="flex w-full pt-2">
              <div className="text-sm w-1/2">Driver: {""}</div>
              <div className="text-sm w-1/2">Driver Mobile: {""}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomHeader;
