import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

const CustomSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    top: 'auto',
    bottom: 16,
    right: 12,
    height: `calc(100% - 115px)`,
    boxSizing: 'border-box',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    zIndex: theme.zIndex.drawer - 5, // Decrease the z-index
  },
}));

function SwipeableEdgeDrawer({ data, status, setStatus,  }) {
  const toggleDrawer = (newOpen) => () => {
    setStatus(newOpen);
  };

  return (
    <div className="h-full rounded-t-sm ">
  
      <div className="h-full   ">
        <CustomSwipeableDrawer
          anchor="right"
          open={status}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableSwipeToOpen={false}
        >
          {data}
        </CustomSwipeableDrawer>
      </div>
    </div>
  );
}

export default SwipeableEdgeDrawer;
