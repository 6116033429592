import { useDispatch, useSelector } from 'react-redux';
import {
  SelectItemMasterHelpStatus,
  setItemMasterHelpStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import { useState } from 'react';
import { selectToken } from '../utility/Redux/security';
import axios from 'axios';
import { useEffect } from 'react';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';
import MasterHelp from '../utility/component/modals/masterHelp';
import { FormatNumber2 } from '../utility/functions/formatNumber';
import { useNavigate } from 'react-router-dom';
import EmptyModal from './freeModal';
import HsnMaster from '../masters/hsn-master';

const HsnMasterHelp = ({ onRowClick ,status,onClose}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',
    backgroundColor: 'white',
  };
  const [masterStatus,setMasterStatus] = useState(false)

  const [rows, setRows] = useState([]);
  const token = useSelector(selectToken);

  const prefix = useSelector(selectPrefix);

  const width = useSelector(selectWidth);
  const columns = [
  
    {
      field: 'HSN_CODE',
      renderHeader: (params) => <div>HSN Code</div>,
      width:
      width <= 768 ? 200 : width <= 1024 ? 300 : width <= 1440 ? 300 : 400,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'TAX_RATE',
      renderHeader: (params) => <div>TAX Rate</div>,
      width:
        width <= 768 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 100 : 200,
      headerAlign: 'center',
      align: 'left',
    },
  ];

  const [searchQuery, setSearchQuery] = useState('');
  const [fullList, setFullList] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.id.toString().includes(searchQuery);
        } else {
          return item.SECTION_NAME.toLowerCase().includes(
            searchQuery.toLowerCase()
          );
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);



  const dispatch = useDispatch();

  const getData = async () => {
    dispatch(setSpinnerLoading('Loading'));
    const data  = {
        table_prefix: prefix,
        "HSN_CODE": ""
    }
    await axios
      .post(
        '/api/master/listHSNCodeMaster',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        if(!p.data.error){
            let list = [];
            const data = p.data.response

            for (let i = 0; i < data.length; i++) {
              list.push({
                ...data[i],
                id:i+1
              });
            }
            setFullList(list);
            setRows(list);
        }
  
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status]);



  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            onClose();
            setRows([]);
            setSearchQuery('')

          }}
          title={'HSN Code Master | Help'}
          columns={columns}
          list={rows}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRowClick={(p) => {
            onRowClick(p);
            setRows([]);
          }}
          CreateNew={()=>{
            setMasterStatus(true)
          }}
          goToMaster={()=>{
            navigate('/hsn_master')
          }}
        />
                      <EmptyModal status={masterStatus} onClose={()=>{
          setMasterStatus(false)
        }} Component={ HsnMaster} afterCreate={()=>{
          setMasterStatus(false)
          getData()
        }}  />
      </div>
    </>
  );
};

export default HsnMasterHelp;
