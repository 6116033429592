// Import Firebase and initialize it with your config (as shown in previous response)
import { initializeApp } from 'firebase/app';
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { setSpinnerLoading } from '../Redux/modal';
import { getDatabase } from 'firebase/database'; // Import the getDatabase function

import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
// Initialize Firebase with your config
const firebaseConfig = {
  apiKey: "AIzaSyC6Zpz7_De9jeUnEYu6v-vZISHqoJFGQpo",
  authDomain: "retailx-126e1.firebaseapp.com",
  projectId: "retailx-126e1",
  storageBucket: "retailx-126e1.appspot.com",
  messagingSenderId: "63087371523",
  appId: "1:63087371523:web:02caee9d6e73bdf9387536",
  measurementId: "G-LVZ68XQYEW"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
signInAnonymously(auth)
  .then(() => {
    console.log('sign');
  })
  .catch((error) => {
    console.log(error);
    // ...
  });
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    console.log('signin');
    // ...
  } else {
    // User is signed out
    // ...
    console.log('signout');
  }
});
const database = getDatabase(app);

// Get a reference to Firebase Storage
const storage = getStorage(app);

async function UploadFile(file, path, name,dispatch) {
  if(dispatch){
    dispatch(setSpinnerLoading('Uploading'))
  }
  const storageRef = ref(storage, path + name);
  try {
    const snapshot = await uploadBytes(storageRef, file);
    if(dispatch){
      dispatch(setSpinnerLoading(false))

    }
    console.log(snapshot)

    return snapshot;
  } catch (error) {
    console.error('Error uploading file:', error);

    if(dispatch){
      dispatch(setSpinnerLoading(false))

    }

    console.error('Error uploading file:', error);
    throw error;
  }
}
async function deleteFile(path) {

  const storageRef = ref(storage, path);

  try {
    // Delete the file
    await deleteObject(storageRef);
    console.log('File deleted successfully');
  } catch (error) {
    console.error('Error deleting file:', error);
    throw error;
  }
}

const DownloadFile = async (path) => {
  const imageRef = ref(storage, path);
  try {
    const imageUrl = await getDownloadURL(imageRef);

    return imageUrl;
    // Now you can use `imageUrl` to display the image in your application.
  } catch (error) {
    return error;
  }
};


export { UploadFile, DownloadFile, deleteFile ,database};
