import React, { useEffect, useState } from 'react'
import { FormatNumber2 } from '../utility/functions/formatNumber'
import { useSelector } from 'react-redux'
import { selectDefultProfileSetting } from '../utility/Redux/profile'

const SaveAmt = ({list}) => {
    const [exactList,setExactList] = useState([])
 const deafaultProfile = useSelector(selectDefultProfileSetting)

    useEffect(()=>{
setExactList(list.map((o)=>{
    const totalMrp = o.MRP_RATE * o.QNTY
    return {
        ...o,
        AMOUNT:o.MRP_RATE >0? o.NET_AMOUNT :0,
        totalMrp:totalMrp
    }
}))
    },[list])

    const totalMrp =  exactList.reduce((acc, row) => acc + Number(row.totalMrp), 0)
    const totalAmt =  exactList.reduce((acc, row) => acc + Number(row.NET_AMOUNT), 0)
  const saveAmt = totalMrp - totalAmt

  return (
    <div className='w-full '>
        { deafaultProfile?.PRINT_YOU_SAVED &&
  <div className='flex justify-center'>
  {saveAmt >0 &&'You Saved :'}   
  {saveAmt >0 &&  <div className='ml-2'>{FormatNumber2(saveAmt)}</div>}
  </div>
        }
      
    
      
   

    </div>
  )
}

export default SaveAmt