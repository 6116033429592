import React, { useEffect, useId, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { CancelButton, SaveButton, UndoButton } from "./buttons";
import { Button } from "@nextui-org/react";
import { useFocus } from "../hooks/hooks";
import { setAlertWithTitle, setSpinnerLoading } from "../Redux/modal";
import { useDispatch } from "react-redux";
import { openItemTitle } from "../constant";
import { toProperCase } from "./format";

const InputModal = ({
  status,
  title,
  Item_Box,
  setItem_Box,
  okButtonText,
  desc,
  type,
  onSave,
  onCancel,
  placeholder,
}) => {
  const [value, setValue] = useState("");
  const [isOpenItem, setIsOpenItem] = useState(false);
  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
    // border: '1px solid white',
    boxShadow: 24,
    borderRadius: "7px",
    outline: "none",
  };
  const formRef = useRef();

  useEffect(() => {
    if (status) {
      dispatch(setSpinnerLoading(false));
      setTimeout(() => {
        if (Item_Box.ITEMNAME === openItemTitle) {
          setIsOpenItem(true);
          setItem_Box((o) => {
            return {
              ...o,
              ITEMNAME: "",
            };
          });
          const itemRef = document.getElementById("itemName");
          console.log(itemRef);

          if (itemRef) {
            const timerId = setTimeout(() => {
              if (itemRef) {
                itemRef.focus();
              }
            }, 500);
            return () => clearTimeout(timerId);
          }
        } else {
          setIsOpenItem(false);
          formRef.current.focus();

          // const timerId = setTimeout(() => {
          //   if (formRef.current) {
          //   }
          // }, 500);
          // return () => clearTimeout(timerId);
        }
      }, 300);
    }
    setValue("");
  }, [status]);
  return (
    <div>
      <Modal open={status}>
        <div style={style} className="bg-white overflow-hidden ">
          <div className="bg-blue-200 flex justify-between p-3">
            <div className="font-semibold">
              {Item_Box.ITEMNAME === "" ? "Open Item" : title}
            </div>
            <div>
              <button onClick={onCancel}>
                <i className="bi bi-x-lg font-semibold"></i>
              </button>
            </div>
          </div>

          <div className="p-3 pb-1 mt-2 ml-2  text-sm">
            Item Name
            <div
              style={{
                border: "1px solid gray",
              }}
              className=" p-2 rounded-sm mt-2"
            >
              <input
                type="text"
                id={"itemName"}
                value={Item_Box.ITEMNAME}
                onKeyDown={(e) => {
                  if (e.code === "Enter" || e.code === "NumpadEnter") {
                    formRef.current.focus();
                  }
                }}
                readOnly={!isOpenItem}
                onChange={(e) => {
                  setItem_Box((o) => {
                    return {
                      ...o,
                      ITEMNAME: toProperCase(e.target.value),
                    };
                  });
                }}
                className="w-[290px] outline-none"
              />
            </div>
            <div className="mt-2">{desc}</div>
            <div
              style={{
                border: "1px solid gray",
              }}
              className=" p-2 rounded-sm mt-2"
            >
              <input
                placeholder={placeholder ? placeholder : ""}
                id={"icode"}
                ref={formRef}
                onKeyDown={(e) => {
                  if (e.code === "Enter" || e.code === "NumpadEnter") {
                    if (e.target.value > 0) {
                      onSave(e.target.value);
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "Please enter Value",
                        })
                      );
                      // if (formRef.current) {
                      //   formRef.current.focus();
                      // }
                    }
                  }
                }}
                onChange={(e) => setValue(e.target.value)}
                type={type}
                className="w-[290px] outline-none"
                value={value}
              />
            </div>
          </div>
          <div className="w-full flex justify-end p-3 ">
            <div className="flex">
              <Button
                onClick={onCancel}
                variant="contained"
                style={{
                  textTransform: "none",
                  letterSpacing: "1px",
                  backgroundColor: "#737371",
                  borderRadius: 7,
                  height: "35px",
                  marginRight: "10px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    fontSize: "13px",
                  }}
                  className="text-white font-sans  whitespace-nowrap w-full"
                >
                  Cancel
                </div>
              </Button>

              <Button
                onClick={() => {
                  if (isOpenItem) {
                    if (Item_Box.ITEMNAME) {
                      if (value > 0) {
                        onSave(value);
                      } else {
                        if (formRef.current) {
                          formRef.current.focus();
                        }
                        dispatch(
                          setAlertWithTitle({
                            title: "Alert",
                            msg: "Please enter Value.",
                          })
                        );
                      }
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "Item Name Required",
                        })
                      );
                    }
                  } else {
                    if (value > 0) {
                      onSave(value);
                    } else {
                      if (formRef.current) {
                        formRef.current.focus();
                      }
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "Please enter Value.",
                        })
                      );
                    }
                  }
                }}
                variant="contained"
                style={{
                  borderRadius: 5,
                  textTransform: "none",
                  letterSpacing: "1px",
                  height: "35px",
                  backgroundColor: "#a8cf45",
                }}
              >
                <div className="text-black px-1 ">{okButtonText} </div>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InputModal;
