import { Modal } from "@mui/material";
import ItemMaster from ".";

const ItemMasterModal = ({ handleClose, status , setRows , rows,refreshItem ,setItemMasterStatus}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "80%",
    width: "auto",
    padding: "2px",
    borderRadius: "8px",
    overflow: "hidden",
    backgroundColor: "white",
  };

  return (
    <>
      <Modal open={status}>
        <div style={style}>
          <ItemMaster hidePadding={true} refreshItem={refreshItem} handleClose={handleClose} setRows={setRows} rows={rows} setItemMasterStatus={setItemMasterStatus}/>
        </div>
      </Modal>
    </>
  );
};

export default ItemMasterModal;
