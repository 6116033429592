// api.js
import axios from 'axios';

export const getItemData = async (payload) => {
  try {
    const response = await axios.post(
      payload.path,
      payload.body,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${payload.token}`,
        }
      }
    );

    // Axios automatically throws an error for non-2xx responses
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error('Failed to perform POST request');
  } 
};
