import { Button, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CallAPI from "../utility/functions/getData";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../utility/Redux/security";
import {
  selectCompanyAddress,
  selectDefultProfileSetting,
  selectPrefix,
  selectUserName,
  selectWidth,
} from "../utility/Redux/profile";
import moment from "moment";
import NumberToWords3 from "../utility/component/numberToFormat";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import {
  BackButton,
  PrintButton,
  SaveButton,
  SendButton,
  SmsButton,
} from "../utility/component/buttons";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../utility/Redux/modal";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import "../styles/print.css";
import { FaWhatsapp } from "react-icons/fa";
import { TiArrowBackOutline, TiMessage } from "react-icons/ti";
import { BiCloudDownload } from "react-icons/bi";
import jsPDF from "jspdf";
import { toJpeg, toPng } from "html-to-image";
import axios from "axios";
import { DownloadFile, UploadFile } from "../utility/functions/firebase";
import { getMessageWallet } from "../utility/constant";
const VoucherPrint = ({ IsVoucher, setVoucherNo }) => {
  const company = useSelector(selectCompanyAddress);
  const userName = useSelector(selectUserName);
  const [amt, setAmt] = useState(0);
  const width = useSelector(selectWidth);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "500px",
    width: "auto",
    padding: "2px",
    borderRadius: "8px",
    overflow: "hidden",
    backgroundColor: "white",
  };
  const defaultProfile = useSelector(selectDefultProfileSetting);
  const [voucherDetails, setVoucherDetails] = useState("");
  const prefix = useSelector(selectPrefix);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const getVoucherData = () => {
    dispatch(setSpinnerLoading("Loading"));
    const data = {
      table_prefix: prefix,
      VOUCHER_NO: IsVoucher,
    };

    CallAPI(
      "/api/voucher/loadVoucher",
      data,
      token,
      (o) => {
        if (!o.data.error) {
          if (o.data.response[0]) {
            setVoucherDetails(o.data.response[0]);
            setAmt(
              o.data.response[0].CREDIT > 0
                ? o.data.response[0].CREDIT
                : o.data.response[0].DEBIT
            );
          }
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
  };
  const handleSendImage = (number, total) => {
    checkWhastappMsgCredits(number, total);
  };
  useEffect(() => {
    if (IsVoucher) {
      getVoucherData();
    }
  }, [IsVoucher]);
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    pageStyle: `
        @page {
          size: portrait;
        }
      `,
    content: () => printRef.current,
  });

  const handleSendSms = (number, amount) => {
    if (defaultProfile?.SEND_BILL_BY_SMS === "Yes") {
      getMessageWallet(company.email, token)
        .then((bal) => {
          if (bal > 0) {
            if (number?.length === 10 && amount) {
              axios
                .post(
                  "/api/sendDigitalBill/sendSms",
                  {
                    INVNO: IsVoucher,
                    MOBILE: voucherDetails.MOBILE,
                    AMOUNT: amt,
                    company_email: company.email,
                  },

                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((response) => {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "SMS sent successfully",
                    })
                  );
                })
                .catch((err) => {
                  console.log(err);
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "SMS Failed",
                    })
                  );
                });
            } else {
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: "Insufficient funds",
                })
              );
            }
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "Insufficient funds",
              })
            );
          }
        })
        .catch(() => {
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Insufficient funds",
            })
          );
        });
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: `You don't have permission to send sms.`,
        })
      );
    }
  };

  const downloadPdf = () => {
    toJpeg(
      printRef.current,
      window.innerWidth < 768 ? { quality: 0.25 } : { quality: 0.95 }
    )
      .then((dataUrl) => {
        const pdf = new jsPDF();
        pdf.addImage(dataUrl, "PNG", 0, 0);
        pdf.save(`Voucher_${IsVoucher}`);
      })
      .catch((err) => console.log(err));
  };

  function dataURLToBlob(dataUrl) {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  const checkWhastappMsgCredits = (number, total) => {
    if (defaultProfile?.SEND_BILL_BY_WHATSAPP === "Yes") {
      getMessageWallet(company.email, token).then((bal) => {
        if (bal > 0) {
          if (number?.length === 10) {
            const amount = FormatNumber2(amt);
            toPng(
              printRef.current,
              window.innerWidth < 768 ? { quality: 0.25 } : { quality: 0.95 }
            )
              .then((dataUrl) => {
                const blob = dataURLToBlob(dataUrl);
                const path = "retailx/voucher/";
                const name = `${prefix + IsVoucher}`;
                UploadFile(blob, path, name)
                  .then(() => {
                    const b = `retailx/voucher/${prefix + IsVoucher}`;
                    DownloadFile(b).then((c) => {
                      const s = c.split(
                        "https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/"
                      );
                      const data = {
                        dataUrl: s[1],
                        invno: IsVoucher.toString(),
                        mobile: number,
                        amount: amount,
                        company_email: company.email,
                      };
                      dispatch(setSpinnerLoading("Sending Image"));
                      axios
                        .post("/api/sendDigitalBill/saveBillImage", data, {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                          },
                        })
                        .then((response) => {
                          dispatch(
                            setAlertWithTitle({
                              title: "Success",
                              msg: "Whatsapp is sent succesfully",
                              lottie: "success",
                            })
                          );
                        })
                        .catch((err) => {
                          console.log(err);

                          if (
                            err?.response?.data?.name === "TokenExpiredError"
                          ) {
                            dispatch(setSessionExpired(true));
                          } else {
                            dispatch(
                              setAlertWithTitle({
                                title: "ERROR",
                                msg: "Something went wrong.",
                                lottie: "reject",
                              })
                            );
                          }
                        })
                        .finally(() => {
                          dispatch(setSpinnerLoading(false));
                        });
                    });
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Insufficient funds",
            })
          );
        }
      });
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: `You don't have permission to send whatsapp.`,
        })
      );
    }
  };

  return (
    <Modal
      open={IsVoucher ? true : false}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={style} className="flex ">
        <div
          style={{
            height: `calc(100% - ${width > 767 ? 0 : 50}px)`,
          }}
          ref={printRef}
          id={"print-component"}
          className="w-[350px] p-2 h-full text-sm"
        >
          <div className="h-full w-full border border-black ">
            <div className="w-full py-2 pt-3">
              <div className="justify-center flex w-full ">
                {company.companyName}
              </div>
              <div className="justify-center flex w-full text-center">
                {company.address}
              </div>
              <div className="justify-center flex w-full text-center">
                {company.city}, {company.state}{" "}
                {company.mobile && `, ${company.mobile}`}{" "}
                {company.website && `, ${company.website}`}
              </div>
            </div>
            <div className="flex w-full justify-center  border-t-black border-t p-2">
              <div className="flex">
                <div>{voucherDetails.VOUCHER_TYPE}</div>
                <div className="ml-2">Voucher</div>
              </div>
            </div>

            <div className="flex w-full justify-between  border-t-black border-t p-2">
              <div className="flex">
                <div>Voucher No. :</div>
                <div className="ml-2">{voucherDetails.VOUCHER_NO}</div>
              </div>

              <div className="flex">
                <div>Date. :</div>
                <div className="ml-2">
                  {moment(voucherDetails.DATE).format("YYYY-MM-DD")}
                </div>
              </div>
            </div>

            <div className="w-full   border-t-black border-t p-2">
              <div className="flex w-full justify-center">
                <div>Mode Of Payment :</div>
                <div className="ml-2">{voucherDetails.PAY_MODE}</div>
              </div>
              <div className="w-full justify-center flex">
                <div>{userName}</div>
              </div>
            </div>

            <div className="w-full   border-t-black border-t p-2">
              <div className="flex w-full justify-start">
                <div>
                  {`${
                    voucherDetails.VOUCHER_TYPE === "Payment"
                      ? "Paid by"
                      : "Recieved from"
                  }`}{" "}
                  <label className="font-semibold mr-1">
                    {" "}
                    {voucherDetails.NAME}{" "}
                  </label>
                  On account of{" "}
                  <label className="font-semibold mr-1">
                    {" "}
                    {voucherDetails.DESC_NAME}{" "}
                  </label>
                  an Amount of{" "}
                  <label className="font-semibold ">
                    {" "}
                    {NumberToWords3(amt)}
                  </label>{" "}
                  Only.
                </div>
              </div>
            </div>

            <div className="w-full   border-t-black border-t p-2">
              <div className="flex w-full justify-start font-[700] text-[20px]">
                <div>Rs :</div>
                <div className="ml-2">{FormatNumber2(amt) + "/-"}</div>
              </div>
            </div>
            <div className="w-full border-t border-t-black flex justify-end p-3 pt-5">
              Signature
            </div>
          </div>
          <div
            style={{
              display: width < 767 ? "flex" : "none",
            }}
            className="py-2   "
          >
            <div
              style={{
                backgroundColor: "#EDEDF2",
                overflow: "hidden",
              }}
              className=" rounded-sm  flex-1  min-w-[140px] items-center "
            >
              <input
                type={"Mobie"}
                style={{
                  fontSize: 13,
                  backgroundColor: "#EDEDF2",
                  paddingLeft: 10,
                  padding: 5,
                  fontWeight: 400,
                  outline: 0,
                }}
                placeholder={"123"}
                value={voucherDetails.MOBILE}
              />
            </div>
            <button
              id={"Button1"}
              size={"small"}
              className="flex items-center px-2  flex-1 ml-1 rounded-sm  justify-center "
              onClick={() => {
                handleSendImage(voucherDetails.MOBILE, amt);
              }}
              style={{
                backgroundColor: "#A8CF45",
                color: "white",
                textTransform: "none",
                width: "100%",
              }}
            >
              <FaWhatsapp size={20} className=" text-white" />
            </button>

            <button
              size={"small"}
              className="flex flex-1 ml-1 items-center justify-center px-2 rounded-sm"
              onClick={() => {
                handleSendSms(voucherDetails.MOBILE, amt);
              }}
              style={{
                backgroundColor: "#F5874F",
                color: "white",
                width: "100%",

                textTransform: "none",
              }}
              variant="contained"
            >
              <TiMessage size={20} className="bg-orange text-white" />
            </button>

            <button
              size={"small"}
              className="flex items-center flex-1 ml-1 px-2 rounded-sm"
              onClick={downloadPdf}
              style={{
                backgroundColor: "#383535",
                color: "white",
                textTransform: "none",
                width: "100%",
                justifyContent: "center",
              }}
              variant="contained"
            >
              <BiCloudDownload size={20} className=" text-white" />
            </button>

            <button
              size={"small"}
              className="flexc flex-1 ml-1 px-2 items-center rounded-sm"
              style={{
                backgroundColor: "#383535",
                borderColor: "#383535",
                textTransform: "none",
                width: "100%",
                color: "white",
                justifyContent: "center",
              }}
              onClick={() => {
                setVoucherNo("");
              }}
              variant="outlined"
            >
              <TiArrowBackOutline size={20} className=" " />
            </button>
          </div>
        </div>

        <div>
          <div
            style={{
              display: width > 767 ? "flex" : "none",
            }}
            className="p-4   flex-col  "
          >
            <div className="w-full">
              <div className="flex justify-between w-full  "></div>

              <div className="flex justify-start items-center w-full  ">
                <div style={{ fontSize: 13 }}>Mobile</div>
                <div
                  style={{
                    height: 27,
                    backgroundColor: "#EDEDF2",
                    overflow: "hidden",
                    marginLeft: 10,
                  }}
                  className=" rounded-sm  w-16 md:w-40  items-center "
                >
                  <input
                    type={"number"}
                    style={{
                      fontSize: 13,
                      backgroundColor: "#EDEDF2",
                      paddingLeft: 10,

                      fontWeight: 400,
                      outline: 0,
                    }}
                    placeholder={"123"}
                    value={voucherDetails.MOBILE}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-5 justify-center">
              <button
                id={"Button1"}
                size={"small"}
                className="flex items-center p-1  rounded-sm focus:bg-red-500 focus:border focus:border-black"
                onClick={() => {
                  // handleSendImage(voucherDetails.MOBILE, amt);
                }}
                style={{
                  backgroundColor: "#A8CF45",
                  color: "white",
                  textTransform: "none",
                  width: "100%",
                  justifyContent: "start",
                  paddingLeft: 20,
                }}
              >
                <div className="ml-4">
                  <FaWhatsapp size={20} className="bg-green text-white" />
                </div>
                <div className="px-2 text-sm"> {"Whatsapp"}</div>{" "}
              </button>

              <Button
                size={"small"}
                //  onClick={()=>{
                //   handleSendSms(voucherDetails.MOBILE,amt)
                //  }}
                style={{
                  backgroundColor: "#F5874F",
                  color: "white",
                  marginTop: 10,
                  width: "100%",
                  justifyContent: "start",
                  paddingLeft: 20,

                  textTransform: "none",
                }}
                variant="contained"
              >
                <TiMessage size={20} className="bg-orange text-white" />
                <div className="px-2">SMS</div>{" "}
              </Button>

              <Button
                size={"small"}
                onClick={downloadPdf}
                style={{
                  backgroundColor: "#F5874F5",
                  color: "white",
                  marginTop: 10,
                  textTransform: "none",
                  width: "100%",
                  justifyContent: "start",
                  paddingLeft: 20,
                }}
                variant="contained"
              >
                <BiCloudDownload size={20} className="bg-brown text-white" />
                <div className="px-2">Download</div>{" "}
              </Button>

              <Button
                size={"small"}
                style={{
                  color: "#383535",
                  marginTop: 10,
                  borderColor: "#383535",
                  textTransform: "none",
                  width: "100%",
                  justifyContent: "start",
                  paddingLeft: 20,
                }}
                onClick={() => {
                  setVoucherNo("");
                }}
                variant="outlined"
              >
                <TiArrowBackOutline
                  //   onClick={() => dispatch(setShareImageModalStatus(false))}
                  size={20}
                  className=" text-brown"
                />
                <div
                  //   onClick={() => dispatch(setShareImageModalStatus(false))}
                  className="px-2"
                >
                  Back{" "}
                </div>{" "}
              </Button>
            </div>

            <ReactToPrint
              trigger={() => {
                return (
                  <Button
                    size={"small"}
                    style={{
                      backgroundColor: "#BAD4F9",
                      color: "black",
                      textTransform: "none",
                      marginTop: 10,
                    }}
                    variant="contained"
                  >
                    <div className="px-2">Print</div>{" "}
                  </Button>
                );
              }}
              content={() => printRef.current}
            />

            <div className="w-full flex justify-center mt-3 "></div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VoucherPrint;
