import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";

import logo from "../../images/GOROUND2.png";
import { Alert1Button, Alert2Button } from "../buttons";
import { Button } from "@nextui-org/react";

export default function AlertModalTwoButton({
  details,
  setAlertTwoButtonModal,
}) {
  const handleClose = () =>
    setAlertTwoButtonModal({
      title: "",
      msg: "",
      status: false,
      setStatus: "",
      button1: "",
      button1Click: "",
      button2: "",
      button2Click: "",
    });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
    minWidth: "280px",
    // border: '1px solid white',
    boxShadow: 24,
    borderRadius: "7px",
    outline: "none",
  };
  const [focusedAlement, setFocusedAlement] = useState("Button1");

  const formRef = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (formRef.current) {
        const inputFields = formRef.current.querySelectorAll("button");
        inputFields.forEach((element) => {
          if (element.id === focusedAlement) {
            element.focus();
            // element.style.border = '1px dashed black'; // Change border color
          }
        });

        const handleKeyDown = (event) => {
          if (event.key === "ArrowRight") {
            const nextButton = document.getElementById("Button2");
            if (nextButton) {
              nextButton.focus();
            }
          } else if (event.key === "Enter" || event.key === "NumpadEnter") {
            const focusedElement = document.activeElement;
            if (!details?.button2) {
              const button1cl = document.getElementById("Button1");
              button1cl.click();
            } else {
              focusedElement.click();
            }
          } else {
            if (event.key === "ArrowLeft") {
              const nextButton = document.getElementById("Button1");
              if (nextButton) {
                nextButton.focus();
              }
            }
          }
        };

        formRef.current.addEventListener("keydown", handleKeyDown);

        return () => {
          formRef.current.removeEventListener("keydown", handleKeyDown);
        };
      }
    }, 200);

    return () => clearTimeout(timeout);
  }, [details, focusedAlement]);
  return (
    <div>
      <Modal open={details.status}>
        <div ref={formRef} style={style} className=" w-full ">
          {details?.horizontal ? (
            <div className="min-w-[250px] max-w-[410px]">
              <div className="bg-white flex p-3 rounded-t-sm">
                <div className="h-8 rounded-[64px] w-8  flex items-center justify-center">
                  {/* <ReportProblemOutlined  /> */}
                  <i className="bi  text-[#f87f7f] text-[28px] justify-center itlgems-center flex bi-exclamation-circle-fill"></i>
                </div>

                <div className="ml-3">
                  <div className="font-semibold text-[16px]">
                    {details.title}
                  </div>
                  <div className=" text-[14px]  text-[#645f5f] mt-1 ">
                    {details.msg}
                  </div>
                </div>
              </div>
              <div className="flex justify-end p-2 bg-[#f2f4f7] rounded-b-sm">
                <div>
                  <Button
                    // ref={updateRef}
                    onClick={handleClose}
                    variant={"contained"}
                    style={{
                      textTransform: "none",
                      letterSpacing: "1px",
                      backgroundColor: "#f87f7f",
                      width: "100%",
                      marginLeft: 4,
                      borderRadius: `7px`,
                    }}
                  >
                    <div className="text-white   px-3 ">Ok </div>
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className={"h-full w-full bg-white  rounded-sm  p-3"}>
              <div className=" flex justify-center w-full rounded-sm">
                <div
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: -30,

                    borderRadius: 100,
                  }}
                  className="bg-white p-[2px]"
                >
                  <img
                    src={logo}
                    alt=""
                    style={{
                      width: "50px",
                      height: "auto",
                    }}
                  />
                </div>
              </div>

              <div className="w-full justify-center text-[#1c1a1a] flex mt-2 font-semibold items-top">
                {details?.title}
              </div>
              <div
                style={{
                  fontSize: "13px",
                }}
                className="w-full justify-center text-center text-[#1c1a1a] flex mt-2 "
              >
                {details?.msg}
              </div>

              <div className="w-full flex justify-center mt-4">
                <Alert1Button
                  id={"Button1"}
                  autofocus={true}
                  text={details?.button1}
                  onClick={details?.button1Click}
                  backgroundColor={details?.button1Bg}
                />
                {details?.button2 ? (
                  <Alert2Button
                    id={"Button2"}
                    backgroundColor={details?.button2Bg}
                    color={details?.button2Color}
                    text={details?.button2 ? details?.button2 : "cancel"}
                    onClick={
                      details?.button2Click
                        ? details?.button2Click
                        : handleClose
                    }
                  />
                ) : null}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
