import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { DownloadFile } from "../../utility/functions/firebase";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectPrefix, selectWidth } from "../../utility/Redux/profile";

const ItemBox2 = ({ item, refreshItems, itemNameStyle, onClick }) => {
  const [firbaseImg, setImg] = useState("");
  const prefix = useSelector(selectPrefix);
  const [isLocalImgAvailbl, setIsLocalImageAvl] = useState([]);
  const icode = item.ICODE;
  const path = `retailx/items/${prefix + icode}`;
  const width = useSelector(selectWidth);
  const fireToken = localStorage.getItem("fire");

  useEffect(() => {
    setImg("");

    setTimeout(() => {
      if (!fireToken) {
        if (!firbaseImg) {
          DownloadFile(path)
            .then((e) => {
              if (!e.includes("FirebaseError")) {
                const urlParams = new URLSearchParams(new URL(e).search);
                const token = urlParams.get("token");
                localStorage.setItem("fire", token);

                setImg(e);
              } else {
                setImg("ERROR");
              }
            })
            .catch((e) => {
              // setCapturedImage('');
              setImg("ERROR");
            });
        }
      } else {
        const i = `https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fitems%2F${prefix}${item.ICODE}?alt=media&token=${fireToken}`;
        setImg(i);
      }
    }, 500);
  }, [item, refreshItems]);

  return (
    <div>
      <Button
        variant="contained"
        onClick={onClick}
        style={itemNameStyle}
        className="text-[#4a4a4a] flex justify-start  text-sm pl-2 pt-2 text-left "
      >
        <div className="h-full w-full p-1 px-2">
          <div className="flex text-[13px] font-semibold  whitespace-nowrap overflow-hidden ">
            {item.ITEMNAME}
          </div>
          <div className="flex justify-between">
            {item.REGIONAL_INAME ? <div> {item.REGIONAL_INAME}</div> : ""}
            {item.ICODE}
            <div>{item.HSN_CODE ? "HSN" + " - " + item.HSN_CODE : ""}</div>
            {firbaseImg && firbaseImg !== "ERROR" ? (
              <img
                src={firbaseImg}
                alt=""
                onError={() => setImg("")}
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            ) : isLocalImgAvailbl[0] ? (
              <img
                src={firbaseImg}
                alt=""
                onError={() => setImg("")}
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            ) : (
              ""
            )}
          </div>
          <div
            style={{
              borderTop: "1px dotted gray",
            }}
            className="flex mt-2 text-gray-500 justify-center"
          >
            {item.AISLE && (
              <div className="flex ml-1">
                [<div>Aisle : </div>
                <div className="pl-1">{item.AISLE}</div>]
              </div>
            )}
            {item.SHELF && (
              <div className="flex ml-1">
                [ <div>Shelf : </div>
                <div className="pl-1">{item.SHELF}</div>]
              </div>
            )}
            {item.BAY && (
              <div className="flex ml-1">
                [<div>Bay : </div>
                <div className="pl-1">{item.BAY}</div>]
              </div>
            )}
          </div>
        </div>
      </Button>
    </div>
  );
};

export default ItemBox2;
