import { Fade, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FontSizeWidth } from "../utility/constant";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCompany,
  selectCompanyAddress,
  selectWidth,
} from "../utility/Redux/profile";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import { useReactToPrint } from "react-to-print";
import { Add, Remove } from "@mui/icons-material";
import qz from "qz-tray";
import { setAlertWithTitle, setSpinnerLoading } from "../utility/Redux/modal";
import { PrnStyle } from "../billing/comp";
import { selectToken } from "../utility/Redux/security";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "auto",
  borderRadius: "8px",
  overflow: "hidden",
  backgroundColor: "white",
};
const tableCellClasses = "border border-border p-2";
const inputCheckboxClasses = "border border-border p-2";
const inputNumberClasses = "border border-border p-1";
const textMutedClasses = "text-muted-foreground font-semibold";

const BarcodeList = ({ status, onClose, list, component, printBarRef }) => {
  const selectedStylelocal = JSON.parse(localStorage.getItem("selectedStyle"));
  const selecteSizeLocal = localStorage.getItem("selectedSize");
  const company = useSelector(selectCompanyAddress);
  const dispatch = useDispatch();
  const columns = [
    {
      name: "Item Name",
      width: 200,
    },
    {
      name: "Purchase Price",
      width: 130,
    },
    {
      name: "Sale Price",
      width: 130,
    },
    {
      name: "Expiry Date",
      width: 130,
    },
    {
      name: "Batch",
      width: 130,
    },
    {
      name: "Qnty",
      width: 90,
    },
    // {
    //   name: "BarCode",
    //   width: 90,
    // },
  ];

  const styles = {
    style1: {
      name: "2/1 inch",
      value: { width: "192px", height: "96px" }, // 2/1 inch
    },
    style2: {
      name: "2/1.5 inch",
      value: {
        width: "192px",
        height: "144px",
      }, // 2/1.5 inch
    },
    style3: {
      name: "2/2 inch",
      value: { width: "192px", height: "192px" }, // 2/2 inch
    },
    style4: {
      name: "1.5/1 inch",
      value: { width: "144px", height: "96px" }, // 1.5/1 inch
    },
  };
  const [selectedPrinter, setSelectedPrinter] = useState("");
  const [printerList, setPrinterList] = useState([]);
  const [selectedSize, setSelectedSize] = useState(
    selecteSizeLocal ? selecteSizeLocal : "style1"
  );
  const handleSelectChange = (event) => {
    setSelectedSize(event.target.value);
    localStorage.setItem("selectedSize", event.target.value);

    setSelectedStyle(styles[event.target.value].value);
  };

  const [selectedStyle, setSelectedStyle] = useState(
    selectedStylelocal ? selectedStylelocal : styles.style1.value
  );
  const handlePrint = async () => {
    dispatch(setSpinnerLoading("Printing"));
    console.log(selectedSize);
    const prn = PrnStyle(company, barcodeList[0], 1, selectedSize);
    let prnCommands = "";
    selectedBarList.forEach((item) => {
      for (let i = 0; i < item.barQnty; i++) {
        prnCommands += PrnStyle(company, item, 1, selectedSize);
      }
    });
    console.log(prnCommands);
    if (prn) {
      const config = qz.configs.create(selectedPrinter); // Replace with your printer name

      try {
        await qz.print(config, [prnCommands]);
        console.log("Print job sent successfully!");
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Printing Successful",
          })
        );
      } catch (err) {
        console.error("Error while printing:", err);
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something went wrong while printing",
          })
        );
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: "Prn File not found for this size",
        })
      );
    }
  };
  const [barcodeList, setBarCodeList] = useState([]);
  const [selectedBarList, setSelectedBarList] = useState([]);
  const [grid, setGrid] = useState(1);
  const [lineWidth, setLineWidth] = useState(1);
  const width = useSelector(selectWidth);
  const token = useSelector(selectToken);
  useEffect(() => {
    const l = list.filter((o) => o.ICODE && o.ITEMNAME);

    setBarCodeList(
      l.map((o) => {
        return {
          ...o,
          barQnty: Number(o.QNTY) + Number(o.FREE_QNTY),
        };
      })
    );

    setSelectedBarList(
      l.map((o) => {
        return {
          ...o,
          barQnty: Number(o.QNTY) + Number(o.FREE_QNTY),
        };
      })
    );
  }, [list, status]);

  useEffect(() => {
    localStorage.setItem("selectedStyle", JSON.stringify(selectedStyle));
  }, [selectedStyle]);

  useEffect(() => {
    const connectPrinterAndList = () => {
      return new Promise((resolve, reject) => {
        // Check if QZ Tray WebSocket is already active
        if (qz.websocket.isActive()) {
          console.log("QZ Tray is already connected.");
          qz.printers
            .find()
            .then((printers) => {
              resolve(printers);
            })
            .catch((err) => {
              console.error("Error finding printers:", err);
              reject(err);
            });
        } else {
          qz.security.setCertificatePromise(function (resolve, reject) {
            // Preferred method - from server
            fetch("./digital-certificate.txt", {
              cache: "no-store",
              headers: { "Content-Type": "text/plain" },
            }).then(function (data) {
              data.ok ? resolve(data.text()) : reject(data.text());
            });
          });

          qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
          qz.security.setSignaturePromise(function (toSign) {
            return function (resolve, reject) {
              axios
                .post(
                  `/api/barCodeSignIn/barCodePrivateKey`,
                  {
                    toSign: toSign,
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((res) => {
                  if (res.data.signature) {
                    resolve(res.data.signature);
                  }
                })
                .catch((e) => console.error(e));
              // Preferred method - from server
              // fetch(
              //   "http://localhost:5050/secure/url/for/sign-message?request=" +
              //     toSign,
              //   {
              //     cache: "no-store",
              //     headers: { "Content-Type": "text/plain" },
              //   }
              // ).then(function (data) {
              //   console.log(data);
              //   data.ok ? resolve(data.text()) : reject(data.text());
              // });

              //Alternate method - unsigned
              // resolve(); // remove this line in live environment
            };
          });

          qz.websocket
            .connect()
            .then(() => {
              qz.printers
                .find()
                .then((printers) => {
                  resolve(printers);
                })
                .catch((err) => {
                  console.error("Error finding printers:", err);
                  reject(err);
                });
            })
            .catch((err) => {
              // dispatch(
              //   setAlertWithTitle({
              //     title: "Alert",
              //     msg: "Make sure you are running qz application",
              //   })
              // );
              console.error("Connection Error:", err);
              reject(err);
            });
        }
      });
    };

    if (status) {
      connectPrinterAndList()
        .then((printers) => {
          setPrinterList(
            printers.map((printer) => ({
              name: printer.name,
              value: printer,
            }))
          );
        })
        .catch((error) => {
          console.error("Error connecting to printer:", error);
        });
    }
  }, [status]);

  return (
    <Modal
      open={status}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade
        in={status}
        style={{
          transitionDelay: status ? "100ms" : "0ms",
        }}
      >
        <div style={style} className="overflow-hidden bg-transparent">
          <div className="flex bg-brown text-white justify-between overflow-hidden items-center min-w-[350px] p-2">
            <div>BarCode List</div>
            <button fontSize={"medium"} onClick={onClose}>
              {" "}
              <i className="bi bi-x-square-fill  text-[22px] text-red-500 font-bold p-1"></i>
            </button>
          </div>

          <div className="p-2 bg-white rounded-b-sm">
            <div className="flex  overflow-hidden border-t border-r border-[#dbd9d9] border-b rounded-t-sm bg-gray-200">
              {columns.map((o, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      width: o.width,
                      fontSize: FontSizeWidth(width),
                    }}
                    className=" py-1 border-l border-[#dbd9d9] font-semibold  text-center"
                  >
                    {o.name}
                  </div>
                );
              })}
            </div>
            <div className=" border-l  border-[#dbd9d9] border-r max-h-[400px] overflow-y-auto">
              {barcodeList.map((o, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      fontSize: FontSizeWidth(width),
                    }}
                    className=" bg-gray-100 border-r   border-b flex border-[#dbd9d9] "
                  >
                    <div
                      style={{
                        width: columns[0].width,
                      }}
                      className="border-[#dbd9d9] border-r p-1 overflow-hidden whitespace-nowrap"
                    >
                      <input
                        type={"checkbox"}
                        className="mr-1"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedBarList((b) => {
                              return [...b, o];
                            });
                          } else {
                            setSelectedBarList((b) =>
                              b.filter((r) => r.ICODE !== o.ICODE)
                            );
                          }
                        }}
                        checked={
                          selectedBarList.find((r) => r.ICODE === o.ICODE)
                            ? true
                            : false
                        }
                        value={
                          selectedBarList.find((r) => r.ICODE === o.ICODE)
                            ? true
                            : false
                        }
                      />

                      {o.ITEMNAME}
                    </div>
                    <div
                      style={{
                        width: columns[1].width,
                      }}
                      className="text-right border-[#dbd9d9] border-r p-1"
                    >
                      {FormatNumber2(o.PUR_PRICE)}
                    </div>

                    <div
                      style={{
                        width: columns[2].width,
                      }}
                      className="text-right border-[#dbd9d9] border-r p-1"
                    >
                      {FormatNumber2(o.SALE_PRICE)}
                    </div>

                    <div
                      style={{
                        width: columns[3].width,
                      }}
                      className=" border-[#dbd9d9] border-r p-1"
                    >
                      {o.EXP_DATE}
                    </div>
                    <div
                      style={{
                        width: columns[4].width,
                      }}
                      className=" border-[#dbd9d9] border-r p-1"
                    >
                      {o.BATCH}
                    </div>
                    <div
                      style={{
                        width: columns[5].width,
                      }}
                      className=" border-[#dbd9d9] border-r hover:cursor-pointer  flex items-center"
                    >
                      <div
                        onClick={() => {
                          setBarCodeList((c) =>
                            c.map((b) => {
                              const q = b.barQnty - 1;

                              if (b.ICODE === o.ICODE) {
                                return {
                                  ...b,
                                  barQnty: q > 0 ? q : 1,
                                };
                              } else {
                                return b;
                              }
                            })
                          );

                          setSelectedBarList((c) =>
                            c.map((b) => {
                              const q = b.barQnty - 1;

                              if (b.ICODE === o.ICODE) {
                                return {
                                  ...b,
                                  barQnty: q > 0 ? q : 1,
                                };
                              } else {
                                return b;
                              }
                            })
                          );
                        }}
                        className="px-2 border-r h-full flex items-center bg-gray-100"
                      >
                        <Remove
                          sx={{
                            fontSize: "12px",
                          }}
                        />
                      </div>
                      <input
                        type="number"
                        value={o.barQnty}
                        onChange={(e) => {
                          if (e.target.value) {
                            setSelectedBarList((c) =>
                              c.map((b) => {
                                if (b.ICODE === o.ICODE) {
                                  return {
                                    ...b,
                                    barQnty:
                                      e.target.value > 0 ? e.target.value : 1,
                                  };
                                } else {
                                  return b;
                                }
                              })
                            );
                            setBarCodeList((c) =>
                              c.map((b) => {
                                if (b.ICODE === o.ICODE) {
                                  return {
                                    ...b,
                                    barQnty:
                                      e.target.value > 0 ? e.target.value : 1,
                                  };
                                } else {
                                  return b;
                                }
                              })
                            );
                          }
                        }}
                        className="w-full  text-center bg-transparent outline-none"
                      />
                      <div
                        onClick={() => {
                          setSelectedBarList((c) =>
                            c.map((b) => {
                              if (b.ICODE === o.ICODE) {
                                return {
                                  ...b,
                                  barQnty: b.barQnty + 1,
                                };
                              } else {
                                return b;
                              }
                            })
                          );

                          setBarCodeList((c) =>
                            c.map((b) => {
                              if (b.ICODE === o.ICODE) {
                                return {
                                  ...b,
                                  barQnty: b.barQnty + 1,
                                };
                              } else {
                                return b;
                              }
                            })
                          );
                        }}
                        className="px-2 border-l h-full flex items-center bg-gray-100"
                      >
                        <Add
                          sx={{
                            fontSize: "12px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="pb-2 pt-2 border-t border-gray-300 mt-3 items-end ">
              <div className="flex">
                <div className="w-[80px] pr-2">Printer</div>:
                <select
                  onChange={(e) => {
                    setSelectedPrinter(e.target.value);
                  }}
                  value={selectedPrinter}
                  className="outline-none mb-2 ml-2 border rounded-sm p-1"
                >
                  {printerList.map((d, key) => (
                    <option key={key} value={d.value}>
                      {d.value}
                    </option>
                  ))}
                </select>{" "}
              </div>

              <div className="flex">
                <div className="w-[80px] pr-2">Size</div>:
                <select
                  onChange={handleSelectChange}
                  value={selectedSize}
                  className="outline-none mb-2 border ml-2 rounded-sm p-1"
                >
                  {Object.keys(styles).map((key) => {
                    return (
                      <option value={key} key={key}>
                        {styles[key].name}
                      </option>
                    );
                  })}
                </select>{" "}
              </div>
              <div className="flex justify-end">
                <button
                  onClick={handlePrint}
                  className=" bg-black h-[35px] px-3 text-sm rounded-sm text-white"
                >
                  Print Bar Code
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default BarcodeList;
