import React, { useEffect, useState } from 'react';
import Scanner from './scanner';
import Modal from '@mui/material/Modal';
import QRCodeScanner from './AllScanner';


 const BarcodeScanner = ({status,setBarCode,setStatus,onChange}) => {
  const [results, setResults] = useState([]);
  const [camera,setCamera] = useState('')
  const onDetected = (result) => {

    setResults(result);
    if(setBarCode){
      setBarCode(result)

    }
    
    if(onChange){
      onChange(result)
    }
    setStatus(false)
  };


  const styleMobile = {
    position: 'absolute',
    top:'50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:'auto',
    height: 'auto',
    boxShadow: 24,
    border:'1px solid black'
  };



  return (
    <Modal open={status}>
    <div style={styleMobile} className='flex  bg-white  '>
<div className='w-full'>
<div className='w-full flex justify-between bg-brown text-white p-1 '>
  <div>
  genius office

  </div>
  <div>
    <button onClick={()=> {
      setStatus(false)
     if(camera){
      camera
      .stop()
      .then(() => {
        console.log('Camera stopped successfully.');
        setCamera("")
      })
      .catch((err) => {
        console.error('Error stopping the camera:', err);
      });
     } 
    }}>
    <i className="bi text-red-400 text-[20px] bi-x-square-fill"></i>

    </button>

  </div>
</div>
<div className=' flex justify-center'>
<QRCodeScanner status={status} onDetected={onDetected}  setCamera={setCamera}/>

</div>





        

  
      </div>
    </div>
    </Modal>
  );
};
export default BarcodeScanner