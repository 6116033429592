import React from "react";
import CallAPI from "../../utility/functions/getData";
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import deleteWholeDatabase from "../../utility/functions/deleteWholeDatabase";
import dataToLocal from "../../utility/component/dataToLocal";

export const GetItemWiseSaleReport = (
  data,
  token,
  dispatch,
  setSummaryList,
  setFullItemWiseReport
) => {
  dispatch(setSpinnerLoading("Loading..."));
  deleteWholeDatabase("fieldway_dfr");

  CallAPI(
    "https://fieldway-kd5zk.ondigitalocean.app/api/dailyProgress/marketingProgressList",
    data,
    token,
    (e) => {
      if (!e.data.error) {
        if (e.data.data[0]) {
          dataToLocal("fieldway_dfr", e.data.data, [
            {
              ...data,
              ICODE: 1,
            },
          ]);
          setFullItemWiseReport(e.data.data);
          setSummaryList(e.data.data);
        } else {
          setSummaryList([]);
          setFullItemWiseReport([]);

          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "No Records Found",
            })
          );
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Something Went Wrong.",
          })
        );
      }
    },
    dispatch,
    () => {
      dispatch(setSpinnerLoading(false));
    }
  );
};
