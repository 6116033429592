import React, { useState } from "react";
import GeniusSelectDate from "./selectDate";
import { useDispatch, useSelector } from "react-redux";
import {
  selectreportFromDate,
  selectreportToDate,
  selectUserType,
  setreportsFromDate,
  setreportsTodate,
} from "../Redux/profile";
import moment from "moment";
const FromToDateMobile = ({
  onPartyMasterSearchClick,
  sectionHelp,
  customerClear,
  onPrintClick,
  maxWidth,
  inputClear,
  onEmployeeSearchClick,
  EmployeeData,
  partyClear,
  onChangeInputText,
  inputText,
  inputTextPlaceHolder,
  hideDate,
  onSectionClick,
  sectionData,
  otherButton,
  inputTextValue,
  onItemClick,
  itemData,
  partyData,
  onCustomerSearchClick,
  customerData,
  date,
  itemCode,
  submit,
  filter,
  reset,
  partyReset,
}) => {
  const dispatch = useDispatch();
  const fromDate = useSelector(selectreportFromDate);
  const toDate = useSelector(selectreportToDate);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [ToDateFocus, setToDateFocus] = useState(false);
  return (
    <div
      style={
        maxWidth
          ? {
              maxWidth: maxWidth,
              border: "1px solid gray",
            }
          : { border: "1px solid gray" }
      }
      className="rounded-sm overflow-hidden"
    >
      {sectionHelp && sectionHelp}

      {!hideDate && (
        <div
          style={{}}
          className=" rounded-t-sm overflow-hidden grid grid-cols-2 gap-2 pt-2  px-2 pb-2 items-center"
        >
          {!hideDate && (
            <GeniusSelectDate
              date={fromDate}
              grid={true}
              onChange={(e) => {
                dispatch(setreportsFromDate(moment(e).format("YYYY-MM-DD")));
                setFromDateFocus(false);
              }}
              width={"110px"}
              dateFocus={fromDateFocus}
              SetDateFocus={setFromDateFocus}
            />
          )}

          {!hideDate && (
            <GeniusSelectDate
              grid={true}
              date={toDate}
              onChange={(e) => {
                dispatch(setreportsTodate(moment(e).format("YYYY-MM-DD")));
                setToDateFocus(false);
              }}
              width={"110px"}
              dateFocus={ToDateFocus}
              SetDateFocus={setToDateFocus}
            />
          )}
        </div>
      )}

      <div className="border border-l-0 borr-b-0 border-r-0 px-2">
        {onPartyMasterSearchClick && (
          <div className="border-b">
            <div className="flex  h-full items-center  py-2 ">
              <div className="flex hover:cursor-pointer h-full items-center">
                <div onClick={onPartyMasterSearchClick}>
                  <i
                    className="bi bi-search"
                    style={{ color: "#f44336", fontSize: 15 }}
                  ></i>
                </div>
              </div>

              <div
                style={{
                  borderRadius: "3px",
                }}
                className="flex flex- flex-1  pl-2 ml-2 "
              >
                <input
                  size={"small"}
                  placeholder="Party Name"
                  value={partyData.NAME ? partyData.NAME : "Party Name"}
                  style={{
                    fontSize: 12,
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    backgroundColor: "transparent",
                  }}
                  readOnly
                  className=" outline-0 flex-1 flex "
                />
              </div>
              {partyClear && (
                <button onClick={partyClear} className="pl-2">
                  <i className="bi bi-x-square-fill text-red-500 text-[20px]"></i>
                </button>
              )}
            </div>
          </div>
        )}

        {onCustomerSearchClick && (
          <div style={{}} className="flex flex-1 py-2   text-[#4B4545]   ">
            <div
              style={{ fontSize: 13 }}
              className=" flex whitespace-normal  flex-1  justify-center items-center hover:cursor-pointer "
            >
              <div className="flex items-center ">
                <button onClick={onCustomerSearchClick}>
                  <i
                    className="bi bi-search"
                    style={{ color: "#f44336", fontSize: 15 }}
                  ></i>
                </button>
              </div>

              <div
                style={{
                  borderRadius: "3px",
                }}
                className="flex text-[#a6a2a2] flex-1  pl-2 ml-2 "
              >
                <input
                  size={"small"}
                  placeholder="Customer Name"
                  value={
                    customerData.first_name
                      ? customerData.CUSTOMER_NAME +
                        " " +
                        " [ " +
                        customerData.CUSTOMER_MOBILE +
                        " ] "
                      : ""
                  }
                  style={{
                    fontSize: 12,
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    backgroundColor: "transparent",
                  }}
                  readOnly
                  className=" outline-0 text-black flex-1 flex"
                />
              </div>
            </div>
            {customerClear && (
              <button onClick={customerClear} className="pl-2">
                <i className="bi bi-x-square-fill text-red-500 text-[20px]"></i>
              </button>
            )}
          </div>
        )}
        {onEmployeeSearchClick && (
          <div style={{}} className="flex flex-1 py-2   text-[#4B4545]   ">
            <div
              style={{ fontSize: 13 }}
              className=" flex whitespace-normal  flex-1  justify-center items-center hover:cursor-pointer "
            >
              <div className="flex items-center ">
                <button onClick={onEmployeeSearchClick}>
                  <i
                    className="bi bi-search"
                    style={{ color: "#f44336", fontSize: 15 }}
                  ></i>
                </button>
              </div>

              <div
                style={{
                  borderRadius: "3px",
                }}
                className="flex text-[#a6a2a2] flex-1  pl-2 ml-2 "
              >
                <input
                  size={"small"}
                  placeholder="Employee Name"
                  value={EmployeeData.first_name ? EmployeeData.first_name : ""}
                  style={{
                    fontSize: 12,
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    backgroundColor: "transparent",
                  }}
                  readOnly
                  className=" outline-0 text-black flex-1 flex"
                />
              </div>
            </div>
          </div>
        )}
        {onItemClick && (
          <div style={{}} className="flex  p-2  text-[#4B4545]   ">
            <div
              style={{ fontSize: 13 }}
              className=" flex whitespace-normal  justify-center items-center hover:cursor-pointer "
            >
              <div className="flex items-center ">
                <button onClick={onItemClick}>
                  <i
                    className="bi bi-search"
                    style={{ color: "#f44336", fontSize: 15 }}
                  ></i>
                </button>

                <span
                  style={{ fontSize: 12, fontWeight: 600 }}
                  className=" text-[#5c5b5b] pl-2 whitespace-nowrap  items-center"
                >
                  {" "}
                  Item :
                </span>
              </div>
              <div className="ml-2 font-semibold text-[15px] text-red-500">
                {itemData?.ITEMNAME
                  ? itemData.ITEMNAME + "[ " + itemData?.ICODE + " ]"
                  : ""}
              </div>
            </div>
          </div>
        )}
        {onSectionClick && (
          <div style={{}} className="flex  p-2  text-[#4B4545]   ">
            <div
              style={{ fontSize: 13 }}
              className=" flex whitespace-normal  justify-center items-center hover:cursor-pointer "
            >
              <div className="flex items-center ">
                <button onClick={onSectionClick}>
                  <i
                    className="bi bi-search"
                    style={{ color: "#f44336", fontSize: 15 }}
                  ></i>
                </button>
              </div>

              <div className="flex text-[#a6a2a2]  pl-2 ml-2  ">
                <input
                  size={"small"}
                  placeholder="Section "
                  value={sectionData?.SECTION_NAME}
                  style={{
                    fontSize: 12,
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    backgroundColor: "transparent",
                  }}
                  readOnly
                  className=" outline-0 w-[140px] "
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="  flex-1 py-2 px-2" style={{}}>
        <div className="flex ">
          {onChangeInputText && (
            <div className=" flex items-center 1 w-full  ">
              <input
                value={inputTextValue}
                onChange={onChangeInputText}
                placeholder={inputTextPlaceHolder}
                className="outline-none py-[3px] text-[16px] pl-2 rounded-sm  border flex-1"
              />
              {inputClear && (
                <button onClick={inputClear} className="px-2 ">
                  <i className="bi bi-x-square-fill text-red-500 text-[20px]"></i>
                </button>
              )}{" "}
            </div>
          )}
        </div>

        <div className="flex mt-2">
          {submit && (
            <div className=" flex-1  flex">
              <button
                onClick={submit}
                className="flex  items-center flex-1 rounded-sm justify-center"
                style={{
                  backgroundColor: "#c1c1c1",
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 4,
                  paddingBottom: 4,
                  color: "black",
                }}
              >
                <div className="flex items-center text-center  w-fit text-sm">
                  Ok
                </div>
              </button>
              {onPrintClick && (
                <button
                  onClick={onPrintClick}
                  className="flex  items-center flex-1 ml-2 rounded-sm justify-center"
                  style={{
                    backgroundColor: "#bad4f9",
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 4,
                    paddingBottom: 4,
                    color: "black",
                  }}
                >
                  <div className="flex items-center text-center  w-fit text-sm">
                    Print
                  </div>
                </button>
              )}
            </div>
          )}

          {otherButton && <div className="flex "> {otherButton} </div>}
        </div>
      </div>
    </div>
  );
};

export default FromToDateMobile;
