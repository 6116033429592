import React, { useState, useEffect } from "react";
import { formatMobileNumber, toProperCase } from "../utility/component/format";
import moment from "moment";
import pickup from "../utility/json/pickup.json";
import delivery from "../utility/json/delivery.json";
import Lottie from "lottie-react";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import { GoogleMap, LoadScript } from "@react-google-maps/api"; // Import LoadScript
import { useDispatch, useSelector } from "react-redux";
import {
  selectLatitude,
  selectLongitude,
  selectPrefix,
} from "../utility/Redux/profile";
import { GetSaleSummary } from "../reports/recievedOrders/func";
import { selectToken } from "../utility/Redux/security";
import { useNavigate } from "react-router-dom";
import { child, get, onValue, ref } from "firebase/database";
import { database } from "../utility/functions/firebase";
import SwipeableEdgeDrawer from "../billing/drawer";
import { LoadBill } from "../billing/function";
import AlertModalTwoButton from "../utility/component/modals/alertWithTwoButton";
import CallAPI from "../utility/functions/getData";
import { setAlertWithTitle, setSpinnerLoading } from "../utility/Redux/modal";

const buttonClasses = "px-4 py-2 rounded-lg";

// Google Maps API Key (replace with your actual API key)
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_MAP_KEY;
const emptyCustomer = {
  CUSTOMER_NAME: "",
  CUSTOMER_ADDRESS: "",
  CUSTOMER_MOBILE: "",
  CUSTOMER_EMAIL: "",
  CUSTOMER_TYPE: "",
  CITY: "",
  COUNTRY: "",
  PIN_CODE: "",
  DISQ_RATE: "",
  LOYALTY_POINTS: 0,
  CUSTOMER_STATUS: "Active",
  PRV_BAL: 0,
  BAL_TYPE: "",
  CUSTOMER_CODE: "",
};
const emptyBillingDetails = {
  mobile: "",
  customerName: "",
  cashAmount: 0,
  cardAmount: 0,
  cardNo: "",
  eAmount: 0,
  btcAmount: 0,
  btcName: "",
  couponAmount: 0,
  couponNo: "",
};

const CartItem = ({ item }) => {
  const prefix = useSelector(selectPrefix);

  return (
    <div className="flex z-100  rounded-sm bg-[#d1d4c4] items-center mb-2 ">
      <div
        style={{
          borderRight: "1px solid #c3c7b5",
        }}
        className=" w-[70px] h-[70px] p-2 flex items-center justify-center"
      >
        <img
          src={`https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fitems%2F${prefix}${
            item.ICODE
          }?alt=media&token=${"49cc9f81-1858-4029-a612-67d2c918e7ff"}`}
          alt={item.ITEMNAME}
          className="w-[100px] max-h-full rounded-sm"
          onError={(e) => {
            e.target.src = `https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fitems%2F${"retailIcon.png"}?alt=media&token=${"49cc9f81-1858-4029-a612-67d2c918e7ff"}`;
          }}
        />
      </div>
      <div className="flex justify-between flex-1 items-center">
        <div className="  ml-3 max-w-[150px] text-[13px]  h-fit ">
          <div className="text-gray-700">{toProperCase(item.ITEMNAME)}</div>
          <div className="text-gray-400 lowercase">
            {" "}
            {item.PACKING} - {item.UNIT}
          </div>
          <div className="font-semibold"> ₹{item.SALE_PRICE}</div>
        </div>
        <div className="text-sm pr-2 flex items-center">
          <i className="bi bi-x flex items-center mt-1"></i>
          <div>{item.QNTY}</div>
        </div>
      </div>
    </div>
  );
};
const RecivedOrderDashboard = () => {
  const [summaryList, setSummaryList] = useState([]);
  const [billOrderDetails, setBillOrderDetails] = useState([]);
  const [customerDetails, setCustomerDetails] = useState(emptyCustomer);
  const [billingDetails, setBillingDetails] = useState(emptyBillingDetails);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });
  const navigate = useNavigate();
  const [viewOrderStatus, setViewOrderStatus] = useState(false);
  // State to store addresses
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  // Function to handle reverse geocoding
  const reverseGeocode = (lat, lng, invno, order) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };
    console.log(latlng);
    console.log(lat);

    geocoder.geocode({ location: latlng }, (results, status) => {
      console.log(results);
      if (status === "OK" && results[0]) {
        setOrderDetails((prev) => ({
          ...prev,
          [invno]: {
            ...prev[invno],
            address: toProperCase(results[0].formatted_address), // Store address by invoice number
          },
        }));
      } else {
        setOrderDetails((prev) => ({
          ...prev,
          [invno]: {
            ...prev[invno],
            address: "Address not available", // Default message if geocode fails
          },
        }));
      }

      calculateDistance(order?.LATITUDE, order.LONGITUDE, order.INVNO, order);
    });
  };
  const [orderDetails, setOrderDetails] = useState({});
  const [selectedOrder, setSelectedOrder] = useState("");
  const lattitude = useSelector(selectLatitude);
  const longitude = useSelector(selectLongitude);
  const STORE_LOCATION = { lat: lattitude, lng: longitude }; // Replace with actual store

  const calculateHaversineDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = ((lat2 - lat1) * Math.PI) / 180; // Delta latitude in radians
    const dLon = ((lon2 - lon1) * Math.PI) / 180; // Delta longitude in radians

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers

    return distance.toFixed(2); // Return distance rounded to 2 decimal places
  };
  const calculateDistance = (lat, lng, invno) => {
    const destination = { lat: parseFloat(lat), lng: parseFloat(lng) };
    const origin = STORE_LOCATION;

    // Calculate distance using Haversine formula
    const distance = calculateHaversineDistance(
      origin.lat,
      origin.lng,
      destination.lat,
      destination.lng
    );

    setOrderDetails((prev) => ({
      ...prev,
      [invno]: {
        ...prev[invno],
        distance: `${distance} km`,
      },
    }));
  };
  useEffect(() => {
    if (isMapLoaded) {
      summaryList.forEach((order) => {
        reverseGeocode(order?.LATITUDE, order.LONGITUDE, order.INVNO, order);
      });
    }
    // Trigger reverse geocoding for each order on mount
  }, [isMapLoaded, summaryList]);
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const rejectOrder = (invno) => {
    LoadBill(
      prefix,
      token,
      dispatch,
      invno,
      setBillOrderDetails,
      setCustomerDetails,
      setBillingDetails,
      setDate,
      "bills"
    ).then((d) => {
      const data = d.map((o) => {
        return {
          ...o,
          BILL_STATUS: "Rejected",
          IP_ADDRESS: "",
          BATCH: "",
          LATITUDE: "",
          LONGITUDE: "",
        };
      });
      CallAPI(
        "/api/billing/saveBill",
        {
          table_prefix: prefix,
          bill_cart: data,
          NEW_MODIFY: "Modify",
          BILL_PERFORMA: "bills",
        },
        token,
        (e) => {
          if (!e.data.error) {
            setViewOrderStatus(false);
            dispatch(
              setAlertWithTitle({
                title: "Rejected",
                msg: `Order No. ${data[0].INVNO} of Customer ${data[0].CUSTOMER_NAME} - [ ${data[0].CUSTOMER_MOBILE} ] rejected successfully`,
              })
            );
            GetSaleSummary(
              {
                table_prefix: prefix,
                FDATE: moment().format("YYYY-MM-DD"),
                TDATE: moment().format("YYYY-MM-DD"),
                NAME: "",
                MOBILE: "",
                CUSTOMER_NAME: "",
                CUSTOMER_MOBILE: "",
              },
              token,
              dispatch,
              setSummaryList
            );
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Error",
                msg: e.data.error,
              })
            );
          }
        },
        dispatch,
        () => {
          dispatch(setSpinnerLoading(false));
        }
      );
    });
  };
  useEffect(() => {
    GetSaleSummary(
      {
        table_prefix: prefix,
        FDATE: moment().format("YYYY-MM-DD"),
        TDATE: moment().format("YYYY-MM-DD"),
        NAME: "",
        MOBILE: "",
        CUSTOMER_NAME: "",
        CUSTOMER_MOBILE: "",
      },
      token,
      dispatch,
      setSummaryList
    );
  }, []);

  useEffect(() => {
    const connectedRef = ref(
      database,
      `loginAuth/${prefix.replace(/_.+_/, "_")}`
    );
    const connectedRef2 = ref(
      database,
      `loginAuth/${prefix.replace(/_.+_/, "_")}/newOrder`
    );
    get(child(connectedRef, `/newOrder`)).then((sn) => {
      onValue(connectedRef2, (sn) => {
        const data = sn.val();
        console.log(data);

        if (sn.val()) {
          GetSaleSummary(
            {
              table_prefix: prefix,
              FDATE: moment().format("YYYY-MM-DD"),
              TDATE: moment().format("YYYY-MM-DD"),
              NAME: "",
              MOBILE: "",
              CUSTOMER_NAME: "",
              CUSTOMER_MOBILE: "",
            },
            token,
            dispatch,
            setSummaryList
          );
        }
      });
    });
  }, []);

  useEffect(() => {
    if (viewOrderStatus) {
      LoadBill(
        prefix,
        token,
        dispatch,
        selectedOrder,
        setBillOrderDetails,
        setCustomerDetails,
        setBillingDetails,
        setDate,
        "bills"
      );
    }
  }, [viewOrderStatus]);

  return (
    <LoadScript
      onLoad={() => setIsMapLoaded(true)}
      onError={() => {
        console.log("Google Maps failed to load.");
      }}
      googleMapsApiKey={GOOGLE_MAPS_API_KEY}
    >
      <div className="bg-white p-6 h-full overflow-y-auto">
        {summaryList[0] ? (
          <div className="grid 2xll:grid-cols-4  xl:grid-cols-3 slg:grid-cols-2 grid-cols-1 gap-4">
            {summaryList.map((i) => (
              <div
                key={i.INVNO}
                className="bg-[#e0e3d5] text-[#2C3E50] min-w-[400px] rounded-lg shadow-sm overflow-hidden"
              >
                <div className="flex p-4 bg-[#daddcf]">
                  <div className="h-[50px] flex items-center justify-center w-[50px] rounded-[50px] overflow-hidden bg-gray-100">
                    <Lottie
                      animationData={
                        i.ORDER_TYPE.toLowerCase() === "pickup"
                          ? pickup
                          : delivery
                      }
                      loop={true}
                      style={{ fontSize: 15, height: 30 }}
                      color="white"
                    />
                  </div>
                  <div className="ml-4 flex justify-between flex-1">
                    <div>
                      <div className="font-bold text-gray-800">
                        {toProperCase(i.CUSTOMER_NAME)}
                      </div>
                      <div className="text-[11px] text-gray-700">
                        {formatMobileNumber(i.CUSTOMER_MOBILE)}
                      </div>
                    </div>
                    <div className="text-right">
                      <div className="font-bold text-green-600">
                        ₹{FormatNumber2(i.GTOTAL)}
                      </div>
                      <div className="text-gray-500 text-[11px]">Cash</div>
                    </div>
                  </div>
                </div>

                <div className="flex pb-4 border-b px-4 bg-[#daddcf] border-[#c4c7bb]">
                  <div className="flex flex-1">
                    <div>
                      <div className="text-[14px] text-gray-600">
                        Order Type
                      </div>
                      <div className="font-semibold text-[15px]">
                        {toProperCase(i.ORDER_TYPE)}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-1 ml-4">
                    <div>
                      <div className="text-[14px] text-gray-600">Time</div>
                      <div className="font-semibold text-[15px]">
                        {moment.utc(i.DATE).format("DD-MMM-YYYY h:mm:ss A")}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: `calc(100% - 167px)`,
                  }}
                  className={"flex flex-col justify-between  "}
                >
                  <div className="mt-3 px-4">
                    <div className="flex items-center">
                      <i className="bi bi-geo-alt-fill text-[#429628]"></i>
                      <div className="ml-3">
                        <div className="text-[13px] font-semibold text-gray-800">
                          {orderDetails[i.INVNO]?.address ||
                            "Fetching address..."}
                        </div>
                        <div className="text-[11px] text-gray-500">
                          {orderDetails[i.INVNO]?.distance
                            ? `${orderDetails[i.INVNO].distance} Distance`
                            : "Fetching distance..."}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex p-4">
                    <button
                      onClick={() => {
                        setViewOrderStatus(true);
                        setSelectedOrder(i.INVNO);
                      }}
                      className={`bg-[#c9ceb8]  flex flex-1 mr-3 text-[#33352b] items-center justify-center   ${buttonClasses}`}
                    >
                      View
                    </button>
                    <button
                      onClick={() => {
                        navigate(`/billing`, {
                          state: {
                            INVNO: i.INVNO,
                          },
                        });
                      }}
                      className={`bg-[#4A5530] text-[ #FFFFFF] flex flex-1 items-center justify-center text-primary-foreground hover:bg-[#404929] ${buttonClasses}`}
                    >
                      <i className="bi bi-check2 flex items-center mt-1"></i>
                      <div className="ml-1">Accept</div>
                    </button>
                    <button
                      onClick={() => {
                        setAlertTwoButtonModal({
                          title: "Reject",
                          msg: "Are You sure you want to reject this order?",
                          status: true,
                          button1: "Yes",
                          button2: "Cancel",
                          button1Click: () => {
                            rejectOrder(i.INVNO);
                            setAlertTwoButtonModal((o) => {
                              return {
                                ...o,
                                status: false,
                              };
                            });
                          },

                          button2Click: () => {
                            setAlertTwoButtonModal((o) => {
                              return {
                                ...o,
                                status: false,
                              };
                            });
                          },
                        });
                      }}
                      className={`bg-[#A23B3B]  text-[#FFFFFF] text-center justify-center flex flex-1 ml-3 hover:bg-[#973737] ${buttonClasses}`}
                    >
                      <i className="bi bi-x flex items-center mt-1"></i>
                      <div className="ml-1">Reject</div>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="h-full w-full flex items-center justify-center">
            <div>No orders found.</div>
          </div>
        )}
        {viewOrderStatus && billOrderDetails[0] && (
          <SwipeableEdgeDrawer
            status={viewOrderStatus}
            setStatus={setViewOrderStatus}
            data={
              <div className="bg-[#e0e3d5] h-full rounded-sm overflow-hidden">
                <div className="bg-[#daddcf] p-2 flex min-w-[300px] items-center justify-between border-b border-[#c9ccbc]">
                  <div>Order Details</div>
                  <div
                    onClick={() => setViewOrderStatus(false)}
                    className="cursor-pointer"
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                </div>
                <div className="flex flex-col justify-between">
                  <div>
                    <div className="flex p-4 bg-[#daddcf] flex-1">
                      <div className="h-[50px] flex items-center justify-center w-[50px] rounded-[50px] overflow-hidden bg-gray-100">
                        <Lottie
                          animationData={
                            billOrderDetails[0].ORDER_TYPE.toLowerCase() ===
                            "pickup"
                              ? pickup
                              : delivery
                          }
                          loop={true}
                          style={{ fontSize: 15, height: 30 }}
                          color="white"
                        />
                      </div>
                      <div className="ml-4 flex justify-between flex-1">
                        <div>
                          <div className="font-bold text-gray-800">
                            {toProperCase(customerDetails.CUSTOMER_NAME)}
                          </div>
                          <div className="text-[11px] text-gray-700">
                            {formatMobileNumber(
                              customerDetails.CUSTOMER_MOBILE
                            )}
                          </div>
                        </div>
                        <div className="text-right">
                          <div className="font-bold text-green-600">
                            ₹
                            {FormatNumber2(
                              billOrderDetails[0]?.GTOTAL
                                ? billOrderDetails[0]?.GTOTAL
                                : 0
                            )}
                          </div>
                          <div className="text-gray-500 text-[11px]">Cash</div>
                        </div>
                      </div>
                    </div>
                    <div className={"  mx-2  mt-4 "}>
                      {billOrderDetails.map((o, key) => {
                        return <CartItem key={key} item={o} />;
                      })}
                    </div>
                  </div>
                  <div className="p-2 w-full absolute bottom-0  ">
                    <button
                      onClick={() => {
                        navigate(`/billing`, {
                          state: {
                            INVNO: billOrderDetails[0].INVNO,
                          },
                        });
                      }}
                      className={`bg-[#4A5530] text-[ #FFFFFF] w-full flex items-center justify-center text-primary-foreground hover:bg-[#404929] ${buttonClasses}`}
                    >
                      <i className="bi bi-check2 flex items-center mt-1"></i>
                      <div className="ml-1">Accept</div>
                    </button>
                    <button
                      onClick={() => {
                        setAlertTwoButtonModal({
                          title: "Reject",
                          msg: "Are You sure you want to reject this order?",
                          status: true,
                          button1: "Yes",
                          button2: "Cancel",
                          button1Click: () => {
                            rejectOrder(billOrderDetails[0].INVNO);
                            setAlertTwoButtonModal((o) => {
                              return {
                                ...o,
                                status: false,
                              };
                            });
                          },

                          button2Click: () => {
                            setAlertTwoButtonModal((o) => {
                              return {
                                ...o,
                                status: false,
                              };
                            });
                          },
                        });
                      }}
                      className={`bg-[#A23B3B]  text-[#FFFFFF] text-center justify-center flex w-full mt-2 hover:bg-[#973737] ${buttonClasses}`}
                    >
                      <i className="bi bi-x flex items-center mt-1"></i>
                      <div className="ml-1">Reject</div>
                    </button>
                  </div>
                </div>
              </div>
            }
          />
        )}
        <AlertModalTwoButton
          details={alertTwoButtonModal}
          setAlertTwoButtonModal={setAlertTwoButtonModal}
        />
      </div>
    </LoadScript>
  );
};

export default RecivedOrderDashboard;
