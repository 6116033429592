import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  selectPrefix,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setreportsFromDate,
  setreportsTodate,
} from "../../utility/Redux/profile";
import { selectToken } from "../../utility/Redux/security";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { GetItemWiseSaleReport } from "./func";
import {
  ItemWiseSaleReportColumns,
  ItemWiseSaleReportPrintColumns,
} from "./columns";
import { SectionWiseSaleReportColumns } from "./columns";
import { GetSectionWiseSaleReport } from "./func";
import Footer from "./footer";
import { PrintButton } from "../../utility/component/buttons";
import GeniusTableForReports from "../../utility/component/printReport";
import GeniusTableReports from "../../utility/component/geniusComponents/geniusTableData";
import CustomerMasterHelp from "../../help/customermasterHelp";
import IsLocalData from "../../utility/component/isLocalData";
import FromToDateMobile from "../../utility/component/fromToDateMobile";
import { SectionWiseSale } from "../../billing/comp";
import { FromToDate } from "../../utility/component/fromToDate";

const IemWiseSaleReport = () => {
  const location = useLocation();
  const { FDATE, TDATE, SECTION_NAME } = location.state || {}; // Access state
  const prefix = useSelector(selectPrefix);
  const [purchaseList, setPurchaseList] = useState([]);
  const fromDate = useSelector(selectreportFromDate);
  const ToDate = useSelector(selectreportToDate);
  const width = useSelector(selectWidth);
  const [secName, setSecName] = useState("");
  const [cusMobile, setCusMobile] = useState("");
  const [fullItemWiseReport, setFullItemWiseReport] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [sectionWiseSaleStatus, setSectionWiseSaleStatus] = useState(false);
  const [getSectionData, setSectionData] = useState({
    table_prefix: prefix,
    FDATE: moment(fromDate).format("YYYY-MM-DD"),
    TDATE: moment(ToDate).format("YYYY-MM-DD"),
    CUSTOMER_MOBILE: cusMobile,
    SECTION_NAME: secName,
  });

  const columns = ItemWiseSaleReportColumns(width);
  const cols = SectionWiseSaleReportColumns(width);
  const [customerHelpStatus, setCustomerHelpStatus] = useState(false);
  const [summaryList, setSummaryList] = useState([]);

  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const okButton = () => {
    const additionalData = {
      table_prefix: prefix,
      FDATE: moment(fromDate).format("YYYY-MM-DD"),
      TDATE: moment(ToDate).format("YYYY-MM-DD"),
      SECTION_NAME: secName,
      CUSTOMER_MOBILE: cusMobile,
    };

    GetSectionWiseSaleReport(getSectionData, token, dispatch, setSummaryList);
    GetItemWiseSaleReport(
      additionalData,
      token,
      dispatch,
      setPurchaseList,
      setFullItemWiseReport
    );
    setSummaryList([]);
    setPurchaseList([]);
  };

  useEffect(() => {
    if ((FDATE, TDATE, SECTION_NAME)) {
      const additionalData = {
        table_prefix: prefix,
        FDATE: moment(FDATE).format("YYYY-MM-DD"),
        TDATE: moment(TDATE).format("YYYY-MM-DD"),
        SECTION_NAME: SECTION_NAME,
        CUSTOMER_MOBILE: "",
      };

      GetSectionWiseSaleReport(
        {
          table_prefix: prefix,
          FDATE: moment(FDATE).format("YYYY-MM-DD"),
          TDATE: moment(TDATE).format("YYYY-MM-DD"),
          CUSTOMER_MOBILE: "",
          SECTION_NAME: SECTION_NAME,
        },
        token,
        dispatch,
        setSummaryList
      );
      GetItemWiseSaleReport(
        additionalData,
        token,
        dispatch,
        setPurchaseList,
        setFullItemWiseReport
      );
      setSummaryList([]);
      setPurchaseList([]);
    }
  }, [FDATE, TDATE, SECTION_NAME]);
  const printColumns = ItemWiseSaleReportPrintColumns(width);
  const sectionPrintRef = useRef(null);
  useEffect(() => {
    setSectionData((e) => {
      return {
        ...e,
        FDATE: moment(fromDate).format("YYYY-MM-DD"),
        TDATE: moment(ToDate).format("YYYY-MM-DD"),
        CUSTOMER_MOBILE: cusMobile,
        SECTION_NAME: secName,
      };
    });
  }, [fromDate, ToDate, secName, cusMobile]);

  const navigate = useNavigate();

  const printRef = useRef();
  const thermalPrintRef = useRef();
  const [selectedPrintOption, setSelectedPrintOption] = useState("print");

  const handlePrint = useReactToPrint({
    content: () =>
      selectedPrintOption === "print"
        ? printRef.current
        : thermalPrintRef.current,
    pageStyle: `
        @page {
          size: landscape;
        }
      `,
    onAfterPrint: () => {
      navigate("/item_wise_sale_report");
      setSelectedPrintOption("print");
    },
  });

  const handlePrintSection = useReactToPrint({
    content: () => sectionPrintRef.current,

    pageStyle: `
        @page {
          size: landscape;
        }
      `,
    onAfterPrint: () => {
      navigate("/item_wise_sale_report");
    },
  });

  useEffect(() => {
    const callback = (details, data) => {
      setSectionData(details[0]);
      setSummaryList(data);
      dispatch(setreportsFromDate(details[0].FDATE));
      dispatch(setreportsTodate(details[0].TDATE));
    };
    const elseFunc = () => {
      console.log("nothing");
    };
    IsLocalData("sectionWiseSale", callback, elseFunc);
  }, []);

  useEffect(() => {
    const callback = (details, data) => {
      setFullItemWiseReport(data);
      setPurchaseList(data);
      dispatch(setreportsFromDate(details[0].FDATE));
      dispatch(setreportsTodate(details[0].TDATE));
    };
    const elseFunc = () => {
      console.log("nothing");
    };
    IsLocalData("itemWiseSale", callback, elseFunc);
  }, []);

  return (
    <div className=" bg-white flex flex-col h-full  overflow-y-scroll  ">
      <div className="flex w-full h-full">
        {width > 1000 ? (
          <div
            className="flex flex-col  pl-3 py-3 pr-0"
            style={{
              width: cols.reduce((acc, row) => acc + Number(row.width), 0),
              height: "100%",
            }}
          >
            <GeniusTableReports
              onRowClick={(e) => {
                const data = {
                  table_prefix: prefix,
                  FDATE: moment(fromDate).format("YYYY-MM-DD"),
                  TDATE: moment(ToDate).format("YYYY-MM-DD"),
                  CUSTOMER_MOBILE: cusMobile,
                  SECTION_NAME: e.values.SECTION_NAME,
                };
                setSecName(e.values.SECTION_NAME);

                GetItemWiseSaleReport(
                  data,
                  token,
                  dispatch,
                  setPurchaseList,
                  setFullItemWiseReport
                );
              }}
              columns={cols}
              data={summaryList}
              footer={() => console.log("hi")}
            />
          </div>
        ) : null}
        <div
          style={{
            width: `calc(100% -  ${
              width > 1000
                ? cols.reduce((acc, row) => acc + Number(row.width), 0)
                : 0
            }px)`,
          }}
          className="flex "
        >
          <div className=" flex flex-col w-full   p-3 ">
            <div className="w-full">
              {width > 1281 ? (
                <FromToDate
                  onPrintClick={handlePrint}
                  onCustomerSearchClick={() => {
                    setCustomerHelpStatus(true);
                  }}
                  customerClear={() => {
                    setCustomerName("");
                    setCusMobile("");
                  }}
                  hideReset={true}
                  customerData={{
                    CUSTOMER_MOBILE: cusMobile,
                    CUSTOMER_NAME: customerName,
                  }}
                  submit={okButton}
                  reset={() => {
                    setSecName("");
                    setCusMobile("");
                    setPurchaseList([]);
                    setSummaryList([]);
                    setCustomerName("");
                  }}
                />
              ) : (
                <FromToDateMobile
                  customerClear={() => {
                    setCustomerName("");
                    setCusMobile("");
                  }}
                  onPrintClick={handlePrint}
                  sectionHelp={
                    <SectionWiseSale
                      normaltext={true}
                      section={secName}
                      setIsSectionDraweropen={setSectionWiseSaleStatus}
                      isSectionDrawerOpen={sectionWiseSaleStatus}
                      data={
                        <GeniusTableReports
                          onRowClick={(e) => {
                            setSectionWiseSaleStatus(false);

                            const data = {
                              table_prefix: prefix,
                              FDATE: moment(fromDate).format("YYYY-MM-DD"),
                              TDATE: moment(ToDate).format("YYYY-MM-DD"),
                              CUSTOMER_MOBILE: getSectionData.CUSTOMER_MOBILE,
                              SECTION_NAME: e.values.SECTION_NAME,
                            };
                            setSecName(e.values.SECTION_NAME);

                            GetItemWiseSaleReport(
                              data,
                              token,
                              dispatch,
                              setPurchaseList,
                              setFullItemWiseReport
                            );
                          }}
                          columns={cols}
                          data={summaryList}
                          footer={() => console.log("hi")}
                        />
                      }
                    />
                  }
                  onCustomerSearchClick={() => {
                    setCustomerHelpStatus(true);
                  }}
                  customerData={{
                    CUSTOMER_MOBILE: cusMobile,
                    CUSTOMER_NAME: customerName,
                  }}
                  submit={okButton}
                />
              )}
            </div>
            {width > 1281 ? (
              <input
                className="flex  h-[30px]   text-sm"
                value={secName}
                readOnly={true}
              />
            ) : (
              <div className="h-[25px]" />
            )}

            <div
              style={{
                height: `calc(100% - ${
                  width < 1000 ? 320 : width < 1300 ? 170 : 90
                }px)`,
                width: "100%",
              }}
              className="  "
            >
              {purchaseList[0] ? (
                <GeniusTableReports
                  columns={columns}
                  data={purchaseList}
                  footer={<Footer columns={columns} rows={purchaseList} />}
                />
              ) : (
                <div className="border rounded-sm h-full flex text-sm text-gray-500 items-center justify-center">
                  No Records Found
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "none",
        }}
      >
        <div ref={printRef}>
          <GeniusTableForReports
            customeHeader={
              <div className="items-center  ">
                <div className="flex items-center">
                  <div className="mr-1 text-sm font-semibold flex justify-center ">
                    Item Wise Sale Report
                  </div>
                  <div className="text-sm font-semibold">From :</div>
                  <div className="ml-1 text-sm">
                    {moment(fromDate).format("DD-MM-yyyy")}{" "}
                  </div>
                  <div className="text-sm font-semibold ml-2">To :</div>
                  <div className="ml-1 text-sm">
                    {moment(ToDate).format("DD-MM-yyyy")}{" "}
                  </div>
                </div>
              </div>
            }
            headerWidth={true}
            data={purchaseList[0] ? purchaseList : fullItemWiseReport}
            columns={printColumns}
            filteredData={summaryList}
            marginTop={"-87px"}
          />
          <CustomerMasterHelp
            status={customerHelpStatus}
            onRowClick={(p) => {
              setCusMobile(p.row.CUSTOMER_MOBILE);
              setCustomerHelpStatus(false);
              setCustomerName(p.row.CUSTOMER_NAME);
            }}
            onClose={() => {
              setCustomerHelpStatus(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default IemWiseSaleReport;
