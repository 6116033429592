import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import spinner from "../../src/utility/json/Spinner.json";
import done from "../../src/utility/json/success2.json";
import wrong from "../../src/utility/json/rejected2.json";
import { useSearchParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import CallAPI from "../utility/functions/getData";
import { useDispatch } from "react-redux";

const CustomerEmailVerified = () => {
  const [searchparams] = useSearchParams();
  const customerCode = searchparams.get("customer");
  const securit = searchparams.get("security");
  const prefix = searchparams.get("p");
  const emailR = searchparams.get("to");
  const expredOn = searchparams.get("ex");
  const [status, setStatus] = useState("loading");
  const security = securit ? decodeURIComponent(securit) : "";
  const email = emailR ? decodeURIComponent(emailR) : "";
  const expireHMC = expredOn ? decodeURIComponent(expredOn) : "";
  const expiryTime = expireHMC ? expireHMC / 745 : 0;
  const dispatch = useDispatch();
  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

  const secretKey = process.env.REACT_APP_AUTH_SECRET_KEY;
  console.log(currentTime);
  console.log(expiryTime);
  useEffect(() => {
    if (security && customerCode) {
      const hmac = CryptoJS.HmacSHA256(customerCode, secretKey);
      const expectedSecurity = hmac.toString(CryptoJS.enc.Base64);
      if (currentTime < expiryTime) {
        if (expectedSecurity === security) {
          CallAPI(
            "/api/verifyCustomerEmail/updateVerifyCustomerEmail",
            {
              CUSTOMER_CODE: customerCode,
              EMAIL_VERIFIED: "Yes",
              table_prefix: prefix,
              CUSTOMER_EMAIL: email,
            },
            security,
            (e) => {
              if (e.status === 200) {
                setStatus("done");
              }
            },
            dispatch
          );
        }
      } else {
        setStatus("expired");
      }
    } else {
      setStatus("wrong");
    }
  }, [(security, customerCode)]);
  return (
    <div className="flex justify-center items-center min-h-screen w-screen bg-gray-100">
      <div className="w-full max-w-md bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="bg-gradient-to-r from-[#fdab11] to-[#f5874f] h-20 rounded-t-lg"></div>
        <div className="flex justify-center ">
          <div className="w-[60px] h-[60px] bg-white -mt-8 rounded-[60px] p-2">
            {}
            <Lottie
              animationData={
                status === "loading"
                  ? spinner
                  : status === "done"
                  ? done
                  : wrong
              }
              loop={true}
              style={{ fontSize: 15 }}
              color="white"
            />
          </div>
        </div>

        <div className="p-6 text-center">
          <h2 className="text-2xl font-semibold text-gray-800 mt-4">
            {status === "loading"
              ? "Processing"
              : status === "done"
              ? "Email is Verified!"
              : "Rejected "}
          </h2>
          <div className="text-gray-600 mt-4">
            {status === "done" ? (
              <p>
                {" "}
                Your email has been successfully verified. You can send email
                now.
              </p>
            ) : status === "loading" ? (
              "Wait while we verify your email."
            ) : status === "expired" ? (
              "Link expired"
            ) : (
              "Check your email and verify again."
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerEmailVerified;
