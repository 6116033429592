export function toProperCase(str) {
  return str
    .toLowerCase()
    .replace(/(^|\s)\S/g, (letter) => letter.toUpperCase());
}
export const formatMobileNumber = (number) => {
  const cleaned = ("" + number).replace(/\D/g, ""); // Remove non-digit characters

  if (cleaned.length === 10) {
    // Format for Indian 10-digit number
    const match = cleaned.match(/^(\d{5})(\d{5})$/);
    if (match) {
      return match[1] + " " + match[2];
    }
  } else {
    return number;
  }

  // Return the original number if it doesn't match any format
};
