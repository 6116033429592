import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserType,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setreportsFromDate,
  setreportsTodate,
} from "../Redux/profile";
import { parseISO } from "date-fns";

import DatePicker from "react-datepicker";

import { setItemMasterHelpStatus } from "../Redux/modal";
import { FontSizeWidth } from "../constant";

const width = window.innerWidth / 100;

export const FromToDate = ({
  onPartyMasterSearchClick,
  clearSection,
  EmployeeData,
  onEmployeeSearchClick,
  partyClear,
  customerClear,
  hideReset,
  onPrintClick,
  inputText,
  hideDate,
  onSectionClick,
  sectionData,
  partyData,
  onCustomerSearchClick,
  itemData,
  customerData,
  date,
  onItemClick,
  submit,
  filter,
  reset,
  partyReset,
  otherButton,
}) => {
  const firstDate = useSelector(selectreportFromDate);
  const SecondDate = useSelector(selectreportToDate);
  const dispatch = useDispatch();
  const [secondDateFocus, SetSecondDateFocus] = useState(false);
  const [firstdateFocus, setfirstDateFocus] = useState("");
  const userType = useSelector(selectUserType);

  const windowWidth = useSelector(selectWidth);
  return (
    <div className="w-full">
      <div
        style={{ borderRadius: "7px", overflow: "hidden" }}
        className=" flex  w-full"
      >
        <div className="flex border  border-[#b5b3b3] rounded-l-sm flex-1  overflow-hidden rounded-r-sm">
          {date?.status === false || hideDate ? null : (
            <div className=" border-r flex items-center max-w-[550px]   p-1  px-4 ">
              <div
                style={{ fontSize: 13 }}
                className=" whitespace-nowrap text-[#4B4545] "
              >
                From
              </div>
              <div
                style={{ borderRadius: "7px", border: "1px solid #b5b3b3" }}
                className="flex ml-2 text-[#4B4545] flex-1 overflow-hidden  "
              >
                <div
                  style={{
                    padding: "2px",
                    borderRight: "1px solid #a6a2a2 ",
                  }}
                  className="bg-[#f1efeb] overflow-hidden "
                >
                  <DatePicker
                    onFocus={() => setfirstDateFocus(true)}
                    onClickOutside={() => setfirstDateFocus(false)}
                    onSelect={() => setfirstDateFocus(false)}
                    open={firstdateFocus}
                    readOnly={true}
                    className=" bg-transparent text-sm w-36 outline-0  "
                    dateFormat=" d MMMM , yyyy "
                    selected={parseISO(firstDate)}
                    onChange={(date) =>
                      dispatch(
                        setreportsFromDate(moment(date).format("YYYY-MM-DD"))
                      )
                    }
                  />
                </div>

                <div
                  style={{
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)",
                  }}
                  className=" items-center flex justify-start px-2  bg-[#f7dda4] rounded-r-sm"
                >
                  <i
                    onClick={() => {
                      setfirstDateFocus(true);
                    }}
                    className="bi bi-calendar2-minus"
                  ></i>
                </div>
              </div>

              <div style={{ fontSize: 13 }} className="  text-[#4B4545] px-3 ">
                To
              </div>
              <div
                style={{ borderRadius: "7px", border: "1px solid #b5b3b3" }}
                className="flex  overflow-hidden  flex-1"
              >
                <div
                  style={{
                    padding: "2px",
                    borderRight: "1px solid #a6a2a2",
                  }}
                  className=" text-[#4B4545] bg-[#f1efeb]   "
                >
                  <DatePicker
                    onFocus={() => SetSecondDateFocus(true)}
                    onClickOutside={() => SetSecondDateFocus(false)}
                    onSelect={() => SetSecondDateFocus(false)}
                    open={secondDateFocus}
                    readOnly={true}
                    style={{ fontSize: 13 }}
                    className=" bg-transparent w-36 text-sm outline-0   "
                    dateFormat=" d MMMM , yyyy "
                    selected={parseISO(SecondDate)}
                    onChange={(date) =>
                      dispatch(
                        setreportsTodate(moment(date).format("YYYY-MM-DD"))
                      )
                    }
                  />
                </div>

                <div
                  style={{
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)",
                  }}
                  className=" items-center text-[#4B4545]  flex justify-start px-2 bg-[#f7dda4] rounded-r-sm"
                >
                  <i
                    onClick={() => {
                      SetSecondDateFocus(true);
                    }}
                    className="bi bi-calendar2-minus"
                  ></i>
                </div>
              </div>
            </div>
          )}

          {/* item code */}
          {(onItemClick ||
            onCustomerSearchClick ||
            onPartyMasterSearchClick ||
            onSectionClick ||
            onEmployeeSearchClick ||
            inputText) && (
            <div
              style={{}}
              className={`${hideDate ? "flex flex-1 max-w-[650px] " : "flex "}`}
            >
              {onItemClick ? (
                <div
                  // onClick={() => dispatch(setcollVoucherAccModStatus(true))}
                  style={{ borderRight: "1px dotted #a6a2a2 " }}
                  className="px-4 flex flex-1   justify-start items-center "
                >
                  <div className="flex items-center" onClick={onItemClick}>
                    <i
                      className="bi bi-search"
                      style={{ color: "#f44336", fontSize: 15 }}
                    ></i>
                    <span className="font-semibold text-[#4B4545] whitespace-nowrap  mr-1 pl-2 flex">
                      {" "}
                      Item :
                    </span>
                  </div>

                  <div
                    style={{
                      borderRadius: "3px",
                    }}
                    className="flex text-[#a6a2a2]  px-2  "
                  >
                    <input
                      size={"small"}
                      placeholder="All"
                      value={
                        itemData?.ICODE
                          ? itemData.ITEMNAME +
                            " " +
                            "[" +
                            " " +
                            itemData?.ICODE +
                            " " +
                            "] "
                          : "All"
                      }
                      style={{
                        fontSize: 12,
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        backgroundColor: "transparent",
                      }}
                      readOnly
                      className=" outline-0 w-[190px] "
                    />
                  </div>
                </div>
              ) : null}

              {/* station id */}
              {onCustomerSearchClick && (
                <div className="flex px-2  flex-1 max-w-[500px] text-[#4B4545] p-2  ">
                  <div
                    style={{ fontSize: FontSizeWidth(windowWidth) }}
                    className=" flex whitespace-normal flex-1   justify-center items-center hover:cursor-pointer "
                  >
                    <div className="flex items-center ">
                      <button onClick={onCustomerSearchClick}>
                        <i
                          className="bi bi-search"
                          style={{ color: "#f44336", fontSize: 15 }}
                        ></i>
                      </button>
                    </div>

                    <div
                      style={{
                        borderRadius: "3px",
                      }}
                      className="flex text-[#a6a2a2] flex-1  pl-2 ml-2  "
                    >
                      <input
                        size={"small"}
                        placeholder="Customer Name"
                        value={
                          customerData.CUSTOMER_NAME
                            ? customerData.CUSTOMER_NAME +
                              " " +
                              " [ " +
                              customerData.CUSTOMER_MOBILE +
                              " ] "
                            : ""
                        }
                        style={{
                          fontSize: FontSizeWidth(windowWidth),
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          backgroundColor: "transparent",
                        }}
                        readOnly
                        className=" outline-0 flex flex-1 text-black "
                      />
                    </div>
                  </div>
                  {customerClear && (
                    <button onClick={customerClear} className="pl-2">
                      <i className="bi bi-x-square-fill text-red-500 text-[18px]"></i>
                    </button>
                  )}
                </div>
              )}
              {onEmployeeSearchClick && (
                <div className="flex px-2  flex-1 max-w-[500px] text-[#4B4545] p-2  ">
                  <div
                    style={{ fontSize: FontSizeWidth(windowWidth) }}
                    className=" flex whitespace-normal flex-1   justify-center items-center hover:cursor-pointer "
                  >
                    <div className="flex items-center ">
                      <button onClick={onEmployeeSearchClick}>
                        <i
                          className="bi bi-search"
                          style={{ color: "#f44336", fontSize: 15 }}
                        ></i>
                      </button>
                    </div>

                    <div
                      style={{
                        borderRadius: "3px",
                      }}
                      className="flex text-[#a6a2a2] flex-1  pl-2 ml-2  "
                    >
                      <input
                        size={"small"}
                        placeholder="Employee Name"
                        value={
                          EmployeeData.first_name ? EmployeeData.first_name : ""
                        }
                        style={{
                          fontSize: FontSizeWidth(windowWidth),
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          backgroundColor: "transparent",
                        }}
                        readOnly
                        className=" outline-0 flex flex-1 text-black "
                      />
                    </div>
                  </div>
                </div>
              )}
              {onPartyMasterSearchClick && (
                <div
                  style={
                    userType === "MANAGER"
                      ? {
                          fontSize: FontSizeWidth(windowWidth),

                          color: "#a6a2a2",
                          // borderRight: '0.5px solid #4B4545',
                        }
                      : {
                          fontSize: FontSizeWidth(windowWidth),
                          color: "#a6a2a2",
                        }
                  }
                  className=" flex whitespace-normal  flex-1 pr-2 max-w-[600px]  justify-start items-center  "
                >
                  {/* party name 1st part */}
                  <div className="flex  h-full items-center flex-1  border-l pl-2">
                    <div
                      className="flex hover:cursor-pointer h-full items-center"
                      onClick={onPartyMasterSearchClick}
                    >
                      <div>
                        <i
                          className="bi bi-search"
                          style={{ color: "#f44336", fontSize: 15 }}
                        ></i>
                      </div>
                    </div>

                    <div
                      style={{
                        borderRadius: "3px",
                      }}
                      className="flex  flex-1   pl-2 ml-2 "
                    >
                      <input
                        size={"small"}
                        placeholder="All"
                        value={partyData.NAME ? partyData.NAME : "Party Name"}
                        style={{
                          fontSize: FontSizeWidth(windowWidth),
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          backgroundColor: "transparent",
                        }}
                        readOnly
                        className=" outline-0 w-full text-black "
                      />
                    </div>
                  </div>
                  {partyClear && (
                    <button onClick={partyClear} className="pl-2">
                      <i className="bi bi-x-square-fill text-red-500 text-[18px]"></i>
                    </button>
                  )}
                </div>
              )}

              {onSectionClick && (
                <div className="flex px-2   text-[#4B4545] p-3   ">
                  <div
                    style={{ fontSize: 13 }}
                    className=" flex whitespace-normal  justify-center items-center hover:cursor-pointer flex-1  "
                  >
                    <div className="flex items-center ">
                      <button onClick={onSectionClick}>
                        <i
                          className="bi bi-search"
                          style={{ color: "#f44336", fontSize: 15 }}
                        ></i>
                      </button>
                    </div>

                    <div className="flex text-[#a6a2a2]  pl-2 ml-2  flex-1 ">
                      <input
                        size={"small"}
                        placeholder="Section"
                        value={
                          sectionData?.SECTION_NAME
                            ? sectionData?.SECTION_NAME
                            : ""
                        }
                        style={{
                          fontSize: 12,
                          paddingTop: "2px",
                          paddingBottom: "2px",
                          backgroundColor: "transparent",
                        }}
                        readOnly
                        className=" outline-0 min-w-[180px]  "
                      />
                    </div>
                    {clearSection && (
                      <button onClick={clearSection} className="pl-2">
                        <i className="bi bi-x-square-fill text-red-500 text-[18px]"></i>
                      </button>
                    )}
                  </div>
                </div>
              )}
              {inputText && inputText}
            </div>
          )}

          {submit && (
            <div className="flex items-center py-1 border-l  overflow-hidden ">
              <button
                onClick={submit}
                className="flex items-center shadow-sm rounded-sm mx-2 "
                style={{
                  backgroundColor: "#c1c1c1",
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 4,
                  paddingBottom: 4,
                  color: "black",
                  height: "32px",
                }}
              >
                <div className="flex items-center text-sm">Ok</div>
              </button>

              {onPrintClick && (
                <button
                  onClick={onPrintClick}
                  className="flex items-center rounded-sm shadow-sm  mr-2 "
                  style={{
                    backgroundColor: "#93c5fd",
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingTop: 4,
                    paddingBottom: 4,
                    color: "black",
                    height: "32px",
                  }}
                >
                  <i className="bi bi-printer pr-2"></i>
                  <div className="flex items-center text-sm">Print</div>
                </button>
              )}
            </div>
          )}

          {otherButton && otherButton}
        </div>

        {!hideReset && (
          <div
            style={{
              borderTop: "1px dotted #a6a2a2 ",
              borderRight: "1px dotted #a6a2a2 ",
              borderBottom: "1px dotted #a6a2a2 ",
            }}
            className="flex grow rounded-r-sm overflow-hidden "
          >
            {/* Party details 2nd part*/}
            {filter ? (
              <filter />
            ) : (
              <div
                style={{
                  borderRight: "1px dotted #a6a2a2",
                }}
                className="flex w-full h-full "
              >
                <div
                  style={{
                    display: windowWidth > 1280 ? "flex" : "none",
                  }}
                  className="flex grow h-full "
                >
                  <div className="w-full h-full">
                    <div
                      className=" h-1/2 print:h-full flex items-center"
                      style={{
                        fontSize: 12,
                        color: "red",
                      }}
                    ></div>
                    <div
                      style={{
                        borderTop: "1px dotted #a6a2a2",
                        color: "#f2666f",
                        fontSize: 12,
                      }}
                      className="h-1/2 flex items-center print:hidden"
                    ></div>
                  </div>
                </div>
              </div>
            )}

            {/* Party details 3rd part*/}
            <div className="px-2 flex justify-center items-center print:hidden overflow-hidden">
              <i
                className="bi bi-backspace-fill"
                style={{ color: "#f2666f", fontSize: 18 }}
                onClick={reset}
              ></i>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
