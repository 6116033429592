import React from 'react'
import { FormatNumber2, FormatNumber3 } from '../../utility/functions/formatNumber'
import { FontSizeWidth } from '../../utility/constant'
import { useSelector } from 'react-redux'
import { selectWidth } from '../../utility/Redux/profile'
const valueInput  = (v)=>{
  if(v > 0 ){
    return  FormatNumber2(v)
  }else{
    return ""
  }
}
const valueInput3  = (v)=>{
  if(v > 0 ){
    return  FormatNumber3(v)
  }else{
    return ""
  }
}
const Footer = ({columns,rows}) => {
  const width = useSelector(selectWidth)
  return (
    <div className='flex  w-full '>
        <div className='flex'>
        {
     columns.map((e,i) => {
        return (
            <div key={i} style={{
                width:e.width,
                borderLeft :i === 0 ? '1px solid black' : '1px solid black',
                borderRight :i === columns.length -1 ? '1px solid black' : '0px solid black',
                
                                borderBottom: '1px solid black',
                                borderTop: '1px solid black',
                                textAlign:columns[i]?.align?columns[i].align :'left',
                                fontSize:FontSizeWidth(width)
                            }} className='p-1  '>
    {e.field ==='ITEMNAME' &&  'Total'}
    {e.field ==='PUR_PRICE' &&   valueInput(rows.reduce((acc, row) => acc + Number(row.PUR_PRICE), 0)) }

    {e.field ==='OPBAL' &&  valueInput3 (rows.reduce((acc, row) => acc +  Number(row.OPBAL), 0)) }
    {e.field ==='PUR_QNTY' && valueInput3 (rows.reduce((acc, row) => acc +  Number(row.PUR_QNTY), 0))  }
    {e.field ==='SALE_QNTY' &&  valueInput3 (rows.reduce((acc, row) => acc +  Number(row.SALE_QNTY), 0))  }
    {e.field ==='BAL_STOCK' &&  valueInput3 (rows.reduce((acc, row) => acc +  Number(row.BAL_STOCK), 0))  }
    {e.field ==='STOCK_VALUE' &&  valueInput(rows.reduce((acc, row) => acc + Number(row.STOCK_VALUE), 0))  }
   


      
                </div>
        )
     })   
        
        
    }  
        </div>
</div>
  )
}

export default Footer