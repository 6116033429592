import { FormatNumber2 } from "../../utility/functions/formatNumber";

export const SaleSummaryPrintTherminalColumns = (width) => {
    const columns = [
      {
        field: 'INVNO',
        accessor: 'INVNO',

        type: 'string',
        headerAlign: 'center',
        width: width < 768 ? 150 : width < 1400 ? 80 : 60,
        headerClassName: 'super-app-theme--header',
  
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Invoice'}{' '}
          </div>
        ),
      },
      {
        field: 'TOTAL',
        accessor: 'TOTAL',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 120,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
         Total
          </div>
        ),
      },
      {
        field: 'DISQ_AMT',
        accessor: 'DISQ_AMT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 120,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
      Discount
          </div>
        ),
      },
   
      {
        field: 'GTOTAL',
        accessor: 'GTOTAL',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 120,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
          Grand Total
          </div>
        ),
      },
    ];
    return columns;
  };