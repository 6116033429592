import { useDispatch, useSelector } from 'react-redux';
import {
  SelectItemMasterHelpStatus,
  setAlertWithTitle,
  setItemMasterHelpStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import { useState } from 'react';
import { selectToken } from '../utility/Redux/security';
import axios from 'axios';
import { useEffect } from 'react';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';
import MasterHelp from '../utility/component/modals/masterHelp';
import { FormatNumber2 } from '../utility/functions/formatNumber';
import moment from 'moment';

const PurchaseBillHelp = ({ onRowClick ,status,onClose }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const [rows, setRows] = useState([]);
  const token = useSelector(selectToken);

  const prefix = useSelector(selectPrefix);

  const windowWidth = useSelector(selectWidth);
  const columns = [
    {
      width:
      windowWidth <= 400
        ? 70
        : windowWidth <= 940
        ? 70
        : windowWidth <= 1280
        ? 40
        : 70,
      headerAlign: 'center',
      align: 'left',
      field: 'INVNO',
      headerName: 'Code',
      renderHeader: (params) => <div>Invno</div>,
    },
    {
      width:
      windowWidth <= 400
        ? 70
        : windowWidth <= 940
        ? 100
        : windowWidth <= 1280
        ? 80
        : 120,
      headerAlign: 'center',
      align: 'left',
      field: 'DATE',
      renderCell: (param) => {
        if (param.value === 0) {
          return <div> {''}</div>;
        } else {
          return  moment(param.value).format(
                                  "DD-MM-YYYY")
        }
      },
      renderHeader: (params) => <div>Date</div>,
    },
    {
      width:
      windowWidth <= 400
      ? 70
      : windowWidth <= 940
      ? 150
      : windowWidth <= 1280
      ? 120
      : 170,
      headerAlign: 'center',
      align: 'left',
      field: 'NAME',
      headerName: 'Item Name',
      renderHeader: (params) => <div>Party Name</div>,
    },
    {
      width:
        windowWidth <= 400
          ? 70
          : windowWidth <= 940
          ? 110
          : windowWidth <= 1280
          ? 100
          : 150,
      headerAlign: 'center',
      align: 'left',
      field: 'MOBILE',
      renderHeader: (params) => <div>Mobile</div>,
    },
    {
      field: 'PUR_BILL_NO',
      renderHeader: (params) => <div>Bill No.</div>,
      width:
        windowWidth <= 768 ? 100 : windowWidth <= 1024 ? 100 : windowWidth <= 1280 ? 70 : windowWidth <= 1440 ? 100 : 150,
      headerAlign: 'center',
      align: 'left',
    },
    {
      width:
        windowWidth <= 768 ? 60 : windowWidth <= 1024 ? 100 : windowWidth <= 1280 ? 120 : windowWidth <= 1440 ? 150 : 150,
      headerAlign: 'center',
      align: 'right',
      field: 'PUR_BILL_AMOUNT',
      headerName: 'Rate',
      renderCell: (param) => {
        if (param.value === 0) {
          return <div> {''}</div>;
        } else {
          return FormatNumber2(param.value);
        }
      },
      renderHeader: (params) => <div>Purchase Bill Amt.</div>,
    },
    
  ];

  const [searchQuery, setSearchQuery] = useState('');
  const [fullList, setFullList] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;
       const regexMobile = /^[0-9]/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.INVNO.toString().includes(searchQuery);
        }
        else if(searchQuery) {
          return item.PUR_BILL_NO.toLowerCase().includes(searchQuery);
        }
        // else if (regexMobile.test(searchQuery.substring(0, 1))) {
        //   return item.MOBILE.toString().includes(searchQuery);
        // }
         else {
          return item.NAME.toLowerCase().includes(
            searchQuery.toLowerCase()
          );
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);



  const dispatch = useDispatch();

  const getData = async () => {
    dispatch(setSpinnerLoading('Loading'));
    const data  = {
        table_prefix: prefix,
        "PUR_BILL_NO": "",
        "NAME": ""
    }
    await axios
      .post(
        '/api/purchase/listPurchaseBill',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        if(!p.data.error){
          let list = [];
          const data = p.data.response

          for (let i = 0; i < data.length; i++) {
            list.push({
              ...data[i],
              id:i+1
            });
          }
          setFullList(list);
          setRows(list);
      }
      
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status]);


  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            setSearchQuery('')
            onClose();
            setRows([]);
          }}
          title={'Purchase Bill(s)- Help'}
          columns={columns}
          list={rows}
         
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRowClick={(p) => {
            onRowClick(p);
            setRows([]);
          }}
        />
      </div>
    </>
  );
};

export default PurchaseBillHelp;
