import React from 'react';
import HandleIndexedDB from '../../Test/handleIndexDb';
const IsLocalData = (database, callback, func) => {
  let db;

  const request = indexedDB.open(database);
  request.onsuccess = function (event) {
    // Assign the opened database instance to the db variable
    db = event.target.result;
    if (db.objectStoreNames.length > 0) {
      const transaction = db.transaction(db.objectStoreNames, 'readonly');

      const objectStoreNames = transaction.objectStoreNames;
      db.close();
      const databaseName = database;
      const storeNamesArray = Array.from(objectStoreNames);
      const storeName = 'data';
      if (storeNamesArray[0]) {
        HandleIndexedDB({
          databaseName,
          storeName,
          callback: (data) => {
            const storeName = 'Details';
            HandleIndexedDB({
              databaseName,
              storeName,
              callback: (d) => {
                callback(d, data);
              },
            });
          },
        });
      } else {
        func();
      }
    } else {
      db.close();
      func();
      const deleteRequest = indexedDB.deleteDatabase(database);

      deleteRequest.onsuccess = function () {
        console.log('Database deleted successfully');
      };

      deleteRequest.onerror = function (event) {
        console.error('Error deleting database:', event.target.error);
      };
      // Delete the database if no object stores are available
    }
  };
};

export default IsLocalData;
