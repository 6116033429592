import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import { selectEmail, selectPrefix,  selectUserType,  setdefaultProfileSetting } from '../utility/Redux/profile';
import { setAlertWithTitle, setSpinnerLoading } from '../utility/Redux/modal';
import CallAPI from '../utility/functions/getData';
import GeniustextInput from '../utility/component/mastertext';
import { SaveButton, UndoButton } from '../utility/component/buttons';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import ModalHeaderStyle from '../utility/component/styles/modalHeader';
import { useNavigate } from 'react-router-dom';
import ModUserHelp from '../help/userhelp';
import CompanyHelp from '../help/companyHelp';




const CreateCompanyDatabse = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 const dispatch = useDispatch()
 const prefix = useSelector(selectPrefix)
 const token = useSelector(selectToken)
  const [companUEmail,setCompanyEmail] = useState('')
  const [companyHelpStatus,setCompanyHelpStatus] = useState(false)
  const [companyPrefix,setCompanyPrefix] = useState('')
  const [companyCode,setCompanyCode] = useState('')
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });


  const SaveData = () => {
    dispatch(setSpinnerLoading('Saving'));
    const data = {
      table_prefix: companyPrefix,
      company_code:companyCode
  };
    CallAPI(
      '/api/CompanyDb/createCompanyDb',
      data,
      token,
      (res) => {
        if (!res.data.error) {
          dispatch(setSpinnerLoading('Saved Successfully'));
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Database Created',
            })
          );
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Something went wrong',
            })
          );
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
        back();
      }
    );
  };

  const back = () => {
 
  };


const navigate  = useNavigate()



  return (
    <div   className="w-full  flex justify-center bg-[#f5f5f7] h-full py-3">

    <div className="text-sm min-w-[340px]  rounded-sm   flex flex-col   h-full px-3 ">
   
    <div  >
             <ModalHeaderStyle
               header={'Create Database'}
               onClose={() => {
                 navigate('/settings')
               }}
             />
     </div>

    <div className="grid grid-cols-1 t:grid-cols-1 gap-2 rounded-b-sm  bg-white border-brown px-4 max-sm:px-2">
        <div className='flex-1 mt-4'>

          <GeniustextInput
            title={'Company Email'}
            grid={true}
            width={windowWidth > 764 ? '250px' : '150px'}
            type={'text'}
            search={() => setCompanyHelpStatus(true)}
            value={companUEmail}
          />
        </div>
          
 

      <div className="flex w-full justify-end  py-3 border-t">
        <SaveButton
          onClick={() => {
            if (companUEmail) {
              setAlertTwoButtonModal({
                ...alertTwoButtonModal,
                status: true,
                title: 'Save',
                msg: 'Do you want to create company database ?',
                button1: 'Yes',
                button2: 'No',

                button1Click: () => {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    status: false,
                  });
                  SaveData();
                },
                button2Click: () => {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    status: false,
                  });
                },
              });
            }else{
                dispatch(setAlertWithTitle({
                    title:'Alert',
                    msg:'Please select company first'
                }))
            }
          }}
        />
        <UndoButton onClick={back} />
      </div>

    
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>

<CompanyHelp status={companyHelpStatus} onClose={()=>{setCompanyHelpStatus(false)}} onRowClick={(p)=>{
    console.log(p.row)
    setCompanyEmail(p.row.company_email)
    setCompanyHelpStatus(false)
    setCompanyPrefix(p.row.table_prefix)
    setCompanyCode(p.row.code)
}}  />
    </div>
  
    </div>
  );
};

export default CreateCompanyDatabse;
