import moment from "moment";
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import CallAPI from "../../utility/functions/getData";

export const saveOfferMaster = (data, token, dispatch, setData) => {
  dispatch(setSpinnerLoading("Saving"));
  const d = {
    ...data,
    ALIAS_CODE: data.ALIAS_CODE !== 0 ? data.ALIAS_CODE : data.ICODE,
  };
  CallAPI(
    "api/offerMaster/saveOffer",
    d,
    token,
    (res) => {
      if (res.data.result) {
        dispatch(
          setAlertWithTitle({
            title: "Success",
            msg: "Data saved successfully",
            lottie: "success",
          })
        );
        setData((e) => {
          return {
            ...e,
            SERIAL_NO: "",
            DATE: moment().format("YYYY-MM-DD"),
            ALIAS_CODE: "",
            ICODE: "",
            ITEMNAME: "",
            SECTION_NAME: "",
            PACKING: "",
            UNIT: "PACK",
            PUR_PRICE: "0",
            SALE_PRICE: "0",
            MRP_RATE: "0",
            DISQ_RATE: "0",
            DISQ_AMT: "0",
            OFFER_SALE_PRICE: "0",
            FDATE: "",
            TDATE: "",
            FTIME: "",
            TTIME: "",
          };
        });
      }
    },
    dispatch
  );
};
