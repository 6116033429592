import React from 'react'
import CallAPI from '../../utility/functions/getData';
import { setAlertWithTitle, setSpinnerLoading } from '../../utility/Redux/modal';
import deleteWholeDatabase from '../../utility/functions/deleteWholeDatabase';
import dataToLocal from '../../utility/component/dataToLocal';

export const GetItemWiseSaleReport = (data,token,dispatch,setSummaryList) => {
  dispatch(setSpinnerLoading('Loading...'));
    deleteWholeDatabase('stockReport')


    CallAPI(
        "/api/purchaseReports/stockReport",
        data,
        token,
        (e) => {
            if (!e.data.error) {
             if(e.data.response[0]){

                          dataToLocal('stockReport', e.data.response, [
                        {
                         ...data,
                         ICODE:1
                        },
                      ]);
                    setSummaryList(e.data.response)

                }else{
                    setSummaryList([])

                     dispatch(
                     setAlertWithTitle({
                     title: 'Alert',
                     msg: 'No Records Found',
                      }))
                }
            } else {
                dispatch(
                    setAlertWithTitle({
                        title: "Alert",
                        msg: "Something Went Wrong.",
                    })
                );
            }
        },
        dispatch,()=>{
            dispatch(setSpinnerLoading(false))
        }
        )
}
