import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../utility/Redux/modal";
import { selectCompanyAddress } from "../utility/Redux/profile";
import axios from "axios";
import CryptoJS from "crypto-js";
import { PiRadioButtonDuotone } from "react-icons/pi";
import ModalHeaderStyle from "../utility/component/styles/modalHeader";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { RestaurantOutlined } from "@mui/icons-material";
import uniqeId from "uniqid";
import moment from "moment";

const CrossCompanyList = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [orignalRows, setOrignalRows] = useState([]);
  const [fullList, setFullList] = useState([]);
  const statusList = [
    {
      status: "Active",
      icon: (
        <PiRadioButtonDuotone
          style={{
            fontSize: "20px",
            color: "green",
          }}
        />
      ),
    },
    {
      status: "InActive",
      icon: (
        <PiRadioButtonDuotone
          style={{
            fontSize: "20px",
            color: "gray",
          }}
        />
      ),
    },
  ];

  const statusOption = statusList.map((o) => ({
    value: o.status,
    label: o.status,
    icon: o.icon,
  }));
  const [filter, setFilter] = useState(statusOption[0]);
  const [searchQuery, setSearchQuery] = useState("");

  const formatOptionLabel = ({ icon, label }) => (
    <div className="flex items-center">
      <div className="mr-1">{icon}</div>
      <span className="">{label}</span>
    </div>
  );
  const colums = [
    {
      name: "Status",
      width: 140,
    },
    {
      name: "Company",
      width: 220,
    },
    // {
    //   name: "Price",
    //   width: 100,
    // },
    {
      name: "Shop Id",
      width: 140,
    },
  ];
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

  const companyDetail = useSelector(selectCompanyAddress);
  const hmac = CryptoJS.HmacSHA256(companyDetail.email, secretPass);

  const hmacString = hmac.toString(CryptoJS.enc.Base64);
  const getData = async () => {
    dispatch(setSpinnerLoading("Loading"));
    const data = {
      company_email: companyDetail.email,
      company_mobile: "",
    };
    await axios
      .post("/api/users/companyHelp", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hmacString}`,
        },
      })
      .then((p) => {
        if (!p.data.error) {
          let list = [];
          const data = p.data.response;

          for (let i = 0; i < data.length; i++) {
            list.push({
              ...data[i],
              id: i + 1,
            });
          }
          setRows(list);
          setFullList(list);
          setOrignalRows(list);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        } else {
          dispatch(
            setAlertWithTitle({
              title: "ERROR",
              msg: "Something went wrong.",
              lottie: "reject",
            })
          );
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.id.toString().includes(searchQuery);
        } else {
          return item.company_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  const UpdateCompanyDetailsAPI = (companyInfo) => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
    const email = companyInfo.company_email;
    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    const data = {
      company_email: companyInfo.company_email,
      company_mobile: companyInfo.company_mobile,
      company_website: companyInfo.company_website,
      address: companyInfo.address,
      city: companyInfo.city,
      state: companyInfo.state,
      country: companyInfo.country,
      pin_code: companyInfo.pin_code,
      shop_id: companyInfo.shop_id,

      currency_name: companyInfo.currency_name,
      currency_symbol: companyInfo.currency_symbol,
      regd_no1: companyInfo.regd_no1,
      regd_no2: companyInfo.regd_no2,
      regd_no3: companyInfo.regd_no3,
      disputes: companyInfo.disputes,
      company_logo: "",
      start_date: moment(companyInfo.start_date).format("YYYY-MM-DD"),
      end_date: moment(companyInfo.end_date).format("YYYY-MM-DD"),
      table_prefix: companyInfo.table_prefix,
      no_of_users: companyInfo.no_of_users,
    };

    axios
      .post(
        `/api/users/updateCompanyInfo`,

        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${hmacString}`,
          },
        }
      )
      .then(async function async(response) {
        if (response.status === 200) {
          dispatch(
            setAlertWithTitle({
              title: "Success",
              msg: response.data.message,
            })
          );

          getData();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const navigate = useNavigate();
  return (
    <div className="p-4 flex bg-[#f3f5f9] justify-center  h-full  text-sm items-center w-full">
      <div className="min-w-[80%] h-[95%]">
        <div className="bg-white   ">
          <ModalHeaderStyle
            header={"Cross Company List"}
            bg={"#f3f5f9"}
            border={"1px solid #babab8"}
            onClose={() => navigate("/settings")}
          />
        </div>
        <div
          style={{
            borderLeft: "1px solid #babab8",
            borderRight: "1px solid #babab8",
            borderBottom: "1px solid #babab8",
            height: `calc(100% - 80px)`,
          }}
          className="p-4 rounded-b-sm "
        >
          <div className="flex  ">
            {colums.map((o, key) => {
              return (
                <div
                  key={key}
                  style={{
                    width: o.width,
                  }}
                  className="px-4 mb-2 font-semibold text-[16px]"
                >
                  {o.name}
                </div>
              );
            })}
          </div>
          <div
            style={{
              height: `calc(100% - 100px)`,
            }}
            className="overflow-y-auto pr-2"
          >
            {rows.map((company, i) => {
              const orignal = orignalRows.find((c) => c.code === company.code);
              return (
                <div className="flex bg-white justify-between p-4 rounded-sm  mt-3 ">
                  <div className="flex">
                    {colums.map((column) => {
                      return (
                        <div
                          style={{
                            width: column.width,
                          }}
                        >
                          {column.name === "Company" && (
                            <div className="h-full items-center flex">
                              {company.company_name}
                            </div>
                          )}
                          {column.name === "Status" && (
                            <div className="h-full items-center flex">
                              <Select
                                options={statusOption}
                                isSearchable={false} // Disable searching within the dropdown
                                isClearable={false} // Disable the clear option (if using)
                                formatOptionLabel={formatOptionLabel}
                                onChange={(selectedOption) => {
                                  if (selectedOption.value === "Active") {
                                    const shop_id = uniqeId();
                                    setFullList((f) =>
                                      f.map((fl) => {
                                        if (fl.code === company.code) {
                                          return {
                                            ...company,
                                            shop_id: shop_id,
                                          };
                                        } else {
                                          return fl;
                                        }
                                      })
                                    );

                                    setRows((f) =>
                                      f.map((fl) => {
                                        if (fl.code === company.code) {
                                          return {
                                            ...company,
                                            shop_id: shop_id,
                                          };
                                        } else {
                                          return fl;
                                        }
                                      })
                                    );
                                  } else {
                                    setFullList((f) =>
                                      f.map((fl) => {
                                        if (fl.code === company.code) {
                                          return {
                                            ...company,
                                            shop_id: "",
                                          };
                                        } else {
                                          return fl;
                                        }
                                      })
                                    );

                                    setRows((f) =>
                                      f.map((fl) => {
                                        if (fl.code === company.code) {
                                          return {
                                            ...company,
                                            shop_id: "",
                                          };
                                        } else {
                                          return fl;
                                        }
                                      })
                                    );
                                  }
                                }}
                                value={
                                  company.shop_id
                                    ? statusOption[0]
                                    : statusOption[1]
                                }
                                className="basic-single w-[120px] outline-none"
                                classNamePrefix="select"
                                styles={{
                                  // Styles for the single selected value
                                  singleValue: (base) => ({
                                    ...base,
                                    color: "black", // Text color for the selected option
                                    padding: "2px", // Padding inside the single value
                                    borderRadius: "5px", // Rounded corners for the single value
                                    border: "none", // Remove border around the single value
                                  }),

                                  // Styles for the control (the input field)
                                  control: (base) => ({
                                    ...base,
                                    color: "black", // Text color for the control
                                    fontSize: "12px", // Font size for the control
                                    border: "none", // Remove border for a cleaner look
                                    outline: "none", // Remove outline on focus
                                    borderRadius: "7px", // Rounded corners for the control
                                    height: "30px", // Set height of the control
                                    minHeight: "30px", // Ensure a minimum height
                                    padding: "0px", // No padding inside the control
                                    boxShadow: "none", // Remove any box shadow,
                                  }),

                                  // Styles for the options in the dropdown
                                  option: (base, state) => ({
                                    ...base,
                                    backgroundColor: state.isSelected
                                      ? "#e7f0ff"
                                      : "white", // Background color for options
                                    color: "black", // Text color for options
                                    display: "flex", // Use flexbox for alignment
                                    alignItems: "center", // Center items vertically
                                    padding: "10px", // Padding for each option
                                    cursor: "pointer", // Pointer cursor on hover
                                    fontSize: "14px", // Size of
                                    "&:hover": {
                                      backgroundColor: "#f0f0f0", // Change background on hover
                                    },
                                  }),

                                  // Optional: Styles for the dropdown indicator (chevron)
                                  dropdownIndicator: (base) => ({
                                    ...base,
                                    color: "white", // Color for the dropdown indicator
                                    padding: "3px",
                                    fontWeight: "normal",
                                  }),

                                  // Optional: Styles for the clear indicator (if using)
                                  clearIndicator: (base) => ({
                                    ...base,
                                    color: "white", // Color for the clear indicator
                                  }),

                                  // Optional: Styles for the loading indicator (if using)
                                  loadingIndicator: (base) => ({
                                    ...base,
                                    color: "white", // Color for the loading indicator
                                  }),

                                  // Optional: Styles for the placeholder (if using)
                                  placeholder: (base) => ({
                                    ...base,
                                    color: "white", // Color for the placeholder text
                                    opacity: 0.8, // Slightly transparent for the placeholder
                                  }),
                                }}
                              />
                            </div>
                          )}
                          {column.name === "Price" && (
                            <div>
                              <div>5000 {company.currency_symbol}</div>
                              <div className="text-gray-500 text-[12px]">
                                Yearly
                              </div>
                            </div>
                          )}

                          {column.name === "Shop Id" && (
                            <div>
                              <div>
                                {" "}
                                {company.shop_id ? (
                                  company.shop_id
                                ) : (
                                  <button
                                    onClick={() => {
                                      const shop_id = uniqeId();
                                      setFullList((f) =>
                                        f.map((fl) => {
                                          if (fl.code === company.code) {
                                            return {
                                              ...company,
                                              shop_id: shop_id,
                                            };
                                          } else {
                                            return fl;
                                          }
                                        })
                                      );

                                      setRows((f) =>
                                        f.map((fl) => {
                                          if (fl.code === company.code) {
                                            return {
                                              ...company,
                                              shop_id: shop_id,
                                            };
                                          } else {
                                            return fl;
                                          }
                                        })
                                      );
                                    }}
                                    className="text-[#ff6a11]"
                                  >
                                    Generate Shop Id{" "}
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>

                  <button
                    disabled={orignal.shop_id === company.shop_id}
                    onClick={() => UpdateCompanyDetailsAPI(company)}
                    className="bg-[#ff6a11] text-white  disabled:bg-[#817067]   px-4 rounded-sm  flex items-center"
                  >
                    {" "}
                    Save
                  </button>
                </div>
              );
            })}
          </div>

          <div
            style={{
              border: "1px solid #babab8",
            }}
            className="rounded-sm w-full p-2 mt-3"
          >
            <input
              className="w-full bg-transparent outline-none"
              placeholder="Seacrh"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrossCompanyList;
