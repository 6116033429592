import { Button, Grow } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserType } from '../utility/Redux/profile';


const BillDashboard = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userType = useSelector(selectUserType)
  const list = [
    {
      name: 'Bill',
      path: '',
      userType: 'MANAGER ADMIN',
      onclick: () => {
     navigate('/bill')
      },
    },
    {
        name: 'Performa',
        path: '',
        userType: 'MANAGER',
        onclick: () => {
          navigate('/performa')

         },
      },
     

  ];
  const allowedList = list.filter(o => o.userType.toLowerCase().includes(userType.toLowerCase()))
  return (
    <div className="h-full p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 t:grid-cols-3 d:grid-cols-5 ll:grid-cols-6 gap-3  ">
        {allowedList[0] &&
          allowedList.map((e, i) => {
            return (
              <Grow
              in={true}
              key={i}
              style={{ transformOrigin: '0 0 0' }}
              {...(true ? { timeout: i === 0 ? 0 : i* 200 } : {})}
            >
              <Button
                key={i}
                onClick={() => {
                    e.onclick();
                }}
                variant={'contained'}
                style={{
                  textTransform: 'none',
                  backgroundColor: 'transparent',
                  padding: '0px',
                  borderRadius: '7px',
                  overflow: 'hidden',
                }}
              >
                <div className="bg-[#e9e9e5] py-4 w-full text-brown">
                  {e.name}
                </div>
              </Button>
              </Grow>
            );
          })}
      </div>
    </div>
  );
};

export default BillDashboard;
