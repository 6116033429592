import { Button } from "@mui/material";
import axios from "axios";
import { toJpeg, toPng } from "html-to-image";
import React, { useEffect } from "react";
import Lottie from "lottie-react";
import { useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { FaWhatsappSquare, FaSms, FaWhatsapp } from "react-icons/fa";
import { TiMessage } from "react-icons/ti";
import whitSpinner from "../../src/utility/json/spinerWhite.json";
import ReactDOMServer from "react-dom/server";
import jsPDF from "jspdf";
import spinner from "../../src/utility/json/spinerWhite.json";
import { BiCloudDownload } from "react-icons/bi";
import { TiArrowBackOutline } from "react-icons/ti";
import ViewBillImage from "./viewBillImage";
import { useRef } from "react";
import { QRCodeSVG } from "qrcode.react";

import ReactToPrint from "react-to-print";
import {
  selectCompanyAddress,
  selectDefultProfileSetting,
  selectPrefix,
  selectUserCode,
  selectUserType,
  selectWidth,
  setUserType,
} from "../utility/Redux/profile";
import { selectToken } from "../utility/Redux/security";
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from "../utility/Redux/modal";
import CallAPI from "../utility/functions/getData";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import { DownloadFile, UploadFile } from "../utility/functions/firebase";
import AlertModalTwoButton from "../utility/component/modals/alertWithTwoButton";
import ActionRemarks from "../utility/component/modals/RemarksModal";
import OrderSlip from "./orderSlip";
import { getMessageWallet } from "../utility/constant";
import ScanQrCodeModal from "../utility/component/scanQrCodeModal";
import Store from "../utility/Redux/store";

const IconButton = ({
  onClick,
  title,
  bg,
  color,
  icon,
  extraSymbol,
  loading,
  border,
}) => {
  return (
    <button
      id={"Button1"}
      size={"small"}
      className="flex  items-center px-1  py-[6px]  rounded-sm  focus:border focus:border-black"
      onClick={onClick}
      style={{
        backgroundColor: bg,
        color: color ? color : "white",
        textTransform: "none",
        border: border ? `1px solid ${border}` : "none",
        width: "100%",
        justifyContent: "start",
        paddingLeft: 20,
      }}
    >
      <div className="ml-4">{icon}</div>
      <div className="px-2 text-sm"> {title}</div> {extraSymbol}
      {loading}
    </button>
  );
};
const ViewBill = ({
  billNo,
  isOriginalBill,
  bill_performa,
  onBack,
  hideVoid,
  setSummaryList,
  hideOrderSlip,
}) => {
  const dispatch = useDispatch();
  const [remarks, setRemarks] = useState("");
  const [isWhatsappSignIn, setIsWhatsappSignin] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const userCode = useSelector(selectUserCode);
  const [remarksStatus, setRemarksStatus] = useState(false);
  const currentInvoiceNumber = billNo;
  const [waDisable, setWadisable] = useState(false);
  const [smsDisable, setsmsdisable] = useState(false);
  const [total, setTotal] = useState();
  const prefix = useSelector(selectPrefix);
  const [billLoading, setBillLoading] = useState(false);
  const componentRef = useRef();
  const [billStatus, setBillStatus] = useState(false);
  const token = useSelector(selectToken);
  const [CustomerMobile, setCustomerMobile] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerCode, setCustomerCode] = useState("");
  const [data, setData] = useState([]);
  const [qrCodeValue, setQrCodeValue] = useState("");
  const pageStyle = `
    @media print {
      @page {
        size: portrait; /* Ensures portrait orientation */
      }
      body {
        -webkit-print-color-adjust: exact; /* Ensure colors are printed */
      }
    }
  `;
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const companyDetails = useSelector(selectCompanyAddress);
  const defaultProfile = useSelector(selectDefultProfileSetting);

  const userType = useSelector(selectUserType);
  const width = useSelector(selectWidth);
  const getViewBillDetatils = () => {
    dispatch(setSpinnerLoading("Loading bill"));
    axios
      .post(
        "/api/billing/loadBill",

        {
          table_prefix: prefix,
          INVNO: currentInvoiceNumber,
          BILL_PERFORMA: "bills",
        },

        {
          headers: headers,
        }
      )

      .then((response) => {
        const dataList = response.data.response;
        setCustomerMobile(dataList[0]?.CUSTOMER_MOBILE);
        setCustomerEmail(dataList[0]?.CUSTOMER_EMAIL);
        setCustomerCode(dataList[0]?.CUSTOMER_CODE);
        setCustomerName(dataList[0]?.CUSTOMER_NAME);
        setData(dataList);
        setTotal(dataList[0]?.GTOTAL);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  function dataURLToBlob(dataUrl) {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }
  const makeBillImg = () => {
    return new Promise((resolve, reject) => {
      toPng(
        componentRef.current,
        window.innerWidth < 768 ? { quality: 0.25 } : { quality: 0.95 }
      )
        .then((dataUrl) => {
          const blob = dataURLToBlob(dataUrl); // Convert data URL to blob
          const path = "retailx/bill/";
          const name = `${prefix + currentInvoiceNumber}`;

          // Upload the file to Firebase
          return UploadFile(blob, path, name); // Ensure UploadFile is returning a promise
        })
        .then(() => {
          const filePath = `retailx/bill/${prefix + currentInvoiceNumber}`;

          // Retrieve the download URL from Firebase Storage
          return DownloadFile(filePath); // Ensure DownloadFile is returning a promise
        })
        .then((fileData) => {
          resolve(fileData); // Resolve with the file data (URL)
        })
        .catch((error) => {
          reject(error); // Handle any error in the promise chain
        });
    });
  };

  const handlePersonalWhatsApp = () => {
    const message = `Dear Customer your Bill No. : ${currentInvoiceNumber} has been generated of Amount : ${FormatNumber2(
      total
    )} ,Thanks for shopping with us.`;

    setWadisable(true);
    makeBillImg().then((url) => {
      axios
        .post(
          "/api/whatsApp/sendWhatsApp",
          {
            number: `91${CustomerMobile}`,
            message: message,
            imagePath: url,
            userId: userCode,
            tablePrefix: prefix,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((d) => {
          if (!d.data.error) {
            dispatch(
              setAlertWithTitle({
                title: "Whatsapp sent",
                msg: "Whatsapp Sent Successfully",
              })
            );
          } else {
            setQrCodeValue(d.data?.qr);
          }
        })
        .catch((e) => {
          console.log(e);
          // dispatch(
          //   setAlertWithTitle({
          //     title: "Alert",
          //     msg: ,
          //   })
          // );
        })
        .finally(() => {
          setWadisable(false);
        });
    });
  };
  const handleSendImage = (number, total) => {
    dispatch(setSpinnerLoading("Loading"));
    if (defaultProfile?.SEND_BILL_BY_WHATSAPP === "Yes") {
      getMessageWallet(companyDetails.email, token)
        .then((bal) => {
          if (bal > 0) {
            if (number?.length === 10) {
              setWadisable(true);
              const amount = FormatNumber2(total);
              toPng(
                componentRef.current,
                window.innerWidth < 768 ? { quality: 0.25 } : { quality: 0.95 }
              )
                .then((dataUrl) => {
                  const blob = dataURLToBlob(dataUrl);
                  const path = "retailx/bill/";
                  const name = `${prefix + currentInvoiceNumber}`;

                  UploadFile(blob, path, name)
                    .then(() => {
                      const b = `retailx/bill/${prefix + currentInvoiceNumber}`;

                      DownloadFile(b).then((c) => {
                        const s = c.split(
                          "https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/"
                        );
                        const data = {
                          dataUrl: s[1],
                          invno: currentInvoiceNumber.toString(),
                          mobile: number,
                          amount: amount,
                          company_email: companyDetails.email,
                        };
                        dispatch(setSpinnerLoading("Sending Image"));
                        axios
                          .post("/api/sendDigitalBill/saveBillImage", data, {
                            headers: headers,
                          })
                          .then((response) => {
                            setWadisable(true);
                            dispatch(
                              setAlertWithTitle({
                                title: "Success",
                                msg: "Whatsapp is sent succesfully",
                                lottie: "success",
                              })
                            );
                            // dispatch(setAlertMsg('Message sent successfully'));
                            // dispatch(setALertStatus(true));
                          })
                          .catch((err) => {
                            console.log(err);

                            if (
                              err?.response?.data?.name === "TokenExpiredError"
                            ) {
                              dispatch(setSessionExpired(true));
                              dispatch(
                                setAlertWithTitle({
                                  title: "Whatsapp Sent",
                                  msg: "Whastapp sent successfully",
                                })
                              );
                            } else {
                              dispatch(
                                setAlertWithTitle({
                                  title: "ERROR",
                                  msg: "Something went wrong.",
                                  lottie: "reject",
                                })
                              );
                            }
                          })
                          .finally(() => {
                            setWadisable(false);
                            dispatch(setSpinnerLoading(false));
                          });
                      });
                    })
                    .catch((e) => {
                      dispatch(setSpinnerLoading(false));
                    });
                })
                .catch((err) => {
                  dispatch(setSpinnerLoading(false));
                })
                .finally(() => {
                  setWadisable(false);
                });
            } else {
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: "Incorrect fields",
                })
              );
            }
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "Insufficient funds",
              })
            );
          }
        })
        .catch(() => {
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Insufficient funds",
            })
          );
        });
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: `You don't have permission to send whatsapp.`,
        })
      );
    }
  };

  const handleSendSms = (number, amount) => {
    dispatch(setSpinnerLoading("Loading"));

    if (defaultProfile?.SEND_BILL_BY_SMS === "Yes") {
      getMessageWallet(companyDetails.email, token, true).then((bal) => {
        if (bal > 0) {
          console.log(amount);

          if (number?.length === 10 && amount) {
            dispatch(setSpinnerLoading("Sending"));
            const data = {
              INVNO: currentInvoiceNumber,
              MOBILE: `+91${number}`,
              AMOUNT: amount,
              company_email: companyDetails.email,
              BILL_URL: "https://retailxapp.com",
            };
            axios
              .post(
                "/api/sendDigitalBill/sendSms",
                data,

                {
                  headers: headers,
                }
              )
              .then((response) => {
                dispatch(
                  setAlertWithTitle({
                    title: "Msg Sent",
                    msg: "Message sent successfully",
                  })
                );
              })
              .catch((err) => {
                console.log(err);
                dispatch(
                  setAlertWithTitle({
                    title: "Error",
                    msg: "Message Failed",
                  })
                );
              })
              .finally(() => {
                dispatch(setSpinnerLoading(false));
              });
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Incorrect fields",
              })
            );
          }
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Insufficient funds",
            })
          );
        }
      });
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: `You don't have permission to send sms.`,
        })
      );
    }
  };
  console.log(companyDetails);
  const handleSendEmail = () => {
    if (customerEmail && customerCode) {
      CallAPI(
        "/api/loadBillForEmail/loadBill",
        {
          companyName: companyDetails.companyName,
          address: companyDetails.address,
          state: companyDetails.state,
          city: companyDetails.city,
          email: companyDetails.email,
          mobile: companyDetails.mobile,
          companyWebsite: companyDetails.companyWebsite,
          sendTo: customerEmail,
          invno: currentInvoiceNumber,
          table_prefix: prefix,
        },
        token,
        (e) => {
          dispatch(
            setAlertWithTitle({
              title: "Sent",
              msg: "Email sent Successfully",
            })
          );
        }
      );
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: "Email not found",
        })
      );
    }
  };
  const downloadPdf = () => {
    toJpeg(
      componentRef.current,
      window.innerWidth < 768 ? { quality: 0.25 } : { quality: 0.95 }
    )
      .then((dataUrl) => {
        const pdf = new jsPDF();
        pdf.addImage(dataUrl, "PNG", 0, 0);
        pdf.save(`Bill_${currentInvoiceNumber}`);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getViewBillDetatils();
  }, []);

  const formRef = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (formRef.current) {
        const inputFields = formRef.current.querySelectorAll("button");

        inputFields.forEach((element) => {
          if (element.id === "PrintButton") {
            element.focus();
            // element.st
          }
        });

        const handleKeyDown = (event) => {
          if (event.key === "ArrowRight") {
            const nextButton = document.getElementById("Button2");
            if (nextButton) {
              nextButton.focus();
            }
          } else if (event.key === "Enter") {
            const focusedElement = document.activeElement;
            focusedElement.click();
          } else if (event.key === "ArrowDown") {
            const focusedElement = document.activeElement;
            const buttons = formRef.current.querySelectorAll("button");
            const focusedIndex = Array.from(buttons).indexOf(focusedElement);
            const nextButton = buttons[focusedIndex + 1];
            if (nextButton) {
              nextButton.focus();
            }
          } else if (event.key === "ArrowTop") {
            const nextButton = document.getElementById("Button1");
            if (nextButton) {
              nextButton.focus();
            }
          }
        };

        formRef.current.addEventListener("keydown", handleKeyDown);

        return () => {
          formRef.current.removeEventListener("keydown", handleKeyDown);
        };
      }
    }, 200);

    return () => clearTimeout(timeout);
  }, [billLoading, formRef]);

  const orderPrintRef = useRef(null);

  return (
    <div
      ref={formRef}
      className="w-full h-fit  flex flex-col md:flex md:flex-row "
    >
      <div
        style={window.innerWidth < 770 ? { width: "100%" } : { width: "auto" }}
        className="  flex-col flex grow justify-center items-center"
      >
        <div className=" w-full flex p-3 max-h-[600px] overflow-y-auto justify-center items-start ">
          {billLoading ? (
            <div>
              <div className="w-6 h-6">
                <Lottie
                  animationData={spinner}
                  loop={true}
                  style={{ fontSize: 15 }}
                  color="white"
                />
              </div>
            </div>
          ) : (
            <div className="h-fit flex">
              <div className="border rounded-sm overflow-hidden h-full ">
                <ViewBillImage
                  bill_performa={bill_performa}
                  isOriginalBill={isOriginalBill}
                  setBillStatus={setBillStatus}
                  setOrderList={setOrderList}
                  ref={componentRef}
                  billNo={billNo}
                  data={data}
                />
              </div>
              {orderList[0] && !hideOrderSlip && (
                <div className="border rounded-sm ml-2">
                  {" "}
                  <OrderSlip orderList={orderList} ref={orderPrintRef} />{" "}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          display: width > 768 ? "flex" : "none",
        }}
        className="p-4  flex-col   "
      >
        <div className="w-full">
          <div className="flex justify-between w-full  "></div>
          {/* <div className="flex justify-start items-center w-full  ">
            <div style={{ fontSize: 13 }} className="w-[50px]">
              Customer
            </div>
            <div
              style={{
                height: 27,
                backgroundColor: "#EDEDF2",
                overflow: "hidden",
                marginLeft: 10,
              }}
              className=" rounded-sm  w-16 md:w-40  items-center "
            >
              <input
                type={"text"}
                id={"customerCode"}
                style={{
                  fontSize: 13,
                  backgroundColor: "#EDEDF2",
                  paddingLeft: 10,

                  fontWeight: 400,
                  outline: 0,
                }}
                placeholder={"eg. jhon"}
                value={customerName}
                onChange={(t) => setCustomerName(t.target.value)}
              />
            </div>
          </div> */}
          <div className="flex justify-start items-center w-full mt-2  ">
            <div style={{ fontSize: 13 }} className="w-[50px]">
              Mobile
            </div>
            <div
              style={{
                height: 27,
                backgroundColor: "#EDEDF2",
                overflow: "hidden",
                marginLeft: 10,
              }}
              className=" rounded-sm  w-16 md:w-40  items-center "
            >
              <input
                type={"Number"}
                disabled
                id={"customerMobile"}
                style={{
                  fontSize: 13,
                  backgroundColor: "#EDEDF2",
                  paddingLeft: 10,

                  fontWeight: 400,
                  outline: 0,
                }}
                placeholder={"eg. 1234567890"}
                value={CustomerMobile}
                onChange={(t) => setCustomerMobile(t.target.value)}
              />
            </div>
          </div>

          <div className="flex justify-start items-center w-full mt-2 ">
            <div style={{ fontSize: 13 }} className="w-[50px]">
              Email
            </div>
            <div
              style={{
                height: 27,
                backgroundColor: "#EDEDF2",
                overflow: "hidden",
                marginLeft: 10,
              }}
              className=" rounded-sm  w-16 md:w-40  items-center "
            >
              <input
                type={"text"}
                disabled
                style={{
                  fontSize: 13,
                  backgroundColor: "#EDEDF2",
                  paddingLeft: 10,

                  fontWeight: 400,
                  outline: 0,
                }}
                id={"customerEmail"}
                placeholder={"eg. te@gmail.com"}
                value={customerEmail}
                onChange={(t) => setCustomerEmail(t.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col mt-5 justify-center">
          <IconButton
            bg={"#08846e"}
            title={"WhatsApp"}
            loading={
              waDisable ? (
                <div className="w-full flex justify-center items-center">
                  <div className="w-6 h-6">
                    <Lottie
                      animationData={spinner}
                      loop={true}
                      style={{ fontSize: 15 }}
                      color="white"
                    />
                  </div>
                </div>
              ) : null
            }
            extraSymbol={
              defaultProfile?.PERSONAL_NO_WHATSAPP === "No" && (
                <div className=" flex items-center ">₹</div>
              )
            }
            icon={<FaWhatsapp size={20} className="bg-[#08846e] text-white" />}
            onClick={() => {
              if (CustomerMobile && !waDisable) {
                handleSendImage(CustomerMobile, total);

                // if (defaultProfile?.PERSONAL_NO_WHATSAPP) {
                //   if (defaultProfile?.PERSONAL_NO_WHATSAPP === "Yes") {
                //     handlePersonalWhatsApp();
                //   } else {
                //     handleSendImage(CustomerMobile, total);
                //   }
                // }
              } else {
                if (!CustomerMobile) {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "Not found any mobile",
                    })
                  );
                }
              }
            }}
          />

          <div className="mt-2">
            <IconButton
              bg={"#1DA1F2"}
              loading={
                smsDisable ? (
                  <div className="w-full flex justify-center items-center">
                    <div className="w-6 h-6">
                      <Lottie
                        animationData={whitSpinner}
                        loop={true}
                        style={{ fontSize: 15 }}
                        color="white"
                      />
                    </div>
                  </div>
                ) : null
              }
              title={"SMS"}
              icon={<TiMessage size={20} className="bg-[#1DA1F2] text-white" />}
              onClick={() => handleSendSms(CustomerMobile, total)}
            />
          </div>
          <div className="mt-2">
            <IconButton
              bg={" #B71C1C "}
              color={"#F1F1F1"}
              title={"Email"}
              icon={<i className="bi bi-envelope"></i>}
              onClick={() => handleSendEmail()}
            />
          </div>
          <div className="mt-2">
            <IconButton
              bg={"#4B5563"}
              loading={
                smsDisable ? (
                  <div className="w-full flex justify-center items-center">
                    <div className="w-6 h-6">
                      <Lottie
                        animationData={whitSpinner}
                        loop={true}
                        style={{ fontSize: 15 }}
                        color="white"
                      />
                    </div>
                  </div>
                ) : null
              }
              title={"Download"}
              icon={
                <BiCloudDownload
                  size={20}
                  className="bg-[#4B5563] text-white"
                />
              }
              onClick={() => {
                downloadPdf();
              }}
            />
          </div>

          {billStatus && !hideVoid && userType === "MANAGER" ? (
            <div className="mt-2">
              <IconButton
                bg={billStatus === "Void" ? "#5491f5" : "#D32F2F"}
                title={billStatus === "Void" ? "Settled" : "Void"}
                icon={
                  <i
                    className={`bi h-[20px] text-[12px] ${
                      billStatus === "Void" ? " bi-patch-check" : "bi-trash3"
                    }`}
                  ></i>
                }
                onClick={() => {
                  if (billStatus === "Settled") {
                    setRemarksStatus(true);
                  } else {
                    setAlertTwoButtonModal({
                      ...alertTwoButtonModal,
                      status: true,
                      title: "Alert",
                      msg: "Are you sure you want to Settled this bill?",
                      button1: "Yes",
                      button2: "Cancel",
                      button2Click: () => {
                        setAlertTwoButtonModal({
                          ...alertTwoButtonModal,
                          status: false,
                        });
                      },
                      button1Click: () => {
                        setAlertTwoButtonModal({
                          ...alertTwoButtonModal,
                          status: false,
                        });
                        dispatch(setSpinnerLoading("Settled"));

                        CallAPI(
                          "/api/billing/changeSaleBillStatus",
                          {
                            table_prefix: prefix,
                            INVNO: currentInvoiceNumber,
                            BILL_STATUS:
                              billStatus === "Void" ? "Settled" : "Void",
                            REMARKS: "",
                          },
                          token,
                          (e) => {
                            if (!e.data.error) {
                              onBack();
                              if (setSummaryList) {
                                setSummaryList((p) =>
                                  p.map((old) => {
                                    if (old.INVNO === currentInvoiceNumber) {
                                      return {
                                        ...old,
                                        BILL_STATUS: "Settled",
                                      };
                                    } else {
                                      return old;
                                    }
                                  })
                                );
                              }
                              dispatch(
                                setAlertWithTitle({
                                  title: "Success",
                                  msg: `Bill successfully  ${
                                    billStatus === "Void" ? "Settled" : "Voided"
                                  }`,
                                })
                              );
                            } else {
                              dispatch(
                                setAlertWithTitle({
                                  title: "Alert",
                                  msg: "Something went wrong",
                                })
                              );
                            }
                          },
                          dispatch,
                          () => {
                            dispatch(setSpinnerLoading(false));
                          }
                        );
                      },
                    });
                  }
                }}
              />
            </div>
          ) : null}

          <div className="mt-2">
            <IconButton
              bg={"#E5E7EB"}
              color={"#374151"}
              border={"#374151"}
              loading={
                smsDisable ? (
                  <div className="w-full flex justify-center items-center">
                    <div className="w-6 h-6">
                      <Lottie
                        animationData={whitSpinner}
                        loop={true}
                        style={{ fontSize: 15 }}
                        color="white"
                      />
                    </div>
                  </div>
                ) : null
              }
              title={"Back"}
              icon={
                <TiArrowBackOutline
                  //   onClick={() => dispatch(setShareImageModalStatus(false))}
                  // test
                  size={20}
                  className=" text-[#374151]"
                />
              }
              onClick={onBack}
            />
          </div>
        </div>

        <div className="mt-2">
          <ReactToPrint
            trigger={() => {
              return (
                <IconButton
                  bg={"#93C5FD"}
                  title={"Print"}
                  color={"black"}
                  icon={<i className="bi bi-printer"></i>}
                  onClick={() => {}}
                />
              );
            }}
            content={() => componentRef.current}
            pageStyle={pageStyle}
          />
        </div>

        {orderList[0] && !hideOrderSlip && (
          <ReactToPrint
            trigger={() => {
              return (
                <Button
                  size={"small"}
                  id={"PrintButton"}
                  style={{
                    backgroundColor: "#BAD4F9",
                    color: "black",
                    textTransform: "none",
                    marginTop: 10,
                  }}
                  variant="contained"
                >
                  <div className="px-2">Order Print</div>{" "}
                </Button>
              );
            }}
            content={() => orderPrintRef.current}
          />
        )}

        <div className="w-full flex justify-center mt-3 "></div>
      </div>

      <div className="fixed bottom-0 w-full flex md:hidden p-3 bg-[#EDEDF2] items-center border-t-2 border-t-slate-200 ">
        <div
          style={{
            height: 35,
            backgroundColor: "#c9c5c5",
            overflow: "hidden",
            width: "45%",
          }}
          className=" rounded-md mx-2 border-2  items-center "
        >
          <input
            type={"Number"}
            style={{
              fontSize: 14,
              backgroundColor: "#c9c5c5",
              paddingLeft: 10,
              height: "100%",
              fontWeight: 400,
              outline: 0,
            }}
            placeholder={"Mobile Number"}
            value={CustomerMobile}
            onChange={(t) => setCustomerMobile(t.target.value)}
          />
        </div>
        <div
          style={{ width: "55%" }}
          className=" rounded-sm flex justify-evenly "
        >
          <FaWhatsappSquare
            size={30}
            className="text-green"
            onClick={() => {
              if (CustomerMobile) {
                handleSendImage(CustomerMobile, total);
                // handleSendSms(CustomerMobile);
              } else {
                if (!CustomerMobile) {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "Not found any mobile",
                    })
                  );
                }
              }
            }}
          />

          <FaSms
            size={30}
            className="text-orange"
            onClick={() => {
              if (CustomerMobile) {
                handleSendSms(CustomerMobile, total);
              } else {
                if (!CustomerMobile) {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "Not found any mobile",
                    })
                  );
                }
              }
            }}
          />

          <Button
            size={"small"}
            style={{
              backgroundColor: "#383535",
              color: "white",
              textTransform: "none",
            }}
            onClick={onBack}
            variant="contained"
          >
            <div className="px-2">Back</div>{" "}
          </Button>
        </div>
      </div>
      <ActionRemarks
        remarksStatus={remarksStatus}
        setRemarksStatus={setRemarksStatus}
        remarks={remarks}
        title={"Do you really want to Delete this Bill?"}
        placeHolder={"Remarks"}
        setRemarks={setRemarks}
        currentInvoiceNumber={currentInvoiceNumber}
        setSummaryList={setSummaryList}
        onBack={onBack}
        onSave={() => {
          if (remarks) {
            dispatch(setSpinnerLoading("Voiding"));
            CallAPI(
              "/api/billing/changeSaleBillStatus",
              {
                table_prefix: prefix,
                INVNO: currentInvoiceNumber,
                BILL_STATUS: "Void",
                REMARKS: remarks,
              },
              token,
              (e) => {
                if (!e.data.error) {
                  setRemarksStatus(false);
                  setRemarks("");
                  onBack();
                  dispatch(
                    setAlertWithTitle({
                      title: "Success",
                      msg: `Bill successfully  ${"Voided"}`,
                    })
                  );
                  if (setSummaryList) {
                    setSummaryList((p) =>
                      p.map((old) => {
                        if (old.INVNO === currentInvoiceNumber) {
                          return {
                            ...old,
                            BILL_STATUS: "Void",
                          };
                        } else {
                          return old;
                        }
                      })
                    );
                  }
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "Something went wrong",
                    })
                  );
                }
              },
              dispatch,
              () => {
                dispatch(setSpinnerLoading(false));
              }
            );
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "Remarks required",
              })
            );
          }
        }}
      />
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      <ScanQrCodeModal
        qrCodeValue={qrCodeValue}
        status={qrCodeValue ? true : false}
        onClose={() => setQrCodeValue("")}
        setQrCodeValue={setQrCodeValue}
      />
    </div>
  );
};

export default ViewBill;
