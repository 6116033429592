import React from 'react';
import './SignInForm.css';

const SignInForm = () => {
    return (
        <div className="container">
            <div className="login-form">
                <h2 className="logo">eudash</h2>
                <h2>Sign In</h2>
                <form>
                    <div className="input-group">
                        <input type="email" placeholder="Enter Your Email" required />
                    </div>
                    <div className="input-group">
                        <input type="password" placeholder="Enter password" required />
                        <span className="password-icon">👁️</span>
                    </div>
                    <div className="options">
                        <label>
                            <input type="checkbox" /> Remember
                        </label>
                        <a href="/" className="forgot-password">Lost Password?</a>
                    </div>
                    <button type="submit" className="sign-in-button">Sign In</button>
                </form>
                <div className="social-login">
                    <button className="social-btn google">G</button>
                    <button className="social-btn facebook">F</button>
                    <button className="social-btn apple">X</button>
                </div>
                <p className="sign-up-text">
                    Don’t have an account? <a href="/">Sign up</a>
                </p>
            </div>
        </div>
    );
};

export default SignInForm;
