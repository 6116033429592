import React from 'react'
import { FormatNumber2 } from '../../utility/functions/formatNumber'
import { useSelector } from 'react-redux'
import { selectWidth } from '../../utility/Redux/profile'

const Footer = ({columns,rows}) => {
  const validBill = rows.filter(row => row.BILL_STATUS !== 'Void')
  const width = useSelector(selectWidth)
  return (
    <div className='flex  w-full mt-1'>
        <div className='flex'>
        {
     columns.map((e,i) => {
        return (
            <div key={i} style={{
              fontSize:width<500 ?'11px':width <1281? '12px':'14px',
                width:e.width,
borderLeft :i === 0 ? '1px solid black' : '1px solid black',
borderRight :i === columns.length -1 ? '1px solid black' : '0px solid black',
                borderBottom: '1px solid black',
                borderTop: '1px solid black',
            }} className='p-1 text-right '>
    {e.field ==='NAME' &&  'Total'}
    {e.field ==='GTOTAL' &&validBill.reduce((acc, row) => acc + Number(  row.GTOTAL), 0)>0 &&   FormatNumber2(validBill.reduce((acc, row) => acc + Number(  row.GTOTAL), 0)) }
    {e.field ==='TOTAL' && validBill.reduce((acc, row) => acc + Number(  row.TOTAL), 0) >0&&  FormatNumber2(validBill.reduce((acc, row) => acc + Number(  row.TOTAL), 0)) }
    {e.field ==='AMOUNT' && validBill.reduce((acc, row) => acc + Number(  row.AMOUNT), 0)>0 &&  FormatNumber2(validBill.reduce((acc, row) => acc + Number(  row.AMOUNT), 0)) }
    {e.field ==='TAX1_AMT' && validBill.reduce((acc, row) => acc + Number(  row.TAX1_AMT), 0) >0 &&  FormatNumber2(validBill.reduce((acc, row) => acc + Number(  row.TAX1_AMT), 0)) }
    {e.field ==='TAX2_AMT' && validBill.reduce((acc, row) => acc + Number(  row.TAX2_AMT), 0) >0 &&  FormatNumber2(validBill.reduce((acc, row) => acc + Number(  row.TAX2_AMT), 0)) }



    {e.field ==='DISQ_AMT' &&validBill.reduce((acc, row) => acc + Number(row.DISQ_AMT), 0)>0 &&   FormatNumber2(validBill.reduce((acc, row) => acc + Number(row.DISQ_AMT), 0)) }

                </div>
        )
     })   
        
        
    }  
        </div>
</div>
  )
}

export default Footer