import moment from "moment";
import { FormatNumber2 } from "../../utility/functions/formatNumber";
import { HeaderWidth } from "../../utility/constant";

export const ItemwisePurchaseColumns = (width) => {
    const columns = [
      {
        field: 'ICODE',
        accessor: 'ICODE',
        type: 'string',
        headerAlign: 'center',
        width:  60,
        headerClassName: 'super-app-theme--header',
  
        renderCell: (param) => {
          return (
            <div
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
           Code
          </div>
        ),
      },

      {
        field: 'ITEMNAME',
         accessor: 'ITEMNAME',
        type: 'string',
        headerAlign: 'center',
  
        width: width < 768 ? 100 : 200,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => {
          return (
            <div
              className={` h-full w-full flex items-center  `}
            >
              {params.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
           Item Name
          </div>
        ),
      },
      {
        field: 'QNTY',
         accessor: 'QNTY',
        type: 'string',
        width: 80,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={`h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
           Quantity
          </div>
        ),
      },
      {
        field: 'PUR_PRICE',
         accessor: 'PUR_PRICE',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 80,
        align: 'right',
        renderCell: (param) => {
          return (
            <div className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : '0'}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
            Rate
          </div>
        ),
      },

      {
        field: 'AMOUNT',
         accessor: 'AMOUNT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 90,
        align: 'right',
        renderCell: (param) => {
          return (
            <div className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : '0'}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
            Amount
          </div>
        ),
      },
   
      {
        field: 'DISQ_AMT',
         accessor: 'DISQ_AMT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 90,
        align: 'right',
        renderCell: (param) => {
          return (
            <div className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : '0'}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
            Discount
          </div>
        ),
      },
      {
        field: 'TAX1_RATE',
         accessor: 'TAX1_RATE',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 60,
        align: 'right',
        renderCell: (param) => {
          return (
            <div className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== "0" ? FormatNumber2(param.value) +" %" : '0'}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
         CGST
          </div>
        ),
      },
      {
        field: 'TAX1_AMT',
         accessor: 'TAX1_AMT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 120,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : '0'}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
        CGST Amount
          </div>
        ),
      },
      {
        field: 'TAX2_RATE',
         accessor: 'TAX2_RATE',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 60,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== "0"  ? FormatNumber2(param.value) + ' %' : '0'}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
           SGST
          </div>
        ),
      },   {
        field: 'TAX2_AMT',
         accessor: 'TAX2_AMT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 120,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== 0 ? FormatNumber2(param.value) : '0'}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
         SGST Amount
          </div>
        ),
      },
      {
        field: 'TAX3_RATE',
         accessor: 'TAX3_RATE',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 60,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value !== "0" ? FormatNumber2(param.value) +' &' : '0'}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
        IGST
          </div>
        ),
      },
      {
        field: 'TAX3_AMT',
         accessor: 'TAX3_AMT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 120,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value!== 0  ? FormatNumber2(param.value) : '0'}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
        IGST Amount
          </div>
        ),
      },
    
      {
        field: 'GTOTAL',
         accessor: 'GTOTAL',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 150,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
          Grand Total
          </div>
        ),
      },
      {
        field: 'SECTION_NAME',
         accessor: 'SECTION_NAME',
        type: 'string',
        width: width < 768 ? 100 : width < 1400 ? 180 : 200,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Section Name
          </div>
        ),
      },
  

    ];

    const tabColumns = [
      {
        field: 'ICODE',
        accessor: 'ICODE',
        type: 'string',
        headerAlign: 'center',
        width:  50,
        headerClassName: 'super-app-theme--header',
  
        renderCell: (param) => {
          return (
            <div
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
           Code
          </div>
        ),
      },

      {
        field: 'ITEMNAME',
         accessor: 'ITEMNAME',
        type: 'string',
        headerAlign: 'center',
  
        width: 120,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => {
          return (
            <div
              className={` h-full w-full flex items-center  `}
            >
              {params.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
           Item Name
          </div>
        ),
      },
      {
        field: 'QNTY',
         accessor: 'QNTY',
        type: 'string',
        width: 50,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={`h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
        Qnty.
          </div>
        ),
      },
      {
        field: 'PUR_PRICE',
         accessor: 'PUR_PRICE',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 60,
        align: 'right',
        renderCell: (param) => {
          return (
            <div className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : '0'}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
            Rate
          </div>
        ),
      },

      {
        field: 'AMOUNT',
         accessor: 'AMOUNT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 70,
        align: 'right',
        renderCell: (param) => {
          return (
            <div className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : '0'}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
            Amount
          </div>
        ),
      },
   
      {
        field: 'DISQ_AMT',
         accessor: 'DISQ_AMT',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 70,
        align: 'right',
        renderCell: (param) => {
          return (
            <div className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : '0'}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
            Discount
          </div>
        ),
      },
     
      {
        field: 'TOTAL_TAX',
         accessor: 'TOTAL_TAX',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 70,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value!== 0  ? FormatNumber2(param.value) : '0'}
            </div>
          );
        },
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
        Total Tax
          </div>
        ),
      },
    
      {
        field: 'GTOTAL',
         accessor: 'GTOTAL',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 80,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
          Grand Total
          </div>
        ),
      },
      {
        field: 'SECTION_NAME',
         accessor: 'SECTION_NAME',
        type: 'string',
        width: 120,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Section Name
          </div>
        ),
      },
  

    ];

    const otherWidth = width - (60+60+80+HeaderWidth()+35)
    const mobileColumns = [


      {
        field: 'ITEMNAME',
        accessor: 'ITEMNAME',
        type: 'string',
        width:  otherWidth,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Item Name
          </div>
        ),
      },
    
      {
        field: 'QNTY',
        accessor: 'QNTY',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 60,
        align: 'right',
 
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
  Qnty
          </div>
        ),
      },
      {
        field: 'PUR_PRICE',
         accessor: 'PUR_PRICE',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 60,
        align: 'right',
        renderCell: (param) => {
          return (
            <div className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : '0'}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
            Rate
          </div>
        ),
      },
      {
        field: 'GTOTAL',
        accessor: 'GTOTAL',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: 80,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={`${
                param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
              } h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
       Amount
          </div>
        ),
      },

    ]
    return width <764? mobileColumns: width <1281? tabColumns: columns;
  };

  export const SectionWisePurchaseColumns = (width) => {
    const columns = [
  
      {
        field: 'SECTION_NAME',
        accessor: 'SECTION_NAME',
        type: 'string',
        width: width < 768 ? 100 : width < 1281 ? 100 : 150,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            Section Name
          </div>
        ),
      },
  
      {
        field: 'SECTION_NAME',
        accessor: 'GTOTAL',
        type: 'number',
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : width < 1281 ? 100 : 150,
        align: 'right',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end `}
            >
              {param.value ? FormatNumber2(param.value) : ''}
            </div>
          );
        },
  
        headerAlign: 'center',
  
        renderHeader: (params) => (
          <div
            className="text-white  pr-4 text-center"
            style={{ fontSize: 13 }}
          >
          Grand Total
          </div>
        ),
      },
    ];
    return columns;
  };