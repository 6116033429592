import axios from "axios";
import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import Lottie from "lottie-react";
import spinner from "../../src/utility/json/Spinner.json";
import done from "../../src/utility/json/success2.json";
import wrong from "../../src/utility/json/rejected2.json";
const ActivationAccount = () => {
  const searchParams = new URLSearchParams(window.location.search);
  // const param1 = searchParams.get('company_email');
  const userEmail = searchParams.get("user_email");
  const [status, setUserStatus] = useState("loading");
  const activationCode = searchParams.get("activate_code");
  const userMobile = searchParams.get("user_mobile");
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
  const u = userEmail ? userEmail : userMobile;
  // const hmac = CryptoJS.HmacSHA256(message, secret);
  const hmac = CryptoJS.HmacSHA256(u, secretPass);
  // Convert the HMAC to a string and encode it using Base64
  const hmacString = hmac.toString(CryptoJS.enc.Base64);

  const ActivateUser = () => {
    const data = {
      user_email: userEmail,
      user_mobile: !userEmail ? userMobile : "",
    };
    axios
      .post(`/api/users/userInfo`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${hmacString}`,
        },
      })
      .then(async function (response) {
        if (response.data.response[0]) {
          axios
            .post(
              "/api/users/activateUser",
              {
                table_prefix: response.data.response[0].table_prefix,
                user_email: userEmail,
                activate_code: activationCode,
                user_name: response.data.response[0].user_name,
                user_mobile: !userEmail ? userMobile : "",
                code: response.data.response[0].code,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${hmacString}`,
                },
              }
            )
            .then((d) => {
              setUserStatus("activated");
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (activationCode) {
      ActivateUser();
    } else {
      setUserStatus("deactivated");
    }
  }, [activationCode]);
  return (
    <div className="flex justify-center items-center min-h-screen w-screen bg-gray-100">
      <div className="w-full max-w-md bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="bg-gradient-to-r from-[#fdab11] to-[#f5874f] h-20 rounded-t-lg"></div>
        <div className="flex justify-center ">
          <div className="w-[60px] h-[60px] bg-white -mt-8 rounded-[60px] p-2">
            <Lottie
              animationData={
                status === "loading"
                  ? spinner
                  : status === "activated"
                  ? done
                  : wrong
              }
              loop={true}
              style={{ fontSize: 15 }}
              color="white"
            />
          </div>
        </div>

        <div className="p-6 text-center">
          <h2 className="text-2xl font-semibold text-gray-800 mt-4">
            {status === "loading"
              ? "Processing"
              : status === "activated"
              ? "Account is activated!"
              : status}
          </h2>
          <div className="text-gray-600 mt-4">
            {status === "activated" ? (
              <p> User Account is Activated and he can login now.</p>
            ) : status === "loading" ? (
              "Wait for user account to be activated"
            ) : (
              "Wrong Link"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivationAccount;
