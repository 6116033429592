import React from "react";
import Modal from "@mui/material/Modal";
import Lottie from "lottie-react";
import spinner from "../../json/Spinner.json";

import { selectSpinnerLoading, selectSpinnerText } from "../../Redux/modal";
import { useSelector } from "react-redux";

export default function SpinnerLoading() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
    // border: '1px solid white',
    boxShadow: 24,
    borderRadius: "7px",
    outline: "none",
  };

  const loading = useSelector(selectSpinnerLoading);
  const text = useSelector(selectSpinnerText);
  return (
    <div>
      <Modal open={loading}>
        <div style={style} className=" w-full ">
          <div
            className={
              "h-full w-full flex items-center  bg-white  rounded-sm  p-[12px]"
            }
          >
            <div
              style={{
                fontSize: "13px",
              }}
              className="w-full  items-center justify-center text-center  text-[#1c1a1a] flex  "
            >
              <div className="h-[18px] w-[18px]  flex items-center ">
                <Lottie
                  animationData={spinner}
                  loop={true}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                />
              </div>
              <div className="pl-3">{text}</div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
