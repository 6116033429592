import moment from "moment";
import { FormatNumber2 } from "../../utility/functions/formatNumber";

export const PurchaseSummaryColumns = (width) => {
  const columns = [
    {
      field: "DATE",
      accessor: "DATE",
      type: "date",
      headerAlign: "center",

      width: width < 768 ? 180 : 180,
      headerClassName: "super-app-theme--header",
      format: "DD-MM-yyyy ,dddd",
      color: "#565555",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value
              ? moment(params.value).format("DD-MM-yyyy ,dddd")
              : ""}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Date
        </div>
      ),
    },
    {
      field: "opening",
      accessor: "opening",
      type: "string",
      headerAlign: "center",

      width: width < 768 ? 100 : width < 1400 ? 180 : 130,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center justify-end  `}>
            {params.value ? FormatNumber2(params.value) : ""}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Opening
        </div>
      ),
    },

    {
      field: "PUR_AMOUNT",
      accessor: "PUR_AMOUNT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Pur. Amount
        </div>
      ),
    },

    {
      field: "SALE_AMOUNT",
      accessor: "SALE_AMOUNT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 120,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Sale Amt.
        </div>
      ),
    },
    {
      field: "closing",
      accessor: "closing",
      type: "string",
      width: width < 768 ? 100 : width < 1400 ? 180 : 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center justify-end  `}>
            {params.value ? FormatNumber2(params.value) : ""}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Closing
        </div>
      ),
    },
  ];

  return columns;
};
