import React, { useEffect, useState } from "react";
import { DownloadFile } from "../utility/functions/firebase";
import { useSelector } from "react-redux";
import { selectPrefix, selectWidth } from "../utility/Redux/profile";
import { toProperCase } from "../utility/component/format";
import { FormatNumber2 } from "../utility/functions/formatNumber";
import HandleIndexedDB from "../Test/handleIndexDb";
import { mobileViewWidth } from "./common";
import "./animat.css";
import {
  fastMovingMovieItem,
  FontSizeWidth,
  openItemTitle,
} from "../utility/constant";
const ItemBox = ({ box, onClick, index, imgCard, mobileWidth }) => {
  const prefix = useSelector(selectPrefix);
  const icode = box.ICODE;
  const path = `retailx/items/${prefix + icode}`;
  const [img, setImg] = useState("");
  const width = useSelector(selectWidth);
  const [isAnimating, setIsAnimating] = useState(false);

  const fireToken = localStorage.getItem("fire");
  useEffect(() => {
    if (!fireToken) {
      if (width > 600) {
        const isImgAvailval = imgCard.filter((o) => o.ICODE === box.ICODE);
        if (!img && navigator.onLine && !isImgAvailval[0]) {
          DownloadFile(path)
            .then((e) => {
              if (!e.includes("FirebaseError")) {
                const urlParams = new URLSearchParams(new URL(e).search);
                const token = urlParams.get("token");
                localStorage.setItem("fire", token);

                const databaseName = "ItemsImages";
                const storeName = "images";
                HandleIndexedDB({
                  databaseName,
                  storeName,
                  callback: function (data, col) {
                    if (!data.find((o) => o.ICODE === box.ICODE)) {
                      const dataToSave = [
                        ...data,
                        {
                          ICODE: box.ICODE,
                          image: e,
                        },
                      ];

                      HandleIndexedDB({ databaseName, storeName, dataToSave });
                    }
                  },
                });

                setImg(e);
              } else {
                setImg("ERROR");
              }
            })
            .catch((e) => {
              // setCapturedImage('');
              setImg("ERROR");
            });
        } else {
          if (imgCard[0]) {
            const i = imgCard.filter((o) => o.ICODE === box.ICODE);
            if (i[0]) {
              setImg(i[0]?.image);
            }
          }
        }
      }
    } else {
      const i = `https://firebasestorage.googleapis.com/v0/b/retailx-126e1.appspot.com/o/retailx%2Fitems%2F${prefix}${box.ICODE}?alt=media&token=${fireToken}`;
      setImg(i);
    }
  }, [box]);
  return (
    <button
      style={{
        boxShadow:
          box.ITEMNAME === openItemTitle ||
          box.SECTION_NAME === fastMovingMovieItem
            ? "none"
            : `inset 8px 8px 16px #f5cc5d, inset -8px -8px 16px #fadb3f`,
        backgroundColor:
          box.ITEMNAME === openItemTitle
            ? "#dae74a"
            : box.SECTION_NAME === fastMovingMovieItem
            ? "#f7b03e"
            : "#fadb3f",
      }}
      onClick={() => {
        onClick(box);
      }}
      className=" shadow-md rounded-sm flex    overflow-hidden pt-1 pb-1 px-2 py-2 t:py-2"
    >
      <div className="flex flex-col h-full justify-between items-center">
        {width > 600 && (
          <div
            style={{
              width: "30px",
              height: "30px",
            }}
          >
            {img && img !== "ERROR" && (
              <img
                alt=""
                src={img}
                className="w-ful h-full"
                onError={() => setImg("")}
              />
            )}
          </div>
        )}
      </div>

      <div
        style={{
          width: `calc(100% - 0px)`,
          fontSize: FontSizeWidth(width),
          height: width > 1280 ? "auto" : "fit",
        }}
        className=" pt-1 sm:ml-3 flex flex-col justify-between  "
      >
        <div className="text-start  flex justify-between overflow-ellipsis whitespace-nowrap overflow-hidden ">
          <div className="flex items-center">
            <div style={{}} className="ll:font-semibold whitespace-normal  ">
              {toProperCase(box.ITEMNAME)}
            </div>
          </div>
          <div className="text-[#4e4a4a] font-semibold  ml-2 ">{box.ICODE}</div>
        </div>
        {
          <div className="flex mt-1  items-center ">
            <div
              style={{
                backgroundColor:
                  box.SALE_PRICE > 0
                    ? box.BAL_STOCK > 0
                      ? "#a8cf45"
                      : "#F08080"
                    : "#D3D3D3",
              }}
              className="p-[4px] h-[4px] w-[4px] mr-1 rounded-full"
            ></div>
            {box.SALE_PRICE > 0 ? (
              <label className=" ll:font-semibold ">
                {FormatNumber2(box.SALE_PRICE) + " " + "₹"}
              </label>
            ) : (
              ""
            )}
            {width > 1280 && (
              <div className="flex items-center h-full justify-end"></div>
            )}
          </div>
        }
      </div>
    </button>
  );
};

export default ItemBox;
