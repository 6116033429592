import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { emailRegex } from "../../billing/common";
import { useDispatch } from "react-redux";
import { setAlertWithTitle } from "../../utility/Redux/modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "auto",
  borderRadius: "8px",
  overflow: "hidden",
  backgroundColor: "white",
};

const modalContainerClasses =
  "fixed inset-0 flex items-center justify-center bg-background bg-opacity-80";
const modalContentClasses = "bg-card rounded-lg shadow-lg p-6 max-w-sm w-full";
const textCenterClasses = "text-center";
const textPrimaryClasses = "text-primary";
const textMutedClasses = "text-muted-foreground";
const inputClasses = "mt-1 p-2 border border-border rounded w-full";
const buttonClasses =
  "mt-6 w-full bg-primary text-primary-foreground p-2 rounded hover:bg-primary/80";

const EmailChangeModal = ({
  status,
  onClose,
  email,
  VerfiyEmailSentAPI,
  list,
}) => {
  const [newEmail, setNewEmail] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setNewEmail("");
  }, [status]);
  return (
    <div className="bg-white">
      <Modal
        open={status}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="flex">
          <div className="min-w-[350px] py-4 px-4">
            {/* Close Icon */}
            <div className="flex justify-end">
              <i
                className="bi bi-x cursor-pointer text-[25px]"
                onClick={onClose}
              ></i>
            </div>

            {/* Email Icons */}
            <div className="flex justify-center">
              <div className="bg-gray-100 border-brown border-2 flex items-center justify-center h-[40px] w-[40px] rounded-[40px]">
                <i className="bi bi-envelope-at"></i>
              </div>
              <div className="bg-brown -ml-[14px] flex items-center justify-center h-[40px] w-[40px] rounded-[40px]">
                <i className="bi bi-envelope-plus-fill text-white"></i>
              </div>
            </div>

            {/* Header */}
            <div className="flex justify-center font-semibold py-3">
              Change Email
            </div>
            <div className="flex justify-center text-gray-700 text-center">
              Please enter your previous email and new email.
            </div>

            {/* Form */}
            <div className="py-2">
              <div className="mt-4">
                {/* Previous Email */}
                <label
                  className={`block ${textMutedClasses}`}
                  htmlFor="prevEmail"
                >
                  Previous Email
                </label>
                <input
                  type="email"
                  id="prevEmail"
                  placeholder="previous@email.com"
                  className={inputClasses}
                  value={email}
                  required
                  disabled
                />

                {/* New Email */}
                <label
                  className={`block ${textMutedClasses} mt-4`}
                  htmlFor="newEmail"
                >
                  New Email
                </label>
                <input
                  type="email"
                  id="newEmail"
                  value={newEmail}
                  onChange={(t) => {
                    const isDuplicate = list.filter(
                      (c) =>
                        c.CUSTOMER_EMAIL === t.target.value && c.CUSTOMER_EMAIL
                    );
                    if (!isDuplicate[0]) {
                      setNewEmail(t.target.value);
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "Email already exist",
                        })
                      );
                    }
                  }}
                  placeholder="new@email.com"
                  className={inputClasses}
                  required
                />

                {/* Submit Button */}
                <button
                  onClick={() => {
                    if (emailRegex.test(newEmail)) {
                      VerfiyEmailSentAPI(newEmail).then((o) => {
                        dispatch(
                          setAlertWithTitle({
                            title: "Alert",
                            msg: `Email sent to ${newEmail} please verfiy it to update`,
                          })
                        );
                      });
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: "Alert",
                          msg: "Please enter valid email",
                        })
                      );
                    }
                  }}
                  type="submit"
                  className={buttonClasses}
                >
                  Update Email
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EmailChangeModal;
