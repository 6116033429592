import { FormatNumber2 } from "../../utility/functions/formatNumber";

export const SummaryColumns = (width) => {
    const columns = [
      {
        field: 'DATE',
        accessor: 'DATE',

        type: 'date',
        headerAlign: 'center',
        width: width < 768 ?70 : width < 1400 ? 90 : 90,
        headerClassName: 'super-app-theme--header',
  
        renderCell: (param) => {
          return (
            <div
              className={`h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Date'}{' '}
          </div>
        ),
      },

      {
        field: 'ICODE',
        accessor: 'ICODE',
        type: 'string',
        headerAlign: 'center',
        width: width < 768 ? 40 : 50,
        headerClassName: 'super-app-theme--header',
      
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Code'}{' '}
          </div>
        ),
      },
      {
        field: 'ITEMNAME',
        accessor: 'ITEMNAME',
        type: 'string',
        width: width < 768 ? 150 : 200,
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Item Name'}{' '}
          </div>
        ),
      },
      {
        field: 'MRP_RATE',
        accessor: 'MRP_RATE',
        type: 'string',
        align:'right',
        width: width < 768 ? 60 : width < 1400 ? 80 : 60,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end  `}
            >
              {FormatNumber2(param.value) }
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
        MRP
          </div>
        ),
      },
      {
        field: 'OFFER_SALE_PRICE',
        accessor: 'OFFER_SALE_PRICE',
        align:'right',
        type: 'number',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end  `}
            >
              {FormatNumber2(param.value) }
            </div>
          );
        },
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 100 : 80,
        headerAlign: 'center',
        align: 'left',
  
        renderHeader: (params) => (
          <div
            className="text-white  "
            style={{ fontSize: 13 }}
          >
            {' '}
          Offer Price
          </div>
        ),
      },
      {
        field: 'FDATE',
        accessor: 'FDATE',
        type: 'date',
        width: width < 768 ? 100 : width < 1400 ? 100 : 90,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
          From Date
          </div>
        ),
      },
      {
        field: 'TDATE',
        accessor: 'TDATE',
        type: 'date',
        width: width < 768 ? 100 : width < 1400 ? 100 : 90,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
          To Date
          </div>
        ),
      },
      {
        field: 'FTIME',
        accessor: 'FTIME',
        type: 'string',
        headerAlign: 'center',
        width: width < 768 ? 60 : 65,
        headerClassName: 'super-app-theme--header',
      
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'From'}{' '}
          </div>
        ),
      },   {
        field: 'TTIME',
        accessor: 'TTIME',
        type: 'string',
        headerAlign: 'center',
        width: width < 768 ? 60 : 65,
        headerClassName: 'super-app-theme--header',
      
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'To'}{' '}
          </div>
        ),
      },
    ];

    const mobileColumss = [
      {
        field: 'DATE',
        accessor: 'DATE',

        type: 'date',
        headerAlign: 'center',
        width: width < 768 ?70 : width < 1400 ? 90 : 90,
        headerClassName: 'super-app-theme--header',
  
        renderCell: (param) => {
          return (
            <div
              className={`h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Date'}{' '}
          </div>
        ),
      },

      {
        field: 'ICODE',
        accessor: 'ICODE',
        type: 'string',
        headerAlign: 'center',
        width: width < 768 ? 40 : 50,
        headerClassName: 'super-app-theme--header',
      
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Code'}{' '}
          </div>
        ),
      },
      {
        field: 'ITEMNAME',
        accessor: 'ITEMNAME',
        type: 'string',
        width: width < 768 ? 150 : 200,
        headerAlign: 'center',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center   `}
            >
              {param.value}
            </div>
          );
        },
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <div className="text-white" style={{ fontSize: 13 }}>
            {' '}
            {'Item Name'}{' '}
          </div>
        ),
      },

      {
        field: 'OFFER_SALE_PRICE',
        accessor: 'OFFER_SALE_PRICE',
        align:'right',
        type: 'number',
        renderCell: (param) => {
          return (
            <div
              className={` h-full w-full  flex items-center  justify-end  `}
            >
              {FormatNumber2(param.value) }
            </div>
          );
        },
        headerClassName: 'super-app-theme--header',
        width: width < 768 ? 70 : 80,
        headerAlign: 'center',
        align: 'left',
  
        renderHeader: (params) => (
          <div
            className="text-white  "
            style={{ fontSize: 13 }}
          >
            {' '}
          Offer Price
          </div>
        ),
      },
  
    ];
    return width <500?mobileColumss: columns;
  };