import React, { useEffect } from "react";

const modalStyles =
  "fixed inset-0 flex items-center justify-center bg-black/30";
const cardStyles =
  "bg-white dark:bg-card rounded-lg shadow-lg p-6 max-w-sm w-full";
const buttonStyles = "rounded-lg px-4 py-2";
const primaryButtonStyles = "bg-[#f97316] text-white hover:bg-[#f97316]";
const secondaryButtonStyles = "bg-zinc-200 text-zinc-800 hover:bg-zinc-300";
const closeButtonStyles =
  "text-zinc-500 hover:text-zinc-700 dark:hover:text-zinc-300";
const textStyles = "text-zinc-600 dark:text-muted-foreground";
const textareaStyles =
  " w-full  p-2 border border-zinc-300 dark:border-zinc-700 rounded-lg focus:outline-none focus:ring ring-zinc-200 dark:ring-zinc-500";
const tagStyles =
  "inline-block bg-zinc-200 text-[12px] text-zinc-800 dark:bg-zinc-700 dark:text-white  px-2 py-1 rounded-full cursor-pointer hover:bg-zinc-300 dark:hover:bg-zinc-600 mr-1";

const ItemInstructionModal = ({
  remarksStatus,
  billCartList,
  setRemarksStatus,
  selectedItem,
  placeHolder,
  onSave,
  previosInstruction,
  remarks,
  setRemarks,
  title,
  onFocus,
  onBlur,
  buttonName,
}) => {
  useEffect(() => {
    const isAlreadyHaveremarks = billCartList.find(
      (item) => item.ITEM_INSTRUCTIONS && item.ICODE === selectedItem
    );

    if (isAlreadyHaveremarks) {
      setRemarks(isAlreadyHaveremarks.ITEM_INSTRUCTIONS);
    } else {
      setRemarks("");
    }
  }, []);
  return (
    <div className={modalStyles}>
      <div className={cardStyles}>
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">Item Instruction</h2>
          <button
            onClick={() => setRemarksStatus(false)}
            className={closeButtonStyles}
          >
            <i className="bi bi-x text-red-500 text-[22px]"></i>
          </button>
        </div>

        {/* Tag Section */}

        {/* Textarea for writing new instruction */}
        <div className="mt-1">
          <p className={textStyles}>Write item instruction below :</p>
          <textarea
            className={textareaStyles}
            value={remarks}
            autoFocus
            rows={4}
            maxLength={100}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter") {
                onSave();
              }
            }}
            onFocus={onFocus ? onFocus : () => {}}
            onBlur={onBlur ? onBlur : () => {}}
            placeholder={placeHolder}
            onChange={(e) => setRemarks(e.target.value)}
          ></textarea>

          <div className="flex flex-wrap">
            {previosInstruction.slice(-10).map((instruction, index) => (
              <span
                key={index}
                className={tagStyles}
                onClick={() => setRemarks(instruction)} // Set remarks on tag click
              >
                {instruction}
              </span>
            ))}
          </div>
        </div>

        {/* Buttons */}
        <div className="mt-6 flex justify-end space-x-2">
          <button
            onClick={() => setRemarksStatus(false)}
            className={`${secondaryButtonStyles} ${buttonStyles}`}
          >
            Cancel
          </button>
          <button
            onClick={onSave}
            className={`${primaryButtonStyles} ${buttonStyles}`}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ItemInstructionModal;
