import { createSlice } from "@reduxjs/toolkit";

const intailStateModal = {
  loading: {
    status: false,
  },
  alert: {
    status: false,
    msg: "",
    okStatus: 0,
    code: "",
  },
  emailVerification: false,
  getOtp: false,
  itemVarientsStatus: false,
  itemVarientsList: [],
  collection: {
    voucher: false,
    report: false,
    partyLedger: false,
    print: false,
    confirmSave: false,
    accountMaster: false,
    VoucherSummary: false,
    accountDetails: {
      accName: "",
      accAddress: "",
      accMobile: "",
      accNumber: "",
      stationId: "",
    },
    CurrentVoucherNo: "",
  },
  section_master: false,
  billing: {
    GuestCreate: false,
    editItemDetials: {},
    billSattalment: false,
    paymentMode: false,
    billHistry: false,
    confirmSaveBill: false,
    confirmPrintBill: false,
    shareBillImage: false,
    billingAccountMasterModal: false,
  },
  saleSummary: {
    viewBill: false,
  },
  modEmployeeMasterHelp: false,

  admin: {
    StationWiseIssueReport: false,
    UsersStatus: false,
    listStationMaster: false,
    locations: false,
    loginRequest: false,
  },
  masters: {
    expense: false,
    AccountMasterDataGrid: false,
    ItemMaster: false,
  },
  expense: {
    voucher: false,
    totalExpense: 0,
    paymode: "CASH",
    voucherPrint: false,
  },

  isSessionExpired: false,
  isTodayIssue: false,

  checkEmailAlert: {
    status: false,
  },
  spinnerLoading: {
    status: false,
    text: "loading",
  },

  checkVerifyEmailAlert: {
    status: false,
  },
  customeAlertMsg: {
    status: false,
    title: "",
    msg: "",
    buttonText: "Ok",
  },
  loginApprovelModal: false,
  loginSucces: false,
  loginRejected: false,
  customeTwoButtonAlert: {
    status: false,
  },
  toast: {
    status: false,
    msg: "",
  },
  alertWithTitel: {
    status: false,
    title: "",
    msg: "",
    buttonText: "Ok",
    lottie: "",
    horizontal: false,
  },
  station_master: false,
  dayEndSummary: false,
  sectionMaster: false,
  sectionMasterHelp: false,
  departmentMasterHelp: false,
  departmentMaster: false,
  modPartyMasterHelp: false,
  modListStationMaster: false,
  partyRateMaster: false,
  numercKeyBoradStatus: false,
  quantityEdit: false,
  itemMasterHelp: false,
  numericKeyboardText: "",
  numericKeyBoardType: "",
  issueStockItem: "",
  saveType: "",
  modCompanyHelp: false,

  geniusKeyboard: false,
};

export const Modals = createSlice({
  name: "modals",
  initialState: intailStateModal,
  reducers: {
    setloading(state, action) {
      state.loading.status = action.payload;
    },
    setItemVarientsStatus(state, action) {
      state.itemVarientsStatus = action.payload;
    },
    setModCompanyHelpStatus(state, action) {
      state.modCompanyHelp = action.payload;
    },
    setItemVarientsList(state, action) {
      state.itemVarientsList = action.payload;
    },
    setEmailVerificationStatus(state, action) {
      state.emailVerification = action.payload;
    },
    setGeniusKeyModStatus(state, action) {
      state.geniusKeyboard = action.payload;
    },
    setStation_MasterStatus(state, action) {
      state.station_master = action.payload;
    },
    setSaveType(state, action) {
      state.saveType = action.payload;
    },
    setSection_MasterStatus(state, action) {
      state.section_master = action.payload;
    },
    setIssueStockItem(state, action) {
      state.issueStockItem = action.payload;
    },
    setModEmployeeMasterHelpStatus(state, action) {
      state.modEmployeeMasterHelp = action.payload;
    },
    setnumericKeyboardStatus(state, action) {
      state.numercKeyBoradStatus = action.payload;
    },
    setnumerickeyboardtext(state, action) {
      state.numericKeyboardText = action.payload;
    },
    setnumericKeyboardType(state, action) {
      state.numericKeyBoardType = action.payload;
    },

    setSpinnerLoading(state, action) {
      state.spinnerLoading.status = action.payload !== false ? true : false;
      state.spinnerLoading.text = action.payload;
    },
    setQuantityEditStatus(state, action) {
      state.quantityEdit = action.payload;
    },
    setModPartyMasterHelpStatus(state, action) {
      state.modPartyMasterHelp = action.payload;
    },
    setModListStationMasterStatus(state, action) {
      state.modListStationMaster = action.payload;
    },
    setSectionMasterStatus(state, action) {
      state.sectionMaster = action.payload;
    },

    setPartyRateMasterStatus(state, action) {
      state.partyRateMaster = action.payload;
    },
    setSectionMasterHelpStatus(state, action) {
      state.sectionMasterHelp = action.payload;
    },
    setDepartmentMasterHelpStatus(state, action) {
      state.departmentMasterHelp = action.payload;
    },
    setDepartmentMasterStatus(state, action) {
      state.departmentMaster = action.payload;
    },
    setItemMasterHelpStatus(state, action) {
      state.itemMasterHelp = action.payload;
    },
    setAlertWithTitle(state, action) {
      state.spinnerLoading.status = false;
      state.loading.status = false;
      state.alertWithTitel.title = action.payload.title;
      state.alertWithTitel.msg = action.payload.msg;
      state.alertWithTitel.status = true;
      state.alertWithTitel.lottie = action.payload.lottie;
      state.alertWithTitel.horizontal = action.payload.horizontal
        ? action.payload.horizontal
        : false;
    },
    setGetOtpModal(state, action) {
      state.getOtp = action.payload;
    },
    setItemMasterModalStatus(state, action) {
      state.masters.ItemMaster = action.payload;
    },
    setDayEndModalStatus(state, action) {
      state.dayEndSummary = action.payload;
    },
    setLoginRejected(state, action) {
      state.loginRejected = action.payload;
    },
    setLoginSuccess(state, action) {
      state.loginSucces = action.payload;
    },
    setLoginApprovelModalStatus(state, action) {
      state.loginApprovelModal = action.payload;
    },
    setLoginRequestsModalStatus(state, action) {
      state.admin.loginRequest = action.payload;
    },
    setLocationMarkers(state, action) {
      state.admin.locations = action.payload;
    },
    setExpVoucPrintModalStatus(state, action) {
      state.expense.voucherPrint = action.payload;
    },
    setExpensePaymode(state, action) {
      state.expense.paymode = action.payload;
    },
    setTotalExpense(state, action) {
      state.expense.totalExpense = action.payload;
    },
    setExpenseVoucherModalStatus(state, action) {
      state.expense.voucher = action.payload;
    },
    setListStationMasterModalStatus(state, action) {
      state.admin.listStationMaster = action.payload;
    },
    setExpenseMasterModalStatus(state, action) {
      state.masters.expense = action.payload;
    },
    setUserStatusModalStatus(state, action) {
      state.admin.UsersStatus = action.payload;
    },
    setVoucherSummaryModalStatus(state, action) {
      state.collection.VoucherSummary = action.payload;
    },
    setVoucherNumber(state, action) {
      state.collection.CurrentVoucherNo = action.payload;
    },
    setCollectionAccountDetails(state, action) {
      state.collection.accountDetails = action.payload;
    },
    setcollVoucherAccModStatus(state, action) {
      state.collection.accountMaster = action.payload;
    },
    setConfirmSaveVoucher(state, action) {
      state.collection.confirmSave = action.payload;
    },
    setCollectionVoucherPrintModalStatus(state, action) {
      state.collection.print = action.payload;
    },
    setPartyLedgerModalStatus(state, action) {
      state.collection.partyLedger = action.payload;
    },
    setVocherModalStatus(state, action) {
      state.collection.voucher = action.payload;
    },
    setCollectionReportModalStatus(state, action) {
      state.collection.report = action.payload;
    },
    setStationWiseIssueReportModalStatus(state, action) {
      state.admin.StationWiseIssueReport = action.payload;
    },
    setviewBillModalStatus(state, action) {
      state.saleSummary.viewBill = action.payload;
    },
    setBillingAccountMasterModalStatus(state, action) {
      state.billing.billingAccountMasterModal = action.payload;
    },
    setShareImageModalStatus(state, action) {
      state.billing.shareBillImage = action.payload;
    },
    setConfirmPrintBillModalStatus(state, action) {
      state.billing.confirmPrintBill = action.payload;
    },
    setConfirmSaveBillstatus(state, action) {
      state.billing.confirmSaveBill = action.payload;
    },
    setAccountMasterDAtagridStatus(state, action) {
      state.masters.AccountMasterDataGrid = action.payload;
    },
    setGuestMasterModalStatus(state, action) {
      state.masters.guestMaster = action.payload;
    },
    setPaymentMode(state, action) {
      state.billing.paymentMode = action.payload;
    },
    setCreateGuestMasterModalStatus(state, action) {
      state.masters.createGuestMaster = action.payload;
    },
    setBillHistryModalStatus(state, action) {
      state.billing.billHistry = action.payload;
    },
    setToast(state, action) {
      state.toast = action.payload;
      // state.toast.status = action.payload.status;
      // state.toast.msg = action.payload.msg;
    },

    setCustomeAlertButtonText(state, action) {
      state.customeAlertMsg.buttonText = action.payload;
    },
    setAlertwithTitleModalStatus(state, action) {
      state.loading.status = false;
      state.alertWithTitel.status = action.payload ? action.payload : false;
    },
    setCheckEmailAlert(state, action) {
      state.checkEmailAlert.status = action.payload;
    },
    setCheckVerifyEmailAlert(state, action) {
      state.checkVerifyEmailAlert.status = action.payload;
    },
    setALertStatus(state, action) {
      state.alert.status = action.payload;
    },
    setCustomeAlertStatus(state, action) {
      state.customeAlertMsg.status = action.payload;
    },
    setAlertMsg(state, action) {
      state.alert.msg = action.payload;
    },
    setCustomeAlertMsg(state, action) {
      state.customeAlertMsg.msg = action.payload;
    },
    setCustomeAlertTitle(state, action) {
      state.customeAlertMsg.title = action.payload;
    },

    setSessionExpired(state, action) {
      state.isSessionExpired = action.payload;
    },
    setNoIssueAlert(state, action) {
      state.isTodayIssue = action.payload;
    },
    setEditItemDetails(state, action) {
      state.billing.editItemDetials = action.payload;
    },
    setAlertOkStatus(state, action) {
      state.alert.okStatus = action.payload;
    },
    setCustomeTwoButtomAlert(state, action) {
      state.customeTwoButtonAlert.status = action.payload;
    },
    setBillSattalmentModalStatus(state, action) {
      state.billing.billSattalment = action.payload;
    },
    setGuestCreateModalStatus(state, action) {
      state.billing.GuestCreate = action.payload;
    },
    resetModalReduxState(state, action) {
      state = intailStateModal;
    },
  },
});

export default Modals.reducer;

export const {
  setCustomeAlertButtonText,
  setDayEndModalStatus,
  setAlertWithTitle,
  setIssueStockItem,
  setNoIssueAlert,
  setSectionMasterStatus,
  setAlertwithTitleModalStatus,
  setSectionMasterHelpStatus,
  setGetOtpModal,
  setnumericKeyboardStatus,
  setItemMasterModalStatus,
  setExpensePaymode,
  setEmailVerificationStatus,
  setLoginApprovelModalStatus,
  setModCompanyHelpStatus,
  setExpVoucPrintModalStatus,
  setSpinnerLoading,
  setSessionExpired,
  setSection_MasterStatus,
  setItemVarientsList,
  setStation_MasterStatus,
  setModEmployeeMasterHelpStatus,
  setSaveType,
  setGeniusKeyModStatus,
  setnumerickeyboardtext,
  setQuantityEditStatus,
  setnumericKeyboardType,
  setDepartmentMasterHelpStatus,
  setModListStationMasterStatus,
  setTotalExpense,
  setDepartmentMasterStatus,
  setLocationMarkers,
  setPartyRateMasterStatus,
  setModPartyMasterHelpStatus,
  setExpenseVoucherModalStatus,
  setListStationMasterModalStatus,
  setExpenseMasterModalStatus,
  setUserStatusModalStatus,
  setVoucherSummaryModalStatus,
  setVoucherNumber,
  setItemMasterHelpStatus,
  setItemVarientsStatus,
  setCollectionAccountDetails,
  setcollVoucherAccModStatus,
  setConfirmSaveVoucher,
  setCollectionVoucherPrintModalStatus,
  setCollectionReportModalStatus,
  setPartyLedgerModalStatus,
  setVocherModalStatus,
  setStationWiseIssueReportModalStatus,
  setBillingAccountMasterModalStatus,
  setConfirmPrintBillModalStatus,
  setGuestCreateModalStatus,
  setBillSattalmentModalStatus,
  setCustomeTwoButtomAlert,
  setConfirmSaveBillstatus,
  setGuestMasterModalStatus,
  setCreateGuestMasterModalStatus,
  setloading,
  setLoginSuccess,
  setviewBillModalStatus,
  setAccountMasterDAtagridStatus,
  setEditItemDetails,
  setALertStatus,
  setAlertMsg,
  setPaymentMode,
  setLoginRejected,
  setAlertOkStatus,
  setShareImageModalStatus,
  setCheckEmailAlert,
  setBillHistryModalStatus,
  setCheckVerifyEmailAlert,
  setCustomeAlertMsg,
  setCustomeAlertStatus,
  setCustomeAlertTitle,
  setItemGroupMasterModalStatus,
  setCreateItemGroupModalStatus,
  setToast,
  setLoginRequestsModalStatus,
  resetModalReduxState,
} = Modals.actions;

export const selectCheckEmailStatus = (state) =>
  state.modals.checkEmailAlert.status;
export const selectItemVarientsList = (state) => state.modals.itemVarientsList;
export const selectEmailVerifyStatus = (state) =>
  state.modals.emailVerification;
export const selectCheckVerifyEmailStatus = (state) =>
  state.modals.checkVerifyEmailAlert.status;
export const SelectAlertStatus = (state) => state.modals.alert.status;
export const selectAlertMsg = (state) => state.modals.alert.msg;
export const selectAlertOkStatus = (state) => state.modals.alert.okStatus;

export const selectCustomeTwoButtomAlert = (state) =>
  state.modals.customeTwoButtonAlert.status;

export const SelectIsExpired = (state) => state.modals.isSessionExpired;

export const slectConfirmSaveBillStatus = (state) =>
  state.modals.billing.confirmSaveBill;
export const selectPrintModalStatus = (state) =>
  state.modals.billing.confirmPrintBill;

export const selectShareImageModalStatus = (state) =>
  state.modals.billing.shareBillImage;
export const selectBillingAccountMasterModalStatus = (state) =>
  state.modals.billing.billingAccountMasterModal;

export const selectSaleviewBillModalStatus = (state) =>
  state.modals.saleSummary.viewBill;
export const selectAdminStationWiseIssueModalsStatus = (state) =>
  state.modals.admin.StationWiseIssueReport;

// collection

export const selectCollectionAccountDetails = (state) =>
  state.modals.collection.accountDetails;

export const selectItemMasterModalStatus = (state) =>
  state.modals.masters.ItemMaster;
export const selectGetOtpModal = (state) => state.modals.getOtp;
export const selectAlertWithTitleDetails = (state) =>
  state.modals.alertWithTitel;
export const selectAlertWithTitleModalStatus = (state) =>
  state.modals.alertWithTitel.status;

export const selectSpinnerLoading = (state) =>
  state.modals.spinnerLoading.status;
export const selectSpinnerText = (state) => state.modals.spinnerLoading.text;

export const selectNumerickeyboardStatus = (state) =>
  state.modals.numercKeyBoradStatus;
export const selectNumerickeyboardtext = (state) =>
  state.modals.numericKeyboardText;
export const selectNumerickeyboardType = (state) =>
  state.modals.numericKeyBoardType;
export const selectIssueItem = (state) => state.modals.issueStockItem;
export const selectGeniusKeyboardStaus = (state) => state.modals.geniusKeyboard;
export const selectItemVarientsStatus = (state) =>
  state.modals.itemVarientsStatus;
