import React, { useRef } from 'react';
import { useState } from 'react';

import CryptoJS from 'crypto-js';
import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompanyAddress, selectEmail, selectPrefix, selectUserType } from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import { setAlertWithTitle, setSpinnerLoading } from '../utility/Redux/modal';
import { useNavigate } from 'react-router-dom';
import CallAPI from '../utility/functions/getData';
import GeniustextInput from '../utility/component/mastertext';
import ModalHeaderStyle from '../utility/component/styles/modalHeader';
import { DeleteButton, ModifyButton, SaveButton, SignOut, UndoButton } from '../utility/component/buttons';
import ModUserHelp from '../help/userhelp';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import { toProperCase } from '../utility/component/format';
import CompanyHelp from '../help/companyHelp';


const UserMaster = () => {
  const [selectedUserType, setSelectedUserType] = useState('USER');
  const [companyInfo, setCompanyInfo] = useState('');
 
  const [userInfoStatus, setUserInfoStatus] = useState(false);
  const [selectedUserStatus, setSelectedUserStatus] = useState('ACTIVE');
  const [userCode, setUserCode] = useState('');
  const headerRef = useRef();
  const [companyHelpStatus,setCompanyHelpStatus] = useState(false)
 const prefix = useSelector(selectPrefix)
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
const resetUserDetails ={
  code:"",

  "company_email": "", 
  "company_website":"",
  "company_name": "",
  "company_mobile":"",
  "address":"",
  "city":"",
  "state":"",
  "pin_code":"",
  "user_email": "",
  "user_name": "",
  "user_type": selectedUserType,
  "user_mobile":"",
  "user_status":selectedUserStatus,
  "shop_id":"",
  "shop_name":"",
  "currency_name":"",
  "disputes":"Jalandhar",
  "regd_no1":"1",
  "regd_no2":"2",
  "regd_no3":"3",

  "user_password": "",
  "currency_symbol":"",
  "company_logo":"",
  "activate_code":"",
  "table_prefix":prefix,
}
  const [userDetails, setUserDetails] = useState(resetUserDetails);


  const [confirmPaswrd, setConfirmPaswrd] = useState('');
  const userEmail = useSelector(selectEmail);
  const [isUSersLimitComplete, setIsUserLimitComplete] = useState(false);

  const companyDetail = useSelector(selectCompanyAddress);
  const dispatch = useDispatch();
  const userType = useSelector(selectUserType);
  const CompanyUserInfo = () => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
    const email = companyDetail.email;
    const hmac = CryptoJS.HmacSHA256(email, secretPass);

    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    const data = {
      company_email: companyDetail.email,
      user_email: phoneRegex.test(userEmail) ? '' : userEmail,
      user_mobile: phoneRegex.test(userEmail) ? userEmail : '',
      company_mobile:""
    };
    axios
      .post(`/api/users/userCompanyInfo`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${hmacString}`,
        },
      })
      .then(async function (response) {
        console.log(response)
        setCompanyInfo(response.data[0]);
        if (response.data[0].no_of_users - response.data[0].TOTAL_USERS > 0) {
          setIsUserLimitComplete(false);
        } else {
          setIsUserLimitComplete(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
  const token = useSelector(selectToken);
  
  const saveUser = () => {
    if (!isUSersLimitComplete || userCode) {
      if (
        (userDetails.user_mobile && phoneRegex.test(userDetails.user_mobile)) ||
        !userDetails.user_mobile
      ) {
        if (
          userDetails.user_email  && userDetails.user_name 
        ) {
          if (userDetails.user_password === confirmPaswrd) {
            if (
              userDetails.user_password
                ? userDetails.user_password.length > 5
                : userDetails.code
            ) {
              const email = userDetails.user_email;
              // const hmac = CryptoJS.HmacSHA256(message, secret);
              const hmac = CryptoJS.HmacSHA256(email, secretPass);
              // Convert the HMAC to a string and encode it using Base64
              const hmacString = hmac.toString(CryptoJS.enc.Base64);
              const companyName = companyInfo.company_name;
              const words = companyName.split(' ');
              const p =
                words.reduce((acc, word) => {
                  if (word) {
                    acc += word[0];
                  }
                  return acc;
                }, '') + '_';

              const data = {
                ...userDetails, 
                start_date:companyInfo.end_date,
                activate_code:companyInfo.activate_code,
                end_date:companyInfo.end_date,
                company_email: companyInfo.company_email,
                company_website: companyInfo.company_website,
                company_name: companyInfo.company_name,
                company_mobile: companyInfo.company_mobile,
                address: companyInfo.address,
                city: companyInfo.city,
                state: companyInfo.state,
                pin_code: companyInfo.pin_code,
                currency_name: companyInfo.currency_name,
                currency_symbol: companyInfo.currency_symbol,
                regd_no1: companyInfo.regd_no1,
                regd_no2: companyInfo.regd_no2,
                regd_no3: companyInfo.regd_no3,
                disputes: companyInfo.disputes,
                company_logo: '',
                table_prefix: prefix,
              };
              dispatch(setSpinnerLoading(userDetails.code ? 'Updating': 'Creating'));

              axios
                .post(`/api/users/createNewUser`, data, {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${hmacString}`,
                  },
                })
                .then(async function async(response) {
                  if (response.status === 200) {
                    if (userDetails.user_email || userCode) {
                      dispatch(
                        setAlertWithTitle({
                          title: 'Success',
                          msg: userCode
                            ? 'User Modified'
                            : 'User created check email for verify account.',
                        })
                      );
                      undo();
                    }
                    if (
                      !userDetails.user_email &&
                      userDetails.user_mobile &&
                      !userCode
                    ) {
                      emailVerified(
                        userDetails.user_mobile,
                        companyInfo.activate_code,
                        userDetails.user_name
                      );
                    }
                  }
                })
                .catch(function (error) {
                  console.log(error);
                  dispatch(setSpinnerLoading(false));
                });

            } else {
              dispatch(
                setAlertWithTitle({
                  title: 'Password Error',
                  msg: 'Password length must be more then 5 digits.',
                })
              );
            }
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error ',
                msg: 'Password and Confirm Password must be same.',
              })
            );
          }
        } else {
          if(!userDetails.user_name){
            dispatch(
              setAlertWithTitle({
              title: 'Alert',
              msg: 'Please enter User Name',
               }))
               const inputFields = formRef?.current?.querySelectorAll("input, select");
               inputFields.forEach((element) => {
                 if (element.id === "user_name") {
                   element.focus();
                 }
               });
          }else{
            if(!userDetails.user_email || userDetails.user_mobile){
              dispatch(
                setAlertWithTitle({
                title: 'Alert',
                msg: 'Please enter Email or Mobile.',
                 }))
                 const inputFields = formRef?.current?.querySelectorAll("input, select");
                 inputFields.forEach((element) => {
                   if (element.id === "user_email") {
                     element.focus();
                   }
                 });
            }
          }
        
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: 'Invalid mobile',
          })
        );
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Users limit crossed',
        })
      );
    }
  };

  const undo = () => {
    setUserDetails({
      code:"",

      "company_email": "", 
      "company_website":"",
      "company_name": "",
      "company_mobile":"",
      "address":"",
      "city":"",
      "state":"",
      "pin_code":"",
      "user_email": "",
      "user_name": "",
      "user_type": selectedUserType,
      "user_mobile":"",
      "user_status":selectedUserStatus,
      "shop_id":"",
      "shop_name":"",
      "currency_name":"",
      "disputes":"Jalandhar",
      "regd_no1":"1",
      "regd_no2":"2",
      "regd_no3":"3",
  
      "user_password": "",
      "currency_symbol":"",
      "company_logo":"",
      "activate_code":"",
      "table_prefix":prefix,
    });
    setConfirmPaswrd('');
    setUserCode('');
    setSelectedUserType('USER');
    setSelectedUserStatus('ACTIVE');
    dispatch(setSpinnerLoading(false));
  };

  useEffect(() => {
    CompanyUserInfo();
  }, []);

  const emailVerified = (mobile, activationCode, userName) => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(mobile, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    const data = {
      user_email: '',
      user_mobile: mobile,
    };
    dispatch(setSpinnerLoading('Verify User'));
    axios
      .post('/api/users/emailVerified', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${hmacString}`,
        },
      })
      .then((res) => {
        if (!res.data.error) {
          sendEmailToAdmin(hmacString, mobile, activationCode, userName);
        } else {
          dispatch(setSpinnerLoading(false));
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Something went wrong',
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(setSpinnerLoading(false));
      });
  };

  const sendEmailToAdmin = (hmacString, mobile, activationCode, userName) => {
    const data = {
      user_email: '',
      user_name: userName,
      activate_code: activationCode,
      user_mobile: mobile,
    };
    dispatch(setSpinnerLoading('Sending Email to admin'));

    axios
      .post('/api/users/sendEmailToAdmin', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${hmacString}`,
        },
      })
      .then((res) => {
        dispatch(
          setAlertWithTitle({
            title: 'Success',
            msg: 'User created Successfully.',
            lottie: 'success',
          })
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        undo();
        dispatch(setSpinnerLoading(false));
      });
  };

  const phoneRegex = /^[6-9]\d{9}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const navigate = useNavigate();

  const CheckUserInfo = () => {
    const userEmail = emailRegex.test(userDetails.user_email)
      ? userDetails.user_email
      : '';
    const userMobile = phoneRegex.test(userDetails.user_mobile)
      ? userDetails.user_mobile
      : '';

    const user = userEmail ? userEmail : userMobile;
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(user, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    const data = {
      user_email: userEmail ? userEmail : '',
      user_mobile: !userEmail ? userMobile : '',
    };
    axios
      .post(`/api/users/userInfo`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${hmacString}`,
        },
      })
      .then(async function (response) {
        if (response.data.response[0]) {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'User allready registered with same id',
            })
          );
          setUserDetails({
            ...userDetails,
            user_mobile: '',
            user_email: '',
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!userCode) {
      if (userDetails.user_mobile || userDetails.user_email) {
        CheckUserInfo();
      }
    }
  }, [userDetails.user_mobile, userDetails.user_email]);

  const UserTypeOption =
    userType === 'ADMIN'
      ? [
          {
            name: 'USER',
            value: 'USER',
          },
          {
            name: 'STORE',
            value: 'STORE',
          },
          {
            name: 'MANAGER',
            value: 'MANAGER',
          },
          {
            name: 'ADMIN',
            value: 'ADMIN',
          },
   
        ]
      : [
          {
            name: 'USER',
            value: 'USER',
          },
          {
            name: 'STORE',
            value: 'STORE',
          },
          {
            name: 'MANAGER',
            value: 'MANAGER',
          },
     
        ];
         const deleteUser = () => {
         dispatch(setSpinnerLoading('Deleting'));
         CallAPI(
         '/api/users/deleteUser',
         {
          company_email: companyInfo.company_email,
        user_email: userDetails.user_email,
        user_mobile: userDetails.user_email ? '' : userDetails.user_mobile,
      },
      token,
      () => {
        dispatch(
          setAlertWithTitle({
            title: 'Success',
            msg: 'User deleted successfully',
          })
        );
        undo();
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
         };

const formRef  = useRef(null)


  return (
    <div ref={formRef} className="w-full flex justify-center  bg-gray-50 h-full  p-3">
      <div className="text-sm  rounded-sm  h-full overflow-hidden">
        <div ref={headerRef} className="bg-white   ">
          <ModalHeaderStyle
            header={'User Master'}
            onClose={() => navigate('/settings')}
          />
        </div>
        <div
          style={{
            height: headerRef.current
              ? `calc(100% - ${headerRef.current.clientHeight}px)`
              : '90%',
          }}
          className="      flex flex-col "
        >
          <div className="overflow-y-auto py-3 px- p-3  bg-white   pr-3">
            {userType === 'ADMIN' && (
              <div className="flex ">
                <GeniustextInput
                  type={'text'}
                  titleBold={true}
                  titleColor={'#383535'}
                  title={'Comapny Name'}
                  search={()=>{
                    setCompanyHelpStatus(true)
                  }}
                  width={'270px'}
                  value={companyInfo.company_name}
                  error={false}
                />
              </div>
            )}{' '}
            <div className="grid grid-cols-1 gap-2 ll:grid-cols-2">
              <div className="flex  ">
                <GeniustextInput
                  search={() => {
                    setUserInfoStatus(true);
                  }}
                  id={'user_name'}
                  value={userDetails.user_name}
                  onChange={(t) => {
                    setUserDetails({
                      ...userDetails,
                      user_name: t.target.value,
                    });
                  }}
                  type={'text'}
                  title={'User Name'}
                  width={'270px'}
                  error={false}
                />
              </div>

              <div className="flex  ">
                <GeniustextInput
                  width={'270px'}
                  type={'text'}
                  id={'user_email'}
                  title={'Email'}
                  value={userDetails.user_email}
                  onChange={(t) => {
                    setUserDetails({
                      ...userDetails,
                      user_email: t.target.value,
                    });
                  }}
                  error={
                    userDetails.user_email
                      ? emailRegex.test(userDetails.user_email)
                        ? false
                        : 'Invalid email'
                      : false
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-2 ll:grid-cols-2">
              <div className="flex">
                <GeniustextInput
                  type={'number'}
                  width={'270px'}
                  title={'Mobile'}
                  value={userDetails.user_mobile}
                  onChange={(t) => {
                    setUserDetails({
                      ...userDetails,
                      user_mobile: t.target.value,
                    });
                  }}
                  error={
                    userDetails.user_mobile
                      ? phoneRegex.test(userDetails.user_mobile)
                        ? false
                        : 'Invalid mobile'
                      : false
                  }
                />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-2 ll:grid-cols-2">
              <div className="flex">
                <GeniustextInput
                  type={'test'}
                  width={'270px'}
                  title={'Shop Id'}
                  value={userDetails.shop_id}
                  onChange={(t) => {
                    setUserDetails({
                      ...userDetails,
                      shop_id: t.target.value,
                    });
                  }}
              
                />
               
              </div>
              <div className=''>
                <GeniustextInput
                  type={'text'}
                  width={'270px'}
                  title={'Shop Name'}
                  value={userDetails.shop_name}
                  onChange={(t) => {
                    setUserDetails({
                      ...userDetails,
                      shop_name: toProperCase(t.target.value) ,
                    });
                  }}
                 
                />

                </div>
            </div>
            <div className="grid grid-cols-1 gap-2 ll:grid-cols-2">
              {!userCode ? (
                <>
                  <div className="flex">
                    <GeniustextInput
                      type={'password'}
                      width={'270px'}
                      title={'Password'}
                      value={userDetails.user_password}
                      error={
                        userDetails.user_password
                          ? userDetails.user_password.length > 5
                            ? false
                            : 'Passwords must be at least 6 characters.'
                          : false
                      }
                      onChange={(t) => {
                        setUserDetails({
                          ...userDetails,
                          user_password: t.target.value,
                        });
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="flex">
                    <GeniustextInput
                      type={'password'}
                      width={'270px'}
                      title={'Password'}
                      onFocus={(e) => {
                        e.currentTarget.select();
                      }}
                      value={userDetails.user_password}
                      onChange={(t) => {
                        setUserDetails({
                          ...userDetails,
                          user_password: t.target.value,
                        });
                      }}
                      error={false}
                    />
                  </div>
                </>
              )}
              {!userCode ? (
                <>
                  <div className="flex">
                    <GeniustextInput
                      width={'270px'}
                      type={'password'}
                      value={confirmPaswrd}
                      onChange={(t) => {
                        setConfirmPaswrd(t.target.value);
                      }}
                      title={'Confirm Password'}
                      error={
                        confirmPaswrd
                          ? userDetails.user_password === confirmPaswrd
                            ? false
                            : 'Passwords must be same.'
                          : false
                      }
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className=" flex ">
                    <GeniustextInput
                      width={'270px'}
                      type={'password'}
                      onFocus={(e) => {
                        e.currentTarget.select();
                      }}
                      onChange={(t) => {
                        setConfirmPaswrd(t.target.value);
                      }}
                      title={'Confirm Password'}
                      error={
                        confirmPaswrd
                          ? confirmPaswrd !== userDetails.user_password
                            ? 'Password must be same.'
                            : false
                          : false
                      }
                    />
                  </div>
                </>
              )}
            </div>
   
            <div className="mt-2 flex justify-between ">
              {' '}
              <div className="flex">
                <GeniustextInput
                  option={UserTypeOption}
                  width={'100px'}
                  value={selectedUserType}
                  onChange={(e) => {
                    setSelectedUserType(e.target.value);
                    setUserDetails({
                      ...userDetails,
                      user_type: e.target.value,
                    });
                  }}
                  title={'User Type'}
                />
                <div className="ml-2">
                  <GeniustextInput
                    option={[
                      {
                        name: 'ACTIVE',
                      },
                      {
                        name: 'PENDING',
                      },
                    ]}
                    onChange={(e) => {
                      setSelectedUserStatus(e.target.value);
                      setUserDetails({
                        ...userDetails,
                        user_status: e.target.value,
                      });
                    }}
                    value={selectedUserStatus}
                    title={'User Status'}
                    width={'100px'}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-end p-3 rounded-b-sm bg-white">
          
            {userCode && userType === 'ADMIN' && (
              <div className="mr-1">
                {' '}
                <DeleteButton
                  onClick={() => {
                    setAlertTwoButtonModal({
                      ...alertTwoButtonModal,
                      status: true,
                      title: 'Alert',
                      msg: 'Do you really want to delete this user?',
                      button1: 'Yes',
                      button2: 'Cancel',

                      button1Click: () => {
                        deleteUser();
                        setAlertTwoButtonModal({
                          ...alertTwoButtonModal,
                          status: false,
                        });
                      },
                      button2Click: () => {
                        setAlertTwoButtonModal({
                          ...alertTwoButtonModal,
                          status: false,
                        });
                      },
                    });
                  }}
                />{' '}
              </div>
            )}

{userDetails.code ?  <ModifyButton onClick={saveUser} /> : <SaveButton onClick={saveUser} /> }
           

            <UndoButton onClick={undo} />
          </div>
        </div>

        <ModUserHelp
          onRowClick={(params) => {
            setUserInfoStatus(false);

            setUserDetails( e =>{
              return {
                ...e,
                user_email: params.row.USER_EMAIL,
                user_password: '',
                user_name: params.row.USER_NAME,
                user_mobile: params.row.USER_MOBILE,
                user_type: params.row.USER_TYPE,
                user_status: params.row.USER_STATUS,
                company_name: params.row.COMPANY_NAME,
                shop_id:params.row.SHOP_ID,
                shop_name:params.row.SHOP_NAME,
                code:params.row.CODE
              }
            
           
            });

            setUserCode(params.row.CODE);
            setSelectedUserStatus(params.row.USER_STATUS);
            setSelectedUserType(params.row.USER_TYPE);
          }}
          comapany={{
            companyEmail: companyInfo.company_email,
            userEmail: companyInfo.userEmail,
          }}
          onClose={() => {
            setUserInfoStatus(false);
          }}
          status={userInfoStatus}
          setStatus={setUserInfoStatus}
        />
 
 <CompanyHelp  status={companyHelpStatus} onRowClick={(p) =>{
setCompanyInfo(p.row)
setUserDetails(resetUserDetails)
setCompanyHelpStatus(false)
 }} onClose={()=>setCompanyHelpStatus(false)}/>
      </div>
    
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
};

export default UserMaster;
