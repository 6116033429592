import React from "react";
import { FormatNumber2 } from "../../utility/functions/formatNumber";
import { FontSizeWidth } from "../../utility/constant";
import { useSelector } from "react-redux";
import { selectWidth } from "../../utility/Redux/profile";
const valueInput  = (v)=>{
  if(v > 0 ){
    return  FormatNumber2(v)
  }else{
    return ""
  }
}
const Footer = ({ columns, rows }) => {
  const width = useSelector(selectWidth)
  return (
    <div className='flex  w-full text-sm mt-1'>
      <div className="flex">
        {columns.map((e, i) => {
          return (
            <div
            key={i}
              style={{
                width: e.width,
                borderLeft: i === 0 ? "1px solid black" : "1px solid black",
                borderRight:
                  i === columns.length - 1
                    ? "1px solid black"
                    : "0px solid black",
                borderBottom: "1px solid black",
                borderTop: "1px solid black",
                fontSize:FontSizeWidth(width)
              }}
              className="p-1 text-right "
            >
              {e.field === "NAME" && "Total"}

              {e.field === "TOTAL" &&
                valueInput(
                  rows.reduce((acc, row) => acc + Number(row.TOTAL), 0)
                )}
              {e.field === "DISQ_AMT" &&
                valueInput(
                  rows.reduce((acc, row) => acc + Number(row.DISQ_AMT), 0)
                )}

              {e.field === "TAX1_AMT" &&
                valueInput(
                  rows.reduce((acc, row) => acc + Number(row.TAX1_AMT), 0)
                )}

              {e.field === "TAX2_AMT" &&
                valueInput(
                  rows.reduce((acc, row) => acc + Number(row.TAX2_AMT), 0)
                )}

              {e.field === "BAL_STOCK" &&
                valueInput(
                  rows.reduce((acc, row) => acc + Number(row.BAL_STOCK), 0)
                )}
                    {e.field === "NET_AMOUNT" &&
                valueInput(
                  rows.reduce((acc, row) => acc + Number(row.NET_AMOUNT), 0)
                )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Footer;
