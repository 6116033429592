import { Html5Qrcode } from "html5-qrcode";

 const IsCameraAvailval = () => {
    return new Promise((resolve, reject) => {
        Html5Qrcode.getCameras()
            .then((devices) => {
                if (devices && devices.length > 0) {
                    resolve(devices); // Resolving with the list of devices
                } else {
                    reject('No cameras found'); // Rejecting with an error message
                }
            })
            .catch((err) => {
                reject('Error fetching cameras: ' + err); // Catching and passing the error message
            });
    });
};

export default IsCameraAvailval