import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import securityReducer from "./security";
import rootSaga from "./saga/sagas";
import modal from "./modal";
import refresh from "./refresh";
import profile from "./profile";
import list from "./list";
import masters from "./masters";
const sagaMiddleware = createSagaMiddleware();
const Store = configureStore({
  reducer: {
    security: securityReducer,
    modals: modal,
    refresh: refresh,
    masters: masters,
    profile: profile,
    list: list,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default Store;
