import { useDispatch, useSelector } from 'react-redux';
import {
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import { useState } from 'react';
import { selectToken } from '../utility/Redux/security';
import axios from 'axios';
import { useEffect } from 'react';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';
import MasterHelp from '../utility/component/modals/masterHelp';
import moment from 'moment';



const PaymentVoucherHelp = ({ onRowClick ,status,onClose,setItemMasterHelpStatus}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const [rows, setRows] = useState([]);
  const token = useSelector(selectToken);

  const prefix = useSelector(selectPrefix);

  const windowWidth = useSelector(selectWidth);
  const columns = [
    {
        width:
          windowWidth <= 400
            ? 70
            : windowWidth <= 940
            ? 100
            : windowWidth <= 1280
            ? 90
            : 120,
        headerAlign: 'center',
        align: 'left',
        field: 'DATE',
        renderCell:(p) =>{
            return moment(p.value).format('DD-MM-YYYY')
        },
        renderHeader: (params) => <div>Date</div>,
      },
    {
      width:
        windowWidth <= 400
          ? 70
          : windowWidth <= 940
          ? 70
          : windowWidth <= 1280
          ? 70
          : 70,
      headerAlign: 'center',
      align: 'left',
      field: 'VOUCHER_NO',
      renderHeader: (params) => <div>Voucher</div>,
    },
    {
        width: windowWidth <= 940 ? 70 : windowWidth <= 1280 ? 100 : 190,
        headerAlign: 'center',
        align: 'left',
        field: 'CUSTOMER_NAME',
        renderCell: (params) => <div>{params.value !== 0 && params.value}</div>,
        renderHeader: (params) => <div>Customer </div>,
      },
      {
          width:
          windowWidth <= 400
          ? 50
          : windowWidth <= 940
          ? 130
          : windowWidth <= 1280
          ? 110
          : 300,
      
          headerAlign: 'center',
          align: 'left',
          field: 'NAME',
          renderHeader: (params) => <div>Party Name</div>,
        },
    {
      width:
      windowWidth <= 400
      ? 70
      : windowWidth <= 940
      ? 150
      : windowWidth <= 1280
      ? 80
      : 150,
      headerAlign: 'center',
      align: 'right',
      field: 'DEBIT',
      renderCell: (params) => <div> {params.value !== 0 ? params.value :""} </div>,
      renderHeader: (params) => <div>Debit</div>,
    },
    {
      width:
        windowWidth <= 400
          ? 70
          : windowWidth <= 940
          ? 110
          : windowWidth <= 1280
          ? 80
          : 150,
      headerAlign: 'center',
      align: 'right',
      renderCell: (params) => <div> {params.value !== 0 ? params.value :""} </div>,
      field: 'CREDIT',
      renderHeader: (params) => <div>Credit</div>,
    },




  ];
  const mobileColumns = [
    {
        width:60,
        headerAlign: 'center',
        align: 'left',
        field: 'DATE',
        renderCell:(p) =>{
            return moment(p.value).format('DD-MM-YYYY')
        },
        renderHeader: (params) => <div>Date</div>,
      },
    {
      width:60,
      headerAlign: 'center',
      align: 'left',
      field: 'VOUCHER_NO',
      renderHeader: (params) => <div>Voucher</div>,
    },
 
      {
        width:60,
          headerAlign: 'center',
          align: 'left',
          field: 'NAME',
          renderHeader: (params) => <div>Party </div>,
        },
    {
      width:60,
      headerAlign: 'center',
      align: 'right',
      field: 'DEBIT',
      renderCell: (params) => <div> {params.value !== 0 ? params.value :""} </div>,
      renderHeader: (params) => <div>Debit</div>,
    },
    {
      width:60,
      headerAlign: 'center',
      align: 'right',
      renderCell: (params) => <div> {params.value !== 0 ? params.value :""} </div>,
      field: 'CREDIT',
      renderHeader: (params) => <div>Credit</div>,
    },




  ];
  const [searchQuery, setSearchQuery] = useState('');
  const [fullList, setFullList] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.INVNO.toString().includes(searchQuery);
        } else {
          return item.CUSTOMER_NAME.toLowerCase().includes(
            searchQuery.toLowerCase()
          );
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  const dispatch = useDispatch();

  const getData = async () => {
    dispatch(setSpinnerLoading('Loading bill'));
    const data  = {
        table_prefix: prefix,

    }
    await axios
      .post(
        '/api/voucher/listVoucher',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        if(!p.data.error){
          let list = [];
          const data = p.data.response

          for (let i = 0; i < data.length; i++) {
            list.push({
              ...data[i],
              id:i+1
            });
          }
          setFullList(list);
          setRows(list);
      }
    
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status]);



  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            onClose();
            setRows([]);
            setSearchQuery('')

          }}
          title={'Payment Voucher | Help'}
          columns={windowWidth<670?mobileColumns: columns}
          list={rows}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRowClick={(p) => {
            onRowClick(p);
            setRows([]);
          }}
        />
      </div>
    </>
  );
};

export default PaymentVoucherHelp;
