import React from "react";
import { FormatNumber2 } from "../../utility/functions/formatNumber";
import { useSelector } from "react-redux";
import { selectWidth } from "../../utility/Redux/profile";
import { balanceType } from "../../utility/constant";

const Footer = ({ columns, rows }) => {
  const width = useSelector(selectWidth);
  return (
    <div className="flex  w-full mt-1">
      <div className="flex">
        {columns.map((e, i) => {
          return (
            <div
              key={i}
              style={{
                fontSize: width < 500 ? "11px" : width < 1281 ? "12px" : "14px",
                width: e.width,
                borderLeft: i === 0 ? "1px solid black" : "1px solid black",
                borderRight:
                  i === columns.length - 1
                    ? "1px solid black"
                    : "0px solid black",
                borderBottom: "1px solid black",
                borderTop: "1px solid black",
              }}
              className="p-1 text-right "
            >
              {e.field === "DATE" && "Total"}
              {e.field === "DEBIT" &&
                rows.reduce((acc, row) => acc + Number(row.DEBIT), 0) > 0 &&
                FormatNumber2(
                  rows.reduce((acc, row) => acc + Number(row.DEBIT), 0)
                )}
              {e.field === "CREDIT" &&
                rows.reduce((acc, row) => acc + Number(row.CREDIT), 0) > 0 &&
                FormatNumber2(
                  rows.reduce((acc, row) => acc + Number(row.CREDIT), 0)
                )}
              {e.field === "BALANCE" && (
                <div className="pr-4">
                  {balanceType(rows[rows.length - 1].BALANCE)}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Footer;
