import { Modal } from "@mui/material";
import React, { useEffect } from "react";
import { ActionButtons, billSettelemntBilling } from "./common";
import { useDispatch, useSelector } from "react-redux";
import { selectPrefix, selectWidth } from "../utility/Redux/profile";
import { selectToken } from "../utility/Redux/security";
import { toProperCase } from "../utility/component/format";
import { setSpinnerLoading } from "../utility/Redux/modal";
import { useNavigate } from "react-router-dom";

const OnlyBillModal = ({
  status,
  roundOffAmt,
  setIsOriginalBill,
  Undo,
  emptyBillingDetails,
  emptyCustomer,
  onClose,
  billingDetails,
  latitude,
  longitude,
  SaleType,
  setViewBillStatus,
  setAlertTwoButtonModal,
  setBillNo,
  billCartList,
  setListAccountMasterHelp,
  remainingTotal,
  setCustomermasetrHelp,
  grandTotalWithTax,
  fromref,
  inputFields,
  setBillingDetails,
  setCustomerList,
  setCustomerDetails,
  customerList,
  TotalAmount,
  customerDetails,
  columns,
  setisTextActive,
  EditCell,
  setBillCartList,
  profileSetting,
  setLastCustomerDiscountrate,
  totalDiscount,
  lastCustomerDiscountrate,
  isCrossOrder,
}) => {
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const width = useSelector(selectWidth);
  const ClearBilling = () => {
    setCustomerDetails(emptyCustomer);
    setBillingDetails(emptyBillingDetails);
  };
  const styleMobile = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
    boxShadow: 24,
  };
  const navigate = useNavigate();
  const hideHeader = true;

  return (
    <Modal open={status}>
      <div style={styleMobile} className="   rounded-sm overflow-hidden ">
        <div className="w-full flex justify-between bg-brown text-white  py-1 px-2  ">
          <div>Bill Settlement</div>
          <div>
            <button onClick={onClose}>
              <i className="bi text-red-400 text-[20px] bi-x-square-fill"></i>
            </button>
          </div>
        </div>
        <div className=" p-2 bg-white">
          {billSettelemntBilling(
            fromref,
            columns,
            setisTextActive,
            customerDetails,
            TotalAmount,
            setCustomerDetails,
            customerList,
            prefix,
            token,
            setCustomerList,
            dispatch,
            setBillingDetails,
            inputFields,
            setCustomermasetrHelp,
            grandTotalWithTax,
            width,
            billingDetails,
            setListAccountMasterHelp,
            remainingTotal,
            hideHeader
          )}
        </div>
        <div className="flex justify-end p-2 bg-white">
          {ActionButtons(
            billCartList,
            billingDetails,
            grandTotalWithTax,
            dispatch,
            prefix,
            token,
            ClearBilling,
            setBillNo,
            setAlertTwoButtonModal,
            setViewBillStatus,
            inputFields,
            customerDetails,
            SaleType,
            onClose,
            longitude,
            latitude,
            Undo,
            setIsOriginalBill,
            roundOffAmt,
            EditCell,
            setBillCartList,
            totalDiscount,
            lastCustomerDiscountrate,
            setBillingDetails,
            profileSetting,
            setLastCustomerDiscountrate,
            isCrossOrder,
            navigate
          )}
        </div>
      </div>
    </Modal>
  );
};

export default OnlyBillModal;
