import React, { useState } from 'react';
import axios from 'axios';

const DemoRegister = () => {
  const [userId, setUserId] = useState('');

  const handleRegister = async () => {
    try {
      // Step 1: Request registration options from the server
      const { data: options } = await axios.get(`http://localhost:4000/api/auth/register-options?userId=${userId}`);

      // Step 2: Initiate WebAuthn Registration
      const credential = await navigator.credentials.create({
        publicKey: options,
      });

      // Convert ArrayBuffers to base64-encoded strings
      const credentialJSON = {
        id: credential.id,
        rawId: bufferToBase64(credential.rawId),
        type: credential.type,
        response: {
          attestationObject: bufferToBase64(credential.response.attestationObject),
          clientDataJSON: bufferToBase64(credential.response.clientDataJSON),
        }
      };

      // Step 3: Send registration result back to the server
      await axios.post('http://localhost:4000/api/auth/register', {
        userId,
        credential: credentialJSON,
      });

      alert('Registration successful');
    } catch (error) {
      console.error(error);
      alert('Registration failed');
    }
  };

  // Utility function to convert ArrayBuffers to base64
  const bufferToBase64 = (buffer) => {
    return btoa(String.fromCharCode(...new Uint8Array(buffer)));
  };

  return (
    <div>
      <h1>Register with WebAuthn</h1>
      <input
        type="text"
        placeholder="Enter your email or user ID"
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
      />
      <button onClick={handleRegister}>Register</button>
    </div>
  );
};

export default DemoRegister;
