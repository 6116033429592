import moment from 'moment';

import React  from 'react';
import { FormatNumber2 } from '../utility/functions/formatNumber';
import { toProperCase } from '../utility/component/format';
const OrderSlip = React.forwardRef((props, ref) => {
  const { orderList} = props;


  return (
    <div
      id={'print-component'}
      className=" w-[320px] h-fit bg-white print-component    print:overflow-visible  "
      ref={ref}
    >
      <div className="w-full h-full">
       
 

    
        <div
          style={{ fontSize: 16 }}
          className="w-full text-sm mt-2 mb-2 font-semibold flex items-center justify-center"
        >

          <div>ORDER SLIP</div>
        </div>

<div className='flex justify-center font-semibold text-[18px] p-2 border-2 border-black'>
  Order No. : 1
</div>

        <table className="w-full">
          <thead
            style={{  borderBottomWidth: '1px' }}
            className="w-full  border-black h-6"
          >
            <tr className="w-full   ">
              <th
                style={{
                  fontSize: 12,
                  borderRightWidth: '1px',
                  width: '30%',
                }}
                className=" whitespace-nowrap  border-black "
              >
                <div className='w-full flex justify-center'>Item Name</div>
              </th>
    
              <th
                style={{
                  fontSize: 12,
                  borderRightWidth: '1px',
                  width: '13%',
                }}
                className="  border-black "
              >
              <div className='w-full flex justify-center'> Qnty</div>
              </th>
   
              <th style={{ fontSize: 12, width: '22%' }}>
              <div className='w-full flex justify-center'> Amount</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {orderList?.map((item, index) => {
              return (
                <tr key={index} style={{
                }}>
                  <td
                    className=" border-black border-dotted text-start "
                    style={{ fontSize: 10, borderRightWidth: '1px' }}
                  >
                      <div className='pl-1'>

                    {toProperCase(item.ITEMNAME)}
                    </div>

                    <div className='text-white h-[2px]'>
                    {' '}

                    </div>
                  </td>
              
                  <td
                    className=" pr-2 border-dotted text-right  border-black "
                    style={{ fontSize: 10, borderRightWidth: '1px' }}
                  >
                    {item.QNTY > 0 && item.QNTY}
                  </td>
             
                  <td className="text-right pr-2 " style={{ fontSize: 10 }}>
                    {FormatNumber2(item.AMOUNT)}
                  </td>
                </tr>
              );
            })}
        
          </tbody>
        </table>
        <div className='border-t border-b pl-1 border-black text-[13px] py-1 '>
Time :        {moment.utc(orderList[0]?.DATE).format('hh:mm A')}
        </div>
      </div>
    </div>
  );
});

export default OrderSlip;
