import React, { useEffect, useRef, useState } from 'react';
import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode';

import './scanner.css';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import { selectWidth } from '../Redux/profile';
import AlertModalTwoButton from './modals/alertWithTwoButton';
import { setAlertWithTitle } from '../Redux/modal';


const QRCodeScanner = ({onDetected,status,setCamera}) => {
  const [devices, setDevices] = useState('');
  const scannerRef = useRef(null);
  const lineRef = useRef(null);
  const [refrshScan, setRefreshScan] = useState(false);
  const [cameraList, setCameraList] = useState([]);
  const defaultCamera = localStorage.getItem('defaultCamera');
const [value,setValue] = useState('')
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });


  // h


  const animateScannerLine = () => {
    if (lineRef.current) {
      lineRef.current.style.display = 'block';
      // Optionally, you can use a CSS animation or requestAnimationFrame for smoother effect
    }
  };
  const windowWidth = useSelector(selectWidth);


 const dispatch = useDispatch()



  const config =
    windowWidth <= 1280
      ? {
        fps: 30, 
          qrbox: { width: 200, height: 200 },
                  supportedScanTypes: [Html5QrcodeSupportedFormats.CODE_128, Html5QrcodeSupportedFormats.EAN_13],

        }

      : {
        fps: 30, 
          qrbox: { width: 200, height: 200 },
                  supportedScanTypes: [Html5QrcodeSupportedFormats.CODE_128, Html5QrcodeSupportedFormats.EAN_13],

        }

  useEffect(() => {
    
      Html5Qrcode.getCameras()
        .then((devices) => {
          if (devices && devices.length > 0) {
            setCameraList(devices);
            if (defaultCamera) {
              const defautCameraAvailvl = devices.filter(
                (o) => o.id === defaultCamera
              );
              if (defautCameraAvailvl[0]) {
                setDevices(defautCameraAvailvl[0].id);
              } else {
                const backCameraAvailvaal = devices.filter((o) =>
                  o.label.toLowerCase().includes('back')
                );
                if (backCameraAvailvaal[0]) {
                  setDevices(backCameraAvailvaal[0].id);
                } else {
                  setDevices(devices[0].id);
                }
              }
            } else {
              const backCameraAvailvaal = devices.filter((o) =>
                o.label.toLowerCase().includes('back')
              );
              if (backCameraAvailvaal[0]) {
                setDevices(backCameraAvailvaal[0].id);
              } else {
                setDevices(devices[0].id);
              }
            }
          } else {
            console.error('No cameras found.');
          }
        })
        .catch((err) => {
          dispatch(setAlertWithTitle({
            title:'Alert',
            msg:'Not found any camera'
          }))
        });
  }, [status]);
  useEffect(() => {
    if (devices) {
      const html5QrCode = new Html5Qrcode('reader');

      html5QrCode
        .start(
          devices,
          config,
          (decodedText, decodedResult) => {
            const v = decodedText;
            
            if(onDetected){
                onDetected(v)

            }
            if (decodedResult) {
              html5QrCode
                .stop()
                .then(() => {
                  console.log('Camera stopped successfully.');
                  setCamera("")
                })
                .catch((err) => {
                  console.error('Error stopping the camera:', err);
                });
      }
            setValue(v)
            animateScannerLine();
           
          },
          (errorMessage) => {
          }
        ).then(()=>{
          if(setCamera){
            setCamera(html5QrCode)
          }
        })
        .catch((err) => {
          console.error('Error starting Html5Qrcode:', err);
        });

      scannerRef.current = html5QrCode;
    }
  }, [devices, refrshScan,status]);






  const selectedCameras = devices
    ? cameraList.filter((o) => o.id === devices)
    : [{ id: '' }];
  const selectedCamera = selectedCameras[0].id;


  return (
    <div className="p-4">

        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
          className="flex justify-start"
        >
      
      
    
          {cameraList.length > 1 && (
            <div className="flex mb-2 ">
              <div className="w-[30px] ">
                <i className="bi bi-camera-fill"></i>
              </div>{' '}
              :
              <select
                onChange={(e) => {
                  if (e.target.value) {
                    localStorage.setItem('defaultCamera', e.target.value);
                    window.location.reload();
                  }
                }}
                className="w-[100px] outline-none overflow-hidden ml-2"
                value={selectedCamera}
              >
                <option></option>
                {cameraList.map((o) => {
                  return (
                    <option className="" value={o.id}>
                      {o.label}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <div>Scan Here</div>
          <div
            id="readers"
            style={{
              width: '200px',
              height: '200px',
              position: 'relative',
            }}
          >
            <div className="scanner-line" ref={lineRef}></div>

            <div id="reader"></div>
          </div>
        Result : {value}
        </div>
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
  


    </div>
  );
};

export default QRCodeScanner;
