import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Lottie from "lottie-react";
import CryptoJS from "crypto-js";
import { Country, State, City } from "country-state-city";
import axios from "axios";
import {
  selectLatitude,
  selectLongitude,
  selectWidth,
  setlatitudeRedux,
  setlongitudeRedux,
} from "../../utility/Redux/profile";
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import CallAPI from "../../utility/functions/getData";
import AlertModal2 from "../../utility/component/modals/alertWithLottie";
import AboutsHeader from "./header";
import register from "../../utility/json/reisterCompany.json";
const RegisterCompany = () => {
  const emptyData = {
    company_name: "",
    company_email: "",
    company_website: "",
    address: "",
    country: "",
    state: "",
    city: "",
    pin_code: "",
    company_mobile: "",
    user_name: "",
    user_email: "",
    user_password: "",
    confirmPassword: "",
  };
  const [formData, setFormData] = useState(emptyData);
  const countries = Country.getAllCountries();
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
  const dispatch = useDispatch();
  const langitude = useSelector(selectLatitude);
  const longitude = useSelector(selectLongitude);
  const phoneRegex = /^[6-9]\d{9}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [stateList, setStatelist] = useState([]);
  const [cityList, setCitylist] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "company_email") {
      checkIsDuplicateUser(value);
    }
    if (name === "company_mobile") {
      checkIsDuplicateUser("", value);
    }
  };
  const clearState = () => {
    setFormData(emptyData);
  };
  const createFinacialYear = (code, company, email, hmac) => {
    const data = {
      company_code: code,
      company_name: company,
      company_email: email,
    };
    axios
      .post(
        `/api/users/createFinancialYear`,

        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${hmac}`,
          },
        }
      )
      .then(async function (response) {
        clearState();
        dispatch(
          setAlertWithTitle({
            title: "Suceess",
            msg: email,
            type: "Company registerd",
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleSubmit = () => {
    if (
      !formData.company_name ||
      !formData.company_email ||
      !formData.company_website ||
      !formData.address ||
      !formData.country ||
      !formData.state ||
      !formData.city ||
      !formData.pin_code ||
      !formData.company_mobile ||
      !formData.user_name ||
      !formData.user_email ||
      !formData.user_password ||
      !formData.confirmPassword
    ) {
      setError("All fields are required!");

      setTimeout(() => {
        setError("");
      }, 4000);

      return;
    }

    if (formData.user_password !== formData.confirmPassword) {
      setError("Passwords do not match!");
      setTimeout(() => {
        setError("");
      }, 4000);
      return;
    }

    if (
      !emailRegex.test(formData.user_email) ||
      !emailRegex.test(formData.company_email)
    ) {
      setError("Please enter a valid email");
      setTimeout(() => {
        setError("");
      }, 4000);
      return;
    }
    const text = formData.company_name;
    const words = text.split(" ");

    const result =
      words.reduce((acc, word) => {
        if (word) {
          acc += word[0];
        }
        return acc;
      }, "") + "_";
    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(formData.user_name, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    const tablePrefix = result.toLowerCase();

    const data = {
      user_email: formData.user_email,
      user_password: formData.user_password,
      user_name: formData.user_name,
      user_type: "MANAGER",
      user_status: "ACTIVE",
      company_email: formData.company_email,
      company_name: formData.company_name,
      company_mobile: formData.company_mobile,
      company_website: formData.company_website,
      group_name: "",
      address: formData.address,
      city: formData.city,
      state: formData.state,
      pin_code: formData.pin_code,
      shop_id: "",
      shop_name: "",
      currency_name: "rupee",
      currency_symbol: "₹",
      regd_no1: "",
      regd_no2: "",
      regd_no3: "",
      disputes: `All Disputes Subject to Jalandhar`,
      company_logo: "",
      table_prefix: tablePrefix,
      latitude: langitude,
      longitidue: longitude,
    };
    dispatch(setSpinnerLoading("Creating"));
    CallAPI(
      "/api/users/registerCompany",
      data,
      hmacString,
      (e) => {
        if (!e.data.error) {
          const code = e.data.results.insertId;
          createFinacialYear(
            code,
            formData.company_email,
            formData.user_email,
            hmacString
          );

          // clearState();
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Something Went Wrong.",
              type: "warning",
            })
          );
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
    setError("");
  };
  const checkIsDuplicateUser = (email, mobile) => {
    if (email || mobile) {
      const isEmail = email && emailRegex.test(email);
      const isMobile = mobile && phoneRegex.test(mobile);
      if (isEmail || isMobile) {
        const u = email ? email : mobile;
        const hmac = CryptoJS.HmacSHA256(u, secretPass);
        // Convert the HMAC to a string and encode it using Base64
        const hmacString = hmac.toString(CryptoJS.enc.Base64);
        const d = {
          company_email: email ? email : "",
          company_mobile: mobile ? mobile : "",
        };
        CallAPI(
          "/api/users/companyInfo",
          d,
          hmacString,
          (e) => {
            if (e.data.response[0]) {
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: `This ${
                    email ? "email address" : "mobile"
                  } is already registered with our system`,
                  type: "warning",
                })
              );
              if (email) {
                setFormData((o) => {
                  return {
                    ...o,
                    company_email: "",
                    company_mobile: "",
                  };
                });
              } else {
                setFormData((o) => {
                  return {
                    ...o,
                    company_mobile: "",
                    company_mobile: "",
                  };
                });
              }
            }
          },
          dispatch
        );
      }
    }
  };
  const longitidue = useSelector(selectLongitude);
  const latitude = useSelector(selectLatitude);
  const reverseGeocode = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat, lng };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          let pincode = "";
          let city = "";
          let state = "";
          let country = "";

          results[0].address_components.forEach((component) => {
            if (component.types.includes("postal_code")) {
              pincode = component.long_name;
            }
            if (component.types.includes("locality")) {
              city = component.long_name;
            }
            if (component.types.includes("administrative_area_level_1")) {
              state = component.long_name;
            }
            if (component.types.includes("country")) {
              country = component.long_name;
            }
          });

          setFormData((o) => {
            const c = countries.filter(
              (cou) => cou.name.toLowerCase() === country.toLowerCase()
            );
            const stateList = State.getStatesOfCountry(c[0].isoCode);
            const s = stateList.filter(
              (cou) => cou.name.toLowerCase() === state.toLowerCase()
            );
            const cityList = City.getCitiesOfState(
              s[0].countryCode,
              s[0].isoCode
            );
            const ci = cityList.filter(
              (cou) => cou.name.toLowerCase() === city.toLowerCase()
            );

            return {
              ...o,
              country: c[0] ? c[0].name : "",
              state: s[0] ? s[0].name : "",
              city: ci[0] ? ci[0].name : "",
              pin_code: pincode,
            };
          });
        } else {
          console.error("No results found");
        }
      } else {
        console.error("Geocoder failed due to: " + status);
      }
    });
  };

  const width = useSelector(selectWidth);
  useEffect(() => {
    if (formData.country) {
      const countryy = countries.filter(
        (country) => country.name === formData.country
      );
      setStatelist(State.getStatesOfCountry(countryy[0].isoCode));
    }
  }, [formData.country]);

  useEffect(() => {
    if (formData.state && stateList[0]) {
      const statee = stateList.filter((state) => state.name === formData.state);
      // Check if statee has at least one element
      if (statee.length > 0) {
        const firstState = statee[0];
        setCitylist(
          City.getCitiesOfState(firstState.countryCode, firstState.isoCode)
        );
      } else {
        // alert("hgdcfujhgsd");
        // setCitylist([]);
      }
    }
  }, [formData.state, stateList]);
  const handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          dispatch(setlatitudeRedux(latitude));
          dispatch(setlongitudeRedux(longitude));
          reverseGeocode(latitude, longitude); // Get address for current location
        },
        (error) => {
          console.error("Error fetching location:", error);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (!window.google) {
        // Inject Google Maps script asynchronously
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDWMcZB-7gJPV0UlHFftCeTWQWZN6TTAuY&callback=initMap`;
        script.async = true;
        document.head.appendChild(script);

        script.onload = () => {
          if (!longitude) {
            handleUseCurrentLocation();
          } else {
            reverseGeocode(Number(latitude), Number(longitude));
          }
        };

        script.onerror = () => {
          console.error("Google Maps script failed to load.");
        };
      } else {
        if (latitude) {
          reverseGeocode(Number(latitude), Number(longitude));
        }
      }
    }
  }, []);
  return (
    <div className="py-2 bg-gray-100 h-screen w-screen">
      <div className={"container flex flex-col justify-center h-full "}>
        {/* <AboutsHeader /> */}
        <div className="flex w-full justify-center   items-center h-full">
          <div
            style={{
              display: width > 1024 ? "flex" : "none",
            }}
            className=" flex-1  "
          >
            <div
              style={{
                height: "785px",
              }}
              className="l:mr-10 flex flex-col l:justify-between  py-10"
            >
              <div className="">
                <div className="text-[28px] font-semibold text-gray-700">
                  Register Your Company
                </div>
                <div className="text-gray-500 pt-2 max-w-[700px]">
                  Join us in revolutionizing the way businesses operate. Our
                  dedicated team is here to support you every step of the way.
                </div>
              </div>

              <div className="w-[420px]  ">
                <Lottie
                  animationData={register}
                  loop={true}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                />
              </div>
            </div>
          </div>
          <div
            onSubmit={handleSubmit}
            className="border rounded-lg bg-white shadow-md p-6 max-w-2xl w-full t:mb-6 h-fit"
          >
            {error && (
              <p className="text-red-500 text-sm border border-red-500 p-2 rounded mb-4">
                {error}
              </p>
            )}
            {successMessage && (
              <p className="text-green-500 text-sm border border-green-500 p-2 rounded mb-4">
                {successMessage}
              </p>
            )}

            {/* Company Information Section */}
            <h2 className=" text-blue-500  text-lg font-semibold  mb-4  rounded-md flex items-center">
              Company Information
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Company Name
                </label>
                <input
                  type="text"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                  required
                />
              </div>

              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Company Email
                </label>
                <input
                  type="email"
                  name="company_email"
                  value={formData.company_email}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                  required
                />
              </div>

              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Website
                </label>
                <input
                  type="url"
                  name="company_website"
                  value={formData.company_website}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                  required
                />
              </div>

              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Address
                </label>
                <input
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                  required
                ></input>
              </div>

              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Country
                </label>
                <select
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                  required
                >
                  <option value="">Select Country</option>
                  {countries.map((e, i) => (
                    <option key={i} value={e.value}>
                      {e.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  State
                </label>
                <select
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                  required
                >
                  <option value="">Select State</option>
                  {stateList.map((e, i) => (
                    <option key={i} value={e.value}>
                      {e.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex">
                <div className="flex flex-1">
                  <div className="w-full">
                    <label className="block text-gray-700 font-semibold mb-1">
                      City
                    </label>
                    <select
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                      required
                    >
                      <option value="">Select City</option>
                      {cityList.map((e, i) => (
                        <option key={i} value={e.value}>
                          {e.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="ml-2 flex flex-1">
                  <div className="w-full">
                    <label className="block text-gray-700 font-semibold mb-1">
                      Pincode
                    </label>
                    <input
                      type="text"
                      name="pin_code"
                      value={formData.pin_code}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                      required
                    />
                  </div>
                </div>
              </div>

              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Mobile
                </label>
                <input
                  type="tel"
                  name="company_mobile"
                  value={formData.company_mobile}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                  required
                />
              </div>
            </div>

            {/* User Information Section */}
            <h2 className=" text-blue-500 text-lg font-semibold  mb-4 mt-6 rounded-md flex items-center">
              User Information
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Username
                </label>
                <input
                  type="text"
                  name="user_name"
                  value={formData.user_name}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  User Email
                </label>
                <input
                  type="email"
                  name="user_email"
                  value={formData.user_email}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Password
                </label>
                <input
                  type="user_password"
                  name="user_password"
                  value={formData.user_password}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Confirm Password
                </label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md w-full p-2 focus:outline-none focus:ring-2 focus:ring-[#f88f09]"
                  required
                />
              </div>
            </div>
            <div className="mt-6">
              <button
                onClick={handleSubmit}
                className="bg-[#f88f09] text-white font-semibold py-3 rounded-md w-full hover:bg-[#e77c05] transition duration-200"
              >
                Register Company
              </button>
            </div>
          </div>
        </div>
      </div>
      <AlertModal2 />
    </div>
  );
};

export default RegisterCompany;
