export function FormatNumber(number) {
    // number.replace(',','')
    return number.toFixed(2).toLocaleString();
  }
  
  export function FormatNumber2(number) {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  
    return formatter.format(number);
  }

  export function IscontainsAlphabetic(value) {
    return /[a-zA-Z]/.test(value);
}
  
  export function FormatNumber3(number) {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    });
  
    return formatter.format(number);
  }
  