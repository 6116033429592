import React from "react";

const ModalHeaderStyle = ({ header, onClose, bg, border }) => {
  return (
    <div
      style={
        border
          ? {
              backgroundColor: bg ? bg : "white",
              border: border ? border : "none",
            }
          : {
              backgroundColor: bg ? bg : "white",

              borderBottom: "1px solid #D2D2D7",
            }
      }
      className={
        "overflow-hidden  rounded-t-sm   w-full justify-between  flex items-center  px-3 py-2"
      }
    >
      <div className="w-full    ">
        <div className=" max-sm:mb-2 w-full flex font-bold text-[#4a4a4a] items-center  ">
          <h3
            style={
              window.innerWidth <= 700
                ? {
                    fontSize: "18px",
                    width: "100%",
                  }
                : {
                    fontSize: "25px",
                    width: "100%",
                  }
            }
            className="  max-sm:px-1 "
          >
            {header}
          </h3>
        </div>
      </div>
      <button fontSize={"medium"} onClick={onClose}>
        {" "}
        <i className="bi bi-x-square-fill  text-[22px] text-red-500 font-bold p-1"></i>
      </button>
    </div>
  );
};

export default ModalHeaderStyle;
