import { useDispatch, useSelector } from 'react-redux';
import {
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import { useState } from 'react';
import { selectToken } from '../utility/Redux/security';
import axios from 'axios';
import { useEffect } from 'react';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';
import MasterHelp from '../utility/component/modals/masterHelp';
import { FormatNumber2 } from '../utility/functions/formatNumber';

const AccountMasterHelp = ({ onRowClick ,status,onClose,onCreatePartyClick,refreshList}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const [rows, setRows] = useState([]);
  const token = useSelector(selectToken);

  const prefix = useSelector(selectPrefix);

  const windowWidth = useSelector(selectWidth);
  const columns = [
    {
      width:
        windowWidth <= 400
          ? 100
          : windowWidth <= 940
          ? 70
          : windowWidth <= 1156
          ? 120
          : 120,
      headerAlign: 'center',
      align: 'left',
      field: 'MOBILE',
      renderHeader: (params) => <div>Mobile</div>,
    },
    {
      width:
        windowWidth <= 400
          ? 100
          : windowWidth <= 940
          ? 130
          : windowWidth <= 1156
          ? 180
          : 240,
      headerAlign: 'center',
      align: 'left',
      field: 'NAME',
      renderHeader: (params) => <div>Party Name</div>,
    },
    {
      width:
        windowWidth <= 400
          ? 70
          : windowWidth <= 940
          ? 80
          : windowWidth <= 1156
          ? 170
          : 220,
      headerAlign: 'center',
      align: 'left',
      field: 'ADDRESS',
      renderHeader: (params) => <div>Addrress</div>,
    },

    {
      width: windowWidth <= 940 ? 50 : windowWidth <= 1156 ? 60 : 70,
      headerAlign: 'center',
      align: 'left',
      field: 'ACCOUNT_NO',
      renderHeader: (params) => <div>Acc.No.</div>,
    },

  ];
  const mobileColumns = [
    {
      width:100,
      headerAlign: 'center',
      align: 'left',
      field: 'MOBILE',
      renderHeader: (params) => <div>Mobile</div>,
    },
    {
      width:120,
      headerAlign: 'center',
      align: 'left',
      field: 'NAME',
      renderHeader: (params) => <div>Party Name</div>,
    },
    {
      width:110,
      headerAlign: 'center',
      align: 'left',
      field: 'ADDRESS',
      renderHeader: (params) => <div>Addrress</div>,
    },


  ];
  const [searchQuery, setSearchQuery] = useState('');
  const [fullList, setFullList] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.MOBILE.toString().includes(searchQuery);
        } else {
          return item.NAME.toLowerCase().includes(
            searchQuery.toLowerCase()
          );
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  const dispatch = useDispatch();

  const getData = async () => {
    dispatch(setSpinnerLoading('Loading'));
    const data  = {
        table_prefix: prefix,
        "SECTION_NAME": "",
        "mobile":"",
        "name":""
    }
    await axios
      .post(
        '/api/master/listAccountMaster',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        if(!p.data.error){
          let list = [];
          const data = p.data.response

          for (let i = 0; i < data.length; i++) {
            list.push({
              ...data[i],
              id:i+1
            });
          }
          setFullList(list);
          setRows(list);
      }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status,refreshList]);



  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            onClose();
            setRows([]);
            setSearchQuery('')

          }}
          title={'Account Master | Help'}
          columns={windowWidth<500?mobileColumns: columns}
          list={rows}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRowClick={(p) => {
            onRowClick(p);
            setRows([]);
          }}
          CreateNew={()=> onCreatePartyClick()}
        />
      </div>
    </>
  );
};

export default AccountMasterHelp;
