// actions.js

import { API_CALL_REQUEST, API_CALL_SUCCESS, API_CALL_FAILURE } from './actionType';

export const apiCallRequest = (payload) => ({
  type: API_CALL_REQUEST,
  payload,
});

export const apiCallSuccess = (data) => ({
  type: API_CALL_SUCCESS,
  data,
});

export const apiCallFailure = (error) => ({
  type: API_CALL_FAILURE,
  error,
});
