import { FormatNumber2 } from "../utility/functions/formatNumber";

export const PrintColumns = (width) => {
  const columns = [
    {
      field: "ICODE",
      accessor: "ICODE",
      type: "string",
      headerAlign: "center",
      width: 43,
      headerClassName: "super-app-theme--header",
      editable: true,

      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          S.No.
        </div>
      ),
    },

    {
      field: "ITEMNAME",
      accessor: "ITEMNAME",
      type: "string",
      headerAlign: "center",

      width: 140,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className={` h-full w-full flex items-center  `}>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Description
        </div>
      ),
    },
    {
      field: "HSN_CODE",
      accessor: "HSN_CODE",
      type: "string",
      width: 70,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          HSN  / SAC
        </div>
      ),
    },
    {
      field: "QNTY",
      accessor: "QNTY",
      type: "number",
      width: 50,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      editable: true,
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
       Qnty
        </div>
      ),
    },
    {
      field: "PUR_PRICE",
      accessor: "PUR_PRICE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 90,
      align: "right",
      editable: true,
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white   text-center" style={{ fontSize: 13 }}>
          Rate
        </div>
      ),
    },

    {
      field: "DISQ_RT",
      accessor: "DISQ_RT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 60,
      editable: true,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white   text-center" style={{ fontSize: 13 }}>
         DSQ % Sch
        </div>
      ),
    },
    {
      field: "DISQ_AMT",
      accessor: "DISQ_AMT",
      type: "number",
      customerheader:'DISC Amount',
      headerClassName: "super-app-theme--header",
      width: 60,
      align: "right",
      editable: true,
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

renderHeader: (params) => (
  <div className="text-white w-full text-center" style={{ fontSize: 13, whiteSpace: 'normal' }}>
    Disc. Amount Scheme
  </div>
),
    },
    
    {
        field: "AMOUNT",
        accessor: "AMOUNT",
        type: "number",
        headerClassName: "super-app-theme--header",
        width: 100,
        align: "right",
        renderCell: (param) => {
          return (
            <div className={` h-full w-full  flex items-center  justify-end `}>
              {param.value ? FormatNumber2(param.value) : "0"}
            </div>
          );
        },
  
        headerAlign: "center",
  
        renderHeader: (params) => (
          <div className="text-white   text-center" style={{ fontSize: 13 }}>
            Taxable Amount
          </div>
        ),
      },


    {
      field: "TAX1_AMT",
      accessor: "TAX1_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 80,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          CGST
        </div>
      ),
    },

    {
      field: "TAX2_AMT",
      accessor: "TAX2_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 80,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          SGST
        </div>
      ),
    },
    {
        field: "TAX3_AMT",
        accessor: "TAX3_AMT",
        type: "number",
        headerClassName: "super-app-theme--header",
        width: 80,
        align: "right",
        renderCell: (param) => {
          return (
            <div className={` h-full w-full  flex items-center  justify-end `}>
              {param.value ? FormatNumber2(param.value) : "0"}
            </div>
          );
        },
        headerAlign: "center",
  
        renderHeader: (params) => (
          <div className="text-white  text-center" style={{ fontSize: 13 }}>
            IGST
          </div>
        ),
      },
    {
      field: "CESS_AMT",
      accessor: "CESS_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 120,
      align: "right",
      editable: true,
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Cess Amt.
        </div>
      ),
    },
    {
      field: "NET_AMT",
      accessor: "NET_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : ""}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white   text-center" style={{ fontSize: 13 }}>
      Amount
        </div>
      ),
    },

   
  ];

  return columns;
};
