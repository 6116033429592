export const PartyColumns = (windowWidth) =>{
  const columns = [
      {
        field: 'ACCOUNT_NO',
        width: windowWidth <= 768 ? 80 : 80,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <div style={{ fontSize: 13 }}>{'Acc. No.'}</div>
        ),
      },
    
  
      {
        field: 'NAME',
        width: windowWidth <= 768 ? 200 : windowWidth <= 1024 ? 200 : 300,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <div
            style={{
              fontSize: 13,
            }}
          >
            {'Party Name'}
          </div>
        ),
      },
      {
        field: 'MOBILE',
        width: windowWidth <= 768 ? 100 : 120,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <div style={{ fontSize: 13 }}>{'Mobile'}</div>
        ),
      },
      {
        field: 'ADDRESS',
        width: windowWidth <= 768 ? 130 : windowWidth <= 1024 ? 130 : 200,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <div
            style={{
              fontSize: 13,
            }}
          >
            {'Address'}
          </div>
        ),
      },
      {
        field: 'CITY',
        width: windowWidth <= 768 ? 130 : windowWidth <= 1024 ? 130 : 130,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <div
            style={{
              fontSize: 13,
            }}
          >
            {'City'}
          </div>
        ),
      },
    ];
    const mobileCol = [
      {
        field: 'ACCOUNT_NO',
        width:  60,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <div style={{ fontSize: 13 }}>{'Acc. No.'}</div>
        ),
      },
    
  
      {
        field: 'NAME',
        width: windowWidth <= 768 ? 150: windowWidth <= 1024 ? 100 : 300,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <div
            style={{
              fontSize: 13,
            }}
          >
            {'Party Name'}
          </div>
        ),
      },
      {
        field: 'MOBILE',
        width: windowWidth <= 768 ? 100 : 120,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <div style={{ fontSize: 13 }}>{'Mobile'}</div>
        ),
      },
    
    ];

   if( windowWidth >764 ){
    return columns

   }else{
return mobileCol
   }
}