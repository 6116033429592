import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import { database } from "../functions/firebase";
import { child, get, onValue, ref } from "firebase/database";
import { selectPrefix, selectUserCode } from "../Redux/profile";

export default function QrCodeScanModal({
  status,
  qrCodeValue,
  setQrCodeValue,
  onClose,
}) {
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const userCode = useSelector(selectUserCode);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
    boxShadow: 24,
    borderRadius: "7px",
    outline: "none",
  };
  useEffect(() => {
    const connectedRef = ref(database, `loginAuth/qrCode/${prefix}`);
    const connectedRef2 = ref(
      database,
      `loginAuth/qrCode/${prefix}/${userCode}`
    );
    get(child(connectedRef, `/${userCode}`)).then((sn) => {
      onValue(connectedRef2, (sn) => {
        const data = sn.val();
        setQrCodeValue(data);
      });
    });
  }, []);
  return (
    <div>
      <Modal open={status} onClose={onClose}>
        <div style={style}>
          <div className="bg-white min-w-[380px] rounded-[10px] overflow-hidden p-[2px] shadow-md">
            <div className="flex justify-center p-2 bg-gradient-to-b from-blue-100 to-white">
              <div className="bg-blue-100 rounded-full h-[40px] w-[40px] flex items-center justify-center">
                <i className="bi bi-qr-code-scan text-[16px] flex items-center text-blue-500"></i>
              </div>
            </div>

            <div className="flex justify-center w-full font-semibold text-black text-lg">
              WhatsApp QR Code
            </div>
            <div className="flex justify-center text-[13px] mt-1 text-gray-600">
              Open WhatsApp and navigate to Linked Devices to scan
            </div>
            <div className="flex justify-center mt-4">
              <QRCodeSVG value={qrCodeValue} size={200} />
            </div>

            <div className="mt-4 flex justify-center px-3 pb-4">
              <button
                onClick={onClose}
                className="bg-blue-500 py-[6px] px-4 rounded-md text-white  w-full hover:bg-blue-600 transition"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
