import React from 'react';
import { setAlertWithTitle, setSpinnerLoading } from '../Redux/modal';
import axios from 'axios';

const SendOtp = (MobileNumber,prefix, randomNumber,email, dispatch,token) => {
  return new Promise((resolve, reject) => {
    dispatch(setSpinnerLoading('Sending OTP'));
    axios
      .post(
        `/api/sendOTP/sendOTP`,
  
        {
          "table_prefix":prefix,
          "user_email": email,
          "mobile": MobileNumber,
          "otp": randomNumber
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async function (response) {
        if (response.status === 200) {
          resolve()
        } else {
          reject()
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Something went wrong',
            })
          );
        }
      })
      .catch(function (error) {
        reject()
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: 'Something went wrong',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  })

};

export default SendOtp;
