import moment from "moment";
import { FormatNumber2 } from "../../utility/functions/formatNumber";
import { balanceType } from "../../utility/constant";

export const SaleSummaryColumns = (width) => {
  const columns = [
    {
      field: "MOBILE",
      accessor: "MOBILE",
      type: "string",
      width: width < 768 ? 100 : width < 1400 ? 180 : 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Mobile
        </div>
      ),
    },
    {
      field: "NAME",
      accessor: "NAME",
      type: "string",
      width: width < 768 ? 100 : width < 1400 ? 180 : 180,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Party Name
        </div>
      ),
    },
    {
      field: "OP_BALANCE",
      accessor: "OP_BALANCE",
      type: "number",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center  justify-end `}>
            {FormatNumber2(Math.abs(param.value))}
          </div>
        );
      },
      headerClassName: "super-app-theme--header",
      width: 100,
      headerAlign: "center",
      align: "right",

      renderHeader: (params) => (
        <div className="text-white  whitespace-normal" style={{ fontSize: 13 }}>
          {" "}
          Opening
        </div>
      ),
    },
    {
      field: "DEBIT",
      accessor: "DEBIT",
      type: "number",
      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center  justify-end `}>
            {FormatNumber2(param.value)}
          </div>
        );
      },
      headerClassName: "super-app-theme--header",
      width: 100,
      headerAlign: "center",
      align: "right",

      renderHeader: (params) => (
        <div className="text-white  whitespace-normal" style={{ fontSize: 13 }}>
          {" "}
          Debit
        </div>
      ),
    },
    {
      field: "CREDIT",
      accessor: "CREDIT",
      type: "string",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "right",

      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   justify-end`}>
            {FormatNumber2(param.value)}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Credit
        </div>
      ),
    },
    {
      field: "BALANCE",
      accessor: "BALANCE",
      type: "string",
      width: 100,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "right",

      renderCell: (param) => {
        return (
          <div className={`h-full w-full  flex items-center   justify-end`}>
            {balanceType(param.value)}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Balance
        </div>
      ),
    },
  ];

  return columns;
};
