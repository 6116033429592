import React from 'react'
import CallAPI from '../../utility/functions/getData';
import { setAlertWithTitle, setSpinnerLoading } from '../../utility/Redux/modal';
import deleteWholeDatabase from '../../utility/functions/deleteWholeDatabase';
import dataToLocal from '../../utility/component/dataToLocal';

export const GetItemWisePurchaseSummary = (data,token,dispatch,setPurchaseList,setFullItemWiseReport) => {
    dispatch(setSpinnerLoading('Loading...'));
    if(data.SECTION_NAME=== ""){
        deleteWholeDatabase('itemWisePurchase')
    
      }
    CallAPI(
        "/api/purchaseReports/itemWisePurchase",
        data,
        token,
        (e) => {
            if (!e.data.error) {
                const res = e.data.response
                if(res[0]){
                    setPurchaseList(res)
               if(data.SECTION_NAME ==="" ){
                dataToLocal('itemWisePurchase', res, [
                    {
                     ...data,
                     ICODE:1
                    },
                  ]);
                setFullItemWiseReport(res)
               }
                }else{
                    setPurchaseList([])

                     dispatch(
                     setAlertWithTitle({
                     title: 'Alert',
                     msg: 'No data found',
                      }))
                }
            } else {
                dispatch(
                    setAlertWithTitle({
                        title: "Alert",
                        msg: "Something Went Wrong.",
                    })
                );
            }
        },
         dispatch,()=>{
            dispatch(setSpinnerLoading(false))
        }
        )
}


export const GetSectionWisePurchaseSummary = (data,token,dispatch,setSummaryList) => {
    deleteWholeDatabase('sectionWisePurchase')
    
    CallAPI(
        "/api/purchaseReports/sectionWisePurchase",
        data,
        token,
        (e) => {
            if (!e.data.error) {
                if(e.data.response[0]){
                    dataToLocal('sectionWisePurchase', e.data.response, [
                        {
                         ...data,
                         ICODE:1
                        },
                      ]);
                }
                setSummaryList(e.data.response)
            } else {
                dispatch(
                    setAlertWithTitle({
                        title: "Alert",
                        msg: "Something Went Wrong.",
                    })
                );
            }
        },
        dispatch
        )
}
