import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  selectPrefix,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setreportsFromDate,
  setreportsTodate,
} from "../../utility/Redux/profile";
import { GetItemWisePurchaseSummary } from "./func";
import { selectToken } from "../../utility/Redux/security";
import AccountMasterHelp from "../../help/listAccountMasterHelp";
import { ItemwisePurchaseColumns } from "./columns";
import Footer from "./footer";
import { SectionWisePurchaseColumns } from "./columns";
import { GetSectionWisePurchaseSummary } from "./func";
import {  PrintButton } from "../../utility/component/buttons";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import GeniusTableReports from "../../utility/component/geniusComponents/geniusTableData";
import { PrintItemwisePurchaseColumns } from "./printColumns";
import GeniusTableForReports from "../../utility/component/printReport";
import IsLocalData from "../../utility/component/isLocalData";
import FromToDateMobile from "../../utility/component/fromToDateMobile";
import { SectionWiseSale } from "../../billing/comp";
import { FromToDate } from "../../utility/component/fromToDate";

const ItemWisePurchase = () => {
  const prefix = useSelector(selectPrefix);
  const [purchaseList, setPurchaseList] = useState([]);
  const fromDate = useSelector(selectreportFromDate);
  const ToDate = useSelector(selectreportToDate);
  const width = useSelector(selectWidth);
  const [secName, setSecName] = useState("");
  const [fullItemWiseReport, setFullItemWiseReport] = useState([]);
  const [getSectionData, setSectionData] = useState({
    table_prefix: prefix,
    FDATE: moment(fromDate).format("YYYY-MM-DD"),
    TDATE: moment(ToDate).format("YYYY-MM-DD"),
    NAME: "",
  });
  const columns = ItemwisePurchaseColumns(width);
   const printColumns = PrintItemwisePurchaseColumns(width);
  const cols = SectionWisePurchaseColumns(width);
  const [partyHelpStatus, setPartyHelpStatus] = useState(false);
  const [summaryList, setSummaryList] = useState([]);

  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const okButton = () => {
    const additionalData = {
      table_prefix: prefix,
      FDATE: moment(fromDate).format("YYYY-MM-DD"),
      TDATE: moment(ToDate).format("YYYY-MM-DD"),
      NAME: getSectionData.NAME,
      SECTION_NAME: "",
    };

    GetSectionWisePurchaseSummary(
      getSectionData,
      token,
      dispatch,
      setSummaryList
    );
    GetItemWisePurchaseSummary(
      additionalData,
      token,
      dispatch,
      setPurchaseList,
      setFullItemWiseReport
    );
    setSummaryList([]);
    setPurchaseList([]);
  };


   useEffect(() => {
    setSectionData((e) => {
      return {
        ...e,
        FDATE: moment(fromDate).format("YYYY-MM-DD"),
        TDATE: moment(ToDate).format("YYYY-MM-DD"),
        SECTION_NAME: secName,
      };
    });
  }, [fromDate, ToDate, secName]);

  const navigate = useNavigate();
  const [sectionWiseSaleStatus,setSectionWiseSaleStatus] = useState(false)

  const printRef = useRef();
  const printRefSection = useRef();
  const thermalPrintRef = useRef();
  const thermalPrintRefSection = useRef();
  const [selectedPrintOption, setSelectedPrintOption] = useState("print");
  const [selectedPrintOptionSection, setSelectedPrintOptionSection] = useState("print");

  const handlePrint = useReactToPrint({
    pageStyle: `
    @page {
      size: landscape;
    }
  `,
    content: () =>
      selectedPrintOption === "print"
        ? printRef.current
        : thermalPrintRef.current,
    onAfterPrint: () => {
      navigate("/item_wise_purchase_report");
      setSelectedPrintOption("print");
    },
  });

    const handlePrint2 = useReactToPrint({
    pageStyle: `
    @page {
      size: portrait;
    }
  `,
    content: () =>
    selectedPrintOptionSection === "print"
        ? printRefSection.current
        : thermalPrintRefSection.current,
    onAfterPrint: () => {
      navigate("/item_wise_purchase_report");
      setSelectedPrintOptionSection("print");
    },
  });

  
  useEffect(()=>{
    const callback = (details, data) => {
      setSectionData(details[0])
      setSummaryList(data)
  dispatch(setreportsFromDate(details[0].FDATE));
  dispatch(setreportsTodate(details[0].TDATE));
    };
    const elseFunc =()=>{console.log('nothing')}
    IsLocalData('sectionWisePurchase', callback, elseFunc);
  },[])

  useEffect(()=>{
    const callback = (details, data) => {
      setFullItemWiseReport(data)
      setPurchaseList(data)
  dispatch(setreportsFromDate(details[0].FDATE));
  dispatch(setreportsTodate(details[0].TDATE));
    };
    const elseFunc =()=>{console.log('nothing')}
    IsLocalData('itemWisePurchase', callback, elseFunc);
  },[])


  return (
    <div className=" bg-white flex flex-col h-full  overflow-y-scroll ">
      <div className="flex w-full h-full">
      {
          width >1000 ?  <div
          className="flex flex-col  pl-3 py-3 pr-0"
          style={{
            width: cols.reduce((acc, row) => acc + Number(row.width), 0),
            height: "100%",
          }}
        >
          <GeniusTableReports
              onRowClick={(e) => {
                const data = {
                  table_prefix: prefix,
                  FDATE: moment(fromDate).format("YYYY-MM-DD"),
                  TDATE: moment(ToDate).format("YYYY-MM-DD"),
                  CUSTOMER_MOBILE: getSectionData.NAME,
                  SECTION_NAME: e.values.SECTION_NAME,
                };
                setSecName(e.values.SECTION_NAME);

                GetItemWisePurchaseSummary(
                  data,
                  token,
                  dispatch,
                  setPurchaseList,
                  setFullItemWiseReport
                );
              }}
              columns={cols}
              data={summaryList}
              footer={() => console.log("hi")}
            />

            <div className="mt-2">
              <PrintButton onClick={handlePrint2}/>
            </div>
      
        </div> : null}
        <div style={{
          width: width >1000 ?   width - (cols.reduce((acc, row) => acc + Number(row.width), 0)+40) :'100%'
        }} className=" flex flex-col p-3 ">
          <div className="w-full">
            {width >1280 ?
            <FromToDate
              partyData={{
                NAME: getSectionData.NAME,
              }}
            
              hideReset={true}
              onPrintClick={handlePrint}
              onPartyMasterSearchClick={(e) => setPartyHelpStatus(true)}
              submit={okButton}
              partyClear={() => {
                setSecName("");
                setSectionData((o =>{
                  return {
                    ...o,
                    SECTION_NAME:"",
                    NAME:""
                  }
                }))
                setPurchaseList([]);
                setSummaryList([]);
              }}
            /> : <FromToDateMobile  
            partyData={{
              NAME: getSectionData.NAME,
            }} 
            partyClear={() => {
              setSecName("");
              setSectionData('')
              setPurchaseList([]);
              setSummaryList([]);
            }}
            onPrintClick={handlePrint}

            onPartyMasterSearchClick={(e) => setPartyHelpStatus(true)}
            submit={okButton}
             sectionHelp={<SectionWiseSale
              normaltext={true}
              section={secName}
            
                setIsSectionDraweropen={setSectionWiseSaleStatus}
                isSectionDrawerOpen={sectionWiseSaleStatus}
    data={  <GeniusTableReports
      onRowClick={(e) => {
        const data = {
          table_prefix: prefix,
          FDATE: moment(fromDate).format("YYYY-MM-DD"),
          TDATE: moment(ToDate).format("YYYY-MM-DD"),
          CUSTOMER_MOBILE: getSectionData.NAME,
          SECTION_NAME: e.values.SECTION_NAME,
        };
        setSectionWiseSaleStatus(false)
        setSecName(e.values.SECTION_NAME);

        GetItemWisePurchaseSummary(
          data,
          token,
          dispatch,
          setPurchaseList,
          setFullItemWiseReport
        );
      }}
      columns={cols}
      data={summaryList}
      footer={() => console.log("hi")}
    />}
              />  }
           />}
          </div>
{width >1280  ? <input className=" h-[30px] outline-none text-sm" readOnly value={secName}/> :<div className="h-[30px]" />}
          <div
            style={{
              height: `calc(100% - 80px)`,
              width: "100%",
            }}
            className=" mb-2 "
          >
             {purchaseList[0] ? (
                <GeniusTableReports
                  columns={columns}
                  data={purchaseList}
                  footer={<Footer columns={columns} rows={purchaseList} />}
                />
              ) : (
                <div className="border rounded-sm h-full flex text-sm text-gray-500 items-center justify-center">
                  No Records Found
                </div>
              )}
          </div>

       
        </div>
      </div>
      <AccountMasterHelp
        onRowClick={(e) => {
          setSectionData((c) => {
            return {
              ...c,
              NAME: e.row.NAME,
            };
          });

          setPartyHelpStatus(false);
        }}
        status={partyHelpStatus}
        onClose={() => {
          setPartyHelpStatus(false);
        }}
      />


  {!secName &&  <div
        style={{
          display: "none",
        }}
      >
        <div ref={printRef}>
             <GeniusTableForReports
            customeHeader={
            <div className="items-center  " >
        
            <div className="flex items-center">
            <div className="mr-1 text-sm font-semibold flex justify-center ">
            Item Wise Purchase Report
            </div>
            <div className="text-sm font-semibold" >
              From  :
              </div>
              <div className="ml-1 text-sm">
                 {moment(fromDate).format('DD-MM-yyyy')} </div>
                 <div className="text-sm font-semibold ml-2" >
              To :
              </div>
              <div className="ml-1 text-sm">
                 {moment(ToDate).format('DD-MM-yyyy')} </div>
                 
                 </div>
              
            </div>}
     marginTop={"120px"}
            headerWidth={true}
            data={purchaseList[0]? purchaseList: fullItemWiseReport}
            columns={printColumns}
            filteredData ={secName? [{
              "SECTION_NAME": secName,
              "GTOTAL": 21945.64
          }] : summaryList}
          
          />
      
        </div>

       
      </div>}
      {secName &&  <div
        style={{
          display: "none",
        }}
      >
        <div ref={printRef}>
             <GeniusTableForReports
            customeHeader={
            <div className="items-center  " >
        
            <div className="flex items-center">
            <div className="mr-1 text-sm font-semibold flex justify-center ">
            Item Wise Purchase Report
            </div>
            <div className="text-sm font-semibold" >
              From  :
              </div>
              <div className="ml-1 text-sm">
                 {moment(fromDate).format('DD-MM-yyyy')} </div>
                 <div className="text-sm font-semibold ml-2" >
              To :
              </div>
              <div className="ml-1 text-sm">
                 {moment(ToDate).format('DD-MM-yyyy')} </div>
                 
                 </div>
              
            </div>}
            marginTop={'-120px'}
            headerWidth={true}
            data={purchaseList[0]? purchaseList: fullItemWiseReport}
            columns={printColumns}
            filteredData ={secName? [{
              "SECTION_NAME": secName,
              "GTOTAL": 21945.64
          }] : summaryList}
          />
      
        </div>

        
      </div>}
    </div>
  );
};

export default ItemWisePurchase;
