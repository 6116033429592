import React from 'react'
import { FormatNumber2 } from '../../utility/functions/formatNumber'
import { FontSizeWidth } from '../../utility/constant'
import { useSelector } from 'react-redux'
import { selectWidth } from '../../utility/Redux/profile'

const Footer = ({columns,rows}) => {
  const width = useSelector(selectWidth)
  return (
    <div className='flex  w-full '>
        <div className='flex'>
        {
     columns.map((e,i) => {
        return (
            <div key={i}  style={{
                width:e.width,
                fontSize:FontSizeWidth(width),
borderLeft :i === 0 ? '1px solid black' : '1px solid black',
borderRight :i === columns.length -1 ? '1px solid black' : '0px solid black',
                borderBottom: '1px solid black',
                borderTop: '1px solid black',
            }} className='p-1 text-right'>
    {e.field ==='NAME' &&  'Total'}

    {e.field ==='DEBIT' && rows.reduce((acc, row) => acc + Number(row.DEBIT), 0)> 0 &&  FormatNumber2(rows.reduce((acc, row) => acc + Number(row.DEBIT), 0)) }
      
    {e.field ==='CREDIT' &&  rows.reduce((acc, row) => acc + Number(row.CREDIT), 0)>0 && FormatNumber2(rows.reduce((acc, row) => acc + Number(row.CREDIT), 0)) }
                </div>
        )
     })   
        
        
    }  
        </div>
</div>
  )
}

export default Footer