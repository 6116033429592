import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalHeaderStyle from "../utility/component/styles/modalHeader";
import GeniustextInput from "../utility/component/mastertext";
import { useDispatch, useSelector } from "react-redux";
import { selectPrefix, selectWidth } from "../utility/Redux/profile";
import moment from "moment";
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from "../utility/component/buttons";
import AccountMasterHelp from "../help/listAccountMasterHelp";
import CustomerMasterHelp from "../help/customermasterHelp";
import ExpenseHelp from "../help/expenseHelp";
import CallAPI from "../utility/functions/getData";
import { selectToken } from "../utility/Redux/security";
import { setAlertWithTitle, setSpinnerLoading } from "../utility/Redux/modal";
import PaymentVoucherHelp from "../help/paymentVoucherHelp";
import AlertModalTwoButton from "../utility/component/modals/alertWithTwoButton";
import VoucherPrint from "../print/voucher";

const PaymentVoucher = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [voucherNo, setVoucherNo] = useState("");
  const prefix = useSelector(selectPrefix);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });
  const voucherTypeOption = [
    {
      name: "Payment",
      value: "Payment",
    },
  ];
  const payModeOption = [
    {
      name: "Cash",
      value: "Cash",
    },
    {
      name: "Cheque",
      value: "Cheque",
    },
  ];
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [accountMasterHelpStatus, setAccountMasterHelpStatus] = useState(false);
  const [customerMasterHelpStatus, setCustomerMasterHelpStatus] =
    useState(false);
  const [expenseHelpStatus, setExpenseHelpStatus] = useState(false);
  const [paymentVoucherHelpStatus, setPaymentVoucherHelpStatus] =
    useState(false);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [data, setData] = useState({
    table_prefix: prefix,
    VOUCHER_NO: "",
    VOUCHER_TYPE: voucherTypeOption[0].value,
    DATE: moment().format("YYYY-MM-DD hh:mm:ss"),
    USER: "",
    ACCOUNT_NO: 0,
    NAME: "",
    MOBILE: "",
    CUSTOMER_CODE: "",
    CUSTOMER_NAME: "",
    CUSTOMER_MOBILE: "",
    DESC_NAME: "",
    EXPENSE_CODE: "",
    EXPENSE_NAME: "",
    DEBIT: "",
    CREDIT: "",
    PAY_MODE: payModeOption[0].value,
    USER_NAME: "",
  });

  const undo = () => {
    setData({
      table_prefix: prefix,
      VOUCHER_NO: "",
      VOUCHER_TYPE: voucherTypeOption[0].value,
      DATE: moment().format("YYYY-MM-DD"),
      USER: "",
      ACCOUNT_NO: 0,
      NAME: "",
      MOBILE: "",
      CUSTOMER_CODE: "",
      CUSTOMER_NAME: "",
      CUSTOMER_MOBILE: "",
      DESC_NAME: "",
      EXPENSE_CODE: "",
      EXPENSE_NAME: "",
      DEBIT: "",
      CREDIT: "",
      PAY_MODE: payModeOption[0].value,
      USER_NAME: "",
    });
  };
  useEffect(() => {
    setData((e) => {
      return {
        ...e,
        DATE: moment(date).format("YYYY-MM-DD"),
      };
    });
  }, [date]);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const windowWidth = useSelector(selectWidth);
  const saveData = () => {
    const value = data.CREDIT + data.DEBIT;
    if (
      data.CUSTOMER_CODE &&
      data.EXPENSE_CODE &&
      value > 0 &&
      data.DESC_NAME
    ) {
      const d = {
        ...data,
        CREDIT: data.CREDIT ? data.CREDIT : 0,
        DEBIT: data.DEBIT ? data.DEBIT : 0,
      };
      dispatch(
        setSpinnerLoading(
          data.VOUCHER_NO ? "Modifying data..." : "Saving data..."
        )
      );
      CallAPI(
        "api/voucher/saveVoucher",
        d,
        token,
        (res) => {
          if (res.status === 200) {
            setAlertTwoButtonModal({
              ...alertTwoButtonModal,
              msg: "Voucher saved. Do you want to print it ?",
              status: true,
              button1: "Print",
              button1Click: () => {
                setAlertTwoButtonModal((r) => {
                  return {
                    ...r,
                    status: false,
                  };
                });
                setVoucherNo(
                  data.VOUCHER_NO ? data.VOUCHER_NO : res.data.result.insertId
                );
              },
              button2: "Cancel",
              button2Click: () => {
                setAlertTwoButtonModal((r) => {
                  return {
                    ...r,
                    status: false,
                  };
                });
              },
            });

            undo();
          } else {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "Something went wrong",
              })
            );
          }
        },
        dispatch,
        () => {
          dispatch(setSpinnerLoading(false));
        }
      );
    } else {
      if (!data.CUSTOMER_CODE) {
        dispatch(
          setAlertWithTitle({
            title: "Alert",
            msg: "Please Select any Customer.",
          })
        );
        const inputFields = formRef?.current?.querySelectorAll("input, select");
        inputFields.forEach((element) => {
          if (element.id === "CUSTOMER_NAME") {
            element.focus();
          }
        });
      } else {
        if (!data.EXPENSE_CODE) {
          dispatch(
            setAlertWithTitle({
              title: "Alert",
              msg: "Please Select any Expense.",
            })
          );
          const inputFields =
            formRef?.current?.querySelectorAll("input, select");
          inputFields.forEach((element) => {
            if (element.id === "EXPENSE_NAME") {
              element.focus();
            }
          });
        } else {
          if (!data.DESC_NAME) {
            dispatch(
              setAlertWithTitle({
                title: "Alert",
                msg: "Please Write some Description.",
              })
            );
            const inputFields = formRef?.current?.querySelectorAll(
              "input, select,textarea"
            );
            inputFields.forEach((element) => {
              if (element.id === "DESC_NAME") {
                element.focus();
              }
            });
          } else {
            if (value < 1) {
              dispatch(
                setAlertWithTitle({
                  title: "Alert",
                  msg: "Please enter Amount.",
                })
              );
              const inputFields = formRef?.current?.querySelectorAll(
                "input, select,textarea"
              );
              inputFields.forEach((element) => {
                if (element.id === "AMOUNT") {
                  element.focus();
                }
              });
            }
          }
        }
      }
    }
  };
  return (
    <div
      ref={formRef}
      className="w-full  flex justify-center bg-[#f5f5f7] h-full "
    >
      <div className="text-sm   rounded-sm   flex flex-col  justify-center h-full ">
        <div>
          <ModalHeaderStyle
            header={"Payment Voucher "}
            onClose={() => {
              navigate("/updation");
            }}
          />
        </div>

        <div
          style={{
            height: `calc(100% - 80px)`,
          }}
          className="   rounded-b-sm  flex flex-col  max-w-[800px] min-w-[340px]"
        >
          <div className="bg-white py-3 rounded-b-sm overflow-y-auto  ">
            <div className=" grid grid-cols-1 t:grid-cols-3 gap-2 px-4">
              <GeniustextInput
                grid={true}
                search={() => {
                  setPaymentVoucherHelpStatus(true);
                }}
                title={"Voucher No."}
                value={data.VOUCHER_NO}
                type={"number"}
                width={windowWidth < 768 ? "200px" : "200px"}
              />
              <GeniustextInput
                grid={true}
                width={"200px"}
                title={"Voucher Type"}
                value={data.VOUCHER_TYPE}
                readOnly={true}
                type={"text"}
              />
              <GeniustextInput
                grid={true}
                width={"200px"}
                title={"Date"}
                fromDate={date}
                setFromDate={setDate}
                fromDateFocus={fromDateFocus}
                setFromDateFocus={setFromDateFocus}
                date={true}
                onChange={(e) => {
                  setData((old) => {
                    return {
                      ...old,
                      DATE: e.target.value,
                    };
                  });
                }}
              />
            </div>

            <div className="border-t  py-4">
              <div className="grid grid-cols-1 t:grid-cols-3 gap-2 px-4">
                <GeniustextInput
                  grid={true}
                  width={windowWidth < 768 ? "200px" : "200px"}
                  title={"Code"}
                  value={data.CUSTOMER_CODE}
                  type={"number"}
                />
                <GeniustextInput
                  grid={true}
                  width={"200px"}
                  id={"CUSTOMER_NAME"}
                  title={"Customer Name"}
                  search={() => {
                    setCustomerMasterHelpStatus(true);
                  }}
                  value={data.CUSTOMER_NAME}
                  type={"text"}
                  onChange={(e) => {
                    setData((old) => {
                      return {
                        ...old,
                        CUSTOMER_NAME: e.target.value,
                      };
                    });
                  }}
                />
                <GeniustextInput
                  grid={true}
                  width={windowWidth < 768 ? "200px" : "250px"}
                  value={data.CUSTOMER_MOBILE}
                  title={"Mobile"}
                  type={"numebr"}
                  onChange={(e) => {
                    setData((old) => {
                      return {
                        ...old,
                        CUSTOMER_MOBILE: e.target.value,
                      };
                    });
                  }}
                />
              </div>
            </div>

            <div className="border-t py-4">
              <div className="grid grid-cols-1 t:grid-cols-2  gap-2 px-4">
                <div className=" w-full">
                  <div className="grid grid-cols-1 t:grid-cols-2 gap-2 flex-1 ">
                    <GeniustextInput
                      grid={true}
                      title={"Expense Code"}
                      value={data.EXPENSE_CODE}
                      type={"number"}
                    />
                    <GeniustextInput
                      grid={true}
                      id={"EXPENSE_NAME"}
                      title={"Expense Name"}
                      search={() => {
                        setExpenseHelpStatus(true);
                      }}
                      value={data.EXPENSE_NAME}
                      type={"text"}
                      onChange={(e) => {
                        setData((old) => {
                          return {
                            ...old,
                            EXPENSE_NAME: e.target.value,
                          };
                        });
                      }}
                    />
                  </div>

                  <div className="grid grid-cols-1 t:grid-cols-2 gap-2  ">
                    <GeniustextInput
                      grid={true}
                      id={"AMOUNT"}
                      width={windowWidth < 768 ? "200px" : "200px"}
                      title={"Amount"}
                      value={
                        data.VOUCHER_TYPE === "Payment"
                          ? data.DEBIT
                          : data.CREDIT
                      }
                      type={"number"}
                      onChange={(e) => {
                        setData((old) => {
                          return {
                            ...old,
                            DEBIT:
                              old.VOUCHER_TYPE === "Payment"
                                ? e.target.value
                                : "",
                            CREDIT:
                              old.VOUCHER_TYPE !== "Payment"
                                ? e.target.value
                                : "",
                          };
                        });
                      }}
                    />
                    <GeniustextInput
                      grid={true}
                      width={"200px"}
                      title={"Payment Mode"}
                      value={data.PAY_MODE}
                      option={payModeOption}
                      onChange={(e) => {
                        setData((old) => {
                          return {
                            ...old,
                            PAY_MODE: e.target.value,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <GeniustextInput
                  id={"DESC_NAME"}
                  value={data.DESC_NAME}
                  height={"118px"}
                  width={"250px"}
                  title={"Description"}
                  textArea={true}
                  onChange={(e) => {
                    setData((old) => {
                      return {
                        ...old,
                        DESC_NAME: e.target.value,
                      };
                    });
                  }}
                />
              </div>
            </div>

            <div className="flex justify-end px-4">
              {data.VOUCHER_NO ? (
                <ModifyButton onClick={saveData} />
              ) : (
                <SaveButton onClick={saveData} />
              )}

              <UndoButton onClick={undo} />
            </div>
          </div>
        </div>
      </div>
      <AccountMasterHelp
        status={accountMasterHelpStatus}
        onClose={() => {
          setAccountMasterHelpStatus(false);
        }}
        onRowClick={(p) => {
          setData((old) => {
            return {
              ...old,
              NAME: p.row.NAME,
              ACCOUNT_NO: p.row.ACCOUNT_NO,
              MOBILE: p.row.MOBILE,
            };
          });
          setAccountMasterHelpStatus(false);
        }}
      />
      <CustomerMasterHelp
        status={customerMasterHelpStatus}
        onRowClick={(p) => {
          setData((old) => {
            return {
              ...old,
              CUSTOMER_CODE: p.row.CUSTOMER_CODE,
              CUSTOMER_MOBILE: p.row.CUSTOMER_MOBILE,
              CUSTOMER_NAME: p.row.CUSTOMER_NAME,
            };
          });
          setCustomerMasterHelpStatus(false);
        }}
        onClose={() => {
          setCustomerMasterHelpStatus(false);
        }}
      />

      <ExpenseHelp
        status={expenseHelpStatus}
        onClose={() => {
          setExpenseHelpStatus(false);
        }}
        onRowClick={(p) => {
          setData((old) => {
            return {
              ...old,
              EXPENSE_CODE: p.row.CODE,
              EXPENSE_NAME: p.row.EXPENSE_NAME,
            };
          });
          setExpenseHelpStatus(false);
        }}
      />

      <PaymentVoucherHelp
        status={paymentVoucherHelpStatus}
        onClose={() => {
          setPaymentVoucherHelpStatus(false);
        }}
        onRowClick={(p) => {
          setData({
            table_prefix: prefix,
            VOUCHER_NO: p.row.VOUCHER_NO,
            VOUCHER_TYPE: p.row.VOUCHER_TYPE,
            DATE: moment(p.row.DATE).format("YYYY-MM-DD hh:mm:ss"),
            USER: p.row.USER,
            ACCOUNT_NO: p.row.ACCOUNT_NO,
            NAME: p.row.NAME,
            MOBILE: p.row.MOBILE,
            CUSTOMER_CODE: p.row.CUSTOMER_CODE,
            CUSTOMER_NAME: p.row.CUSTOMER_NAME,
            CUSTOMER_MOBILE: p.row.CUSTOMER_MOBILE,
            DESC_NAME: p.row.DESC_NAME,
            EXPENSE_CODE: p.row.EXPENSE_CODE,
            EXPENSE_NAME: p.row.EXPENSE_NAME,
            DEBIT: p.row.DEBIT,
            CREDIT: p.row.CREDIT,
            PAY_MODE: p.row.PAY_MODE,
            USER_NAME: p.row.USER_NAME,
          });
          setDate(moment(p.row.DATE).format("YYYY-MM-DD hh:mm:ss"));
          setPaymentVoucherHelpStatus(false);
        }}
      />
      <AlertModalTwoButton details={alertTwoButtonModal} />

      <VoucherPrint IsVoucher={voucherNo} setVoucherNo={setVoucherNo} />
    </div>
  );
};

export default PaymentVoucher;
