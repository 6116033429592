import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  selectPrefix,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setreportsFromDate,
  setreportsTodate,
} from "../../utility/Redux/profile";
import { selectToken } from "../../utility/Redux/security";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { GetItemWiseSaleReport } from "./func";
import {
  ItemWiseSaleReportColumns,
  ItemWiseSaleReportPrintColumns,
} from "./columns";
import { SectionWiseSaleReportColumns } from "./columns";
import Footer from "./footer";
import GeniusTableForReports from "../../utility/component/printReport";
import GeniusTableReports from "../../utility/component/geniusComponents/geniusTableData";
import IsLocalData from "../../utility/component/isLocalData";
import FromToDateMobile from "../../utility/component/fromToDateMobile";
import { FromToDate } from "../../utility/component/fromToDate";
import FieldWayEmployeeHelp from "../../help/fieldWayEmployeeHelp";
import { setAlertWithTitle } from "../../utility/Redux/modal";
import MapWithMultyMarkers from "../../utility/component/multyMarker";

const DFRReport = () => {
  const prefix = useSelector(selectPrefix);
  const [purchaseList, setPurchaseList] = useState([]);
  const fromDate = useSelector(selectreportFromDate);
  const ToDate = useSelector(selectreportToDate);
  const width = useSelector(selectWidth);
  const [secName, setSecName] = useState("");
  const [cusMobile, setCusMobile] = useState("");
  const [fullItemWiseReport, setFullItemWiseReport] = useState([]);
  const [customerName, setCustomerName] = useState("");

  const columns = ItemWiseSaleReportColumns(width);
  const cols = SectionWiseSaleReportColumns(width);
  const [customerHelpStatus, setCustomerHelpStatus] = useState(false);
  const [summaryList, setSummaryList] = useState([]);
  const [employeEmail, setEmployeeEmail] = useState("");

  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const okButton = () => {
    if (employeEmail) {
      setSummaryList([]);
      setPurchaseList([]);

      const additionalData = {
        USER_EMAIL: employeEmail,
        table_prefix: prefix.replace(/_.+_/, "_"),
        FDATE: moment(fromDate).format("YYYY-MM-DD"),
        TDATE: moment(ToDate).format("YYYY-MM-DD"),
      };
      GetItemWiseSaleReport(
        additionalData,
        token,
        dispatch,
        setPurchaseList,
        setFullItemWiseReport
      );
    } else {
      dispatch(
        setAlertWithTitle({
          title: "Alert",
          msg: "Please select employee",
        })
      );
    }
  };
  const printColumns = ItemWiseSaleReportPrintColumns(width);
  const sectionPrintRef = useRef(null);

  const navigate = useNavigate();

  const printRef = useRef();
  const thermalPrintRef = useRef();
  const [selectedPrintOption, setSelectedPrintOption] = useState("print");

  const handlePrint = useReactToPrint({
    content: () =>
      selectedPrintOption === "print"
        ? printRef.current
        : thermalPrintRef.current,
    pageStyle: `
        @page {
          size: landscape;
        }
      `,
    onAfterPrint: () => {
      navigate("/item_wise_sale_report");
      setSelectedPrintOption("print");
    },
  });

  useEffect(() => {
    const callback = (details, data) => {
      setFullItemWiseReport(data);
      setPurchaseList(data);
      dispatch(setreportsFromDate(details[0].FDATE));
      dispatch(setreportsTodate(details[0].TDATE));
    };
    const elseFunc = () => {
      console.log("nothing");
    };
    IsLocalData("fieldway_dfr", callback, elseFunc);
  }, []);

  return (
    <div className=" bg-white flex flex-col h-full w-full  overflow-y-scroll  ">
      <div className="flex w-full h-full">
        <div className="flex w-full">
          <div className=" flex flex-col w-full   p-3 ">
            <div className="w-full">
              {width > 1281 ? (
                <FromToDate
                  onPrintClick={handlePrint}
                  onEmployeeSearchClick={() => {
                    setCustomerHelpStatus(true);
                  }}
                  hideReset={true}
                  EmployeeData={{
                    mobile: cusMobile,
                    first_name: customerName,
                  }}
                  submit={okButton}
                  reset={() => {
                    setSecName("");
                    setCusMobile("");
                    setPurchaseList([]);
                    setSummaryList([]);
                    setCustomerName("");
                  }}
                />
              ) : (
                <FromToDateMobile
                  onEmployeeSearchClick={() => {
                    setCustomerHelpStatus(true);
                  }}
                  hideReset={true}
                  EmployeeData={{
                    mobile: cusMobile,
                    first_name: customerName,
                  }}
                  onPrintClick={handlePrint}
                  submit={okButton}
                />
              )}
            </div>
            {width > 1281 ? (
              <input
                className="flex  h-[30px]   text-sm"
                value={secName}
                readOnly={true}
              />
            ) : (
              <div className="h-[25px]" />
            )}
            <div className="flex h-full w-full  ">
              <div
                style={{
                  height: `calc(100% - ${
                    width < 1000 ? 320 : width < 1300 ? 170 : 90
                  }px)`,
                  width:
                    columns.reduce((acc, row) => acc + Number(row.width), 0) +
                    20,
                }}
                className="  "
              >
                {purchaseList[0] ? (
                  <GeniusTableReports columns={columns} data={purchaseList} />
                ) : (
                  <div className="border rounded-sm h-full flex text-sm text-gray-500 items-center justify-center">
                    No Records Found
                  </div>
                )}
              </div>
              <div className=" flex-1  ml-5">
                <MapWithMultyMarkers locations={purchaseList} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "none",
        }}
      >
        <div ref={sectionPrintRef}>
          <GeniusTableForReports
            customeHeader={
              <div className="items-center  ">
                <div className="flex items-center">
                  <div className="mr-1 text-sm font-semibold flex justify-center ">
                    DFR Report
                  </div>
                  <div className="text-sm font-semibold">From :</div>
                  <div className="ml-1 text-sm">
                    {moment(fromDate).format("DD-MM-yyyy")}{" "}
                  </div>
                  <div className="text-sm font-semibold ml-2">To :</div>
                  <div className="ml-1 text-sm">
                    {moment(ToDate).format("DD-MM-yyyy")}{" "}
                  </div>
                </div>
              </div>
            }
            data={summaryList}
            headerWidth={true}
            columns={cols}
            marginTop={"-87px"}
          />
        </div>
      </div>

      <div
        style={{
          display: "none",
        }}
      >
        <div ref={printRef}>
          <GeniusTableForReports
            customeHeader={
              <div className="items-center  ">
                <div className="flex items-center">
                  <div className="mr-1 text-sm font-semibold flex justify-center ">
                    DFR Report
                  </div>
                  <div className="text-sm font-semibold">From :</div>
                  <div className="ml-1 text-sm">
                    {moment(fromDate).format("DD-MM-yyyy")}{" "}
                  </div>
                  <div className="text-sm font-semibold ml-2">To :</div>
                  <div className="ml-1 text-sm">
                    {moment(ToDate).format("DD-MM-yyyy")}{" "}
                  </div>
                </div>
              </div>
            }
            headerWidth={true}
            data={purchaseList[0] ? purchaseList : fullItemWiseReport}
            columns={printColumns}
            filteredData={summaryList}
            marginTop={"-87px"}
          />
          <FieldWayEmployeeHelp
            status={customerHelpStatus}
            onRowClick={(p) => {
              setCusMobile(p.row.CUSTOMER_MOBILE);
              setCustomerHelpStatus(false);
              setCustomerName(p.row.first_name);
              setEmployeeEmail(p.row.email);
            }}
            onClose={() => {
              setCustomerHelpStatus(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DFRReport;
