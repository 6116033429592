import { Loader } from "@googlemaps/js-api-loader";
import { useEffect, useRef } from "react";

const MapWithMultyMarkers = (props) => {
  const mapRef = useRef(null);
  const locationInfo = props.locations;
  console.log(locationInfo);

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_MAP_KEY,
      version: "weekly",
      libraries: ["places"],
    });

    loader.load().then((google) => {
      const geocoder = new google.maps.Geocoder();

      // Initialize the map
      const map = new google.maps.Map(mapRef.current, {
        center: {
          lat: 31.4379995,
          lng: 75.7585377,
        },
        zoom: 9,
      });

      let prevInfowindow = null;

      // Iterate through the locationInfo array
      locationInfo.forEach((info) => {
        const latitude = Number(info.gps_latitude || 31.4379995);
        const longitude = Number(info.gps_longitude || 75.7585377);

        const marker = new google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          map,
          title: `Customer : ${info.customer_name}`,
        });

        // Add click event listener for marker
        marker.addListener("click", () => {
          // Close the previously opened infowindow
          if (prevInfowindow) {
            prevInfowindow.close();
          }

          const latLng = { lat: latitude, lng: longitude };

          // Geocode the latLng to get the address
          geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === "OK") {
              if (results[0]) {
                const infowindow = new google.maps.InfoWindow({
                  content: `
                    <div style="font-family: Arial, sans-serif; font-size: 14px; color: #333; width: 380px; border-radius: 8px; box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); padding: 15px; background-color: #fff;">
                      <!-- Header -->
                      <div style="display: flex; flex-direction: row; align-items: center; margin-bottom: 10px; border-bottom: 1px solid #eee; padding-bottom: 10px;">
                        <p style="font-size: 16px; font-weight: 600; margin-right: 10px;">Customer:</p>
                        <p style="font-size: 16px; color: #555;">${info.customer_name}</p>
                 
                      </div>
          
                      <!-- Product Info -->
                      <div style="display: flex; flex-direction: row; align-items: center; margin-bottom: 10px;">
                        <p style="font-size: 15px; font-weight: 600; margin-right: 10px;">Product:</p>
                        <p style="font-size: 15px; color: #555;">${info.products_discussed}</p>
                      </div>
          
                      <!-- Address Info -->
                      <div style="margin-top: 10px; display: flex; flex-direction: column;">
                        <p style="font-size: 15px; font-weight: 600; margin-bottom: 5px;">Address:</p>
                        <p style="font-size: 14px; color: #555; line-height: 1.5; background-color: #f7f7f7; padding: 8px; border-radius: 4px;">
                          ${results[0].formatted_address}
                        </p>
                      </div>
                    </div>
                  `,
                });

                infowindow.open(map, marker);
                prevInfowindow = infowindow;
              } else {
                console.log("No results found");
              }
            } else {
              console.log(`Geocoder failed due to: ${status}`);
            }
          });
        });
      });
    });
  }, [props]);

  return <div ref={mapRef} style={{ height: "100%" }} />;
};

export default MapWithMultyMarkers;
