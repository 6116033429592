import moment from "moment";
import {
  FormatNumber2,
  FormatNumber3,
} from "../../utility/functions/formatNumber";
import { HeaderWidth } from "../../utility/constant";

export const ItemWiseSaleReportColumns = (width) => {
  const columns = [
    {
      field: "ICODE",
      accessor: "ICODE",
      type: "string",
      headerAlign: "center",

      width: width < 768 ? 80 : 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div
            className={`${
              params.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full flex items-center  `}
          >
            {params.value}{" "}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Code"}{" "}
        </div>
      ),
    },
    {
      field: "ITEMNAME",
      accessor: "ITEMNAME",
      type: "string",
      width: 280,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Item Name
        </div>
      ),
    },
    {
      field: "PUR_PRICE",
      accessor: "PUR_PRICE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== "0" ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Purchase Price
        </div>
      ),
    },

    {
      field: "OPBAL",
      accessor: "OPBAL",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 130 : 130,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== "0" ? FormatNumber3(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Opening
        </div>
      ),
    },
    {
      field: "PUR_QNTY",
      accessor: "PUR_QNTY",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 130 : 130,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== "0" ? FormatNumber3(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Purchase Qnty
        </div>
      ),
    },
    {
      field: "SALE_QNTY",
      accessor: "SALE_QNTY",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 130 : 130,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== "0" ? FormatNumber3(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Sale Qnty
        </div>
      ),
    },
    {
      field: "BAL_STOCK",
      accessor: "BAL_STOCK",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 130 : 130,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== "0" ? FormatNumber3(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Bal. Stock
        </div>
      ),
    },
    {
      field: "STOCK_VALUE",
      accessor: "STOCK_VALUE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 140 : 140,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== "0" ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Stock Value
        </div>
      ),
    },
  ];
  const extraWidth = width - [70 + 90 + HeaderWidth() + 30];
  const mobileColumns = [
    {
      field: "ICODE",
      accessor: "ICODE",
      type: "string",
      width: 70,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Code
        </div>
      ),
    },
    {
      field: "ITEMNAME",
      accessor: "ITEMNAME",
      type: "string",
      width: extraWidth,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Item Name
        </div>
      ),
    },
    {
      field: "BAL_STOCK",
      accessor: "BAL_STOCK",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 90,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== "0" ? FormatNumber3(param.value) : ""}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Bal. Stock
        </div>
      ),
    },
  ];
  return width > 500 ? columns : mobileColumns;
};

export const ItemWiseSaleReportPrintColumns = (width) => {
  const columns = [
    {
      field: "DATE",
      accessor: "DATE",
      type: "date",
      headerAlign: "center",

      width: width < 768 ? 100 : 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div
            className={`${
              params.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full flex items-center  `}
          >
            {moment(params.value).format("DD-MM-YYYY")}{" "}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          {"Date"}{" "}
        </div>
      ),
    },
    {
      field: "ITEMNAME",
      accessor: "ITEMNAME",
      type: "string",
      width: width < 768 ? 100 : width < 1400 ? 180 : 180,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Item Name
        </div>
      ),
    },

    {
      field: "CUSTOMER_NAME",
      accessor: "CUSTOMER_NAME",
      type: "string",
      width: width < 768 ? 100 : width < 1400 ? 180 : 180,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Customer Name
        </div>
      ),
    },
    {
      field: "CUSTOMER_MOBILE",
      accessor: "CUSTOMER_MOBILE",
      type: "string",
      width: width < 768 ? 100 : width < 1400 ? 180 : 180,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Customer Mobile
        </div>
      ),
    },

    {
      field: "QNTY",
      accessor: "QNTY",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 100,
      align: "right",

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Qnty
        </div>
      ),
    },
    {
      field: "SALE_PRICE",
      accessor: "SALE_PRICE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== "0" ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Sale Price
        </div>
      ),
    },
    {
      field: "AMOUNT",
      accessor: "AMOUNT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== "0" ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Amount
        </div>
      ),
    },
    {
      field: "TAX1_RATE",
      accessor: "TAX1_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== "0" ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          CGST %
        </div>
      ),
    },
    {
      field: "TAX1_AMT",
      accessor: "TAX1_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 110,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          CGST Amount
        </div>
      ),
    },
    {
      field: "TAX2_RATE",
      accessor: "TAX2_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== "0" ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          SGST %
        </div>
      ),
    },
    {
      field: "TAX2_AMT",
      accessor: "TAX2_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 110,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== 0 ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          SGST Amount
        </div>
      ),
    },
    {
      field: "TAX3_RATE",
      accessor: "TAX3_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== "0" ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          IGST %
        </div>
      ),
    },
    {
      field: "TAX3_AMT",
      accessor: "TAX3_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 110,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== 0 ? FormatNumber2(param.value) : ""}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          IGST Amount
        </div>
      ),
    },
    {
      field: "SECTION_NAME",
      accessor: "SECTION_NAME",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 110,
      align: "right",

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          Section
        </div>
      ),
    },
    {
      field: "GTOTAL",
      accessor: "GTOTAL",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 120,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value ? FormatNumber2(param.value) : ""}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Grand Total
        </div>
      ),
    },
  ];
  return columns;
};
