const deleteWholeDatabase = (database) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.deleteDatabase(database);

    request.onsuccess = () => {
      console.log(`Database "${database}" deleted successfully`);
      resolve(`Database "${database}" deleted successfully`);
    };

    request.onerror = (event) => {
      console.error(`Error deleting database "${database}":`, event.target.error);
      reject(event.target.error);
    };

    request.onblocked = () => {
      console.warn(`Deletion of database "${database}" is blocked`);
      reject(new Error(`Database "${database}" deletion blocked`));
    };
  });
};
  export default deleteWholeDatabase;
  

