import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";

import { useDispatch, useSelector } from "react-redux";
import { useFocus } from "../../hooks/hooks";
import { CancelButton, SubmitButton } from "../buttons";
import { setAlertWithTitle } from "../../Redux/modal";
import { selectOtpMobile } from "../../Redux/security";

function maskPhoneNumber(phoneNumber) {
  const maskedSection = phoneNumber.slice(0, -3).replace(/\d/g, "*"); // Replaces all but the last 3 digits
  const visibleSection = phoneNumber.slice(-3); // Keeps the last 3 digits
  return maskedSection + visibleSection;
}
const OtpAlertModal = ({ status, otp, onCancel, onSubmit }) => {
  const dispatch = useDispatch();
  const [firstValue, setFirstValue] = useState("");
  const [secValue, setSecValue] = useState("");
  const [wrongOtp, setWrongOTP] = useState(false);
  const [thirdValue, setThirdValue] = useState("");
  const [fourthValue, setFourthValue] = useState("");
  const [firstref, setFirstFocus] = useFocus();
  const [secref, setSecFocus] = useFocus();
  const [thirdref, setThirdFocus] = useFocus();
  const [fourthref, setFourthFocus] = useFocus();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
    // border: '1px solid white',
    boxShadow: 24,
    borderRadius: "7px",
    outline: "none",
  };

  useEffect(() => {
    setFirstFocus();
  }, []);
  const phoneNumber = useSelector(selectOtpMobile);
  const maskedPhoneNumber = phoneNumber ? maskPhoneNumber(phoneNumber) : "";
  return (
    <div>
      <Modal open={status}>
        <div style={style} className=" w-full min-w-[250px] max-w-[300px]">
          <div className={"h-full w-full bg-white  rounded-sm  p-3"}>
            <div className=" flex justify-center w-full rounded-sm">
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: -30,

                  borderRadius: 100,
                }}
                className="bg-white p-[2px]"
              >
                {/* <img
                  src={logo}
                  alt=""
                  style={{
                    width: '50px',
                    height: 'auto',
                  }}
                /> */}
              </div>
            </div>

            <div className="w-full justify-center text-center text-[#1c1a1a] flex mt-2 font-semibold items-top">
              Verify OTP
            </div>
            <div className="w-full justify-center text-center text-[#1c1a1a] flex text-[11px] mt-1">
              OTP Sent to {maskedPhoneNumber}
            </div>
            <div
              style={{
                fontSize: "13px",
              }}
              className="w-full justify-center text-center text-[#1c1a1a] flex mt-2 "
            ></div>

            <div className="w-full justify-center mt-2  rounded-sm">
              {/* <AlertOkButton onClick={handleClose} /> */}
              <div className="flex w-full justify-center">
                <input
                  type="number"
                  maxLength={1}
                  value={firstValue}
                  ref={firstref}
                  autoFocus={true}
                  onChange={(e) => {
                    setFirstValue(e.target.value.slice(-1));
                    if (e.target.value) {
                      setSecFocus();
                    }
                  }}
                  className="rounded-sm py-2 text-center border border-[#383535] focus:border-[#03A9F4] focus:border"
                  style={{
                    width: "20%",
                    outline: "none",
                  }}
                  // Set focus on the first input initially
                />
                <input
                  className="rounded-sm border border-[#383535] focus:border-[#03A9F4] focus:border py-2 mx-3 text-center"
                  style={{
                    width: "20%",
                    outline: "none",
                  }}
                  type="number"
                  maxLength={1}
                  value={secValue}
                  ref={secref}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace") {
                      if (!secValue) {
                        setFirstFocus();
                      }
                    }
                  }}
                  onChange={(e) => {
                    setSecValue(e.target.value.slice(-1));
                    if (e.target.value) {
                      setThirdFocus();
                    }
                  }}
                />
                <input
                  className="rounded-sm border border-[#383535] focus:border-[#03A9F4] py-2 mr-3 text-center"
                  style={{
                    width: "20%",
                    outline: "none",
                  }}
                  type="number"
                  maxLength={1}
                  value={thirdValue}
                  ref={thirdref}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace") {
                      if (thirdValue === "") {
                        setSecFocus();
                      }
                    }
                  }}
                  onChange={(e) => {
                    setThirdValue(e.target.value.slice(-1));
                    if (e.target.value) {
                      setFourthFocus();
                    }
                  }}
                />
                <input
                  className="rounded-sm border border-[#383535] focus:border-[#03A9F4] py-2 text-center"
                  style={{
                    width: "20%",
                    outline: "none",
                  }}
                  type="number"
                  maxLength={1}
                  value={fourthValue}
                  ref={fourthref}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace") {
                      if (fourthValue === "") {
                        setThirdFocus();
                      }
                    }
                  }}
                  onChange={(e) => {
                    setFourthValue(e.target.value.slice(-1));
                  }}
                />
              </div>
              {wrongOtp && (
                <div className="w-full justify-end flex text-sm text-red-500 pr-3 mt-1">
                  Wrong OTP
                </div>
              )}
            </div>

            <div className="mt-4">
              <SubmitButton
                onClick={() => {
                  if (
                    Number(firstValue + secValue + thirdValue + fourthValue) ===
                    Number(otp)
                  ) {
                    onCancel();
                    setWrongOTP(false);
                    setFirstValue("");
                    setSecValue("");

                    setThirdValue("");

                    setFourthValue("");

                    onSubmit();
                    dispatch(
                      setAlertWithTitle({
                        title: "Success",
                        msg: "OTP Validated",
                        lottie: "success",
                      })
                    );
                  } else {
                    setWrongOTP(true);
                  }
                }}
              />
              <div className="mt-2">
                <CancelButton
                  onClick={() => {
                    onCancel();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OtpAlertModal;
