import { setdefaultProfileSetting } from "../utility/Redux/profile";
import { handleSignout } from "../utility/component/modals/logout";
import { database } from "../utility/functions/firebase";
import CallAPI from "../utility/functions/getData";
import { child, get, onValue, ref, set } from "firebase/database";
export const getDefaultProfileSetting = (
  prefix,
  code,
  token,
  dispatch,
  navigate,
  setTwoButtonAlert
) => {
  const data = {
    table_prefix: prefix,
    code: code,
  };
  CallAPI(
    "/api/userSettings/userDefaultSetting",
    data,
    token,
    (e) => {
      if (!e.data.error) {
        if (e.data.response[0]) {
          dispatch(
            setdefaultProfileSetting({
              ...e.data.response[0],
            })
          );
        } else {
          setTwoButtonAlert((p) => {
            return {
              ...p,
              status: true,
              title: "Alert",
              msg: "Kindly contact your manager to update you default settings.",
              button1: "ok",
              button1Click: () => {
                setTwoButtonAlert((p) => {
                  return {
                    ...p,
                    status: false,
                  };
                });
                // handleSignout(
                //   dispatch,
                //   navigate,
                //   prefix

                // );
              },
            };
          });
        }
      }
    },
    dispatch
  );
};

export const getExpireAt = () => {
  const expire = localStorage.getItem("expiresAt");
  return expire;
};

export function detectIsAnyOtherUserTryToLogin(
  prefix,
  setAlertTwoButtonModal,
  alertTwoButtonModal,
  dispatch,
  navigate,
  userCode,
  statusField
) {
  const connectedRef = ref(database, `loginAuth/${prefix}/user/${userCode}/`);
  const connectedMsgRef = ref(
    database,
    `loginAuth/${prefix}/user/${userCode}/${statusField}/msg`
  );
  get(child(connectedRef, `/${statusField}/connection`))
    .then((sn) => {
      console.log(sn.exists());
      console.log(statusField);
      if (sn.exists()) {
        const data = sn.val();

        if (data.includes(getExpireAt())) {
          get(child(connectedRef, `/${statusField}/msg`)).then((msg) => {
            if (msg.exists()) {
              onValue(connectedMsgRef, (sn) => {
                if (sn.exists()) {
                  const data = sn.val();
                  if (data === "isConnected") {
                    set(connectedMsgRef, `rejected`);
                  }
                }
              });
            }
          });
        } else {
          setAlertTwoButtonModal({
            ...alertTwoButtonModal,
            status: true,
            title: "Alert",
            msg: "This account is already connected E-1",
            button1: "Signout",

            button1Click: () => {
              setAlertTwoButtonModal({
                ...alertTwoButtonModal,
                status: false,
              });
              handleSignout(dispatch, navigate);
            },
          });
        }
      } else {
        setAlertTwoButtonModal({
          ...alertTwoButtonModal,
          status: true,
          title: "Alert",
          msg: "This account is already connected E-2",
          button1: "Signout",

          button1Click: () => {
            setAlertTwoButtonModal({
              ...alertTwoButtonModal,
              status: false,
            });
            handleSignout(dispatch, navigate);
          },
        });
      }
    })
    .catch((e) => {
      setAlertTwoButtonModal({
        ...alertTwoButtonModal,
        status: true,
        title: "Alert",
        msg: "This account is already connected E-3",
        button1: "Signout",

        button1Click: () => {
          setAlertTwoButtonModal({
            ...alertTwoButtonModal,
            status: false,
          });
          handleSignout(dispatch, navigate);
        },
      });
    });
}
