import { Autocomplete, FormControl, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import React from 'react'
import { FontSizeWidth } from '../constant';
import { useSelector } from 'react-redux';
import { selectWidth } from '../Redux/profile';

const TextBoxMui = ({label,id,bgColor,fontWeight,align,onKeyDown,width,value,search,onChange,type,option,readOnly,refrance,onFocus,onClick,autoFucus,color,fontSize}) => {

 
  const handleWheel = (e) => {
    if (document.activeElement === e.currentTarget) {
      e.preventDefault(); // Prevent default behavior (scrolling) only if input is focused
    }

  };

  const windowWidth = useSelector(selectWidth)

  return (
    <>
{!option &&     <FormControl size={'small'} sx={{ width: width,fontSize:FontSizeWidth(windowWidth),}} variant="outlined">

<InputLabel sx={{
  fontSize:fontSize?fontSize:FontSizeWidth(windowWidth),
  fontWeight:fontWeight?fontWeight:'normal',
color:color?color:'gray',
'&.Mui-focused': {
  color: 'black', // Color when focused
  fontSize:FontSizeWidth(windowWidth),

}
}} >{label}</InputLabel>
<OutlinedInput
autoFocus={autoFucus?autoFucus:false}
onFocus={onFocus}
id={id?id:''}

onKeyDown={onKeyDown ?onKeyDown:()=>{}}
onClick={onClick?onClick:()=>{}}
 onChange={onChange} value={value}
  type={type ? type :'text'}
  readOnly={readOnly ?readOnly:false}
  inputRef={refrance}
  inputProps={{
  
    style: { textAlign: align?align:'left',outline:'none', fontSize: FontSizeWidth(windowWidth),backgroundColor:bgColor?bgColor:'white'} // Apply text alignment here
  }}
  sx={{
    fontSize:FontSizeWidth(windowWidth),
    fontWeight:fontWeight?fontWeight:'normal',
  }}
onWheel={handleWheel}
  endAdornment={search ?
    <InputAdornment position="end">
 
  <button onClick={search}>
  <i className="bi bi-search text-orange ml-2 flex items-center"></i>

  </button>
    </InputAdornment> :null
  }
  label={label}
/>
</FormControl>}
{option ?<Autocomplete
  disablePortal
  size={'small'}
  value={value}
  options={option.map((t, i) => ({
    label: t.value, 
    id: i
  }))}
  style={{
    fontSize:FontSizeWidth(windowWidth),
  }}
  onChange={(e, v) => {
    if (v) {
      onChange(v.label);
    }
  }}
  disableClearable={true}
  isOptionEqualToValue={(option, value) => option.label === value}
  renderInput={(params) => (
    <TextField 
      {...params} 
      label={label} 
      InputProps={{
        ...params.InputProps,
        sx: { fontSize: FontSizeWidth(windowWidth) },  // Set the font size here
      }}
      InputLabelProps={{
        sx: { fontSize: FontSizeWidth(windowWidth) },  // Also set font size for the label
      }}
    />
  )}
/>
: null}
    </>

  )
}

export default TextBoxMui