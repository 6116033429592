import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../utility/Redux/security";
import {
  selectEmail,
  selectPrefix,
  selectUserType,
  setdefaultProfileSetting,
} from "../utility/Redux/profile";
import { setAlertWithTitle, setSpinnerLoading } from "../utility/Redux/modal";
import CallAPI from "../utility/functions/getData";
import GeniustextInput from "../utility/component/mastertext";
import { SaveButton, UndoButton } from "../utility/component/buttons";
import AlertModalTwoButton from "../utility/component/modals/alertWithTwoButton";
import ModalHeaderStyle from "../utility/component/styles/modalHeader";
import { useNavigate } from "react-router-dom";
import ModUserHelp from "../help/userhelp";

const UserDefaultSetting = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [userhelpStatus, setuserhelpStatus] = useState(false);
  const [defaultSettingEmail, setDefaultSettingEmail] = useState("");
  const [sendWhatsapp, setSendWhatsapp] = useState(false);
  const [showSaveBill, setShowSaveBill] = useState(false);
  const [modifySaleBill, setModifySaleBill] = useState(false);
  const [isCheckOffer, setIsCheckOffer] = useState(false);
  const [modifyPurBill, setModifyPurBill] = useState(false);
  const [isLoginOtp, setIsLoginOtp] = useState(false);
  const [sendSms, setSendSms] = useState(false);
  const [otpValidDays, setOTPValidDays] = useState(1);
  const orginalUserType = useSelector(selectUserType);
  const saleOption = [
    {
      name: "Inclusive",
      value: "Inclusive",
    },
    {
      name: "Exclusive",
      value: "Exclusive",
    },
  ];
  const [saleType, setSaleType] = useState(saleOption[0].value);
  const [isPersonlWhatsApp, setIsPersonlWhatsApp] = useState(false);
  const [userCode, setUserCode] = useState("");
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: "",
    msg: "",
    status: false,
    setStatus: "",
    button1: "",
    button1Click: "",
    button2: "",
    button2Click: "",
  });
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const userEmail = useSelector(selectEmail);
  const [checkstock, setCheckStock] = useState(false);
  const saveSystemDefaultSetting = () => {
    dispatch(setSpinnerLoading("Saving"));
    const data = {
      table_prefix: prefix.replace(/_.+_/, "_"),
      CODE: userCode,
      SALE_TYPE: saleType,
      SEND_BILL_BY_WHATSAPP: sendWhatsapp ? "Yes" : "No",
      SEND_BILL_BY_SMS: sendSms ? "Yes" : "No",
      CHECK_STOCK: checkstock ? "Yes" : "No",
      PRINT_YOU_SAVED: showSaveBill ? "Yes" : "No",
      MODIFY_SALE_BILL: modifySaleBill ? "Yes" : "No",
      MODIFY_PURCHASE_BILL: modifyPurBill ? "Yes" : "No",
      OFFER_SALE_PRICE: isCheckOffer ? "Yes" : "No",
      LOGIN_OTP: isLoginOtp ? "Yes" : "No",
      PERSONAL_NO_WHATSAPP: isPersonlWhatsApp ? "Yes" : "No",
      VALID_FOR_DAYS: otpValidDays ? otpValidDays : 1,
    };

    CallAPI(
      "/api/userSettings/changeUserDefaultSetting",
      data,
      token,
      (res) => {
        if (!res.data.error) {
          if (userEmail === defaultSettingEmail) {
            dispatch(setdefaultProfileSetting(data));
          }
          dispatch(setSpinnerLoading("Saved Successfully"));
          dispatch(
            setAlertWithTitle({
              title: "Data saved successfully",
              msg: "Please log in again to apply these changes to your account",
              lottie: "success",
            })
          );
        } else {
          dispatch(
            setAlertWithTitle({
              title: "Error",
              msg: "Something went wrong",
            })
          );
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
        back();
      }
    );
  };

  const back = () => {
    setSendSms(false);
    setSendWhatsapp(false);
    setDefaultSettingEmail("");
    setUserCode("");
    setSaleType(saleOption[0].value);
    setCheckStock(false);
    setModifyPurBill(false);
    setModifySaleBill(false);
    setShowSaveBill(false);
    setIsLoginOtp(false);
    setOTPValidDays(1);
    setIsCheckOffer(false);
    setIsPersonlWhatsApp(false);
  };

  const getUserDefaultSetting = (code) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const data = {
      code: code,
      table_prefix: prefix.replace(/_.+_/, "_"),
    };
    CallAPI(
      "/api/userSettings/userDefaultSetting",
      data,
      token,
      (res) => {
        if (!res.data.error) {
          if (res.data.response[0]) {
            setSaleType(res.data.response[0].SALE_TYPE);
            setSendWhatsapp(
              res.data.response[0].SEND_BILL_BY_WHATSAPP === "Yes"
            );
            setSendSms(res.data.response[0].SEND_BILL_BY_SMS === "Yes");
            setCheckStock(res.data.response[0].CHECK_STOCK === "Yes");
            setShowSaveBill(res.data.response[0].PRINT_YOU_SAVED === "Yes");
            setModifyPurBill(
              res.data.response[0].MODIFY_PURCHASE_BILL === "Yes"
            );
            setModifySaleBill(res.data.response[0].MODIFY_SALE_BILL === "Yes");
            setIsLoginOtp(res.data.response[0].LOGIN_OTP === "Yes");
            setIsCheckOffer(res.data.response[0].OFFER_SALE_PRICE === "Yes");
            setIsPersonlWhatsApp(
              res.data.response[0].PERSONAL_NO_WHATSAPP === "Yes"
            );
            setOTPValidDays(res.data.response[0].VALID_FOR_DAYS);
          } else {
            dispatch(
              setAlertWithTitle({
                title: "No data",
                msg: "No data found",
              })
            );
          }
        }
      },
      dispatch
    );
  };
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const formRef = useRef(null);
  return (
    <div
      ref={formRef}
      className="w-full  flex justify-center bg-[#f5f5f7] h-full py-3"
    >
      <div className="text-sm min-w-[340px]  rounded-sm   flex flex-col   h-full px-3 ">
        <div>
          <ModalHeaderStyle
            header={"User Default Settings"}
            onClose={() => {
              navigate("/settings");
            }}
          />
        </div>

        <div className="grid grid-cols-1 t:grid-cols-1 gap-2 rounded-b-sm  bg-white border-brown px-4 max-sm:px-2">
          <div className="flex-1 mt-4">
            <GeniustextInput
              title={"User Email"}
              grid={true}
              width={windowWidth > 764 ? "250px" : "150px"}
              type={"text"}
              search={() => setuserhelpStatus(true)}
              value={defaultSettingEmail}
            />
          </div>
          {orginalUserType === "ADMIN" && (
            <GeniustextInput
              grid={true}
              option={saleOption}
              value={saleType}
              onChange={(e) => setSaleType(e.target.value)}
              width={windowWidth > 764 ? "250px" : "150px"}
              title={"Sale Type"}
            />
          )}
          {orginalUserType === "MANAGER" && (
            <GeniustextInput
              grid={true}
              value={otpValidDays}
              type={"number"}
              onChange={(e) => setOTPValidDays(e.target.value)}
              width={windowWidth > 764 ? "250px" : "150px"}
              title={"OTP Valid Days"}
            />
          )}
          Permissions
          <div className=" w-full border p-2  ">
            <div className="grid grid-cols-1 t:grid-cols-2 gap-2 w-full mt-1 ">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={sendWhatsapp}
                  onChange={(e) => setSendWhatsapp(e.target.checked)}
                />
                <div className="ml-1 text-[15px] font-semibold">
                  Send Bill by WhatsApp
                </div>
              </div>
              <div className="flex items-center ">
                <input
                  type="checkbox"
                  checked={sendSms}
                  onChange={(e) => setSendSms(e.target.checked)}
                />
                <div className="ml-1 text-[15px] font-semibold">
                  Send Bill by SMS
                </div>
              </div>
            </div>
            <div className="flex w-full  mt-2">
              <div className="grid grid-cols-1 t:grid-cols-2 gap-2 w-full mt-1 ">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={checkstock}
                    onChange={(e) => setCheckStock(e.target.checked)}
                  />
                  <div className="ml-1 text-[15px] font-semibold">
                    Check Stock
                  </div>
                </div>

                <div className="flex items-center ">
                  <input
                    type="checkbox"
                    checked={showSaveBill}
                    onChange={(e) => setShowSaveBill(e.target.checked)}
                  />
                  <div className="ml-1 text-[15px] font-semibold">
                    Show Saved Amount in bill
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full  mt-2">
              <div className="grid grid-cols-1 t:grid-cols-2 gap-2 w-full mt-1 ">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={modifySaleBill}
                    onChange={(e) => setModifySaleBill(e.target.checked)}
                  />
                  <div className="ml-1 text-[15px] font-semibold">
                    Modify billing
                  </div>
                </div>

                <div className="flex items-center ">
                  <input
                    type="checkbox"
                    checked={modifyPurBill}
                    onChange={(e) => setModifyPurBill(e.target.checked)}
                  />
                  <div className="ml-1 text-[15px] font-semibold">
                    Modify Purchase
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full  mt-2">
              <div className="grid grid-cols-1 t:grid-cols-2 gap-2 w-full mt-1 ">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={isCheckOffer}
                    onChange={(e) => setIsCheckOffer(e.target.checked)}
                  />
                  <div className="ml-1 text-[15px] font-semibold">
                    Check Offer
                  </div>
                </div>
                {orginalUserType === "MANAGER" && (
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={isLoginOtp}
                      onChange={(e) => setIsLoginOtp(e.target.checked)}
                    />
                    <div className="ml-1 text-[15px] font-semibold">
                      Login Otp
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          Service Type
          <div className="w-full border p-2">
            <div className="flex font-semibold">WhatsApp Type</div>
            <div className="flex  mt-1">
              {/* <div className="flex items-center">
                <input
                  type="radio"
                  checked={isPersonlWhatsApp}
                  onChange={(e) => setIsPersonlWhatsApp(true)}
                />
                <div className="ml-1 text-[15px] ">Personal </div>
              </div> */}

              <div className="flex items-center ml-3">
                <input
                  type="radio"
                  checked={!isPersonlWhatsApp}
                  onChange={(e) => setIsPersonlWhatsApp(false)}
                />
                <div className="ml-1 text-[15px] ">Commercial</div>
              </div>
            </div>
            {isPersonlWhatsApp && (
              <div className="ml-4 mt-1">
                <div>Disclaimer</div>
                <div className="max-w-[390px] text-red-700">
                  It is not guaranteed that you will not be
                  <label className="font-semibold text-red-900 mx-1">
                    blocked
                  </label>
                  by using this method. WhatsApp does not allow bots or
                  unofficial clients on their platform, so this method cannot be
                  considered entirely safe. We highly recommend using a
                  commercial method.
                </div>
              </div>
            )}
          </div>
          <div className="flex w-full justify-end  py-3">
            <SaveButton
              onClick={() => {
                if (defaultSettingEmail) {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    status: true,
                    title: "Save",
                    msg: "Are you want to save this Data ?",
                    button1: "Yes",
                    button2: "No",

                    button1Click: () => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: false,
                      });
                      saveSystemDefaultSetting();
                    },
                    button2Click: () => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: false,
                      });
                    },
                  });
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: "Alert",
                      msg: "Please select user first",
                    })
                  );
                }
              }}
            />
            <UndoButton onClick={back} />
          </div>
          <AlertModalTwoButton
            details={alertTwoButtonModal}
            setAlertTwoButtonModal={setAlertTwoButtonModal}
          />
        </div>
      </div>
      <ModUserHelp
        onRowClick={(p) => {
          setDefaultSettingEmail(p.row.USER_EMAIL);
          setuserhelpStatus(false);
          setUserCode(p.row.CODE);
          getUserDefaultSetting(p.row.CODE);
          console.log(p.row);
        }}
        status={userhelpStatus}
        onClose={() => {
          setuserhelpStatus(false);
        }}
      />
    </div>
  );
};

export default UserDefaultSetting;
