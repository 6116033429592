import { parseISO } from 'date-fns';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const GeniusSelectDate = ({ date,onChange,width, setDate, SetDateFocus, dateFocus,border,autoFocus ,grid}) => {
  return (
    <div
      style={{
        border: `1px solid ${border}`,
        width:grid?'auto':'fit'

      }}
      className="flex rounded-sm h-full   overflow-hidden"
    >
      <div
        style={{
          padding: '5px',
          borderRight: '1px solid #a6a2a2 ',
          width:width?width: '100%',
        }}
        className="bg-[#f1efeb] overflow-hidden w-full flex items-center flex-1 "
      >
        <DatePicker
          className=" bg-transparent flex items-center text-sm flex-1 outline-0 overflow-hidden  "
          dateFormat={
            window.innerWidth <= 500 ? ' d MMM , yyyy ' : 'd MMM , yyyy'
          }
        
          autoFocus={autoFocus}
          selected={parseISO(moment(date).format('YYYY-MM-DD'))}
          onChange={(e) => {
            if(onChange){
              onChange(e)
            }
            if(setDate){
              setDate(moment(e).format('YYYY-MM-DD'));

            }
            SetDateFocus(false);
          }}
          onFocus={() => {
            SetDateFocus(true);
          }}
          readOnly={true}
          onClickOutside={() => SetDateFocus(false)}
          open={dateFocus}
     

        />
      </div>
      <div
        style={{
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.5)',
        }}
        className="flex items-center  bg-[#f7dda4] rounded-r-sm "
      >
        <i
          className="bi bi-calendar2  px-2 py-[3px]"
          onClick={() => SetDateFocus(true)}
          style={{ fontSize: '14px' }}
        ></i>
      </div>
    </div>
  );
};

export default GeniusSelectDate;
