import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import {
  selectEmail,
} from '../../utility/Redux/profile';

const ProtectedRoute = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email = useSelector(selectEmail);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const expires = localStorage.getItem('expiresAt');
  const date = new Date().getTime();
  const UserTime = () => {
    if (expires >= date) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      return navigate('/auth/login');
    }
  };

  const checkUserToken = () => {
    const userToken = localStorage.getItem('tkn');

    if (userToken) {
      UserTime();
    } else {
      setIsLoggedIn(false);
      return navigate('/auth/login');
    }
  };

  useEffect(() => {
    checkUserToken();
  }, [email]);
  return <React.Fragment>{isLoggedIn ? props.children : null}</React.Fragment>;
};
export default ProtectedRoute;
