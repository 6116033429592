import { Fade, Modal } from '@mui/material'
import React from 'react'
import Party_Master from '../masters/account-master';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: 'white',
  };
const PartyMasterCreaterModal = ({status,onClose,hidePartyList,afterCreate}) => {
  return (
    <Modal
    open={status}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Fade
      in={status}
      style={{
        transitionDelay: status ? "100ms" : "0ms",
      }}
    >
      <div style={style}>
<Party_Master afterCreate={afterCreate} hidePartyList={hidePartyList} onClose={onClose} />
        </div>
        </Fade>
        </Modal>
  )
}

export default PartyMasterCreaterModal