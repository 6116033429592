import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUserType, selectWidth } from "../utility/Redux/profile";
import ModalHeaderStyle from "../utility/component/styles/modalHeader";
import GeniustextInput from "../utility/component/mastertext";
import ProfilePicFrame from "../utility/component/styles/profilePicFrame";
import UploadImageButtons from "../utility/component/UploadImageButtons";
import { SaveButton, UndoButton } from "../utility/component/buttons";
import { setModCompanyHelpStatus } from "../utility/Redux/modal";
import CompanyHelp from "../help/companyHelp";
import GeniusSelectWithTitle from "../utility/component/geniusComponents/customeSelect";
import { getAllISOCodes } from "iso-country-currency";
import moment from "moment";

const CompanyInfo = ({
  countries,
  selectedFile,
  setSelectedFile,
  webcamStatus,
  setUserEmail,
  setCompanyDetails,
  setWebcamStatus,
  webcamRef,
  capturedImage,
  setCapturedImage,
  companyInfo,
  stateList,
  cityList,
  handleDrop,
  firebaseUrl,
  setCompanyInfo,
  UpdateCompanyDetailsAPI,
  setNumerOfUser,
  no_of_users,
  path,
  setFirebaseUrl,
  activeUsers,
  setActiveusers,
}) => {
  const formRef = useRef(null);
  const symbleList = getAllISOCodes();
  const [companyHelpStatus, setCompanyhelpStatus] = useState(false);
  const symbelList2 = symbleList.map((d) => {
    return {
      name: `${d.symbol} ---- ${d.currency}`,
      value: d.currency,
      isoCode: d.iso,
      symbol: d.symbol,
    };
  });
  useEffect(() => {
    const inputFields = formRef.current.querySelectorAll("input, select");

    // Add a keydown event listener to each input and select field
    inputFields.forEach((field, index) => {
      field.addEventListener("keydown", (event) => {
        if (event.key === "Enter") {
          event.preventDefault(); // Prevent form submission

          const nextIndex = (index + 1) % inputFields.length; // Calculate the index of the next field
          if (index === 9) {
            inputFields[0].focus(); // Set focus on the 13th field for the 10th field
          } else {
            if (index === 4) {
              inputFields[6].focus(); // Set focus on the 13th field for the 10th field
            } else {
              inputFields[nextIndex].focus(); // Set focus on the next field
            }
          }
        }
      });
    });
    return () => {
      inputFields.forEach((field) => {
        field.removeEventListener("keydown", () => {});
      });
    };
  }, []);

  const headerRef = useRef(null);
  const windowSize = useSelector(selectWidth);
  const navigate = useNavigate();
  const userType = useSelector(selectUserType);
  const dispatch = useDispatch();

  return (
    <div
      ref={formRef}
      className="w-full flex  justify-center  bg-gray-50 h-full  p-3"
    >
      <div className="text-sm  rounded-sm  h-full overflow-hidden">
        <div ref={headerRef} className="bg-white   ">
          <ModalHeaderStyle
            header={"Company Master"}
            onClose={() => navigate("/settings")}
          />
        </div>
        <div
          style={{
            height: headerRef.current
              ? `calc(100% - ${headerRef.current.clientHeight}px)`
              : "90%",
          }}
          className="      flex flex-col  "
        >
          <div className="overflow-y-scroll  py-3 px-5 justify-center p-3  pt-5 bg-white  flex  ">
            <div>
              <div className="flex  ">
                <div>
                  <div className="flex justify-between ">
                    <GeniustextInput
                      type={"text"}
                      search={
                        userType === "ADMIN"
                          ? () => {
                              setCompanyhelpStatus(true);
                            }
                          : false
                      }
                      title={"Company Name"}
                      width={windowSize > 764 ? "280px" : "140px"}
                      error={false}
                      value={companyInfo.company_name}
                      onChange={(t) => {
                        setCompanyInfo({
                          ...companyInfo,
                          company_name: t.target.value,
                        });
                      }}
                      maxLength={100}
                    />
                  </div>
                  <div>
                    <div className="flex justify-between ">
                      <GeniustextInput
                        width={windowSize > 764 ? "280px" : "140px"}
                        type={"text"}
                        title={"Email"}
                        value={companyInfo.user_email}
                        error={"email"}
                        onChange={(t) => {
                          console.log(t.target.value);
                        }}
                        maxLength={100}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-between ">
                      <GeniustextInput
                        width={windowSize > 764 ? "280px" : "140px"}
                        type={"number"}
                        title={"Mobile"}
                        value={companyInfo.company_mobile}
                        error={"mobile"}
                        onChange={(t) => {
                          setCompanyInfo({
                            ...companyInfo,
                            company_mobile: t.target.value,
                          });
                        }}
                        maxLength={100}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  {" "}
                  <div className="w-[140px] h-[140px] ml-3 ">
                    <ProfilePicFrame
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                      webcamRef={webcamRef}
                      webcamStatus={webcamStatus}
                      capturedImage={capturedImage}
                      setCapturedImage={setCapturedImage}
                      setWebcamStatus={setWebcamStatus}
                      customerFirebasePic={firebaseUrl}
                      path={path}
                      setFirebaseUrl={setFirebaseUrl}
                    />
                  </div>
                  <div className="flex mt-2 w-[140px] ml-3 ">
                    <div className="flex w-[100%] items-center ">
                      <UploadImageButtons
                        title={"Company Logo"}
                        setSelectedFile={setSelectedFile}
                        webcamStatus={webcamStatus}
                        setWebcamStatus={setWebcamStatus}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="flex justify-between ">
                  <GeniustextInput
                    type={"text"}
                    title={" Website"}
                    width={windowSize > 764 ? "280px" : "140px"}
                    error={false}
                    value={companyInfo.company_website}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        company_website: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>

                <div className="flex justify-between ml-3 ">
                  <GeniustextInput
                    type={"text"}
                    title={"Address"}
                    width={windowSize > 764 ? "280px" : "140px"}
                    error={false}
                    value={companyInfo.address}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        address: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>
              </div>

              <div className="flex">
                <div className="flex justify-between ">
                  <GeniusSelectWithTitle
                    height={"160px"}
                    title={"Country"}
                    country={true}
                    width={windowSize > 764 ? "280px" : "140px"}
                    options={countries}
                    value={companyInfo.country}
                    notEditable={true}
                    handleClick={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        country: e,
                      });
                    }}
                  />
                  {/* <GeniustextInput
                    title={'Country'}
                    country={true}
                    option={countries}
                    width={windowSize > 764 ? '280px' : '140px'}
                    error={false}
                    value={companyInfo.country}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        country: t.target.value,
                      });
                    }}
                    maxLength={100}
                  /> */}
                </div>
                <div className="flex justify-between ml-3">
                  <GeniusSelectWithTitle
                    height={"160px"}
                    title={"State"}
                    width={windowSize > 764 ? "280px" : "140px"}
                    options={stateList}
                    value={companyInfo.state}
                    notEditable={true}
                    handleClick={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        state: e,
                      });
                    }}
                  />
                  {/* <GeniustextInput
                    title={'State'}
                    width={windowSize > 764 ? '280px' : '140px'}
                    error={false}
                    option={stateList}
                    value={companyInfo.state}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        state: t.target.value,
                      });
                    }}
                    maxLength={100}
                  /> */}
                </div>
              </div>
              <div className="flex">
                <div className="flex justify-between ">
                  <GeniusSelectWithTitle
                    height={"160px"}
                    title={"City"}
                    width={windowSize > 764 ? "280px" : "140px"}
                    options={cityList}
                    value={companyInfo.city}
                    notEditable={true}
                    handleClick={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        city: e,
                      });
                    }}
                  />
                  {/* <GeniustextInput
                    country={true}
                    option={cityList}
                    title={'City'}
                    width={windowSize > 764 ? '280px' : '140px'}
                    error={false}
                    value={companyInfo.city}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        city: t.target.value,
                      });
                    }}
                    maxLength={100}
                  /> */}
                </div>
                <div className="flex justify-between ml-3 ">
                  <GeniustextInput
                    type={"text"}
                    title={"Pin Code"}
                    width={windowSize > 764 ? "280px" : "140px"}
                    error={false}
                    value={companyInfo.pin_code}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        pin_code: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>
              </div>
              {userType === "ADMIN" && (
                <div className="flex justify-between  ">
                  <GeniustextInput
                    type={"number"}
                    title={"No. of Users Allowed"}
                    width={windowSize > 764 ? "280px" : "140px"}
                    error={false}
                    value={no_of_users}
                    onChange={(t) => {
                      setNumerOfUser(t.target.value);
                    }}
                    maxLength={100}
                  />
                  <div className="flex justify-between ml-2  ">
                    <GeniustextInput
                      type={"number"}
                      title={"No. of Users Active"}
                      width={windowSize > 764 ? "280px" : "140px"}
                      error={false}
                      value={activeUsers}
                      onChange={(t) => {
                        setActiveusers(t.target.value);
                      }}
                      maxLength={100}
                    />
                  </div>
                </div>
              )}

              <div className="h-[1px] flex  w-full bg-[#d2d2d7]"></div>

              <div className="flex mt-3">
                <div className="flex justify-between ">
                  <GeniusSelectWithTitle
                    height={"200px"}
                    title={"Currency Name"}
                    country={true}
                    width={windowSize > 764 ? "280px" : "140px"}
                    options={symbelList2}
                    value={companyInfo.currency_name}
                    notEditable={true}
                    handleClick={(e) => {
                      const s = symbelList2.filter(
                        (column) => column.value === e
                      );
                      setCompanyInfo({
                        ...companyInfo,
                        currency_name: e,
                        currency_symbol: s[0].symbol,
                      });
                    }}
                  />
                  {/* <GeniustextInput
                    type={'text'}
                    title={'Currency Name'}
                    width={windowSize > 764 ? '280px' : '140px'}
                    error={false}
                    value={companyInfo.currency_name}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        currency_name: t.target.value,
                      });
                    }}
                    maxLength={100}
                  /> */}
                </div>
                <div className="flex justify-between ml-3">
                  <GeniustextInput
                    type={"text"}
                    title={"Currency Symbol"}
                    width={windowSize > 764 ? "280px" : "140px"}
                    error={false}
                    value={companyInfo.currency_symbol}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        currency_symbol: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>
              </div>
              <div className="flex ">
                <div className="flex justify-between ">
                  <GeniustextInput
                    type={"text"}
                    title={"Regd No. -1"}
                    width={windowSize > 764 ? "280px" : "140px"}
                    error={false}
                    value={companyInfo.regd_no1}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        regd_no1: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>
                <div className="flex justify-between ml-3">
                  <GeniustextInput
                    type={"text"}
                    title={"Regd No. -2"}
                    width={windowSize > 764 ? "280px" : "140px"}
                    error={false}
                    value={companyInfo.regd_no2}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        regd_no2: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>
              </div>
              <div className="flex ">
                <div className="flex justify-between ">
                  <GeniustextInput
                    type={"text"}
                    title={"Regd No. -3"}
                    width={windowSize > 764 ? "280px" : "140px"}
                    error={false}
                    value={companyInfo.regd_no3}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        regd_no3: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>
                <div className="flex justify-between ml-3">
                  <GeniustextInput
                    type={"text"}
                    title={"Disputes"}
                    width={windowSize > 764 ? "280px" : "140px"}
                    error={false}
                    value={companyInfo.disputes}
                    onChange={(t) => {
                      setCompanyInfo({
                        ...companyInfo,
                        disputes: t.target.value,
                      });
                    }}
                    maxLength={100}
                  />
                </div>
              </div>

              {userType === "ADMIN" && (
                <div className="flex ">
                  <div className="flex justify-between ">
                    <GeniustextInput
                      type={"date"}
                      title={"From Date - license"}
                      width={windowSize > 764 ? "280px" : "140px"}
                      error={false}
                      readOnly={true}
                      value={moment(companyInfo.start_date).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={(t) => {
                        setCompanyInfo({
                          ...companyInfo,
                          start_date: moment(t.target.value).format(
                            "YYYY-MM-DD HH:mm:ss"
                          ),
                        });
                      }}
                      maxLength={100}
                    />
                  </div>
                  {console.log(companyInfo)}
                  <div className="flex justify-between ml-3">
                    <GeniustextInput
                      type={"date"}
                      title={"To Date - license"}
                      width={windowSize > 764 ? "280px" : "140px"}
                      error={false}
                      value={moment(companyInfo.end_date).format("YYYY-MM-DD")}
                      onChange={(t) => {
                        setCompanyInfo({
                          ...companyInfo,
                          end_date: moment(t.target.value).format(
                            "YYYY-MM-DD HH:mm:ss"
                          ),
                        });
                      }}
                      maxLength={100}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              borderTop: "1px solid #D2D2D7",
            }}
            className="flex justify-end bg-white py-5 rounded-b-sm  px-4"
          >
            <div className="mr-2">
              <UndoButton
                onClick={() => {
                  setCompanyInfo({
                    user_email: "",
                    user_password: "",
                    user_name: "",
                    user_type: "",
                    user_status: "",
                    company_email: "",
                    company_name: "",
                    company_mobile: "",
                    company_website: "",
                    company_logo: "",
                    address: "",
                    city: "",
                    state: "",
                    country: "",
                    pin_code: "",
                    currency_name: "",
                    currency_symbol: "",
                    regd_no1: "",
                    regd_no2: "",
                    regd_no3: "",
                    disputes: "",
                  });
                }}
              />
            </div>

            <SaveButton onClick={UpdateCompanyDetailsAPI} />
          </div>
        </div>
        <CompanyHelp
          onRowClick={(e) => {
            setCompanyDetails({
              address: e.row.address,
              city: e.row.city,
              state: e.row.state,
              mobile: e.row.company_mobile,
              email: e.row.company_email,
              companyName: e.row.company_name,
              companyWebsite: e.row.company_website,
            });
            setUserEmail(e.row.user_email);
            setCompanyhelpStatus(false);
          }}
          onClose={() => [setCompanyhelpStatus(false)]}
          status={companyHelpStatus}
        />
      </div>
    </div>
  );
};

export default CompanyInfo;
