import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';
import { selectCompanyAddress, selectEmail } from '../utility/Redux/profile';
import { setSpinnerLoading } from '../utility/Redux/modal';
import CallAPI from '../utility/functions/getData';
import DashboadBox from './box';
import ModUserInfo from '../utility/component/help/modUserInfo';

const GeniusDashboard = () => {
  const comapnyDetails = useSelector(selectCompanyAddress);
  const [companyList, setCompanyList] = useState([]);
  const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
  const [companyInfohelpStatus, setCompanyInfoHelpStatus] = useState(false);
  const [companyEmail, setCompanyEmail] = useState('');
  const [userEmail, setUserEmail] = useState(useSelector(selectEmail));
  const hmac = CryptoJS.HmacSHA256(comapnyDetails.email, secretPass);
  const hmacString = hmac.toString(CryptoJS.enc.Base64);
  const dispacth = useDispatch();
  useEffect(() => {
    const data = {
      company_email: comapnyDetails.email,
      company_mobile:""
    };

    dispacth(setSpinnerLoading('Loading'));
    CallAPI(
      '/api/users/companyHelp',
      data,
      hmacString,
      (e) => {
        if (!e.data.error) {
          setCompanyList(e.data.response);
        }
      },
      dispacth,
      () => {
        dispacth(setSpinnerLoading(false));
      }
    );
  }, []);

  return (
    <div className="h-full p-4 ">
      <div className="grid grid-cols-2 t:grid-cols-3 d:grid-cols-6 ll:grid-cols-6 gap-3  ">
        {companyList.map((e, i) => {
          return (
            <DashboadBox
              i={i}
              e={e}
              setCompanyEmail={setCompanyEmail}
              setCompanyInfoHelpStatus={setCompanyInfoHelpStatus}
              setUserEmail={setUserEmail}
            />
          );
        })}
        <ModUserInfo
          status={companyInfohelpStatus}
          setStatus={setCompanyInfoHelpStatus}
          company_email={companyEmail}
          user_email={userEmail}
          user={'MANAGER'}
        />
      </div>
    </div>
  );
};

export default GeniusDashboard;
