import moment from "moment";
import { FormatNumber2 } from "../../utility/functions/formatNumber";

export const PrintPurchaseSummaryColumns = (width) => {
  const columns = [
    {
      field: "INVNO",
      accessor: "INVNO",
      type: "string",
      headerAlign: "center",
      width: width < 768 ? 150 : width < 1400 ? 80 : 80,
      headerClassName: "super-app-theme--header",

      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Invoice
        </div>
      ),
    },

    {
      field: "PUR_BILL_DATE",
      accessor: "PUR_BILL_DATE",
      type: "date",
      headerAlign: "center",

      width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div
            className={` h-full w-full flex items-center  `}
          >
            {params.value ? moment(params.value).format("DD-MM-yyyy") : ""}
           
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Pur Bill Date
        </div>
      ),
    },
    {
      field: "BILL_TYPE",
      accessor: "BILL_TYPE",
      type: "string",
      width: 120,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Bill Type
        </div>
      ),
    },
    {
      field: "NAME",
      accessor: "NAME",
      type: "string",
      width: width < 768 ? 100 : width < 1400 ? 180 : 160,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center   `}>
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          {" "}
          Party Name
        </div>
      ),
    },


    {
      field: "ADDRESS",
      accessor: "ADDRESS",
      type: "string",
      width: width < 768 ? 100 : width < 1400 ? 130 : 130,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      renderCell: (param) => {
        return <div>{param.value}</div>;
      },
      renderHeader: (params) => (
        <div className="text-white" style={{ fontSize: 13 }}>
          Address
        </div>
      ),
    },
    {
      field: "DISQ_AMT",
      accessor: "DISQ_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 80,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Disc.
        </div>
      ),
    },

    {
      field: "TOTAL",
      accessor: "TOTAL",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 100,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Total
        </div>
      ),
    },
    {
      field: "TAX1_RATE",
      accessor: "TAX1_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 80,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value !== "0" ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          CGST %
        </div>
      ),
    },
    {
      field: "TAX1_AMT",
      accessor: "TAX1_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 110,
      align: "right",
      renderCell: (param) => {
        return (
          <div className={` h-full w-full  flex items-center  justify-end `}>
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          CGST
        </div>
      ),
    },
    {
      field: "TAX2_RATE",
      accessor: "TAX2_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 80,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== "0" ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          SGST %
        </div>
      ),
    },
    {
      field: "TAX2_AMT",
      accessor: "TAX2_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 110,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== 0 ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          SGST
        </div>
      ),
    },
    {
      field: "TAX3_RATE",
      accessor: "TAX3_RATE",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 80,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== "0" ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          IGST %
        </div>
      ),
    },
    {
      field: "TAX3_AMT",
      accessor: "TAX3_AMT",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: width < 768 ? 100 : 110,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value !== 0 ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },
      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  text-center" style={{ fontSize: 13 }}>
          IGST
        </div>
      ),
    },

    {
      field: "GTOTAL",
      accessor: "GTOTAL",
      type: "number",
      headerClassName: "super-app-theme--header",
      width: 110,
      align: "right",
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === "Void" && "text-[#e97a7a]"
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value ? FormatNumber2(param.value) : "0"}
          </div>
        );
      },

      headerAlign: "center",

      renderHeader: (params) => (
        <div className="text-white  pr-4 text-center" style={{ fontSize: 13 }}>
          Grand Total
        </div>
      ),
    },
  ];
  return columns;
};
