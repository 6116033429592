
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import './datagrid/print.css'
import { FormatNumber2 } from '../functions/formatNumber';
import Printcomp from './printcomp';
const GeniusTableForReports = ({
  date,
  date1,
  stationId,
  columns,
  data,
  title,
  Fotter,marginTop,
  header,
  headerWidth,
  customeHeader,filteredData
}) => {
  
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });


    const companyName = localStorage.getItem('company');
  const [finalFilterData,setFinalFilterData] = useState([])
 const [TotalFilterData,setTotalFilterData] = useState([])

useEffect(()=>{
  setTotalFilterData(rows[0] ? filteredData? [...rows ,{
    ...rows[0],
    values:{
      ...rows[0].values,
      ITEMNAME:"Total...",
      SECTION_NAME:'',
      ICODE:"",
      QNTY :data.reduce((acc, row) => acc + Number(row.QNTY), 0),
      AMOUNT :data.reduce((acc, row) => acc + Number(row.AMOUNT), 0),
      TAX1_AMT : data.reduce((acc, row) => acc + Number(row.TAX1_AMT), 0).toFixed(2),
      TAX2_AMT : data.reduce((acc, row) => acc + Number(row.TAX2_AMT), 0).toFixed(2),
      TAX3_AMT : data.reduce((acc, row) => acc + Number(row.TAX3_AMT), 0).toFixed(2),
      TAX1_RATE : "",
      TAX2_RATE : "",
 GTOTAL:data.reduce((acc, row) => acc + Number(row.GTOTAL), 0).toFixed(2),
      TAX3_RATE : "",
      PUR_PRICE:"",
      DISQ_AMT : data.reduce((acc, row) => acc + Number(row.DISQ_AMT), 0).toFixed(2),






    }
  }
  ]  :[] :[])
},[rows,finalFilterData,filteredData])



  useEffect(()=>{
  let listtt =  filteredData? filteredData[0] ? [...filteredData,
      {
        SECTION_NAME :'TOTAL..'
      }
      ] :[]:[]
  setFinalFilterData(listtt)
  },[filteredData])



   
  return (
    <div
    id="main"
    style={{ width: '100%', height: '100%', backgroundColor: 'white' }}
  >
          <div
        id="print-header"
        style={{
          width: '1200px',
          
height:'150px'
       
        }}
          className=" absolute top-0 left-0  px-4"
      >
    <div style={{
             width: '100%',
             height: '100%',
        }} className='pt-5'>
             <div style={{ width: '100%' }}>
             <p
            className="flex justify-center items-center font-semibold"
            style={{ fontSize: '20px' }}
          >
            {companyName}
          </p>
             </div>
             <div
          style={{ width: '100%',  boxSizing: 'border-box' }}
          className="  mt-1"
        >
          <p className="flex justify-center">
            {title ? title + ' ' : ''}{' '}
          </p>
          {date && (
            <>
              <input
                style={{ width: '9%' }}
                className="ml-3"
                value={date}
                readOnly
              />
              <p className="flex justify-center">To</p>

              <input
                style={{ width: '9%' }}
                className="ml-3"
                value={date1}
                readOnly
              />
            </>
          )}
       <div className='flex justify-center'>
          {customeHeader ? customeHeader :<div className='h-[150px]'>
            
            </div>}

          </div>
        </div>
        </div>
     
      </div>

      <div
        id="print-component"
        className={`${headerWidth ? `pt-[70px]` : 'pt-10'} px-4`}
        style={
          window.innerWidth < 600
            ? {
                width: '100%',
                height: 'auto',
                overflow: 'hidden',
                marginTop: '10%',

              }
            : {
                width: '100%',
                height: 'auto',
                overflow: 'hidden',
                marginTop: '6.5%',

              }
        }
      >
        <table
          {...getTableProps()}
          style={{ border: '1px solid black' }}
          className="w-full"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                style={{ fontSize: '12px' }}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column, index) => (
                  <td
                    {...column.getHeaderProps()}
                    className=" text-center py-2  overflow-hidden"
                    style={
                      columns[index].width
                        ? {
                            width: columns[index].width,
                            fontWeight: 500,
                            borderRight: '1px solid black',
                            borderBottom: '1px solid black',
                          }
                        : {
                            flex: columns[index].flex,
                            fontWeight: 500,
                            borderRight: '1px solid black',
                            borderBottom: '1px solid black',
                          }
                    }
                  >

                    {columns[index].renderHeader().props.children}
                  </td>
                ))}
              </tr>
            ))}
          </thead>
          <tbody style={{
            border: '1px solid black',
          }} className='' {...getTableBodyProps()}>
           
            {  !filteredData ? rows.map((row) => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '8px',
                        borderRight: '1px dashed black',
                        borderTop: '1px dashed black',
                        fontSize: '11px',
                      }}
                    >
                      <div
                        style={{
                          textAlign: cell.render('Cell').props.cell.column.align
                            ? cell.render('Cell').props.cell.column.align
                            : 'left',
                        }}
                        className="whitespace-nowrap"
                      >
                        {cell.render('Cell').props.cell.column.type === 'date'
                          ? moment(cell.render('Cell').props.cell.value).format(
                              'YYYY-MM-DD'
                            )
                          : cell.render('Cell').props.cell.value === '0.00' ||cell.render('Cell').props.cell.value === 0 
                          ? null
                          : cell.render('Cell')}
                      </div>
                    </td>
                  ))}
                </tr>
              );
            }) : <Printcomp finalFilterData={finalFilterData} TotalFilterData ={TotalFilterData} prepareRow={prepareRow} />}
          </tbody>
        </table>
        <div style={{
          width:columns.reduce((acc, row) => acc + Number(row.width), 0)
        }}>

        {Fotter ? Fotter : ''}
        </div>

        <div className="w-full flex justify-between">
          <div
            style={{
              fontSize: '12px',
            }}
            className="flex  pt-2"
          >
            <span style={{ fontSize: '10px', color: '#3b3a39' }}>
              {' '}
              Powered by{' '}
            </span>{' '}
            <span
              style={{ fontSize: '11px', color: '#171716' }}
              className="pl-1"
            >
              geniusoffice{' '}
            </span>
          </div>
          <div className="flex pt-2 items-center ">
            <div
              style={{
                fontSize: '11px',
                color: '#3b3a39',
                fontWeight: '600px',
              }}
            >
              Date :{' '}
            </div>

            <div
              style={{
                fontSize: '10px',
                color: '#3b3a39',
                fontWeight: '500px',
              }}
              className="pl-1"
            >
              {moment().format('YYYY-MM-DD HH:mm ')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeniusTableForReports;
